import React, { Fragment } from "react";
import styles from "../../../css/style.module.css";
import { Collapse, IconButton } from "@material-ui/core";
import { Offer, OfferStatus } from "../../../requests/offers";
import moment from "moment";
import { OffersTd as Td, OffersTr as Tr } from "./styled";
import { RectangleBadgeFilled } from "../../../componentsV3/styled-components/badges";
import { styledTheme } from "../../../themesV3/styledTheme";
import OfferCardsBlock from "./offer-cards-block";
import Downarrow from "../../../assets/images/arrow/down-black-arrow.svg"
import CrossImg from "../../../assets/images/arrow/cross.svg"
import UpArrow from "../../../assets/images/arrow/uparrow.svg"
import Dash from "../../../assets/images/arrow/dash.svg"

const bg = {
  pending: "#DFE6FF",
  cancelled: "#FFE3D5",
  declined: styledTheme.colors.lightYellow,
  accepted: "lightGreen",
};

const colors = {
  pending: "#1E2C93",
  cancelled: "#9A1214",
  declined: styledTheme.colors.darkYellow,
  accepted: "green",
};

interface OfferRowProps {
  offer: Offer;
  index?: number;
  onCancelClick: (offerId: number) => void;
}
function OfferRow({ offer, index, onCancelClick }: OfferRowProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <Fragment>
      <Tr backgroundColor={index && index % 2 ? "#f5f6fa" : "#ffffff"}>

        <Td>{offer.id}</Td>
        <Td>
          {moment(offer.createdAt).format("YYYY/MM/DD")}
          <span style={{ color: "#9a9a9a", paddingLeft: 15 }}>{moment(offer.createdAt).format("HH:mm")}</span>
        </Td>
        <Td><div className={styles.swapuser}>{offer.outboundUser?.fullName}</div>{offer.outboundShift.shiftName}</Td>
        <Td><div className={styles.swapuser}>{offer.inboundUser?.fullName}</div>{offer.inboundShift.shiftName}</Td>
        <Td>
          <RectangleBadgeFilled backgroundColor={bg[offer.status]} color={colors[offer.status]}>
            {offer.status}
          </RectangleBadgeFilled>
        </Td>
        <Td>
          {offer.status === OfferStatus.PENDING ? (
            <IconButton className={styles.canclebtn} disableRipple onClick={() => onCancelClick(offer.id)} ><img src={CrossImg} alt="rooster" />Cancel offer</IconButton>
          ) : <img src={Dash} alt="rooster" />}
        </Td>
        <Td>
          <IconButton
            disableRipple
            aria-label={`Offer ${offer.id} row icon`}
            onClick={() => setOpen(!open)}
          >
            {open ? <img src={UpArrow} alt="rooster" /> : <img src={Downarrow} alt="rooster" />}
          </IconButton>
        </Td>
      </Tr>
      <Tr style={{ borderBottom: '1px solid #e6e6e6' }}>
        <Td colSpan={7} style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <OfferCardsBlock offer={offer} onCancelClick={onCancelClick} />
          </Collapse>
        </Td>
      </Tr>
    </Fragment>
  );
}

export default OfferRow;
