import React from "react";
import styled from "styled-components";
import arrowLeft from "../../../assets/images/arrow/arrow-left.svg";
import arrowRight from "../../../assets/images/arrow/arrow-right.svg";

const Wrapper = styled.div`
  width: 30px;
  height: 25px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
  }
`;

export const DateNavButtonText = styled.div`
  height: 30px;
  width: fit-content;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  color: #282828;
  align-items: center;
  padding: 0 4px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s;
`;

export const DateNavText = styled.div`
  height: 30px;
  width: fit-content;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  font-size: 16px;
  font-weight: 600;
  color: #282828;
`;

const Img = styled.img`
  height: auto;
  width: auto;
`;

interface ArrowButtonProps {
  onClick: () => void;
}

export function DateNavButtonLeft({ onClick }: ArrowButtonProps) {
  return (
    <Wrapper onClick={onClick}>
      <Img alt="arrow-left" src={arrowLeft} />
    </Wrapper>
  );
}

export function DateNavButtonRight({ onClick }: ArrowButtonProps) {
  return (
    <Wrapper onClick={onClick}>
      <Img alt="arrow-right" src={arrowRight} />
    </Wrapper>
  );
}

// export function DateNavButtonText({ children }: { children: string }) {
//   return <TextWrapper>{children}</TextWrapper>;
// }
