import { Box, Grid, Typography } from "@material-ui/core";
import { Field, Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import * as yup from "yup";
import styles from "../../../css/model.module.css";
import { postImportTemplate } from "../../../requests/roster";
import { Roster } from "../../../screens/RosterScreen/requests";
import {
  TemplateMini,
  requestGetTemplates,
} from "../../../screens/Templates/requests";
import StandardButton from "../../Buttons/StandardButton";
import { OnRangeCalendarSelectedProps } from "../../calendars/RangeCalendar";
import DateRangeSelectorFormik from "../../inputs/Dates/DateRangeSelectorFormik";
import MultipleSelectFormik from "../../inputs/Selectors/MultipleSelectFormik";
import ModalContainer from "../ModalContainer";
import ModalWindow from "../ModalWindow";
import { ModalBaseProps } from "../interfaces";
import useStyles from "./styles";

interface ImportTemplateModalProps extends ModalBaseProps {
  onActionSuccess?: () => void;
  onActionError?: (error: Error) => void;
  rosterNames: Array<Roster>;
  selectedRoster: any;
}

export interface ImportTemplateFormValues {
  roster: "" | number;
  templates: Array<TemplateMini>;
  dates: OnRangeCalendarSelectedProps | undefined;
}

const validationSchema = yup.object().shape({
  // roster: yup.number().required("no roster selected"),
  templates: yup
    .array(yup.mixed())
    .required("no template selected")
    .min(1, "no template selected"),
  dates: yup.lazy((value) => {
    const v: OnRangeCalendarSelectedProps | undefined =
      value as OnRangeCalendarSelectedProps;
    if (v && "single" in v) {
      return yup.object({
        single: yup.object().shape({
          day: yup.number().required(),
          month: yup.number().required(),
          year: yup.number().required(),
        }),
      });
    }
    return yup.object({
      range: yup.object().shape({
        leftDate: yup.object().shape({
          day: yup.number().required(),
          month: yup.number().required(),
          year: yup.number().required(),
        }),
        rightDate: yup.object().shape({
          day: yup.number().required(),
          month: yup.number().required(),
          year: yup.number().required(),
        }),
      }),
    });
  }),
});

const ImportTemplateModal = ({
  onClose,
  open = false,
  rosterNames,
  onActionSuccess,
  selectedRoster,
  onActionError,
}: ImportTemplateModalProps) => {
  const classes = useStyles();

  const [templates, settemplates] = useState<Array<TemplateMini>>([]);

  const getTempaltes = useCallback(async () => {
    try {
      const result = await requestGetTemplates();
      settemplates(result);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getTempaltes();
  }, [getTempaltes]);

  const submit = async (v: ImportTemplateFormValues) => {
    try {
      await postImportTemplate({ ...v, roster: selectedRoster });
      if (onActionSuccess) {
        onActionSuccess();
      }
    } catch (err) {
      console.log(err);
      if (onActionError) {
        onActionError(err);
      }
    }
  };

  return (
    <ModalContainer onClose={onClose} open={open}>
      <ModalWindow>
        <Formik
          initialValues={{ roster: "", templates: [], dates: undefined }}
          onSubmit={submit}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({
            errors,
            isValid,
            dirty,
            isSubmitting,
            resetForm,
            setFieldValue,
          }: FormikProps<ImportTemplateFormValues>) => (
            <Form noValidate autoComplete="off">
              <Box className={styles.container}>
                <Box>
                  <Box className={styles.titlebox}>
                    <BsArrowLeftShort
                      className={styles.backicon}
                      onClick={onClose}
                    />
                    <Typography
                      variant="h5"
                      align="center"
                      className={styles.title}
                    >
                      Import Template
                    </Typography>
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box className={classes.leftSideContainer}>
                        <Typography color="textSecondary">
                          Select Templates
                        </Typography>
                        <Box className={classes.selectorButtonsOuterHolder}>
                          <Field
                            component={MultipleSelectFormik}
                            name="templates"
                            options={templates.map((t) => ({
                              fullName: t.name,
                              value: t.id,
                            }))}
                            onSelectedValueChange={(rosterId: any) => {
                              setFieldValue("templates", rosterId);
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <DateRangeSelectorFormik name="dates" />
                    </Grid>
                  </Grid>
                </Box>
                <Box className={styles.buttonsholder}>
                  <Box
                    className={`${styles.submitbuttonsholder} ${styles.fillbtn}`}
                  >
                    <Box className={styles.cancelbuttonmargin}>
                      <StandardButton
                        title="Cancel"
                        className={"secondary"}
                        onClick={() => {
                          resetForm();
                          onClose();
                        }}
                      />
                    </Box>
                    <StandardButton
                      title="Import"
                      type="submit"
                      accent
                      disabled={isSubmitting || !isValid || !dirty}
                    />
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </ModalWindow>
    </ModalContainer>
  );
};

export default ImportTemplateModal;
