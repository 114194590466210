import { Input, Select } from "@material-ui/core";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import { getUserDetails, putUserDetails } from "../../../requests/user";
import { User } from "../../StaffScreen";

const Form = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.greyishBrown};
  margin-bottom: ${({ theme }) => theme.gap[1]};
`;

const InputOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputsHolder = styled.div`
  display: flex;
  flex-direction: row;
`;

const Spacer = styled.div`
  width: ${({ theme }) => theme.gap[1]};
`;

const ButtonHolder = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

interface FormInputs {
  code?: string | null;
  mobileNumber?: string | null;
}

function DoNotDisturbForm() {
  const { enqueueSnackbar } = useSnackbar();

  const options = [
    { value: "", text: "None" },
    { value: "64", text: "+64" },
    { value: "61", text: "+61" },
  ];

  const [code, setCode] = useState(options[0].value);

  const [mobileNumber, setMobileNumber] = useState("");

  const { data, refetch } = useQuery<User, AxiosError>(
    "user-details",
    getUserDetails,
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get user details`,
          { variant: "warning" }
        );
      },
    }
  );

  const { mutate } = useMutation<unknown, AxiosError, FormInputs>(
    (values) => putUserDetails(values),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to save contact information`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        await refetch();
        enqueueSnackbar(`Contact information saved`, {
          variant: "success",
        });
      },
    }
  );

  useEffect(() => {
    if (data) {
      setCode(data.mobileCode ? data.mobileCode + "" : "");
      setMobileNumber(data.mobileNumber ? data.mobileNumber + "" : "");
    }
  }, [data]);

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    mutate({
      code: code || null,
      mobileNumber: mobileNumber.replace(/^0+/, "") || null,
    });
  }

  const handleChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ): void => {
    setCode(event.target.value as string);
  };

  return (
    <Form onSubmit={onSubmit}>
      <InputOuterContainer>
        <Label>Code</Label>
        <InputsHolder>
          <Select name="code" value={code} onChange={handleChange}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </Select>
        </InputsHolder>
      </InputOuterContainer>
      <Spacer />
      <Spacer />
      <Spacer />

      <InputOuterContainer>
        <Label>Mobile Number</Label>
        <InputsHolder>
          <Input
            name="mobileNumber"
            type="number"
            inputMode="numeric"
            defaultValue={""}
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
        </InputsHolder>
      </InputOuterContainer>
      <ButtonHolder>
        <StandardButton type="submit" title="Save" accent />
      </ButtonHolder>
    </Form>
  );
}

export default DoNotDisturbForm;
