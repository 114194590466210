import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import styles from "../../css/style.module.css"
import style from "./style.module.css";
import { BsArrowLeftShort } from 'react-icons/bs';
import TemplateListItem from "./TemplateListItem";
import { Box, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ModalContainer from "../../componentsV3/modals/ModalContainer";
import { useSnackbar } from "notistack";
import CreateTemplateWindow, {
  CreateTemplateWindowSubmitValues,
} from "./CreateTemplateWindow";
import {
  createNewTemplate,
  requestGetTemplates,
  GetTemplatesResponse,
  TemplateMini,
  editTemplateName,
  deleteTemplate,
} from "./requests";
import { useQuery } from "react-query";
import StandardButton from "../../componentsV3/Buttons/StandardButton";
import { RWrapper } from "../../componentsV3/layout/RWrapper";
import Spinner from "../../componentsV3/Spinner";

const templateExistsMessage = "Template already exists!";

export default () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [modalOpen, setmodalOpen] = useState<boolean>(false);
  const [apiError, setapiError] = useState<string>("");
  const [currentTemplateId, setcurrentTemplateId] = useState<
    number | undefined
  >(undefined);
  const [currentTemplateName, setcurrentTemplateName] = useState<string>("");

  const [templates, settemplates] = useState<GetTemplatesResponse>([]);

  const { isLoading } = useQuery(
    "requestGetTemplates",
    async () => requestGetTemplates(),
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar({ variant: "error" });
      },
    }
  );

  useEffect(() => {
    let timer = setTimeout(() => {
      setapiError("");
    }, 2000);
    return () => clearTimeout(timer);
  }, [apiError]);

  useEffect(() => {
    _getTemplates();
  }, []);

  const _defineCurrentTemplateName = useCallback(
    (tID?: number) => {
      try {
        if (tID && templates.length) {
          const te = templates.find((t) => t.id === tID);
          const name = te?.name;
          setcurrentTemplateName(tID && name ? name : "");
        } else {
          if (currentTemplateName) setcurrentTemplateName("");
        }
      } catch (error) {
        console.debug(error);
      }
    },
    [currentTemplateName, templates]
  );

  // to keep current template name updated
  useEffect(() => {
    _defineCurrentTemplateName(currentTemplateId);
  }, [currentTemplateId, _defineCurrentTemplateName]);

  const _submitTemplate = async ({
    name,
  }: CreateTemplateWindowSubmitValues) => {
    if (currentTemplateId) {
      try {
        const editedTemplateId = await editTemplateName(
          currentTemplateId,
          name
        );
        const updatedTemplates = templates.map((t) => {
          if (t.id === editedTemplateId) {
            t.name = name;
          }
          return t;
        });
        settemplates(updatedTemplates);
        _closeModal();
      } catch (error) {
        console.error(error);
        const { status } = error.response;
        if (status === 409) {
          setapiError(templateExistsMessage);
        }
      }
    } else {
      try {
        const templateId: number = await createNewTemplate(name);
        _openTemplate(templateId);
      } catch (error) {
        console.error(error);
        const { status } = error.response;
        if (status === 409) {
          setapiError(templateExistsMessage);
        }
      }
    }
  };

  const _getTemplates = async () => {
    try {
      const t = await requestGetTemplates();
      settemplates(t);
    } catch (error) {
      console.error(error);
    }
  };

  const _openTemplate = (id: number) => {
    history.push(`/templates/${id}`);
  };

  const _deleteTemplate = async (id: number) => {
    try {
      const deletedId: number = await deleteTemplate(id);
      const filtered = templates.filter((t) => t.id !== deletedId);
      settemplates(filtered);
      _closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const _openModal = (id?: number) => {
    if (id) {
      setcurrentTemplateId(id);
    }
    setmodalOpen(true);
  };

  const _closeModal = () => {
    setcurrentTemplateId(undefined);
    setmodalOpen(false);
  };

  if ( isLoading ) {
    return <Spinner/>;
  }
  return (
    <RWrapper>
      <ModalContainer onClose={_closeModal} open={modalOpen}>
        <CreateTemplateWindow
          onSubmit={_submitTemplate}
          onDelete={_deleteTemplate}
          apiError={apiError}
          onCancel={_closeModal}
          templateId={currentTemplateId}
          templateName={currentTemplateName}
        />
      </ModalContainer>

      <Box className={styles.pagetitlebox}>
        <Box display='flex' alignItems='center'>
          <BsArrowLeftShort className={styles.backicon} onClick={() => history.push(`/`)}/>
          <Typography variant="h4" color="textPrimary">Templates</Typography>
        </Box>
        <Box className={styles.rightSide}>    
          <Box className={styles.fillbtn}>    
            <IconButton onClick={() => _openModal()}>
              <svg width="12" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z" fill="white"/>
              </svg>
              Create New
            </IconButton>
          </Box>
        </Box>
      </Box>

      {!templates.length ? (
        <div className={style.notemplatescontainer}>
          <span className={style.notemplatestext}>No Templates</span>
          <StandardButton
            title="Create a Template"
            accent
            onClick={() => _openModal()}
          />
        </div>
      ) : (
        <div className={`${style.templatelistcontainer} ${styles.commonbox}`}>
          {templates.map((template: TemplateMini) => (
            <TemplateListItem
              key={template.id}
              title={template.name}
              id={template.id}
              onClick={_openTemplate}
              onOptionsClick={_openModal}
            />
          ))}
        </div>
      )}
    </RWrapper>
  );
};
