import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: "pointer",
    height: "100%",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    boxSizing: "border-box",
    backgroundColor: ({
      checked,
      color,
    }: {
      checked: boolean;
      color?: string;
    }) => {
      try {
        if (!color) {
          return checked ? theme.palette.primary.main : "transparent";
        } else {
          return checked ? color : "transparent";
        }
      } catch (err) {
        return checked ? theme.palette.primary.main : "transparent";
      }
    },
    border: ({ checked }: any) => {
      return checked ? `none` : `1px solid ${theme.palette.grey[300]}`;
    },
    transition: "all 0.2s",
  },
}));

interface CheckBoxFormikProps {
  name: string;
  color?: string;
}

export default function CheckBoxFormik({ name, color }: CheckBoxFormikProps) {
  const [, meta, helpers] = useField(name);
  const c = useStyles({ checked: meta.value, color });
  const { setValue } = helpers;

  const clickHandler = () => {
    setValue(!meta.value);
  };

  return <div className={c.container} onClick={clickHandler} />;
}
