import { authorizedRequest } from "../../../httprequests";

interface DoChangePasswordRequest {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

interface DoChangePasswordResponse {}

export interface DoChangePasswordReturn {}

export const requestChangePassword = (
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
) => {
  return authorizedRequest.post<
    DoChangePasswordRequest,
    DoChangePasswordResponse
  >("/auth/changepassword", {
    old_password: oldPassword,
    new_password: newPassword,
    confirm_password: confirmPassword,
  });
};
