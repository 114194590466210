import { authorizedRequest } from "../httprequests";

export interface ListEmail {
  id: number;
  uuid: string;
  sentAt: Date;
  fromEmail: string;
  toEmail: string;
  subject: string;
  body: string;
  status: "sent" | "failed" | "queued";
  order: "DESC" | "ASC";
  limit: number,
  page: number,
  search: string,
}
export interface GetListEmailResponse {
  data: Array<ListEmail>;
  totalData: number;
  searchedCount: number;
}
export function getListEmail(
  limit: number,
  page: number,
  search?: string,
  status?: "sent" | "queued" | "failed",
  order?: "DESC" | "ASC",
) {
  return authorizedRequest.get<GetListEmailResponse>(
    `/email/get-emails/order/${order}/status/${status || ""}?limit=${limit}&page=${page}&search=${search}`
  );
}