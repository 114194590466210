import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const mainTheme = (palleteType: "light" | "dark" = "light") =>
  createMuiTheme({
    palette: {
      type: palleteType,
      background: {
        default: palleteType === "dark" ? "#303030" : "#f7f7f7",
        paper: palleteType === "dark" ? "#424242" : "#ffffff",
      },
      primary: {
        main: "#0583f2",
      },
    },
    typography: {
      fontSize: 12,
    },
    shape: {
      borderRadius: 2,
    },
  });

export default responsiveFontSizes(mainTheme("light"));