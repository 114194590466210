import React from "react";
import { RWrapper } from "../../../components/layout/RWrapper";
import styled from "styled-components";
import { Typography, Divider, Grid, Box } from "@material-ui/core";
import EmailTemplateForm from "./template-form";
import SmsTimeOffsetForm from "./sms-time-offset-form";
import TenantLogoForm from "./tenant-logo";
import RoosterTheme from "./RoosterTheme";
import TwoFAForm from "../../../screens/adminView/settings/two-factor-auth-form";

const TitleBox = styled.div`
  margin: 0.5em 0;
`;

const ContentContainer = styled.div`
  margin-top: 8px;
`;

const Spacer = styled.div`
  height: ${({ theme }) => theme.gap[1]};
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.default};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding: ${({ theme }) => theme.gap[1]};
  margin-bottom: ${({ theme }) => theme.gap[1]};
  color: ${({ theme }) => theme.colors.grey};
`;

const DescriptionRow = styled.div`
  height: 20px;
  margin: 2px 0;
`;

const Interpolated = styled.span`
  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  color: ${({ theme }) => theme.colors.base.white};
  padding: 2px 4px;
  user-select: text;
  // cursor: pointer;
`;

function SettingsScreen() {
  return (
    <RWrapper>
      <TitleBox>
        <Typography variant="h4" color="textPrimary">
          Settings
        </Typography>
      </TitleBox>
      <Divider />
      <ContentContainer>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <EmailTemplateForm
              templateName="welcome_email"
              userFriendlyTemplateName="Welcome email"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{reset_password_link}}"}</Interpolated> -
                    URL to the reset password page
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the new user
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="shift_became_available_issuer_email"
              userFriendlyTemplateName="Shift became available email (issuer)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the owner of the shift
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_available_status}}"}</Interpolated>{" "}
                    - Shift availability status
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_name}}"}</Interpolated> - Shift name
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_dts}}"}</Interpolated> - Shift
                    start dts (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_date}}"}</Interpolated> -
                    Shift start date (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_time}}"}</Interpolated> -
                    Shift start time (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_dts}}"}</Interpolated> - Shift
                    end dts (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_date}}"}</Interpolated> - Shift
                    end date (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_time}}"}</Interpolated> - Shift
                    end time (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{location}}"}</Interpolated> - Shift
                    location
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="shift_available_taken_issuer_email"
              userFriendlyTemplateName="Available shift taken email (issuer)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the owner of the shift
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_available_taken_by}}"}
                    </Interpolated>{" "}
                    - Full name of the recipient who has taken the available
                    shift
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_available_status}}"}</Interpolated>{" "}
                    - Shift availability status
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_name}}"}</Interpolated> - Shift name
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_dts}}"}</Interpolated> - Shift
                    start dts (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_date}}"}</Interpolated> -
                    Shift start date (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_time}}"}</Interpolated> -
                    Shift start time (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_dts}}"}</Interpolated> - Shift
                    end dts (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_date}}"}</Interpolated> - Shift
                    end date (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_time}}"}</Interpolated> - Shift
                    end time (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{location}}"}</Interpolated> - Shift
                    location
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="swap_request_placed_issuer_email"
              userFriendlyTemplateName="Swap request placed email (issuer)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the recipient of the email (issuer of the offer)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_name}}"}</Interpolated> -
                    Shift name which is going to be swapped
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_start_dts}}"}</Interpolated>{" "}
                    - Start dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_dts}}"}</Interpolated> -
                    End dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_date}}"}</Interpolated>{" "}
                    - End date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_time}}"}</Interpolated>{" "}
                    - End time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_location}}"}</Interpolated>{" "}
                    - Locations of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_owner}}"}</Interpolated> -
                    Owner of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_with_name}}"}</Interpolated>{" "}
                    - Name of the shift to swap with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_dts}}"}
                    </Interpolated>{" "}
                    - Start dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_dts}}"}
                    </Interpolated>{" "}
                    - End dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_date}}"}
                    </Interpolated>{" "}
                    - End date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_time}}"}
                    </Interpolated>{" "}
                    - End time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_location}}"}
                    </Interpolated>{" "}
                    - Locations of the shift to be swapped with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_owner}}"}
                    </Interpolated>{" "}
                    - Owner of the shift to be swapped with
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="swap_request_cancelled_issuer_email"
              userFriendlyTemplateName="Swap request cancelled email (issuer)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the recipient of the email (issuer of the offer)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_name}}"}</Interpolated> -
                    Shift name which is going to be swapped
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_start_dts}}"}</Interpolated>{" "}
                    - Start dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_dts}}"}</Interpolated> -
                    End dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_date}}"}</Interpolated>{" "}
                    - End date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_time}}"}</Interpolated>{" "}
                    - End time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_location}}"}</Interpolated>{" "}
                    - Locations of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_owner}}"}</Interpolated> -
                    Owner of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_with_name}}"}</Interpolated>{" "}
                    - Name of the shift to swap with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_dts}}"}
                    </Interpolated>{" "}
                    - Start dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_dts}}"}
                    </Interpolated>{" "}
                    - End dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_date}}"}
                    </Interpolated>{" "}
                    - End date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_time}}"}
                    </Interpolated>{" "}
                    - End time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_location}}"}
                    </Interpolated>{" "}
                    - Locations of the shift to be swapped with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_owner}}"}
                    </Interpolated>{" "}
                    - Owner of the shift to be swapped with
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="swap_request_cancelled_recipient_email"
              userFriendlyTemplateName="Swap request cancelled email (recipient)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the recipient of the email (issuer of the offer)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_name}}"}</Interpolated> -
                    Shift name which is going to be swapped
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_start_dts}}"}</Interpolated>{" "}
                    - Start dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_dts}}"}</Interpolated> -
                    End dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_date}}"}</Interpolated>{" "}
                    - End date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_time}}"}</Interpolated>{" "}
                    - End time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_location}}"}</Interpolated>{" "}
                    - Locations of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_owner}}"}</Interpolated> -
                    Owner of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_with_name}}"}</Interpolated>{" "}
                    - Name of the shift to swap with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_dts}}"}
                    </Interpolated>{" "}
                    - Start dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_dts}}"}
                    </Interpolated>{" "}
                    - End dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_date}}"}
                    </Interpolated>{" "}
                    - End date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_time}}"}
                    </Interpolated>{" "}
                    - End time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_location}}"}
                    </Interpolated>{" "}
                    - Locations of the shift to be swapped with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_owner}}"}
                    </Interpolated>{" "}
                    - Owner of the shift to be swapped with
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="swap_request_declined_recipient_email"
              userFriendlyTemplateName="Swap request declined email (recipient)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the recipient of the email (user declined the offer)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_name}}"}</Interpolated> -
                    Shift name which is going to be swapped
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_start_dts}}"}</Interpolated>{" "}
                    - Start dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_dts}}"}</Interpolated> -
                    End dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_date}}"}</Interpolated>{" "}
                    - End date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_time}}"}</Interpolated>{" "}
                    - End time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_location}}"}</Interpolated>{" "}
                    - Locations of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_owner}}"}</Interpolated> -
                    Owner of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_with_name}}"}</Interpolated>{" "}
                    - Name of the shift to swap with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_dts}}"}
                    </Interpolated>{" "}
                    - Start dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_dts}}"}
                    </Interpolated>{" "}
                    - End dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_date}}"}
                    </Interpolated>{" "}
                    - End date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_time}}"}
                    </Interpolated>{" "}
                    - End time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_location}}"}
                    </Interpolated>{" "}
                    - Locations of the shift to be swapped with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_owner}}"}
                    </Interpolated>{" "}
                    - Owner of the shift to be swapped with
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="swap_request_accepted_recipient_email"
              userFriendlyTemplateName="Swap request accepted email (recipient)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the recipient of the email (user accepted the offer)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_name}}"}</Interpolated> -
                    Shift name which is going to be swapped
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_start_dts}}"}</Interpolated>{" "}
                    - Start dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_dts}}"}</Interpolated> -
                    End dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_date}}"}</Interpolated>{" "}
                    - End date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_time}}"}</Interpolated>{" "}
                    - End time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_location}}"}</Interpolated>{" "}
                    - Locations of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_owner}}"}</Interpolated> -
                    Owner of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_with_name}}"}</Interpolated>{" "}
                    - Name of the shift to swap with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_dts}}"}
                    </Interpolated>{" "}
                    - Start dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_dts}}"}
                    </Interpolated>{" "}
                    - End dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_date}}"}
                    </Interpolated>{" "}
                    - End date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_time}}"}
                    </Interpolated>{" "}
                    - End time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_location}}"}
                    </Interpolated>{" "}
                    - Locations of the shift to be swapped with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_owner}}"}
                    </Interpolated>{" "}
                    - Owner of the shift to be swapped with
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
          </Grid>

          <Grid item xs={6}>
            <EmailTemplateForm
              templateName="reset_password_email"
              userFriendlyTemplateName="Reset password email"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{reset_password_link}}"}</Interpolated> -
                    URL to the reset password page
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="shift_became_unavailable_issuer_email"
              userFriendlyTemplateName="Shift became unavailable email (issuer)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the owner of the shift
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_available_status}}"}</Interpolated>{" "}
                    - Shift availability status
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_name}}"}</Interpolated> - Shift name
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_dts}}"}</Interpolated> - Shift
                    start dts (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_date}}"}</Interpolated> -
                    Shift start date (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_time}}"}</Interpolated> -
                    Shift start time (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_dts}}"}</Interpolated> - Shift
                    end dts (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_date}}"}</Interpolated> - Shift
                    end date (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_time}}"}</Interpolated> - Shift
                    end time (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{location}}"}</Interpolated> - Shift
                    location
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="shift_available_taken_recipient_email"
              userFriendlyTemplateName="Available shift taken email (recipient)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the recipient of the email (user who took the shift)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_available_status}}"}</Interpolated>{" "}
                    - Shift availability status
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_name}}"}</Interpolated> - Shift name
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_dts}}"}</Interpolated> - Shift
                    start dts (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_date}}"}</Interpolated> -
                    Shift start date (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_time}}"}</Interpolated> -
                    Shift start time (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_dts}}"}</Interpolated> - Shift
                    end dts (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_date}}"}</Interpolated> - Shift
                    end date (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_time}}"}</Interpolated> - Shift
                    end time (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{location}}"}</Interpolated> - Shift
                    location
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="swap_request_placed_recipient_email"
              userFriendlyTemplateName="Swap request placed email (recipient)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the recipient of the email (user who was offered the
                    swap)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_name}}"}</Interpolated> -
                    Shift name which is going to be swapped
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_start_dts}}"}</Interpolated>{" "}
                    - Start dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_dts}}"}</Interpolated> -
                    End dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_date}}"}</Interpolated>{" "}
                    - End date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_time}}"}</Interpolated>{" "}
                    - End time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_location}}"}</Interpolated>{" "}
                    - Locations of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_owner}}"}</Interpolated> -
                    Owner of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_with_name}}"}</Interpolated>{" "}
                    - Name of the shift to swap with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_dts}}"}
                    </Interpolated>{" "}
                    - Start dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_dts}}"}
                    </Interpolated>{" "}
                    - End dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_date}}"}
                    </Interpolated>{" "}
                    - End date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_time}}"}
                    </Interpolated>{" "}
                    - End time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_location}}"}
                    </Interpolated>{" "}
                    - Locations of the shift to be swapped with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_owner}}"}
                    </Interpolated>{" "}
                    - Owner of the shift to be swapped with
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="swap_request_declined_issuer_email"
              userFriendlyTemplateName="Swap request declined email (issuer)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the recipient of the email (issuer of the offer)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_name}}"}</Interpolated> -
                    Shift name which is going to be swapped
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_start_dts}}"}</Interpolated>{" "}
                    - Start dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_dts}}"}</Interpolated> -
                    End dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_date}}"}</Interpolated>{" "}
                    - End date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_time}}"}</Interpolated>{" "}
                    - End time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_location}}"}</Interpolated>{" "}
                    - Locations of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_owner}}"}</Interpolated> -
                    Owner of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_with_name}}"}</Interpolated>{" "}
                    - Name of the shift to swap with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_dts}}"}
                    </Interpolated>{" "}
                    - Start dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_dts}}"}
                    </Interpolated>{" "}
                    - End dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_date}}"}
                    </Interpolated>{" "}
                    - End date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_time}}"}
                    </Interpolated>{" "}
                    - End time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_location}}"}
                    </Interpolated>{" "}
                    - Locations of the shift to be swapped with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_owner}}"}
                    </Interpolated>{" "}
                    - Owner of the shift to be swapped with
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
            <EmailTemplateForm
              templateName="swap_request_accepted_issuer_email"
              userFriendlyTemplateName="Swap request accepted email (issuer)"
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    name of the recipient of the email (user who created the
                    offer)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_name}}"}</Interpolated> -
                    Shift name which is going to be swapped
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_start_dts}}"}</Interpolated>{" "}
                    - Start dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_dts}}"}</Interpolated> -
                    End dts of the shift to swap (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_date}}"}</Interpolated>{" "}
                    - End date of the shift to swap (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_end_time}}"}</Interpolated>{" "}
                    - End time of the shift to swap (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_location}}"}</Interpolated>{" "}
                    - Locations of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_owner}}"}</Interpolated> -
                    Owner of the shift to swap
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_to_swap_with_name}}"}</Interpolated>{" "}
                    - Name of the shift to swap with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_dts}}"}
                    </Interpolated>{" "}
                    - Start dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_date}}"}
                    </Interpolated>{" "}
                    - Start date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_start_time}}"}
                    </Interpolated>{" "}
                    - Start time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_dts}}"}
                    </Interpolated>{" "}
                    - End dts of the shift to be swapped with (full date and
                    time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_date}}"}
                    </Interpolated>{" "}
                    - End date of the shift to be swapped with (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_end_time}}"}
                    </Interpolated>{" "}
                    - End time of the shift to be swapped with (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_location}}"}
                    </Interpolated>{" "}
                    - Locations of the shift to be swapped with
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>
                      {"{{shift_to_swap_with_owner}}"}
                    </Interpolated>{" "}
                    - Owner of the shift to be swapped with
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
          </Grid>
        </Grid>
      </ContentContainer>
      <Divider />
      <ContentContainer>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SmsTimeOffsetForm />
            <Spacer />
            <EmailTemplateForm
              templateName="shift_soon_reminder_sms"
              userFriendlyTemplateName="Shift reminder sms"
              toggleVisible
              description={
                <Description>
                  <DescriptionRow>
                    Interpolation values for the body of the email:
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{recipient_name}}"}</Interpolated> - Full
                    the recipient
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_name}}"}</Interpolated> - Shift name
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_dts}}"}</Interpolated> - Shift
                    start dts (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_date}}"}</Interpolated> -
                    Shift start date (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_start_time}}"}</Interpolated> -
                    Shift start time (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_dts}}"}</Interpolated> - Shift
                    end dts (full date and time string)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_date}}"}</Interpolated> - Shift
                    end date (YYYY/MM/DD)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{shift_end_time}}"}</Interpolated> - Shift
                    end time (HH:mm)
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{location}}"}</Interpolated> - Shift
                    location
                  </DescriptionRow>
                  <DescriptionRow>
                    <Interpolated>{"{{minutes_to_shift_start}}"}</Interpolated>{" "}
                    - Amount of minutes left before the shift starts
                  </DescriptionRow>
                </Description>
              }
            />
            <Spacer />
          </Grid>
          <Grid item xs={6}>
            <TenantLogoForm />
          </Grid>
          <Grid item xs={6}>
            <Box
              style={{
                backgroundColor: "white",
                padding: "8px",
                height: "100%",
                alignItems: "center",
                display: "flex",
              }}
            >
              <RoosterTheme />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box style={{ backgroundColor: "white", padding: "8px" }}>
              <Typography
                variant="body1"
                color="textPrimary"
                style={{ marginBottom: 8 }}
              >
                Enable 2FA Authentication
              </Typography>
              <TwoFAForm />
            </Box>
          </Grid>
        </Grid>
      </ContentContainer>
    </RWrapper>
  );
}

export default SettingsScreen;
