import React from "react";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Grow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
} from "@material-ui/core";
import styled from "styled-components";
import zIndex from "@material-ui/core/styles/zIndex";
import { ExitToApp } from "@material-ui/icons";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { useProgressBar } from "../bars/ProgressBarGlobal";
import { deleteLogout } from "../../requests/logout";
import { useHistory } from "react-router-dom";
import { deepOrange } from "@material-ui/core/colors";

const DropdownPaper = styled(Paper)`
  width: fit-content;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 40px;
`;

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      height: "100%",
      objectFit: "contain",
    },
    navlinkContainer: {
      display: "flex",
      alignItems: "center",
      color: '#282828',
      cursor: "pointer",
    },
    iconContainer: {
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(1),
      boxSizing: "border-box",
      // border: "1px solid black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: `background-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeOut}`,
    },
    icon: {
      // width: 50,
      height: "60%",
    },
  };
});

const UserAvatar = styled(Avatar)`
  height:38px;
  width:38px;
  margin-top: auto;
  background-color: #1890ff;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
`;
interface UserDropdownProps {
  userName: string;
  userFullName: string;
  userRole: string;
}
export const UserDropdown: React.FC<UserDropdownProps> = ({
  userName,
  userFullName,
  userRole,
}) => {
  const c = useStyles();
  const { barActivate, barStop } = useProgressBar();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

  const onOpen = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(currentTarget),
    []
  );

  const onClose = React.useCallback(() => setAnchorEl(undefined), []);

  async function logout() {
    try {
      barActivate();
      const rt = localStorage.getItem("rt");
      if (rt) {
        await deleteLogout(rt);
      }
    } catch (err) {
    } finally {
      localStorage.clear();
      history.replace("/login");
      barStop();
    }
  }
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: deepOrange[300],
      },
      children: name,
    };
  }

  return (
    <>
      <Box className={c.navlinkContainer}>
        <Box className={c.iconContainer} onClick={onOpen}>
          <UserAvatar
            {...stringAvatar(userName.toLocaleUpperCase())}
            style={{
              height: "40px",
              width: "40px",
              marginTop: "13px",
              marginRight: "10px",
              color:"#fff",
            }}
          />
          <div style={{ display: "block" }}>
            <span
              style={{
                fontWeight: 700,
                fontSize: "1rem",
                lineHeight: "1.40rem",
                float: "left",
              }}
            >
              {userFullName}
            </span>
            <span
              style={{
                fontSize: "0.75rem",
                lineHeight: "1rem",
                float: "left",
                clear: "left",
              }}
            >
              {userRole}
            </span>
          </div>
        </Box>

        <ExitToApp style={{ marginLeft: "10px" }} onClick={logout} />
      </Box>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        placement="bottom-end"
        transition
        style={{ zIndex: zIndex.tooltip }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <DropdownPaper>
              <ClickAwayListener onClickAway={onClose}>
                <List dense>
                  {/* <ListItem button onClick={onClose} disabled>
                    <StyledListItemIcon>
                      <AccountCircle />
                    </StyledListItemIcon>
                    <ListItemText primary="My Account" />
                  </ListItem> */}
                  <ListItem
                    button
                    onClick={() => history.push("user/change-password")}
                  >
                    <StyledListItemIcon>
                      <VpnKeyIcon />
                    </StyledListItemIcon>
                    <ListItemText primary="Change password" />
                  </ListItem>
                </List>
              </ClickAwayListener>
            </DropdownPaper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
