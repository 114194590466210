import { Box, Button, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import isEqual from "lodash.isequal";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Day } from ".";
import arrowLeft from "../../../../assets/images/arrow/arrow-left.svg";
import arrowRight from "../../../../assets/images/arrow/arrow-right.svg";
import StandardButton from "../../../../componentsV3/Buttons/StandardButton";
import { useProgressBar } from "../../../../componentsV3/bars/ProgressBarGlobal";
import CalendarBase from "../../../../componentsV3/calendars/CalendarBase";
import Dropdown from "../../../../componentsV3/inputs/Dropdown";
import ModalContainer from "../../../../componentsV3/modals/ModalContainer";
import ModalWindowCenter from "../../../../componentsV3/modals/ModalWindowCenter";
import { useUserSelections } from "../../../../context/userSelectionsProvider";
import model from "../../../../css/model.module.css";
import styles from "../../../../css/style.module.css";
import {
  Roster,
  Shift,
  getRosterStatuses,
  postPublishMonthRoster,
  requestGetRosters,
} from "../../requests";
import Cell from "./Cell";

const useStyles = makeStyles((theme) => {
  return {
    pagetitlebox: {
      margin: `${theme.spacing(1)}px 0`,
    },
    titleDateText: {
      fontSize: theme.typography.fontSize * 1.4,
      color: "#282828",
    },
    titleDateMonthText: {
      width: 160,
      textAlign: "center",
    },
  };
});

interface MonthlyRosterProps {
  date?: Moment;
  shifts: {
    [key: string]: Array<Shift>;
  };
  shiftsCount: {
    [key: string]: Array<any>;
  };
  today?: Day;
}

export default function MonthlyRoster({
  date,
  shifts,
  shiftsCount,
  today,
}: MonthlyRosterProps) {
  const history = useHistory();
  const c = useStyles();

  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedRosters } = useUserSelections();

  const { year, month } = useParams<{ year: string; month: string }>();
  const [monthToShow, setMonthToShow] = useState<number | undefined>(undefined);
  const [yearToShow, setYearToShow] = useState<number | undefined>(undefined);
  const [currentMoment, setCurrentMoment] = useState<Moment | undefined>(
    undefined
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [dayRosters, setDayRosters] = useState<{
    [key: number]: {
      status: "worked" | "unpublished" | "published";
      rosters: Array<{ id: number; color: string; name: string }>;
    };
  }>({});
  const [buttonPublishVisible, setButtonPublishVisible] =
    useState<boolean>(false);

  const [rosters, setRosters] = useState<Array<Roster>>([]);
  const [allLocations, setAllLocations] = useState<boolean>(true);

  const {
    selectedLocation: locationSelected,
  } = useUserSelections();

  const retrieveRosters = useCallback(async () => {
    if (allLocations) {
      try {
        const rn = await requestGetRosters("all");
        setRosters(rn);
      } catch (error) {
        enqueueSnackbar("Unable to retrieve rosters", { variant: "error" });
      }
    } else if (locationSelected && locationSelected.id) {
      try {
        const rn = await requestGetRosters(locationSelected.id);
        setRosters(rn);
      } catch (error) {
        enqueueSnackbar("Unable to retrieve rosters", { variant: "error" });
      }
    }
  }, [locationSelected, allLocations, enqueueSnackbar]);

  useEffect(() => {
    retrieveRosters();
  }, [allLocations, retrieveRosters]);

  useEffect(() => {
    const m = Number(month);
    const y = Number(year);
    if (year && y && month && m) {
      if (m && m > 0 && m <= 12) setMonthToShow(m);
      if (y && y >= 2000 && y <= 2100) setYearToShow(y);
    } else {
      const d = new Date();
      setMonthToShow(d.getMonth() + 1);
      setYearToShow(d.getFullYear());
    }
  }, [month, year]);

  useEffect(() => {
    if (yearToShow && monthToShow) {
      setCurrentMoment(moment(`${yearToShow}-${monthToShow}`, "YYYY-MM"));
    }
  }, [yearToShow, monthToShow]);

  useEffect(() => {
    const isUnpublished = Object.entries(dayRosters).find(
      ([key, value]) => value.status === "unpublished"
    );
    if (isUnpublished) {
      setButtonPublishVisible(true);
    } else {
      setButtonPublishVisible(false);
    }
  }, [dayRosters]);

  const retrieveRosterStatuses = useCallback(async () => {
    if (selectedRosters.length && yearToShow && monthToShow) {
      try {
        const result = await getRosterStatuses({
          rosterIds: selectedRosters.map((r) => r.id),
          year: yearToShow,
          month: monthToShow,
        });
        setDayRosters(result);
      } catch (err) {
        console.error(err);
      }
    }
  }, [monthToShow, yearToShow, selectedRosters]);

  useEffect(() => {
    retrieveRosterStatuses();
  }, [retrieveRosterStatuses]);

  const publishRoster = useCallback(async () => {
    if (yearToShow && monthToShow) {
      try {
        barActivate();
        const { code } = await postPublishMonthRoster(
          yearToShow,
          monthToShow,
          selectedRosters.map((r) => r.id)
        );
        if (code === 0) {
          enqueueSnackbar("No shifts to publish", { variant: "warning" });
        } else {
          enqueueSnackbar("Roster published", { variant: "success" });
        }
        retrieveRosterStatuses();
        setDialogOpen(false);
      } catch (err) {
        if (err.response) {
          enqueueSnackbar(err.response.data.message, { variant: "error" });
        } else {
          enqueueSnackbar("Unable to publish roster", { variant: "error" });
        }
      } finally {
        barStop();
      }
    } else {
      enqueueSnackbar("No year / month selected", { variant: "warning" });
    }
  }, [
    yearToShow,
    monthToShow,
    enqueueSnackbar,
    barActivate,
    barStop,
    selectedRosters,
    retrieveRosterStatuses,
  ]);

  const navToMonth = ({
    year,
    month,
  }: {
    year: number | string;
    month: number | string;
  }) => {
    history.push(`/roster/year/${year}/month/${month}`);
  };

  const nextMonth = (
    year: number,
    month: number
  ): { year: number; month: number } => {
    let y = year;
    let m = month + 1;

    if (month === 12) {
      m = 1;
      y = year + 1;
    }

    return { year: y, month: m };
  };

  const prevMonth = (
    year: number,
    month: number
  ): { year: number; month: number } => {
    let y = year;
    let m = month - 1;

    if (month === 1) {
      m = 12;
      y = year - 1;
    }

    return { year: y, month: m };
  };

  const { pathname } = useLocation();

  const [calendarViewSelectorText, setCalendarViewSelectorText] =
    useState<string>("");

  const adjustCalendarViewSelectorText = useCallback(() => {
    if (pathname.includes("day")) {
      setCalendarViewSelectorText("Day");
    } else if (pathname.includes("month")) {
      setCalendarViewSelectorText("Month");
    } else {
      setCalendarViewSelectorText("Year");
    }
  }, [pathname]);

  useEffect(() => {
    adjustCalendarViewSelectorText();
  }, [adjustCalendarViewSelectorText]);

  const handleCalendarViewSelectChange = useCallback(
    ({ value }: { title: string; value?: number | string }) => {
      const [, , , year, , month] = pathname.split("/");
      if (value === "year") {
        // go current year or year in the pathname
        history.push(`/roster/year/${year ? year : ""}`);
      } else if (value === "month") {
        // go to current month or month in the pathname
        const d = new Date();
        history.push(
          `/roster/year/${year ? year : d.getFullYear()}/month/${month ? month : ""
          }`
        );
      } else if (value === "day") {
        // go to today
        const d = new Date();
        history.push(
          `/roster/year/${d.getFullYear()}/month/${d.getMonth() + 1
          }/day/${d.getDate()}`
        );
      }
    },
    [history, pathname]
  );

  if (!yearToShow || !monthToShow) {
    return null;
  }

  if (!currentMoment) {
    return null;
  }

  return (
    <>
      <ModalContainer open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <ModalWindowCenter>
          <Box className={model.new_container}>
            <Box className={model.titlebox}>
              <Typography className={model.title} variant="h5">
                Publish Roster?
              </Typography>
            </Box>
            <Typography className={model.content_area} variant="body1">
              Some shifts for selected rosters are not assigned. These shifts
              will become available after roster publish. Confirm that you want
              to proceed
            </Typography>

            <Box className={model.buttonsholder}>
              <Box className={model.submitbuttonsholder}>
                <Box className={styles.cancelbuttonmargin}>
                  <StandardButton
                    title="No"
                    className={"danger"}
                    onClick={() => setDialogOpen(false)}
                  />
                </Box>
                <StandardButton
                  title="Yes"
                  className={"secondary"}
                  onClick={publishRoster}
                />
              </Box>
            </Box>
          </Box>
        </ModalWindowCenter>
      </ModalContainer>

      <Divider className={styles.rsspace} />
      <Box className={styles.framecontainer}>
        <Box className={styles.monthcalenderbox}>
          <Box className={styles.calenderbox}>
            <Box className={styles.calendermenuoverlay}>
              <Box className={styles.calendermenuoverlaybox}>
                <Box
                  className={styles.calenderbutton}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Box
                    onClick={() =>
                      navToMonth(prevMonth(yearToShow, monthToShow))
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img alt="arrow-right" src={arrowLeft} />
                  </Box>

                  <Box className={c.titleDateMonthText}>
                    <Typography className={c.titleDateText} color="textPrimary">
                      <Box
                        style={{ cursor: "pointer", fontWeight: 600 }}
                        onClick={() =>
                          history.push(
                            `/roster/year/${currentMoment.format("YYYY")}`
                          )
                        }
                      >
                        {currentMoment.format("YYYY MMMM")}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    onClick={() =>
                      navToMonth(nextMonth(yearToShow, monthToShow))
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img alt="arrow-right" src={arrowRight} />
                  </Box>
                </Box>
                <Box className={styles.calendermenubtn}>
                  <Dropdown
                    textShown={calendarViewSelectorText}
                    onSelect={handleCalendarViewSelectChange}
                    options={[
                      { title: "Year", value: "year" },
                      { title: "Month", value: "month" },
                      { title: "Day", value: "day" },
                    ]}
                  />
                </Box>
              </Box>
              {buttonPublishVisible ? (
                <Box className={styles.calendermenuoverlaybox}>
                  <Box className={styles.calendermenubtn}>
                    <Button
                      variant="contained"
                      className={styles.cfill}
                      disableElevation
                      onClick={() => setDialogOpen(true)}
                    >
                      Publish
                    </Button>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box className={styles.dayNamesWrapper}>
          <Box className={styles.dayNamesContainer}>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Mon</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Tue</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Wed</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Thu</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Fri</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Sat</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Sun</Typography>
            </Box>
          </Box>
          <CalendarBase
            month={Number(currentMoment.format("M"))}
            year={Number(currentMoment.format("YYYY"))}
            spacing={1}
            cellComponent={(d) =>
              d.type === "current" ? (
                <Cell
                  day={d}
                  number={d.day}
                  current={isEqual(
                    { year: d.year, month: d.month, day: d.day },
                    today
                  )}
                  shifts={
                    shifts[
                    `${d.year}.${d.month.toString().padStart(2, "0")}.${d.day
                      .toString()
                      .padStart(2, "0")}`
                    ]
                  }
                  shiftsCount={
                    shiftsCount[
                    `${d.year}.${d.month.toString().padStart(2, "0")}.${d.day
                      .toString()
                      .padStart(2, "0")}`
                    ]
                  }
                />
              ) : null
            }
          />
        </Box>
      </Box>
    </>
  );
}
