import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import arrowOut from "../../../assets/images/pictograms/arrowout.svg";
import arrowIn from "../../../assets/images/pictograms/arrowin.svg";
import { useHistory } from "react-router-dom";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import {
  getIncomingOffers,
  getOutgoingOffers,
  Offer,
  deleteOffer,
  postAcceptOffer,
  postAcceptTakeShiftOffer,
} from "../../../requests/offers";
import { useSnackbar } from "notistack";
import { useProgressBar } from "../../../componentsV3/bars/ProgressBarGlobal";
import SwapShiftOfferRow from "./swapShiftOfferRow";
import moment from "moment";
import SwapModal from "../UserSwapScreen/SwapModal";
import { Shift } from "../../RosterScreen/requests";
import { getOwnAvailableShifts } from "../../../requests/shifts";
import styled from "styled-components";

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid lightgrey;
  margin: 5px 0;
  border-radius: 8px;
  padding: 4px 4px;
`;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flex: 1,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      boxSizing: "border-box",
    },
    content: {
      width: "100%",
    },
    card: {
      minHeight: 200,
      padding: theme.spacing(2),
      border: "1px solid #e6e6e6",
      borderRadius: 8,
      height: "100%",
    },
    cardTitleContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cardIconTitleHolder: {
      display: "flex",
      alignItems: "center",
    },
    cardTitle: {
      fontSize: theme.typography.fontSize * 1.2,
      paddingBottom: 10,
    },
    arrow: {
      marginRight: theme.spacing(1),
    },
    cardInnerContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
    },
  };
});

export default function UserRequestsScreen() {
  const c = useStyles();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [incomingOffers, setIncomingOffers] = useState<Array<Offer>>([]);

  const [outgoingOffers, setOutgoingOffers] = useState<Array<Offer>>([]);

  const [availableShifts, setAvailableShifts] = useState<Array<Shift>>([]);

  const retrieveIncomingOffers = useCallback(
    async function () {
      try {
        barActivate();
        const result = await getIncomingOffers();
        setIncomingOffers(result);
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, { variant: "error" });
        } else {
          console.error(err);
          enqueueSnackbar("Unable to retrieve incoming offers", {
            variant: "error",
          });
        }
      } finally {
        barStop();
      }
    },
    [enqueueSnackbar, barActivate, barStop]
  );

  const retrieveOutgoingOffers = useCallback(
    async function () {
      try {
        barActivate();
        const result = await getOutgoingOffers();
        setOutgoingOffers(result);
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, { variant: "error" });
        } else {
          console.error(err);
          enqueueSnackbar("Unable to retrieve outgoing offers", {
            variant: "error",
          });
        }
      } finally {
        barStop();
      }
    },
    [enqueueSnackbar, barActivate, barStop]
  );

  const retrieveAvailableOffers = useCallback(
    async function () {
      try {
        barActivate();
        const result = await getOwnAvailableShifts();
        setAvailableShifts(result);
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, { variant: "error" });
        } else {
          console.error(err);
          enqueueSnackbar("Unable to retrieve outgoing offers", {
            variant: "error",
          });
        }
      } finally {
        barStop();
      }
    },
    [barActivate, barStop, enqueueSnackbar]
  );

  useEffect(() => {
    retrieveIncomingOffers();
    retrieveOutgoingOffers();
    retrieveAvailableOffers();
  }, [retrieveIncomingOffers, retrieveOutgoingOffers, retrieveAvailableOffers]);

  const [modalShifts, setModalShifts] = useState<{
    left?: Shift;
    right?: Shift;
    offerId?: number;
    out?: boolean;
  }>({ offerId: undefined, left: undefined, right: undefined, out: false });

  function offerClickHandler(offer: Offer, out: boolean) {
    setModalShifts({
      left: out ? offer.outboundShift : offer.inboundShift,
      right: out ? offer.inboundShift : offer.outboundShift,
      offerId: offer.id,
      out,
    });
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
    setModalShifts({
      left: undefined,
      right: undefined,
      offerId: undefined,
      out: false,
    });
  }

  async function cancelOffer(id: number) {
    try {
      barActivate();
      await deleteOffer(id);
      enqueueSnackbar("Offer cancelled", { variant: "success" });
      retrieveIncomingOffers();
      retrieveOutgoingOffers();
      closeModal();
    } catch (err) {
      if (err.response) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      } else {
        console.error(err);
        enqueueSnackbar("Unable to cancel offer", { variant: "error" });
      }
    } finally {
      barStop();
    }
  }

  async function acceptOffer(id: number, swap: boolean) {
    try {
      barActivate();
      swap ? await postAcceptOffer(id) : await postAcceptTakeShiftOffer(id);
      enqueueSnackbar("Offer accepted", { variant: "success" });
      retrieveIncomingOffers();
      retrieveOutgoingOffers();
      retrieveAvailableOffers();
      closeModal();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Unable to accept shift. Try again later", {
          variant: "error",
        });
      }
    } finally {
      barStop();
    }
  }

  return (
    <RWrapper>
      <SwapModal
        onClose={closeModal}
        open={modalOpen}
        currentShift={modalShifts.left}
        swapShift={modalShifts.right}
        onDelete={cancelOffer}
        offerId={modalShifts.offerId}
        onAccept={modalShifts.out ? undefined : acceptOffer}
      />
      <div className={c.content}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <Paper className={c.card} elevation={0}>
              <div className={c.cardTitleContainer}>
                <div className={c.cardIconTitleHolder}>
                  <img alt="arrow" src={arrowOut} className={`${c.arrow}`} />
                  <Typography className={c.cardTitle} color="textPrimary">
                    Outgoing shift swap
                  </Typography>
                </div>
              </div>
              <Divider />
              <div
                className={c.cardInnerContainer}
                style={{ margin: "10px 0px" }}
              >
                {outgoingOffers.map((o) => {
                  const leftStartMoment =
                    o.outboundShift && moment(o.outboundShift.startDts);
                  const leftEndMoment =
                    o.outboundShift && moment(o.outboundShift.endDts);
                  const rightStartMoment = moment(o.inboundShift.startDts);
                  const rightEndMoment = moment(o.inboundShift.endDts);
                  const leftTop =
                    o.outboundShift &&
                    `${leftStartMoment.format(
                      "HH:mm"
                    )} - ${leftEndMoment.format("HH:mm")}`;
                  const leftBottom =
                    o.outboundShift &&
                    ` ${leftStartMoment.format("DD MMM YYYY")}`;

                  const rightTop = `${rightStartMoment.format(
                    "HH:mm"
                  )} - ${rightEndMoment.format("HH:mm")}`;
                  const rightBottom = ` ${rightStartMoment.format(
                    "DD MMM YYYY"
                  )}`;

                  return (
                    <SwapShiftOfferRow
                      key={o.id}
                      onClick={() => offerClickHandler(o, true)}
                      leftSide={
                        o.outboundShift
                          ? { top: leftTop, bottom: leftBottom }
                          : undefined
                      }
                      rightSide={{ top: rightTop, bottom: rightBottom }}
                    />
                  );
                })}
              </div>
              <StandardButton
                title="New Request"
                onClick={() => history.push("/")}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <Paper className={c.card} elevation={0}>
              <div className={c.cardTitleContainer}>
                <div className={c.cardIconTitleHolder}>
                  <img alt="arrow" src={arrowIn} className={`${c.arrow}`} />
                  <Typography className={c.cardTitle} color="textPrimary">
                    Incoming shift swap
                  </Typography>
                </div>
              </div>
              <Divider />
              <div className={c.cardInnerContainer}>
                {incomingOffers.map((o) => {
                  const leftStartMoment =
                    o.outboundShift && moment(o.outboundShift.startDts);
                  const leftEndMoment =
                    o.outboundShift && moment(o.outboundShift.endDts);
                  const rightStartMoment = moment(o.inboundShift.startDts);
                  const rightEndMoment = moment(o.inboundShift.endDts);
                  const leftTop =
                    o.outboundShift &&
                    `${leftStartMoment.format(
                      "HH:mm"
                    )} - ${leftEndMoment.format("HH:mm")}`;
                  const leftBottom =
                    o.outboundShift &&
                    ` ${leftStartMoment.format("DD MMM YYYY")}`;

                  const rightTop = `${rightStartMoment.format(
                    "HH:mm"
                  )} - ${rightEndMoment.format("HH:mm")}`;
                  const rightBottom = `${rightStartMoment.format(
                    "DD MMM YYYY"
                  )}`;

                  return (
                    <SwapShiftOfferRow
                      key={o.id}
                      onClick={() => offerClickHandler(o, false)}
                      leftSide={
                        o.outboundShift
                          ? { top: leftTop, bottom: leftBottom }
                          : undefined
                      }
                      rightSide={{ top: rightTop, bottom: rightBottom }}
                    />
                  );
                })}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <Paper className={c.card} elevation={0}>
              <Typography className={c.cardTitle} color="textPrimary">
                Shifts I have made Available
              </Typography>
              <Divider />
              <div className={c.cardInnerContainer}>
                {availableShifts.map((s) => {
                  return (
                    <RowContainer key={s.id}>
                      <Typography variant="body2">
                        {moment(s.startDts).format("DD MMM YYYY HH:mm")}
                      </Typography>
                    </RowContainer>
                  );
                })}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </RWrapper>
  );
}
