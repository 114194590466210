import React, { ReactNode } from "react";
import styles from "./styles.module.css";
import { Paper } from "@material-ui/core";

const ModalWindowCenter = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <Paper className={`${styles.container} ${className ? className : ""}`}>
      <div className={styles.container2}>{children}</div>
    </Paper>
  );
};

export default ModalWindowCenter;
