import { Box, InputLabel, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import StandardButton from "../../../../../componentsV3/Buttons/StandardButton";
import SelectFormik from "../../../../../componentsV3/inputs/SelectFormik";
import ModalWindowCenter from "../../../../../componentsV3/modals/ModalWindowCenter";
import styles from "../../../../../css/style.module.css";
import { emailRegex, phoneRegExp } from "../../../../../GLOBALS";
export interface CreateTicketModalInnerSubmitValues {
  supportType: string;
  description: string;
  name: string;
  email: string;
  mobile: string;
  subject: string
}

interface AddTicketModalProps {
  onSubmit: (values: CreateTicketModalInnerSubmitValues) => void;
  onCancel: () => void;
}

export default ({ onSubmit, onCancel }: AddTicketModalProps) => {
  const SupportType = [
    { title: "Admin Support", value: "Admin Support" },
    { title: "Project Support", value: "Project Support" },
    { title: "Reporting Support", value: "Reporting Support" },
    { title: "Timesheet Support", value: "Timesheet Support" },
    {
      title: "Other Technical Support",
      value: "Other Technical Support",
    },
  ];

  const validation = Yup.object().shape({
    supportType: Yup.string().required("Required"),
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().matches(emailRegex, "Invalid email").required("Required"),
    description: Yup.string()
      .min(10, "Too Short!")
      .max(500, "Too Long!")
      .required("Required"),
    mobile: Yup.string().matches(phoneRegExp, 'Invalid mobile number').min(11, "Too Short!")
  });

  return (
    <ModalWindowCenter className={styles.tiketmodals}>
      <Box className={styles.pagetitlebox}>
        <Typography variant="h4" color="textPrimary">
          Rooster - Contact Support
        </Typography>
      </Box>

      <Box>
        <Formik
          initialValues={{
            supportType: "",
            subject: "",
            name: "",
            email: "",
            mobile: "",
            description: "",
          }}
          validationSchema={validation}
          onSubmit={onSubmit}
        >
          {({
            errors,
            isValid,
            dirty,
            isSubmitting,
            touched,
            handleChange,
            values,
          }) => (
            <Form className={styles.form} noValidate>
              <InputLabel color="primary" style={{ marginBottom: 10 }}>
                Support Type
              </InputLabel>

              <Field
                component={SelectFormik}
                name="supportType"
                options={SupportType}
                onChnage={handleChange}
                className={`${errors.supportType && touched.supportType
                  ? "input-error"
                  : null
                  }`}
              />

              {errors.supportType && touched.supportType && (
                <div className={`${styles.errorblock}`}>{errors.supportType}</div>
              )}

              {values.supportType === "Other Technical Support" ? (
                <>
                  <InputLabel color="primary" style={{ marginBottom: 10 }}>
                    Subject
                  </InputLabel>
                  <Field
                    as="textarea"
                    name="subject"
                    placeholder="Enter details of your technical support item"
                    type="text"
                    className={`${styles.input} ${errors.subject && touched.subject
                      ? "input-error"
                      : null
                      }`}
                  />
                </>
              ) : (
                <></>
              )}

              <InputLabel color="primary" style={{ marginBottom: 10 }}>
                Name
              </InputLabel>
              <Field
                name="name"
                type="name"
                className={`${styles.input} ${errors.name && touched.name ? "input-error" : null
                  }`}
                autoCorrect="off"
                autoCapitalize="none"
              />
              {errors.name && touched.name && (
                <div className={`${styles.errorblock}`}>{errors.name}</div>
              )}
              <InputLabel color="primary" style={{ marginBottom: 10 }}>
                Email
              </InputLabel>

              <Field
                name="email"
                type="email"
                className={`${styles.input} ${errors.email && touched.email ? "input-error" : null
                  }`}
                autoCorrect="off"
                autoCapitalize="none"
              />
              {errors.email && touched.email && (
                <div className={`${styles.errorblock}`}>{errors.email}</div>
              )}
              <InputLabel color="primary" style={{ marginBottom: 10 }}>
                Mobile
              </InputLabel>
              <Field
                name="mobile"
                type="number"
                className={`${styles.input} ${errors.mobile && touched.mobile ? "input-error" : null
                  }`}
              />
              {errors.mobile && touched.mobile && (
                <div className={`${styles.errorblock}`}>{errors.mobile}</div>
              )}
              <InputLabel color="primary" style={{ marginBottom: 10 }}>
                Description
              </InputLabel>

              <Field
                as="textarea"
                name="description"
                type="text"
                className={`${styles.input} ${errors.description && touched.description ? "input-error" : null
                  }`}
              />
              {errors.description && touched.description && (
                <div className={`${styles.errorblock}`}>{errors.description}</div>
              )}
              <Box className={styles.buttonsholder}>
                <Box
                  className={`${styles.submitbuttonsholder} ${styles.fillbtn}`}
                  style={{ display: "flex" }}
                >
                  <StandardButton
                    title="Submit"
                    type="submit"
                    accent
                    disabled={isSubmitting || !isValid || !dirty}
                  />
                  <Box className={styles.cancelbuttonmargin}>
                    <StandardButton
                      className={"secondary"}
                      title="Cancel"
                      onClick={onCancel}
                    />
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ModalWindowCenter>
  );
};
