import React, { useState } from "react";
import style from "../../css/style.module.css";
import ForgotPasswordForm, { SubmitValues } from "./ForgotPasswordForm";
import ResendPassword from "./ResendPassword";
import { useParams } from "react-router-dom";
import ResetPasswordForm from "./ResetPasswordForm";
import { History } from "history";
import { requestResetPassword, requestNewPassword } from "./requests";
import { useSnackbar } from "notistack";
import { Typography, Box, Grid } from "@material-ui/core";
import SimpleSlider from "../../componentsV3/SlickSlider";
import proaxiomLogo from "../../assets/images/proaxiom.png";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import proaxiomLogoX from "../../componentsV3/nav/x.png";

export default ({ history }: { history: History }) => {
  const { rkey } = useParams<{ rkey: string }>();
  const [passwordSent, setpasswordSent] = useState(false);
  const [email, setEmail] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const _submit = async ({ email }: SubmitValues) => {
    if (!email) return;
    try {
      setEmail(email);
      await requestResetPassword(email);
      setpasswordSent(true);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setpasswordSent(true);
      }
    }
  };

  const _submitPassword = async ({
    password,
    confirmPassword,
  }: {
    password: string;
    confirmPassword: string;
  }) => {
    if (!rkey || !password) return;
    try {
      await requestNewPassword(rkey, password, confirmPassword);
      history.push("/login");
    } catch (err) {
      if (err.response && err.response.data.message) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar(
          "Something is wrong. Please click the link in the email again",
          { variant: "error" }
        );
      }
    }
  };

  return (
    <>
      <Grid container className={style.screencontainer}>
        <Grid item xs={12} sm={12} md={6} className={style.ScreenLeft}>
          <SimpleSlider />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={style.ScreenRight}>
          <Link to="/login" className={style.backarrow}>
            {" "}
            <FaArrowLeft />{" "}
          </Link>
          <Box className={style.formContainer}>
            <Box className={style.logoholder}>
              <Box className={style.formcontent}>
                <Typography variant="h3" className="mt-1">
                  Forgot password?
                </Typography>
                <Typography variant="body1">
                  Enter the email address you used when you joined and we’ll
                  send you instructions to reset your password.
                </Typography>
              </Box>
            </Box>
            {rkey ? (
              <ResetPasswordForm
                onSubmit={_submitPassword}
                history={history}
                resetKey={rkey}
              />
            ) : !passwordSent ? (
              <ForgotPasswordForm history={history} onSubmit={_submit} />
            ) : (
              <ResendPassword
                history={history}
                onSubmit={_submit}
                email={email}
              />
            )}
          </Box>
          <Box className={style.proaxiomLogo}>
            <Typography
              color="textPrimary"
              style={{
                fontSize: "14px",
                color: "#9A9A9A",
                marginRight: 5,
                lineHeight: "1",
              }}
              className={style.userRole}
            >
              Powered by{" "}
            </Typography>
            <img src={proaxiomLogoX} alt="logo" />
            <img
              className={style.proaxiomLogoImg}
              src={proaxiomLogo}
              alt="logo"
              width="190px"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
