import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import style from "../../../css/style.module.css";
import { Link } from "react-router-dom";
import arrow from "./img/arrow.svg";
import { motion } from "framer-motion";
import { emailRegex } from "../../../GLOBALS";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md";

export interface FormValues {
  email: string;
  password: string;
}

interface FormProps {
  onSubmit: (values: FormValues) => Promise<void>;
  externalError: string;
}

const validation = ({ email, password }: FormValues) => {
  const errors: { [key: string]: string } = {};
  if (!email) {
    errors.email = "Email required";
  } else if (!emailRegex.test(email)) {
    errors.email = "Invalid email address";
  }
  if (!password) {
    errors.password = "Password required";
  } else if (password.length < 6) {
    errors.password = "Password must be at least 6 chars";
  }
  return errors;
};

export default ({ onSubmit, externalError }: FormProps) => {
  const [showPassword, setShowPassword] = useState(false);

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  return (
    <div className={`${style.formcontainer} ${style.loginform}`}>
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={validation}
        onSubmit={onSubmit}
      >
        {({ errors, isValid, dirty, isSubmitting, touched }) => (
          <Form className={style.form} noValidate>
            <label htmlFor="Email">Email</label>
            <Field
              label="Email"
              name="email"
              type="email"
              placeholder="@mail.com"
              className={`${style.input} ${
                errors.email && touched.email ? "input-error" : null
              }`}
              autoComplete="username"
              autoCorrect="off"
              autoCapitalize="none"
            />
            {errors.email && touched.email && (
              <div className={`${style.errorblock}`}>{errors.email}</div>
            )}
            <label htmlFor="Password">Password</label>
            <div className={style.showpassword}>
              <Field
                type={showPassword ? "text" : "password"}
                name="password"
                className={`${style.input} ${
                  errors.password && touched.password ? "input-error" : null
                }`}
                placeholder="•••••••••••"
                autoComplete="current-password"
              />
              <span onClick={toggleShowPassword}>
                {showPassword ? (
                  <span className={style.showbtn}>
                    <MdOutlineVisibility />
                  </span>
                ) : (
                  <span className={style.showbtn}>
                    <AiOutlineEyeInvisible />
                  </span>
                )}
              </span>
            </div>
            {errors.password && touched.password && (
              <div className={`${style.errorblock}`}>{errors.password}</div>
            )}
            <div className={style.buttonsrow}>
              {/* <label className={style.checkbox}>
              <FormControlLabel control={<Checkbox style ={{color: "#9A9A9A",}}/>} label="Remember me" />
              </label> */}
              <Link
                to="/forgot-password"
                className={`${style.forgotpasswordtext} `}
              >
                {" "}
                Forgot Password?{" "}
              </Link>
            </div>
            <motion.button
              type="submit"
              className={style.button}
              disabled={isSubmitting || !dirty || !isValid}
            >
              <span className={style.buttonText}>sign in</span>
              <img alt="" src={arrow} className={style.arrow} />
            </motion.button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
