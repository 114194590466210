import {
  Button,
  Divider,
  Grid,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StandardButton from "../../../components/Buttons/StandardButton";
import CalendarHorizontalBase from "../../../components/calendars/CalendarHorizontalBase";
import { Shift } from "../../RosterScreen/requests";
import { useShift } from "../UserRequestSwapScreen/Provider";
import Card from "./swapShiftCard";

interface SwapShiftComponentDesktopProps {
  startMoment: Moment;
  setstartMoment: (m: Moment) => void;
  shiftsToSwap: {
    [key: string]: Array<Shift>;
  };
  selectedShift?: Shift;
  setselectedShift: (m: Shift | undefined) => void;
  requestShift: Array<Shift>;
  onSelectSwapClick: (s: void) => void;
}

const useStyles = makeStyles((theme) => {
  const cellHeight = theme.typography.fontSize * 5;
  return {
    desktopCalendar: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      // backgroundColor: "red",
      padding: theme.spacing(1),
      boxSizing: "border-box",
      minWidth: "fit-content",
    },
    desktopCalendarContainer: {
      display: "flex",
      flex: 1,
    },
    desktopCalendarHolder: {
      display: "flex",
      width: "100%",
    },
    desktopCalendarNamesColumn: {
      display: "flex",
      flexDirection: "column",
      // backgroundColor: "yellow",
      width: 150,
      overflow: "hidden",
    },
    desktopCalendarColumn: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      // border: "1px solid white",
    },
    desktopCalendarCell: {
      width: "100%",
      minWidth: 82,
      padding: theme.spacing(0.5),
      height: cellHeight,
      // backgroundColor: "black",
      boxSizing: "border-box",
    },
    desktopCalendarCellInner: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      // backgroundColor: "lightgreen",
      fontSize: theme.typography.fontSize,
      whiteSpace: "nowrap",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
      overflow: "hidden",
      transition: `box-shadow ${theme.transitions.duration.shorter}ms`,
    },
    activeShadow: {
      cursor: "pointer",
      "&:hover": {
        boxShadow: theme.shadows[1],
      },
    },
    desktopCalendarControlBar: {
      width: "100%",
      marginBottom: theme.spacing(1),
      // height: cellHeight * 2,
      // backgroundColor: "gold",
    },
    desktopCalendarNavigation: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      height: cellHeight,
    },
    desktopCalendarDescription: {
      display: "flex",
      alignItems: "center",
      flex: 1,
      height: cellHeight,
    },
    desktopCalendarDescriptionCell: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: theme.typography.fontSize,
    },
    monthText: {
      width: 150,
      textAlign: "center",
    },
    arrow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 40,
      height: "50%",
      borderRadius: theme.shape.borderRadius,
      textAlign: "center",
      cursor: "pointer",
      touchAction: "manipulation",
      transition: `all ${theme.transitions.duration.shorter}ms`,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    cardHolder: {
      // backgroundColor: "gold",
      width: "100%",
      padding: theme.spacing(1),
      boxSizing: "border-box",
      marginTop: theme.spacing(1),
    },
    cardHolderSelectedCardWrapper: {
      marginTop: theme.spacing(3),
    },
    cardHolderRequestedCardWrapper: {
      marginBottom: theme.spacing(3),
    },
    selectButtonHolder: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      marginTop: theme.spacing(1),
    },
    titleAboveTheCard: {
      marginBottom: theme.spacing(1),
    },
  };
});

export default function SwapShiftComponentDesktop({
  startMoment,
  setstartMoment,
  shiftsToSwap,
  selectedShift,
  setselectedShift,
  requestShift,
  onSelectSwapClick,
}: SwapShiftComponentDesktopProps) {
  const c = useStyles();
  const history = useHistory();
  const { setShifts } = useShift();
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.only("sm"));
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.only("md"));
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.only("lg"));
  const xl = useMediaQuery((theme: Theme) => theme.breakpoints.only("xl"));

  const [amountOfDaysToShow, setamountOfDaysToShow] = useState(7);
  const [arrayOfShifts, setarrayOfShifts] = useState<Array<Shift>>([]);

  useEffect(() => {
    if (xs) {
      setamountOfDaysToShow(2);
    } else if (sm) {
      setamountOfDaysToShow(3);
    } else if (md) {
      setamountOfDaysToShow(4);
    } else if (lg) {
      setamountOfDaysToShow(7);
    } else if (xl) {
      setamountOfDaysToShow(12);
    }
  }, [xs, sm, md, lg, xl]);

  function dayPlus() {
    setstartMoment(startMoment.clone().add(1, "day"));
  }
  function dayMinus() {
    setstartMoment(startMoment.clone().subtract(1, "day"));
  }
  function monthPlus() {
    setstartMoment(startMoment.clone().add(1, "month"));
  }
  function monthMinus() {
    setstartMoment(startMoment.clone().subtract(1, "month"));
  }

  function getMoment(index: number) {
    return startMoment.clone().add(index, "days");
  }

  function onShiftCellClicked(shift?: Shift) {
    if (shift) {
      if (shift.id === selectedShift?.id) {
        setselectedShift(undefined);
      } else {
        setselectedShift(shift);
      }
    }
  }
  useEffect(() => {
    if (Object.keys(shiftsToSwap).length) {
      const flat = Object.entries(shiftsToSwap)
        .map(([k, v]) => v)
        .flat();
      setarrayOfShifts(flat);
    } else {
      setarrayOfShifts([]);
    }
  }, [shiftsToSwap]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <Paper className={c.desktopCalendar} elevation={0}>
            <div className={c.desktopCalendarControlBar}>
              <div className={c.desktopCalendarNavigation}>
                <div className={c.arrow} onClick={dayMinus}>
                  {"<"}
                </div>
                <div className={c.arrow} onClick={monthMinus}>
                  {"<<"}
                </div>
                <span className={c.monthText}>
                  {startMoment.format("MMMM")}
                </span>
                <div className={c.arrow} onClick={monthPlus}>
                  {">>"}
                </div>
                <div className={c.arrow} onClick={dayPlus}>
                  {">"}
                </div>
              </div>

              <h3>My Shifts</h3>
              <Divider />
              <div className={c.desktopCalendarDescription}>
                <div className={c.desktopCalendarHolder}>
                  {Array.from(Array(amountOfDaysToShow)).map((_, i) => (
                    <div key={i} className={c.desktopCalendarCell}>
                      <div className={c.desktopCalendarDescriptionCell}>
                        <span>{getMoment(i).format("dddd")}</span>
                        <span>{getMoment(i).format("DD MMM")}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Divider />
            </div>
            <div className={c.desktopCalendarContainer}>
              <div className={c.desktopCalendarHolder}>
                <CalendarHorizontalBase
                  startMoment={startMoment}
                  amountOfDays={amountOfDaysToShow}
                  cellComponent={(d, m, i) => (
                    <div
                      className={c.desktopCalendarColumn}
                      key={m.format("YYYY-MM-DD")}
                    >
                      {arrayOfShifts.map((shift) => {
                        const timeString =
                          shift &&
                          moment(shift.startDts).format("YYYY-MM-DD") ===
                            m.format("YYYY-MM-DD") &&
                          `${moment(shift.startDts).format("HH:mm")} - ${moment(
                            shift.endDts
                          ).format("HH:mm")}`;

                        return (
                          timeString && (
                            <div className={c.desktopCalendarCell}>
                              <div
                                className={`${c.desktopCalendarCellInner} ${
                                  timeString ? c.activeShadow : ""
                                }`}
                                onClick={() => onShiftCellClicked(shift)}
                              >
                                {timeString}
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  )}
                />
              </div>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Grid container justify="center">
            <Button
              variant="contained"
              color="primary"
              style={{ height: "60px", width: "180px" }}
              disabled={requestShift.length === 5}
              onClick={() => history.goBack()}
            >
              Add additional shifts to swap request
            </Button>
          </Grid>

          <Paper elevation={0} className={c.cardHolder}>
            {requestShift.length
              ? requestShift.map((shift) => (
                  <div className={c.cardHolderRequestedCardWrapper}>
                    <Typography
                      variant="body1"
                      className={c.titleAboveTheCard}
                      align="center"
                    >
                      Request Shift
                    </Typography>
                    <Card shift={shift} />
                    {requestShift.length === 1 &&
                      requestShift[0].available === true &&
                      !selectedShift && (
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => onSelectSwapClick()}
                        >
                          Take Shift
                        </Button>
                      )}
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{ float: "right" }}
                      onClick={() => {
                        setShifts((currentShift: any) =>
                          currentShift.filter((s: any) => {
                            return s !== shift;
                          })
                        );
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                ))
              : null}

            {selectedShift && requestShift.includes(selectedShift) === false ? (
              <div className={c.cardHolderSelectedCardWrapper}>
                <Typography
                  variant="body1"
                  className={c.titleAboveTheCard}
                  align="center"
                >
                  Shift to swap with
                </Typography>
                <Card shift={selectedShift} />
                <div className={c.selectButtonHolder}>
                  <StandardButton
                    title="Select"
                    onClick={() => onSelectSwapClick()}
                  />
                </div>
              </div>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
