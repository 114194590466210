import React, { useState, ReactNode } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import {
  getMessageEmail,
  postMessageEmail,
  putMessageEnabled,
} from "../../../requests/settings";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import { animated, useTransition } from "react-spring";
import Switch from "../../../componentsV3/Buttons/Switch";
import styles from "../../../css/style.module.css";
import { Box } from "@material-ui/core";

const SubjectInput = styled.input<{ errored?: boolean }>`
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  padding: 15px 12px;
  width: 100%;
  background: #f9f9f9;
  box-sizing: border-box;
  font-family: monospace;
  margin-bottom: ${({ theme }) => theme.gap[1]};
`;

const TextArea = styled.textarea<{ errored?: boolean }>`
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  resize: none;
  height: 140px;
  padding: 15px 0 0 12px;
  width: 100%;
  background: #f9f9f9;
  box-sizing: border-box;
`;

interface Inputs {
  subject?: string | null;
  body: string;
  enabled?: boolean;
}

interface EmailTemplateFormProps {
  templateName: string;
  userFriendlyTemplateName: string;
  TemplateIssuer?: string;
  TemplateRecipient?: string;
  toggleVisible?: boolean;
  description?: ReactNode;
}
function EmailTemplateForm({
  templateName,
  userFriendlyTemplateName,
  TemplateIssuer,
  TemplateRecipient,
  toggleVisible,
  description,
}: EmailTemplateFormProps) {
  const [open, setOpen] = useState<boolean>(false);

  const transition = useTransition(open, null, {
    from: { maxHeight: 0 },
    enter: { maxHeight: 800 },
    leave: { maxHeight: 0 },
  });

  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, refetch } = useQuery<Inputs, AxiosError>(
    templateName,
    () => getMessageEmail(templateName),
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get ${userFriendlyTemplateName}`,
          { variant: "error" }
        );
      },
    }
  );

  const { mutate } = useMutation<unknown, AxiosError, Inputs>(
    (data) => postMessageEmail(templateName, data),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to submit ${userFriendlyTemplateName}`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        await refetch();
        reset();
        enqueueSnackbar(`${userFriendlyTemplateName} saved`, {
          variant: "success",
        });
      },
    }
  );

  const { mutate: toggle } = useMutation<unknown, AxiosError, boolean>(
    (enabled) => putMessageEnabled(templateName, enabled),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to toggle ${userFriendlyTemplateName}`,
          { variant: "error" }
        );
      },
      onSuccess: () => {
        refetch();
      },
    }
  );

  const {
    register,
    handleSubmit,
    errors,
    formState: { isDirty },
    reset,
  } = useForm<Inputs>();

  const submit = (data: Inputs) => {
    mutate({ ...data, subject: data.subject ? data.subject : null });
  };

  function toggleFormOpen() {
    setOpen((o) => !o);
  }

  const [isActive, setIsActive] = useState(false);

  const handleClick = (event: any) => {
    setIsActive((current) => !current);
  };

  return (
    <Box className={styles.accrodianbox}>
      <Box className={styles.accrodiantitlebox} onClick={toggleFormOpen}>
        {/* <div /> */}

        <button
          onClick={handleClick}
          className={isActive ? `${styles.active}` : `${styles.accrodianbtn}`}
        >
          {userFriendlyTemplateName}{" "}
          <>
            {TemplateIssuer?.length ? (
              <span className={styles.TemplateIssuer}>{TemplateIssuer}</span>
            ) : null}
            {TemplateRecipient?.length ? (
              <span className={styles.TemplateRecipient}>
                {TemplateRecipient}
              </span>
            ) : null}
          </>
        </button>

        {/* <Typography variant="h6">{userFriendlyTemplateName}</Typography> */}

        {toggleVisible ? (
          <Switch active={data?.enabled} onToggle={toggle} />
        ) : (
          <div />
        )}
      </Box>

      {transition.map(
        ({ item, props, key }) =>
          item && (
            <animated.form
              key={key}
              onSubmit={handleSubmit(submit)}
              style={{ overflow: "hidden", ...props }}
            >
              {description}
              <label htmlFor="Title">Title</label>
              <SubjectInput
                name="subject"
                ref={register}
                disabled={isLoading}
                defaultValue={data?.subject || ""}
                placeholder="Title"
                autoComplete={"off"}
                errored={!!errors.subject}
              />
              <label htmlFor="Description">Description</label>
              <TextArea
                name="body"
                ref={register({ required: true })}
                disabled={isLoading}
                defaultValue={data?.body}
                errored={!!errors.body}
                placeholder="Description"
              />
              <Box className={styles.acbtn}>
                <StandardButton
                  title="Reset"
                  type="button"
                  className={"secondary"}
                  onClick={() => reset()}
                />
                <StandardButton
                  type="submit"
                  title="Save"
                  accent
                  disabled={!isDirty}
                />
              </Box>
            </animated.form>
          )
      )}
    </Box>
  );
}

export default EmailTemplateForm;
