import {
  FormControlLabel,
  Radio,
  RadioGroup,
  DialogContent,
  makeStyles,
  Box,
  Grid,
  Dialog,
} from "@material-ui/core";
import { AxiosError } from "axios";
import styles from "../../../css/style.module.css";
import { useSnackbar } from "notistack";
import React, { FormEvent, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import { PutThemeVersion } from "../../../requests/user";
import { useThemeVersion } from "../../../context/ThemeVersionProvider";
import RoosterV3Img from "../../../assets/images/Rooster-v3.png";
import RoosterV2Img from "../../../assets/images/Rooster-v2.png";

const useStyles = makeStyles((theme) => {
  return {
    radio: {
      "& .Mui-checked": {
        color: "#0583f2",
      },
    },
  };
});

const Form = styled.form`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

const InputOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonHolder = styled.div`
  display: flex;
  flex: 1;
  margin-top: 15px;
  justify-content: flex-end;
  align-items: flex-end;
`;

type ThemeValue = "v2" | "v3";

interface ThemeInputs {
  themeVersion: ThemeValue;
}

function RoosterTheme() {
  const style = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { roosterVersionV3 } = useThemeVersion();
  const themeInitialValue: ThemeValue = roosterVersionV3 ? "v3" : "v2";

  const [themeValue, setThemeValue] = useState<ThemeValue>(themeInitialValue);

  const { mutate } = useMutation<unknown, AxiosError, ThemeInputs>(
    (values) => PutThemeVersion(values),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to Change Theme`,
          { variant: "error" }
        );
      },
      onSuccess: () => {
        window.location.reload();
        enqueueSnackbar(`Theme Successfully Changed`, {
          variant: "success",
        });
      },
    }
  );

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    mutate({
      themeVersion: themeValue,
    });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThemeValue(event.target.value as ThemeValue);
  };

  const [openV2, setOpenV2] = React.useState(false);
  const [openV3, setOpenV3] = React.useState(false);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <InputOuterContainer>
          <RadioGroup
            style={{ flexDirection: "row" }}
            aria-labelledby="theme-controlled-radio-buttons-group"
            name="theme-controlled-radio-buttons-group"
            value={themeValue}
            onChange={handleChange}
          >
            <Grid container spacing={3} style={{ marginBottom: 10 }}>
              <Grid item lg={2} md={2} sm={1}>
                <FormControlLabel
                  value="v2"
                  control={<Radio />}
                  label="V2"
                  className={style.radio}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6}>
                <Box className={styles.themepreviewimg}>
                  <img src={RoosterV2Img} alt="version-2" onClick={() => setOpenV2(true)} />
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={2} md={2} sm={1}>
                <FormControlLabel
                  value="v3"
                  control={<Radio />}
                  label="V3"
                  className={style.radio}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6}>
                <Box className={styles.themepreviewimg}>
                  <img src={RoosterV3Img} alt="version-3" onClick={() => setOpenV3(true)} />
                </Box>
              </Grid>
            </Grid>
          </RadioGroup>
        </InputOuterContainer>

        <ButtonHolder>
          <StandardButton
            type="submit"
            title="Save"
            accent
            {...(themeValue === themeInitialValue && { disabled: true })}
          />
        </ButtonHolder>
      </Form>

      <Dialog
        open={openV2}
        onClose={() => setOpenV2(false)}
        className={styles.themepreviewimg}
      >
        <DialogContent>
          <img src={RoosterV2Img} alt="version-2" />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openV3}
        onClose={() => setOpenV3(false)}
        className={styles.themepreviewimg}
      >
        <DialogContent>
          <img src={RoosterV3Img} alt="version-3" />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default RoosterTheme;
