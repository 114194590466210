import { Box, InputLabel, Typography, makeStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import StandardButton from "../../../../../componentsV3/Buttons/StandardButton";
import { useProgressBar } from "../../../../../componentsV3/bars/ProgressBarGlobal";
import SelectFormik from "../../../../../componentsV3/inputs/SelectFormik";
import ModalWindowCenter from "../../../../../componentsV3/modals/ModalWindowCenter";
import styles from "../../../../../css/style.module.css";
import { getUserDetails } from "../../../../../requests/user";

const useStyles = makeStyles(() => ({
    input: {
        "&:disabled": {
            color: 'rgb(163, 163, 163)',
        },
    },
}));

export interface EditTicketModalInnerSubmitValues {
    supportType: string;
    description: string;
    subject: string;
    status: string;
    comment: string;
}

interface EditTicketModalProps {
    onSubmit: (values: EditTicketModalInnerSubmitValues) => void;
    onCancel: () => void;
    selectTicket: any;
}

export default ({ onSubmit, onCancel, selectTicket }: EditTicketModalProps) => {

    const c = useStyles();
    const { barStop } = useProgressBar();
    const [userRole, setUserRole] = useState<any>();

    const getUser = useCallback(async () => {
        try {
            const u = await getUserDetails();

            if (u?.isAdministrator) {
                setUserRole(true);
            } else {
                setUserRole(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            barStop();
        }
    }, [barStop]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    const Status = [
        { title: "Pending", value: "Pending" },
        { title: "In-Progress", value: "InProgress" },
        { title: "Resolved", value: "Resolved" },
        { title: "Closed", value: "Closed" },
    ];

    let validation: any = Yup.object().shape({

        description: Yup.string()
            .min(10, "Description Too Short!")
            .max(500, "Description Too Long!")
            .required("Required"),
        comment: Yup.string().nullable()
    });

    if (userRole) {

        validation = Yup.object().shape({
            description: Yup.string(),
            comment: Yup.string()
                .min(10, "Comment Too Short!")
                .max(500, "Comment Too Long!")
                .required("Required"),
        });
    }


    return (
        <ModalWindowCenter className={styles.tiketmodals}>
            <Box className={styles.pagetitlebox}>
                <Typography variant="h4" color="textPrimary">
                    Update - Support Ticket
                </Typography>
            </Box>
            <Box>
                <Formik
                    initialValues={{
                        supportType: selectTicket.supportType,
                        description: selectTicket.description,
                        subject: selectTicket.subject,
                        status: selectTicket.status,
                        comment: selectTicket.comment
                    }}
                    validationSchema={validation}
                    onSubmit={onSubmit}
                >
                    {({
                        errors,
                        dirty,
                        isSubmitting,
                        touched,
                        handleChange,
                        values,
                    }) => (
                        <Form className={styles.form} noValidate>
                            <InputLabel style={{ color: "#000", marginBottom: 10, }}>
                                Support Type
                            </InputLabel>

                            <Typography variant="h6" style={{ color: '#a3a3a3', marginBottom: 15, fontSize: 15 }}>{selectTicket.supportType}</Typography>


                            {selectTicket.supportType === "Other Technical Support" ? (
                                <>
                                    <InputLabel style={{ color: "#000", marginBottom: 10 }}>
                                        Subject
                                    </InputLabel>
                                    <Field
                                        as="textarea"
                                        name="subject"
                                        type="text"
                                        className={`${c.input} ${styles.input} ${errors.subject && touched.subject
                                            ? "input-error"
                                            : null
                                            }`}
                                        disabled={selectTicket.subject}

                                    />
                                    {errors.subject && touched.subject && (
                                        <div className={`${styles.errorblock}`}>{errors.subject}</div>
                                    )}
                                </>
                            ) : null}

                            <>
                                <InputLabel style={{ color: "#000", marginBottom: 10 }}>
                                    Description
                                </InputLabel>
                                <Field
                                    as="textarea"
                                    name="description"
                                    placeholder="Enter details of your technical support item"
                                    type="text"
                                    className={`${c.input} ${styles.input} ${errors.description && touched.description
                                        ? "input-error"
                                        : null
                                        }`}
                                    disabled={userRole}

                                />
                                {errors.description && touched.description && (
                                    <div className={`${styles.errorblock}`}>{errors.description}</div>
                                )}
                            </>

                            <InputLabel style={{ color: "#000", marginBottom: 10 }}>
                                Status
                            </InputLabel>

                            <Field
                                component={SelectFormik}
                                name="status"
                                options={Status}
                                onChnage={handleChange}
                                className={`${c.input} ${errors.status && touched.status
                                    ? "input-error"
                                    : null
                                    }`}
                            />

                            <>
                                <InputLabel style={{ color: "#000", marginBottom: 10 }}>
                                    Comment
                                </InputLabel>


                                <Field
                                    as="textarea"
                                    name="comment"
                                    type="text"
                                    className={`${c.input} ${styles.input} ${errors.comment && touched.comment ? "input-error" : null
                                        }`}
                                    disabled={!userRole}
                                />
                                {errors.comment && touched.comment && (
                                    <div className={`${styles.errorblock}`}>{errors.comment}</div>
                                )}</>


                            <Box className={styles.buttonsholder}>
                                <Box
                                    className={`${styles.submitbuttonsholder} ${styles.fillbtn}`}
                                    style={{ display: "flex" }}
                                >
                                    <StandardButton
                                        title="Submit"
                                        type="submit"
                                        accent
                                        disabled={isSubmitting || !dirty}
                                    />
                                    <Box className={styles.cancelbuttonmargin}>
                                        <StandardButton
                                            className={"secondary"}
                                            title="Cancel"
                                            onClick={onCancel}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </ModalWindowCenter>
    );
};
