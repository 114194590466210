import * as React from "react";
import { Shift } from "../../RosterScreen/requests";

export const ShiftContext = React.createContext<any>([]);

export const ShiftProvider: React.FC = ({ children }) => {
  const [shifts, setShifts] = React.useState<Array<Shift>>([]);

  return (
    <ShiftContext.Provider value={{ shifts, setShifts }}>
      {children}
    </ShiftContext.Provider>
  );
};

export const useShift = () => {
  const context = React.useContext(ShiftContext);

  if (!context) {
    throw new Error(`useShift must be called within a ShiftProvider`);
  }

  return context;
};
