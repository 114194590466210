import React from "react";
import styles from "./style.module.css"
import { FieldProps } from "formik";
import { InputBase, Select, MenuItem } from "@material-ui/core";

interface Option {
  value: string | number;
  title: string | number;
}

interface Options {
  options: Array<Option>;
}

const SelectFormik = ({
  field: { value, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
}: FieldProps & Options) => {
  
  return (
    <Select
      {...field}
      value={value || ""}
      className={styles.outer}
      fullWidth
      input={<InputBase />}
    >
      <MenuItem value={""}>None</MenuItem>
      {options.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.title}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectFormik;
