import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  leftSideContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  cancelbuttonmargin: {
    marginRight: theme.spacing(1),
  },
  buttonsHolder: {
    display: "flex",
    justifyContent: "flex-end",
  },
  spacerVertical: {
    height: theme.spacing(3),
  },
  selectorButtonsOuterHolder: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxHeight: 176,
    width: "100%",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  }
}));
