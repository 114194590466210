import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Popover,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import isEqual from "lodash.isequal";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Day } from ".";
import StandardButton from "../../../components/Buttons/StandardButton";
import SwitchButton from "../../../components/Buttons/SwitchButton";
import CalendarBase from "../../../components/calendars/CalendarBase";
import { RWrapper } from "../../../components/layout/RWrapper";
import { Shift } from "../../RosterScreen/requests";
import styles from "./styles.module.css";

const useStyles = makeStyles((theme) => {
  const subTitleBoxHeight = theme.typography.fontSize * 3;

  return {
    pagetitlebox: {
      margin: `${theme.spacing(1)}px 0`,
    },
    titleDateContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: subTitleBoxHeight,
    },
    titleDateText: {
      fontSize: theme.typography.fontSize * 1.4,
    },
    titleDateMonthText: {
      width: 160,
      textAlign: "center",
    },
    arrowButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      width: 40,
      borderRadius: theme.shape.borderRadius,
      transition: `all ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeOut}`,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    calendarContainer: {
      marginTop: theme.spacing(1),
    },
    dayNamesContainer: {
      height: subTitleBoxHeight,
      display: "flex",
    },
    checkboxButton: {
      justifyContent: "left",
      backgroundColor: "white",
      padding: "18px",
      width: "20px",
      "&.Mui-checked": {
        color: "black",
      },
    },
    dayNameBlock: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
    },
    cell: ({ available, own }: { available?: boolean; own?: boolean }) => ({
      backgroundColor: available && !own ? "lightgreen" : undefined,
    }),

    currentDay: ({
      available,
      own,
    }: {
      available?: boolean;
      own?: boolean;
    }) => ({
      backgroundColor:
        available && !own ? "lightgreen" : "rgba(5, 131, 242, 0.1)",
    }),
    cellNumberIndicatorContainer: {
      display: "flex",
      width: "100%",
      // backgroundColor: "gold",
      justifyContent: "space-between",
      alignItems: "center",
    },

    cellIndicator: ({
      available,
      own,
    }: {
      available?: boolean;
      own?: boolean;
    }) => ({
      display: available ? "flex" : "none",
      height: theme.typography.fontSize * 1,
      width: theme.typography.fontSize * 1,
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      backgroundColor:
        available && !own
          ? theme.palette.success.main
          : available && own
            ? theme.palette.warning.main
            : undefined,
    }),
    cellShiftsContainer: {
      display: "flex",
      flex: 1,
      // backgroundColor: "red",
      justifyContent: "center",
      alignItems: "center",
    },
    cellShiftsDetailsArrowContainer: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      // width: 20,
      flex: 1,
      borderRadius: theme.shape.borderRadius,
      transition: `all ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeOut}`,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    cellShiftsDetailsArrowPlaceholder: {
      flex: 1,
    },
    cellShiftsDetailsContainer: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      flex: 5,
      height: "100%",
      // justifyContent: "center",
      // alignItems: "center",
    },
    cellShiftsDetailsTimeText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    popoverContainer: {
      height: 150,
      width: 300,
      maxWidth: "80wv",
      padding: theme.spacing(2),
    },
    popoverDateContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1),
    },
    popoverTextSplitContainer: {
      display: "flex",
      flex: 1,
    },
    popoverSplitText: {
      flex: 1,
    },
    popoverDateText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    popoverButtonContainer: {
      marginTop: theme.spacing(1),
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },
    popoverButtonContainerInner: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  };
});

interface CellProps {
  current: boolean;
  number: number;
  shifts?: Array<Shift>;
  onAvailableChange: (shift: Shift) => void;
  buttonDisabled: boolean;
}
const Cell = ({
  number,
  shifts,
  current,
  onAvailableChange,
  buttonDisabled,
}: CellProps) => {
  const history = useHistory();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "shiftDetails",
  });

  const [dialogOpen, setdialogOpen] = useState<boolean>(false);

  const [shiftIdx, setshiftIdx] = useState<number>(0);
  useEffect(() => {
    setshiftIdx(0);
  }, [shifts]);

  const currentShift =
    shifts && shifts[shiftIdx] ? shifts[shiftIdx] : undefined;

  const prevShift = () => {
    const newIdx = shiftIdx - 1;
    if (shifts && shifts[newIdx]) {
      setshiftIdx(newIdx);
    }
  };

  const nextShift = () => {
    const newIdx = shiftIdx + 1;
    if (shifts && shifts[newIdx]) {
      setshiftIdx(newIdx);
    }
  };

  const onMakeAvailableClick = () => {
    if (currentShift) {
      onAvailableChange(currentShift);
    }
  };

  const c = useStyles({
    available: currentShift && currentShift.available ? true : undefined,
    own: currentShift && currentShift.own,
  });

  return (
    <Paper className={`${styles.cell} ${current ? c.currentDay : ""} ${c.cell}`}  >
      <div className={c.cellNumberIndicatorContainer}>
        <Typography color="textPrimary">{number}</Typography>
        <div className={c.cellIndicator} />
      </div>
      {shifts && shifts.length && currentShift ? (
        <div className={c.cellShiftsContainer}>
          {shifts.length > 1 && shifts[shiftIdx - 1] ? (
            <div
              className={c.cellShiftsDetailsArrowContainer}
              onClick={prevShift}
            >
              <Typography color="textPrimary">{"<"}</Typography>
            </div>
          ) : (
            <div className={c.cellShiftsDetailsArrowPlaceholder} />
          )}
          <div
            className={c.cellShiftsDetailsContainer}
            {...bindTrigger(popupState)}
          >
            {shifts[shiftIdx].startDts && shifts[shiftIdx].endDts ? (
              <Typography
                color="textPrimary"
                className={c.cellShiftsDetailsTimeText}
              >
                Time:
                {moment(currentShift.startDts).format("HH:mm")} -{" "}
                {moment(currentShift.endDts).format("HH:mm")}
              </Typography>
            ) : null}
            <Typography color="textPrimary" className={styles.boxcontent}>
              Shift Name: {currentShift.shiftName}
            </Typography>
            <Typography color="textPrimary" className={styles.boxcontent}>
              Location: {currentShift.roster?.location?.name}
            </Typography>
            <Typography color="textPrimary" className={styles.boxcontent}>
              Owner: {currentShift.user?.fullName}
            </Typography>
          </div>
          {shifts.length > 1 && shifts[shiftIdx + 1] ? (
            <div
              className={c.cellShiftsDetailsArrowContainer}
              onClick={nextShift}
            >
              <Typography color="textPrimary">{">"}</Typography>
            </div>
          ) : (
            <div className={c.cellShiftsDetailsArrowPlaceholder} />
          )}
        </div>
      ) : null}
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        BackdropProps={{}}
      >
        {currentShift ? (
          <Paper className={c.popoverContainer} elevation={0}>
            <div className={c.popoverDateContainer}>
              <Typography className={c.popoverDateText} color="textPrimary">
                {moment(currentShift.startDts).format("ddd DD MMMM YYYY")}
              </Typography>
              <div className={c.cellIndicator} />
            </div>
            <div className={c.popoverTextSplitContainer}>
              <Typography className={c.popoverSplitText} color="textPrimary">
                Time
              </Typography>
              <Typography className={c.popoverSplitText} color="textPrimary">
                {`${moment(currentShift.startDts).format("HH:mm")} - ${moment(
                  currentShift.endDts
                ).format("HH:mm")}`}
              </Typography>
            </div>
            <div className={c.popoverTextSplitContainer}>
              <Typography className={c.popoverSplitText} color="textPrimary">
                Shift name
              </Typography>
              <Typography className={c.popoverSplitText} color="textPrimary">
                {currentShift.shiftName}
              </Typography>
            </div>
            <div className={c.popoverTextSplitContainer}>
              <Typography className={c.popoverSplitText} color="textPrimary">
                Location
              </Typography>
              <Typography className={c.popoverSplitText} color="textPrimary">
                {currentShift.roster?.location?.name}
              </Typography>
            </div>
            <div className={c.popoverTextSplitContainer}>
              <Typography className={c.popoverSplitText} color="textPrimary">
                Owner
              </Typography>
              <Typography className={c.popoverSplitText} color="textPrimary">
                {currentShift.user?.fullName}
              </Typography>
            </div>

            {new Date(currentShift.startDts) > new Date() ? (
              <div className={c.popoverButtonContainer}>
                {currentShift.own ? (
                  <div className={c.popoverButtonContainerInner}>
                    <SwitchButton
                      title={"Swap"}
                      onClick={() => {
                        history.push("/swap", { shift: currentShift });
                      }}
                    />
                    <SwitchButton
                      title={
                        currentShift.available
                          ? "Make unavailable"
                          : "Make available"
                      }
                      color={currentShift.available ? "warning" : undefined}
                      onClick={onMakeAvailableClick}
                      disabled={buttonDisabled}
                    />
                  </div>
                ) : (
                  <SwitchButton
                    title={"Take Shift"}
                    color={currentShift.available ? "success" : undefined}
                    onClick={() => setdialogOpen(true)}
                  />
                )}
              </div>
            ) : null}
          </Paper>
        ) : null}
      </Popover>

      <Dialog open={dialogOpen} onClose={() => setdialogOpen(false)}>
        <DialogTitle>Accept shift?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm that you want to take this shift
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StandardButton title="Close" onClick={() => setdialogOpen(false)} />
          <StandardButton
            title="Confirm"
            accent
            onClick={async () => {
              await onMakeAvailableClick();
              await setdialogOpen(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

interface UserMonthlyRosterDesktopProps {
  date?: Moment;
  shifts: {
    [key: string]: Array<Shift>;
  };
  today?: Day;
  selectedDay?: Day;
  setselectedDay: (d: Day | undefined) => void;
  onPrevMonthClick: () => void;
  onNextMonthClick: () => void;
  onAvailableChange: (shift: Shift) => void;
  onHandleCheckBox: (available: boolean) => void;
}

export default function UserMonthlyRosterDesktop({
  date,
  shifts,
  today,
  selectedDay,
  setselectedDay,
  onPrevMonthClick,
  onNextMonthClick,
  onAvailableChange,
  onHandleCheckBox,
}: UserMonthlyRosterDesktopProps) {
  const c = useStyles();
  if (!date) {
    return null;
  }

  const handleClick = async (e: any) => {
    onHandleCheckBox(e.target.checked);
  };

  return (
    <RWrapper>
      <div className={c.pagetitlebox}>
        <Typography variant="h4" color="textPrimary">
          My Roster
        </Typography>
      </div>
      <Divider />
      <div className={c.titleDateContainer}>
        <div className={c.arrowButton} onClick={onPrevMonthClick}>
          <Typography className={c.titleDateText} color="textPrimary">
            {"<"}
          </Typography>
        </div>
        <div className={c.titleDateMonthText}>
          <Typography className={c.titleDateText} color="textPrimary">
            {date.format("YYYY MMMM")}
          </Typography>
        </div>
        <div className={c.arrowButton} onClick={onNextMonthClick}>
          <Typography className={c.titleDateText} color="textPrimary">
            {">"}
          </Typography>
        </div>
      </div>
      <Divider />
      <div className={c.dayNamesContainer}>
        <div className={c.dayNameBlock}>
          <Typography color="textPrimary">Monday</Typography>
        </div>
        <div className={c.dayNameBlock}>
          <Typography color="textPrimary">Tuesday</Typography>
        </div>
        <div className={c.dayNameBlock}>
          <Typography color="textPrimary">Wednesday</Typography>
        </div>
        <div className={c.dayNameBlock}>
          <Typography color="textPrimary">Thursday</Typography>
        </div>
        <div className={c.dayNameBlock}>
          <Typography color="textPrimary">Friday</Typography>
        </div>
        <div className={c.dayNameBlock}>
          <Typography color="textPrimary">Saturday</Typography>
        </div>
        <div className={c.dayNameBlock}>
          <Typography color="textPrimary">Sunday</Typography>
        </div>
      </div>
      <Divider />
      <div className={c.calendarContainer}>
        <CalendarBase
          month={Number(date.format("M"))}
          year={Number(date.format("YYYY"))}
          spacing={1}
          cellComponent={(d) =>
            d.type === "current" ? (
              <Cell
                onAvailableChange={onAvailableChange}
                number={d.day}
                current={isEqual(
                  { year: d.year, month: d.month, day: d.day },
                  today
                )}
                shifts={
                  shifts[
                  `${d.year}.${d.month.toString().padStart(2, "0")}.${d.day
                    .toString()
                    .padStart(2, "0")}`
                  ]
                }
                buttonDisabled={false}
              />
            ) : null
          }
        />
      </div>
      <div>
        <Checkbox onClick={handleClick} color="primary" />
        Show Available Shifts
      </div>
    </RWrapper>
  );
}
