import { Box, Grid, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import proaxiomLogo from "../../assets/images/ProaxiomLogo.png";
import SimpleSlider from "../../componentsV3/SlickSlider";
import style from "../../css/style.module.css";
import Logo from "./Logo";
import { requestVerifyCode } from "./requests";
import { Formik, Field, Form } from "formik";
import { motion } from "framer-motion";
import * as Yup from "yup";

const TwoFactor: React.FC<{ email: string }> = ({ email }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  const [code, setCode] = useState<string>("");

  const { mutateAsync } = useMutation(
    () => requestVerifyCode({ email, code }),
    {
      onError: () => {
        enqueueSnackbar(`Invalid code`, { variant: "error" });
      },
      onSuccess: async () => {
        push("/");
      },
    }
  );

  const validation = Yup.object().shape({
    number: Yup.string()
      .min(0, "Too Short!")
      .max(6, "Too Long!")
      .required("Required"),
  });

  const onSubmit = (value: any) => {
    setCode(value.number);
    mutateAsync();
  };

  return (
    <>
      <Grid container className={style.screencontainer}>
        <Grid item xs={12} sm={12} md={6} className={style.ScreenLeft}>
          <SimpleSlider />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={style.ScreenRight}>
          <Box className={style.formContainer}>
            <Box className={style.logoholder}>
              <Logo />
              <Box className={style.formcontent}>
                <Typography variant="h3" className="mt-1">
                  Two-Factor Authentication
                </Typography>
                <Typography variant="body1">
                  Risus eget velit risus et ultrices erat. Morbi amet, at nec
                  dignissim donec.
                </Typography>
              </Box>
            </Box>
            <Box>
              <Formik
                initialValues={{ number: "" }}
                validationSchema={validation}
                onSubmit={onSubmit}
              >
                {({ errors, isValid, dirty, isSubmitting, touched }) => (
                  <Form className={style.form} noValidate>
                    <Field
                      className={`${style.input} ${
                        errors.number && touched.number ? "input-error" : null
                      }`}
                      name="number"
                      type="number"
                      autoCorrect="off"
                      autoCapitalize="none"
                      placeholder="Authentication Code"
                    />

                    {errors.number && touched.number && (
                      <div className={`${style.errorblock}`}>
                        {errors.number}
                      </div>
                    )}

                    <motion.button
                      type="submit"
                      className={style.button}
                      disabled={!dirty}
                    >
                      <span className={style.buttonText}>Verify</span>
                    </motion.button>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
          <Box className={style.proaxiomLogo}>
            <Typography
              color="textPrimary"
              style={{
                fontSize: "14px",
                color: "#9A9A9A",
                marginRight: 5,
                lineHeight: "1",
              }}
              className={style.userRole}
            >
              Powered by{" "}
            </Typography>
            <img
              className={style.proaxiomLogoImg}
              src={proaxiomLogo}
              alt="logo"
              width="190px"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export { TwoFactor };
