import React from "react";
import { Formik, Field, Form } from "formik";
import style from "../style.module.css";
import { motion, AnimatePresence } from "framer-motion";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styled from "styled-components";

export interface ChangePasswordSubmitValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const Input = styled(Field)`
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 400;
  width: 40%;
  padding: 0.6rem 0.6rem;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }
`;

export interface FormProps {
  onSubmit: ({
    oldPassword,
    newPassword,
    confirmPassword,
  }: ChangePasswordSubmitValues) => void;
  externalError: string;
  //   history: History;
}

const validation = ({
  oldPassword,
  newPassword,
  confirmPassword,
}: {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  passwordNotMatched?: string;
}) => {
  const errors: { [key: string]: string } = {};
  if (!oldPassword) {
    errors.oldPassword = "Old Password is required";
  } else if (oldPassword.length < 6) {
    errors.oldPassword = "Old Password is too short";
  } else if (!newPassword) {
    errors.newPassword = "New Password is required";
  } else if (newPassword.length < 6) {
    errors.newPassword = "New Password is too short";
  } else if (!confirmPassword) {
    errors.confirmPassword = "Confirm Password is required";
  } else if (confirmPassword.length < 6) {
    errors.confirmPassword = "Confirm Password is too short";
  } else if (newPassword !== confirmPassword) {
    errors.passwordNotMatched =
      "New Passwod and Confirm Password must be matched!";
  }
  return errors;
};

export default ({ onSubmit, externalError }: FormProps) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validate={validation}
          onSubmit={onSubmit}
        >
          {({ errors, isValid, dirty, touched, isSubmitting }) => (
            <Form className={style.form} noValidate>
              <div
                className={`${style.errorblock} ${
                  externalError ||
                  (errors.oldPassword && touched.oldPassword
                    ? style.erropacity1
                    : "") ||
                  (errors.newPassword && touched.newPassword
                    ? style.erropacity1
                    : "") ||
                  (errors.confirmPassword && touched.confirmPassword
                    ? style.erropacity1
                    : "") ||
                  (errors.passwordNotMatched && touched.passwordNotMatched
                    ? style.erropacity1
                    : "")
                }`}
              >
                <span className={style.errortext}>
                  {externalError ? (
                    <Alert severity="error" style={{ marginBottom: "13px" }}>
                      {externalError}
                    </Alert>
                  ) : (
                    ""
                  )}
                  {errors.oldPassword ? (
                    <Alert severity="error" style={{ marginBottom: "13px" }}>
                      {errors.oldPassword}
                    </Alert>
                  ) : (
                    ""
                  )}
                  {errors.newPassword ? (
                    <Alert severity="error" style={{ marginBottom: "13px" }}>
                      {errors.newPassword}
                    </Alert>
                  ) : (
                    ""
                  )}
                  {errors.confirmPassword ? (
                    <Alert severity="error" style={{ marginBottom: "13px" }}>
                      {errors.confirmPassword}
                    </Alert>
                  ) : (
                    ""
                  )}
                  {errors.passwordNotMatched ? (
                    <Alert severity="error" style={{ marginBottom: "13px" }}>
                      {errors.passwordNotMatched}
                    </Alert>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <Box>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={2}
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      justifyItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      Old Password
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Input
                      name="oldPassword"
                      type="password"
                      placeholder="Old Password"
                      className={style.input}
                      autoCorrect="off"
                      autoCapitalize="none"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      justifyItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      New Password
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Input
                      name="newPassword"
                      type="password"
                      placeholder="New Password"
                      className={style.input}
                      autoCorrect="off"
                      autoCapitalize="none"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      justifyItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      Confirm Password
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Input
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                      className={style.input}
                      autoCorrect="off"
                      autoCapitalize="none"
                    />
                  </Grid>
                  <Grid item style={{ margin: "5px 2px" }} xs={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </motion.div>
    </AnimatePresence>
  );
};
