import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalContainer from "../../../componentsV3/modals/ModalContainer";
import ModalWindowCenter from "../../../componentsV3/modals/ModalWindowCenter";
import { Grid } from "@material-ui/core";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import { Shift } from "../../RosterScreen/requests";
import SwapShiftCard from "./swapShiftCard";

const useStyles = makeStyles((theme) => {
  return {
    arrowsContainer: {
      display: "flex",
      flex: 1,
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    buttonsHolder: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: theme.spacing(2),
      flexWrap: "wrap",
    },
    btn: {
      minWidth: 130,
      margin: theme.spacing(0.5),
    },
    btnWarn: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      border: "none",
      "&:focus, &:active": {
        border: "none",
      },
    },
  };
});

interface SwapModalProps {
  onClose: () => void;
  open: boolean;
  currentShift?: Shift;
  swapShift?: Shift[];
  onSubmit?: (p: { currentShift: Shift; swapShift: Shift }) => void;
  onDelete?: (id: number) => void;
  onAccept?: (id: number) => void;
  offerId?: number;
  onTakeShift?: (p: { swapShift: Shift }) => void;
}

export default function SwapModal({
  onClose,
  open,
  currentShift,
  swapShift,
  onSubmit,
  onDelete,
  onAccept,
  offerId,
  onTakeShift,
}: SwapModalProps) {
  const c = useStyles();

  return (
    <ModalContainer onClose={onClose} open={open}>
      <ModalWindowCenter>
        {swapShift?.length && (
          <Grid container spacing={1}>
            {currentShift
              ? swapShift.map((shift) => {
                return (
                  <>
                    {" "}
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <SwapShiftCard shift={currentShift} outlined />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className={c.arrowsContainer}>
                        <SwapHorizIcon color="action" />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <SwapShiftCard shift={shift} outlined />
                    </Grid>
                  </>
                );
              })
              : swapShift.map((shift) => {
                return (
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <SwapShiftCard shift={shift} outlined />
                  </Grid>
                );
              })}
          </Grid>
        )}

        <div className={c.buttonsHolder}>
          <StandardButton
            className={`secondary ${c.btn}`}
            title="Cancel"
            onClick={onClose}
          />
          {currentShift && swapShift?.length && onSubmit ? (
            <Fragment>
              <StandardButton
                className={`primary ${c.btn}`}
                title="Send Request"
                accent
                onClick={() =>
                  swapShift?.map((shift) =>
                    onSubmit({ currentShift, swapShift: shift })
                  )
                }
              />
            </Fragment>
          ) : null}
          {!currentShift && swapShift && onTakeShift ? (
            <Fragment>
              <StandardButton
                className={`primary ${c.btn}`}
                title="Send Request"
                accent
                onClick={() => onTakeShift({ swapShift: swapShift[0] })}
              />
            </Fragment>
          ) : null}
          {currentShift && swapShift && onDelete && offerId ? (
            <Fragment>
              <StandardButton
                className={`danger ${c.btn} ${c.btnWarn}`}
                title={onAccept ? "Decline offer" : "Cancel shift request"}
                onClick={() => onDelete(offerId)}
              />
            </Fragment>
          ) : null}
          {currentShift && swapShift && onAccept && offerId ? (
            <Fragment>
              <StandardButton
                className={`primary ${c.btn}`}
                accent
                title="Accept offer"
                onClick={() => onAccept(offerId)}
              />
            </Fragment>
          ) : null}
        </div>
      </ModalWindowCenter>
    </ModalContainer>
  );
}
