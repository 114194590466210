import React, { useCallback, useEffect, useState } from "react";
import style from "../../css/style.module.css";
import LoginForm, { FormValues } from "./LoginForm";
import Logo from "./Logo";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { requestLogin } from "./requests";
import { useProgressBar } from "../../componentsV3/bars/ProgressBarGlobal";
import { Typography, Box, Grid } from "@material-ui/core";
import proaxiomLogo from "../../assets/images/proaxiom.png";
import SimpleSlider from "../../componentsV3/SlickSlider";
import { useSnackbar } from "notistack";
import { TwoFactor } from "./two-factor";
import { decode } from "jsonwebtoken";
import proaxiomLogoX from "../../componentsV3/nav/x.png";

export default ({ history }: RouteComponentProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const [apiError, setapiError] = useState("");
  const { barActivate, barStop } = useProgressBar();
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    const token: string | null = localStorage.getItem("token");
    const decoded = token ? decode(token, { complete: true }) : undefined;
    const userId = decoded ? (decoded as any).payload.bird : undefined;
    const verified: boolean = localStorage.getItem("isVerified") === "true";

    if (token && userId && verified) {
      push("/");
    } else {
      localStorage.clear();
    }
  }, []);

  let timeout: any;

  const submit = useCallback(
    async (values: FormValues) => {
      if (!values.email || !values.password) return;
      try {
        barActivate();
        const { isVerified, twoFA } = await requestLogin(values);
        if (isVerified) {
          localStorage.setItem("isVerified", String(isVerified));
        }
        if (twoFA) {
          setEmail(values.email);
        } else {
          push("/");
        }
        // enqueueSnackbar("You are Logged In Sucessfully!", {
        //   variant: "success",
        // });
      } catch (error) {
        localStorage.clear();
        clearTimeout(timeout);
        timeout = setTimeout(() => setapiError(""), 3000);

        if (error.response && error.response.status === 401) {
          enqueueSnackbar("You Entered Wrong Credentials!", {
            variant: "error",
          });
          setapiError("Email or password does not match");
        } else {
          setapiError("Something went wrong. Please try again later");
          console.log("Login");
        }
      } finally {
        barStop();
      }
    },
    [barActivate, barStop, enqueueSnackbar]
  );

  if (email && localStorage.getItem("isVerified") === "true") {
    return <TwoFactor email={email} />;
  }

  return (
    <>
      <Grid container className={style.screencontainer}>
        <Grid item xs={12} sm={12} md={6} className={style.ScreenLeft}>
          <SimpleSlider />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={style.ScreenRight}>
          <Box className={style.formContainer}>
            <Box className={style.logoholder}>
              <Logo />
              <Box className={style.formcontent}>
                <Typography variant="h3" className="mt-1">
                  Sign in to Rooster
                </Typography>
                <Typography variant="body1">
                  Risus eget velit risus et ultrices erat. Morbi amet, at nec
                  dignissim donec.
                </Typography>
              </Box>
            </Box>
            <LoginForm onSubmit={submit} externalError={apiError} />
          </Box>
          <Box className={style.proaxiomLogo}>
            <Typography
              color="textPrimary"
              style={{
                fontSize: "14px",
                color: "#9A9A9A",
                marginRight: 5,
                lineHeight: "1",
              }}
              className={style.userRole}
            >
              Powered by{" "}
            </Typography>
            <img src={proaxiomLogoX} alt="logo" />
            <img
              className={style.proaxiomLogoImg}
              src={proaxiomLogo}
              alt="logo"
              width="190px"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
