import React, { useState, useEffect, useRef } from "react";
import styles from "../../../css/style.module.css";
import CalendarBase, { CalendarBaseDay } from "../CalendarBase";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import { Box } from "@material-ui/core";
import moment from "moment";

type OnSelected = (props: OnRangeCalendarSelectedProps | undefined) => void;

// export type OnRangeCalendarSelectedProps =
//   | OnDateRangeSelected
//   | OnSingleDateSelected
//   | undefined;

export interface OnRangeCalendarSelectedProps {
  range?: { leftDate: CalendarBaseDay; rightDate: CalendarBaseDay };
  single?: CalendarBaseDay;
}

interface OnDateRangeSelected {
  range: { leftDate: CalendarBaseDay; rightDate: CalendarBaseDay };
}

interface OnSingleDateSelected {
  single: CalendarBaseDay;
}

interface RangeCaledarProps {
  year?: number;
  month?: number;
  date?: Date;
  onSelected?: OnSelected;
  selectSingle?: boolean;
}

const leftEdge = [0, 7, 14, 21, 28, 35];
const rightEdge = [6, 13, 20, 27, 34, 41];

const RangeCaledar = ({
  year,
  month,
  onSelected,
  selectSingle,
}: RangeCaledarProps) => {
  const classes = useStyles();

  const [displayYear, setdisplayYear] = useState<number | undefined>(undefined);
  const [displayMonth, setdisplayMonth] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    const y = Number(year);
    const m = Number(month);

    if (y) {
      setdisplayYear(y);
    } else {
      setdisplayYear(new Date().getFullYear());
    }
    if (m) {
      setdisplayMonth(m);
    } else {
      setdisplayMonth(new Date().getMonth() + 1);
    }
  }, [year, month]);

  const goToNextMonth = () => {
    if (displayYear && displayMonth) {
      let y = displayYear;
      let m = displayMonth + 1;

      if (displayMonth === 12) {
        m = 1;
        y = displayYear + 1;
      }
      setdisplayYear(y);
      setdisplayMonth(m);
    }
  };

  const goToPrevMonth = () => {
    if (displayYear && displayMonth) {
      let y = displayYear;
      let m = displayMonth - 1;

      if (displayMonth === 1) {
        m = 12;
        y = displayYear - 1;
      }
      setdisplayYear(y);
      setdisplayMonth(m);
    }
  };

  const [leftDate, setleftDate] = useState<CalendarBaseDay | undefined>(
    undefined
  );
  const [rightDate, setrightDate] = useState<CalendarBaseDay | undefined>(
    undefined
  );

  const dateChanged = useRef(false);

  useEffect(() => {
    if (onSelected) {
      if (!leftDate && dateChanged.current) {
        onSelected(undefined);
      } else if (leftDate === rightDate) {
      } else if (leftDate && rightDate) {
        onSelected({ range: { leftDate, rightDate } });
      } else if (leftDate && !rightDate) {
        onSelected({ single: leftDate });
      }
    }
  }, [leftDate, rightDate, onSelected]);

  const handleDayClick = (d: CalendarBaseDay) => {
    dateChanged.current = true;
    if (selectSingle) {
      setleftDate(d);
    } else {
      if (!leftDate && !rightDate) {
        setleftDate(d);
      } else if (leftDate === d) {
        setleftDate(rightDate);
        setrightDate(undefined);
      } else if (rightDate === d) {
        setrightDate(undefined);
      } else if (leftDate && !rightDate) {
        if (
          moment(`${d.year}-${d.month}-${d.day}`, "YYYY-MM-DD").isAfter(
            moment(
              `${leftDate.year}-${leftDate.month}-${leftDate.day}`,
              "YYYY-MM-DD"
            )
          )
        ) {
          setrightDate(d);
        } else {
          setrightDate(leftDate);
          setleftDate(d);
        }
      } else if (leftDate && rightDate) {
        setleftDate(d);
        setrightDate(undefined);
      }
    }
  };

  const cellActive = (
    d: CalendarBaseDay,
    index: number
  ): "left" | "right" | "full" | "single" | false => {
    if (leftDate && rightDate) {
      const momentF = moment(
        `${leftDate.year}-${leftDate.month}-${leftDate.day}`,
        "YYYY-MM-DD"
      );
      const momentS = moment(
        `${rightDate.year}-${rightDate.month}-${rightDate.day}`,
        "YYYY-MM-DD"
      );

      const between = moment(
        `${d.year}-${d.month}-${d.day}`,
        "YYYY-MM-DD"
      ).isBetween(momentF, momentS);
      if (between) {
        if (leftEdge.includes(index)) {
          return "left";
        } else if (rightEdge.includes(index)) {
          return "right";
        } else {
          return "full";
        }
      } else if (
        d.day === leftDate.day &&
        d.month === leftDate.month &&
        d.year === leftDate.year
      ) {
        return "left";
      } else if (
        d.day === rightDate.day &&
        d.month === rightDate.month &&
        d.year === rightDate.year
      ) {
        return "right";
      }
    } else {
      if (
        (leftDate &&
          d.day === leftDate.day &&
          d.month === leftDate.month &&
          d.year === leftDate.year) ||
        (rightDate &&
          d.day === rightDate.day &&
          d.month === rightDate.month &&
          d.year === rightDate.year)
      ) {
        return "single";
      }
    }
    return false;
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.arrow} onClick={goToPrevMonth}>
          {"<"}
        </div>
        <Typography className={classes.month}>
          {moment(`${displayYear}-${displayMonth}`, "YYYY-MM").format(
            "YYYY MMMM"
          )}
        </Typography>
        <div className={classes.arrow} onClick={goToNextMonth}>
          {">"}
        </div>
      </div>
      <Box className={styles.dayNamesRangeContainer}>
        <Box className={styles.dayNameBlock}>
          <Typography color="textPrimary">Mo</Typography>
        </Box>
        <Box className={styles.dayNameBlock}>
          <Typography color="textPrimary">Tu</Typography>
        </Box>
        <Box className={styles.dayNameBlock}>
          <Typography color="textPrimary">We</Typography>
        </Box>
        <Box className={styles.dayNameBlock}>
          <Typography color="textPrimary">Th</Typography>
        </Box>
        <Box className={styles.dayNameBlock}>
          <Typography color="textPrimary">Fr</Typography>
        </Box>
        <Box className={styles.dayNameBlock}>
          <Typography color="textPrimary">Sa</Typography>
        </Box>
        <Box className={styles.dayNameBlock}>
          <Typography color="textPrimary">Su</Typography>
        </Box>
      </Box>
      <CalendarBase
        spacing={0}
        year={displayYear}
        month={displayMonth}
        cellComponent={(d, i) => {
          const { day, type } = d;
          const active = cellActive(d, i);
          return (
            <div
              className={`${classes.cell}`}
              onClick={() => handleDayClick(d)}
            >
              <div
                className={`${classes.inner} ${
                  active === "full"
                    ? classes.highligted
                    : active === "single"
                    ? `${classes.circle} ${classes.highligted}`
                    : active === "left"
                    ? classes.circleLeft
                    : active === "right"
                    ? classes.circleRight
                    : ""
                }`}
              >
                <Typography
                  className={
                    type === "current"
                      ? active
                        ? `${classes.numbercurrent} ${classes.whiteText}`
                        : `${classes.numbercurrent}`
                      : active
                      ? `${classes.numbergrayed} ${classes.activeGrayText}`
                      : classes.numbergrayed
                  }
                >
                  {`${day}`.padStart(2, "0")}
                </Typography>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default RangeCaledar;
