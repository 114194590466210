import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

interface SwitchButtonProps {
  title: string;
  color?: "warning" | "primary" | "success";
  onClick?: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 30,
      width: "fit-content",
      minWidth: 60,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid`,
      borderColor: ({
        borderColor,
      }: {
        borderColor?: "warning" | "primary" | "success";
      }) => {
        return borderColor === "warning"
          ? theme.palette.warning.main
          : borderColor === "success"
          ? theme.palette.success.main
          : borderColor === "primary"
          ? theme.palette.primary.main
          : theme.palette.common.black;
      },
      transition: `all ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeOut}`,
      boxShadow: theme.shadows[2],
      "&:hover": {
        boxShadow: theme.shadows[3],
      },
    },
  };
});

export default function SwitchButton({
  title,
  onClick,
  color,
  disabled,
}: SwitchButtonProps) {
  const c = useStyles({ borderColor: color });

  const clickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={c.container} onClick={disabled ? () => {} : clickHandler}>
      <Typography color="textPrimary">{title}</Typography>
    </div>
  );
}
