import React from "react";
import styled from "styled-components";

const CheckboxOuter = styled.div<{ sizeOuter?: number }>`
  height: ${({ sizeOuter }) => sizeOuter || "24"}px;
  width: ${({ sizeOuter }) => sizeOuter || "24"}px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border 1px solid lightgrey;
  cursor: pointer;
  box-sizing: border-box;
`;

const CheckboxInner = styled.div<{ sizeInner?: number; active: boolean }>`
  height: ${({ sizeInner }) => sizeInner || "12"}px;
  width: ${({ sizeInner }) => sizeInner || "12"}px;
  border-radius: 2px;
  border: ${(props: { active: boolean }) =>
    props.active ? "none" : "1px solid lightgrey"};
  background-color: ${(props: { active: boolean }) =>
    props.active ? "#0583f2" : "transparent"};
  transition: all 0.2s ease-out;
`;

interface CheckboxProps {
  checked: boolean;
  onClick: (checked: boolean) => void;
  id?: string;
  sizeOuter?: number;
  sizeInner?: number;
}
function RegularCheckbox({
  checked,
  onClick,
  id,
  sizeOuter,
  sizeInner,
}: CheckboxProps) {
  return (
    <CheckboxOuter
      id={id}
      onClick={() => onClick(!checked)}
      sizeOuter={sizeOuter}
    >
      <CheckboxInner active={checked} sizeInner={sizeInner} />
    </CheckboxOuter>
  );
}
export default RegularCheckbox;
