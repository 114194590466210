import React from "react";
import styles from "./styles.module.css";

interface FolatingButtonProps {
  title: string | JSX.Element;
  height?: string | number;
  width?: string | number;
  onClick?: () => void;
  active?: boolean;
}

export default ({
  height,
  width,
  title,
  onClick,
  active,
}: FolatingButtonProps) => {
  return (
    <div
      className={`${styles.container} ${active && styles.active}`}
      onClick={onClick}
      style={{ minWidth: width }}
    >
      <span className={styles.title}>{title}</span>
    </div>
  );
};
