import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Divider,
  Typography,
  Box,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import CalendarHorizontalBase from "../../../componentsV3/calendars/CalendarHorizontalBase";
import moment, { Moment } from "moment";
import { Shift } from "../../RosterScreen/requests";
import { UserShiftsToSwapResponse } from "../../../requests/shifts";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import Card from "./swapShiftCard";
import { useShift } from "../UserRequestSwapScreen/Provider";
import style from "../../../css/style.module.css";
import arrowLeft from "../../../assets/images/arrow/arrow-left.svg";
import arrowRight from "../../../assets/images/arrow/arrow-right.svg";
import arrowMoveLeft from "../../../assets/images/arrow/arrow-move-left.svg";
import arrowMoveRight from "../../../assets/images/arrow/arrow-move-right.svg";
interface SwapShiftComponentDesktopProps {
  startMoment: Moment;
  setstartMoment: (m: Moment) => void;
  shiftsToSwap: UserShiftsToSwapResponse;
  selectedShift?: Array<Shift>;
  ownShift: Shift;
  onSelectSwapClick: (s: void) => void;
}

const useStyles = makeStyles((theme) => {
  const cellHeight = theme.typography.fontSize * 5;
  return {
    desktopCalendar: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      boxSizing: "border-box",
      minWidth: "fit-content",
    },
    leftdesktopCalendar: {
      padding: 20,
      background: "#fff",
      border: "1px solid #e6e6e6",
      borderRadius: 14,
      display: 'flex',
      justifyContent: 'center'
    },
    desktopCalendarContainer: {
      display: "flex",
      flex: 1,
    },
    desktopCalendarHolder: {
      display: "flex",
      width: "100%",
    },
    desktopCalendarNamesColumn: {
      display: "flex",
      flexDirection: "column",
      // backgroundColor: "yellow",
      width: 150,
      overflow: "hidden",
    },
    desktopCalendarColumn: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      // border: "1px solid white",
    },
    desktopCalendarCell: {
      width: "100%",
      minWidth: 82,
      padding: theme.spacing(0.5),
      height: cellHeight,
      // backgroundColor: "black",
      boxSizing: "border-box",
    },
    desktopCalendarCellInner: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      // backgroundColor: "lightgreen",
      fontSize: theme.typography.fontSize,
      whiteSpace: "nowrap",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
      overflow: "hidden",
      transition: `box-shadow ${theme.transitions.duration.shorter}ms`,
    },
    activeShadow: {
      cursor: "pointer",
      "&:hover": {
        boxShadow: theme.shadows[1],
      },
    },
    desktopCalendarControlBar: {
      width: "100%",
      marginBottom: theme.spacing(1),
      // height: cellHeight * 2,
      // backgroundColor: "gold",
    },
    desktopCalendarNavigation: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      height: cellHeight,
    },
    desktopCalendarDescription: {
      display: "flex",
      alignItems: "center",
      flex: 1,
      height: cellHeight,
    },
    desktopCalendarDescriptionCell: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: theme.typography.fontSize,
    },
    monthText: {
      width: 150,
      textAlign: "center",
    },
    arrow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 40,
      height: "50%",
      borderRadius: theme.shape.borderRadius,
      textAlign: "center",
      cursor: "pointer",
      touchAction: "manipulation",
      transition: `all ${theme.transitions.duration.shorter}ms`,
    },
    cardHolder: {
      // backgroundColor: "gold",
      width: "100%",
      padding: theme.spacing(1),
      boxSizing: "border-box",
    },
    cardHolderSelectedCardWrapper: {
      marginTop: theme.spacing(3),
    },
    removeButtonHolder: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      padding: "4px",
    },
    selectButtonHolder: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      paddingTop: "4px",
    },
    titleAboveTheCard: {
      marginBottom: theme.spacing(1),
    },
    titleBelowTheCard: {
      marginTop: theme.spacing(1),
    },
    titleInCard: {
      margin: theme.spacing(2),
    },
  };
});

export default function SwapShiftComponentDesktop({
  startMoment,
  setstartMoment,
  shiftsToSwap,
  selectedShift,
  ownShift,
  onSelectSwapClick,
}: SwapShiftComponentDesktopProps) {
  const c = useStyles();
  const { shifts, setShifts } = useShift();

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.only("sm"));
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.only("md"));
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.only("lg"));
  const xl = useMediaQuery((theme: Theme) => theme.breakpoints.only("xl"));

  const [amountOfDaysToShow, setamountOfDaysToShow] = useState(7);

  useEffect(() => {
    if (xs) {
      setamountOfDaysToShow(2);
    } else if (sm) {
      setamountOfDaysToShow(3);
    } else if (md) {
      setamountOfDaysToShow(4);
    } else if (lg) {
      setamountOfDaysToShow(7);
    } else if (xl) {
      setamountOfDaysToShow(12);
    }
  }, [xs, sm, md, lg, xl]);

  function dayPlus() {
    setstartMoment(startMoment.clone().add(1, "day"));
  }
  function dayMinus() {
    setstartMoment(startMoment.clone().subtract(1, "day"));
  }
  function monthPlus() {
    setstartMoment(startMoment.clone().add(1, "month"));
  }
  function monthMinus() {
    setstartMoment(startMoment.clone().subtract(1, "month"));
  }

  function getMoment(index: number) {
    return startMoment.clone().add(index, "days");
  }

  function onShiftCellClicked(shift?: Shift) {
    if (shift) {
      if (selectedShift?.length && selectedShift.includes(shift)) {
        setShifts((current: any) =>
          current.filter((shift: any) => shift.id === shift.id)
        );
      } else if (shifts.length < 5) {
        setShifts([...shifts, shift]);
      } else {
        setShifts([...shifts]);
      }
    }
  }

  function onSelectRemoveClick(shift: Shift, id: any) {
    const newShift = shifts.filter((shift: any) => shift.id !== id);
    setShifts(newShift);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Box className={c.leftdesktopCalendar}>
          <Paper className={c.desktopCalendar} elevation={0}>
            <Box className={c.desktopCalendarControlBar}>
              <Box
                className={`${c.desktopCalendarNavigation} ${style.calenderbox}`}
              >
                <Box className={`${style.calenderbutton}`}>
                  <Box className={c.arrow} onClick={dayMinus}>
                    <img src={arrowLeft} />
                  </Box>
                  <Box className={c.arrow} onClick={monthMinus}>
                    <img src={arrowMoveLeft} width="15px" />
                  </Box>
                  <span className={c.monthText}>
                    {startMoment.format("MMMM")}
                  </span>
                  <Box className={c.arrow} onClick={monthPlus}>
                    <img src={arrowMoveRight} width="15px" />
                  </Box>
                  <Box className={c.arrow} onClick={dayPlus}>
                    <img src={arrowRight} />
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box className={c.desktopCalendarDescription}>
                <Box className={c.desktopCalendarNamesColumn}>
                  <Box className={c.desktopCalendarCell}>
                    <Typography
                      variant="body1"
                      className={c.desktopCalendarDescriptionCell}
                    >
                      Staff Name
                    </Typography>
                  </Box>
                </Box>

                <Box className={c.desktopCalendarHolder}>
                  {Array.from(Array(amountOfDaysToShow)).map((_, i) => (
                    <Box key={i} className={c.desktopCalendarCell}>
                      <Box className={c.desktopCalendarDescriptionCell}>
                        <span>{getMoment(i).format("dddd")}</span>
                        <span>{getMoment(i).format("DD MMM")}</span>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Divider />
            </Box>
            <Box className={c.desktopCalendarContainer}>
              <Box className={c.desktopCalendarNamesColumn}>
                {Object.keys(shiftsToSwap).map((k) => (
                  <Box key={k} className={c.desktopCalendarCell}>
                    <Typography
                      variant="body1"
                      className={c.desktopCalendarCellInner}
                    >
                      {shiftsToSwap[k as any].userName.fullName}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Box className={c.desktopCalendarHolder}>
                <CalendarHorizontalBase
                  startMoment={startMoment}
                  amountOfDays={amountOfDaysToShow}
                  cellComponent={(d, m, i) => (
                    <Box
                      className={c.desktopCalendarColumn}
                      key={m.format("YYYY-MM-DD")}
                    >
                      {Object.keys(shiftsToSwap).map((k) => {
                        const shifts =
                          shiftsToSwap[k as any].shifts[m.format("YYYY.MM.DD")];
                        const shift = shifts && shifts[0] ? shifts[0] : undefined;
                        const timeString = shift
                          ? `${moment(shift.startDts).format("HH:mm")} - ${moment(
                            shift.endDts
                          ).format("HH:mm")}`
                          : "";
                        return (
                          <Box key={k} className={c.desktopCalendarCell}>
                            <Box
                              className={`${c.desktopCalendarCellInner} ${timeString ? c.activeShadow : ""
                                }`}
                              onClick={() => onShiftCellClicked(shift)}
                            >
                              {timeString}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box className={c.leftdesktopCalendar}>
          <Paper elevation={0} className={c.cardHolder}>
            <Box>
              <Typography
                variant="body1"
                className={c.titleAboveTheCard}
                align="center"
              >
                Current Shift
              </Typography>
              {ownShift ? <Card shift={ownShift} /> : null}
            </Box>
            <Typography
              variant="body1"
              className={c.titleBelowTheCard}
              align="center"
            >
              Shifts to swap with
            </Typography>
            {selectedShift?.length
              ? selectedShift.map((shift) => (
                <Box className={c.cardHolderSelectedCardWrapper}>
                  <Card shift={shift} />
                  <Box className={c.removeButtonHolder}>
                    <StandardButton
                      className={"danger"}
                      title="Remove"
                      onClick={() => onSelectRemoveClick(shift, shift.id)}
                    />
                  </Box>
                </Box>
              ))
              : null}
            <Box className={c.selectButtonHolder}>
              <StandardButton
                title="Select"
                accent
                onClick={() => onSelectSwapClick(shifts)}
                disabled={shifts.length ? false : true}
              />
            </Box>
          </Paper>
        </Box>

      </Grid>
    </Grid>
  );
}
