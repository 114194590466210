import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import * as Yup from "yup";
import Switch from "../../../componentsV3/Buttons/Switch";
import { useProgressBar } from "../../../componentsV3/bars/ProgressBarGlobal";
import {
  getUserDetails,
  getUserQRCode,
  postVerifyQRCode,
  stop2FA,
} from "../../../requests/user";
import style from "../../Login/LoginForm/style.module.css";
interface QRFormProps {
  refetchUserDetail: any;
}

const QRForm: React.FC<QRFormProps> = ({ refetchUserDetail }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading } = useQuery("getUserQRCode", getUserQRCode, {
    refetchOnWindowFocus: false,
  });

  const [code, setCode] = useState<number>(0);

  const { mutateAsync } = useMutation(() => postVerifyQRCode({ code }), {
    onError: () => {
      refetchUserDetail();
    },
    onSuccess: async (data: any) => {
      if (data.verified) {
        enqueueSnackbar(
          `Successfully enabled Two-Factor Authentication (2FA)`,
          { variant: "success" }
        );
        refetchUserDetail();
      } else {
        enqueueSnackbar(`Invalid code`, { variant: "error" });
      }
    },
  });

  const validation = Yup.object().shape({
    number: Yup.string()
      .min(0, "Too Short!")
      .max(6, "Too Long!")
      .required("Required"),
  });

  const onSubmitHandler = (value: any) => {
    setCode(value.number);
    mutateAsync();
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box>
      <img src={data.qr_code} alt="Authentication Code" />

      <Formik
        initialValues={{ number: "" }}
        validationSchema={validation}
        onSubmit={onSubmitHandler}
      >
        {({ errors, isValid, dirty, isSubmitting, touched }) => (
          <Form className={style.form} noValidate>
            <div
              className={`${style.errorblock} ${errors.number && touched.number ? style.erropacity1 : ""
                }`}
            >
              <span className={style.errortext}>
                {errors.number ? errors.number : ""}
              </span>
            </div>
            <Field
              type="number"
              name="number"
              className={`${style.input} ${errors.number && touched.number ? "input-error" : null
                }`}
              placeholder="••••••"
            />
            <motion.button
              type="submit"
              style={{ width: "auto" }}
              className={style.button}
              whileHover={!dirty || !isValid ? {} : { scale: 1.1 }}
              whileTap={!dirty || !isValid ? {} : { scale: 0.9 }}
              disabled={!dirty}
            >
              <span className={style.buttonText}>Enable 2FA</span>
            </motion.button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

function TwoFAForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { barActivate, barStop } = useProgressBar();
  const [enable2FA, setEnable2FA] = useState<boolean>(false);

  const [pass, setPass] = useState("");

  const {
    data: userDetail,
    isLoading,
    isFetching,
    refetch: refetchUserDetail,
  } = useQuery("getUserDetails", getUserDetails, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setEnable2FA(data.TwoFAEnabled ?? false);
    },
  });

  const { mutateAsync } = useMutation(() => stop2FA({ pass }), {
    onError: (err: any) => {
      enqueueSnackbar(err.response.data, {
        variant: "error",
      });
    },
    onSuccess: async () => {
      enqueueSnackbar(`Successfully disabled Two-Factor Authentication (2FA)`, {
        variant: "success",
      });
      refetchUserDetail();
    },
  });

  const validation = Yup.object().shape({
    password: Yup.string()
      .min(7, "Password must be at least 6 chars")
      .required("Required"),
  });

  const onSubmit = (value: any) => {
    setPass(value.password);
    mutateAsync();
  };

  if (isLoading || isFetching) {
    barActivate();
  } else {
    barStop();
  }

  if (!userDetail) {
    return <>Enable to retrieve user detail</>;
  }

  if (!userDetail.TwoFAEnabled && enable2FA) {
    return <QRForm refetchUserDetail={refetchUserDetail} />;
  }

  return (
    <>
      {!enable2FA ? (
        <Switch
          active={enable2FA}
          onToggle={() => {
            setEnable2FA((val) => {
              return !val;
            });
          }}
        />
      ) : null}
      {userDetail.TwoFAEnabled ? (
        <>
          <Alert severity="warning">
            To disable Multi-Factor Authentication (MFA), please enter your
            account password for authentication purposes.
          </Alert>
          <Formik
            initialValues={{ password: "" }}
            validationSchema={validation}
            onSubmit={onSubmit}
          >
            {({ errors, isValid, dirty, isSubmitting, touched }) => (
              <Form className={style.form} noValidate>
                <div
                  className={`${style.errorblock} ${errors.password && touched.password ? style.erropacity1 : ""
                    }`}
                >
                  <span className={style.errortext}>
                    {errors.password ? errors.password : ""}
                  </span>
                </div>
                <label htmlFor="Password">Password</label>
                <div className={style.showpassword}>
                  <Field
                    type="password"
                    name="password"
                    className={`${style.input} ${errors.password && touched.password ? "input-error" : null
                      }`}
                    placeholder="•••••••••••"
                  />
                </div>

                <motion.button
                  type="submit"
                  style={{ width: "auto" }}
                  className={style.button}
                  whileHover={!dirty || !isValid ? {} : { scale: 1.1 }}
                  whileTap={!dirty || !isValid ? {} : { scale: 0.9 }}
                  disabled={isSubmitting || !dirty || !isValid}
                >
                  <span className={style.buttonText}>Disable 2FA</span>
                </motion.button>
              </Form>
            )}
          </Formik>
        </>
      ) : null}
    </>
  );
}

export default TwoFAForm;
