import { useEffect, useState } from "react";

export default function useOnScreen(options: IntersectionObserverInit) {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [visible, setvisible] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setvisible(entry.isIntersecting);
    }, options);

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [ref, options]);

  return { setRef, visible };
}
