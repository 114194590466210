import React from "react";
import styled from "styled-components";
import { Typography, Grid } from "@material-ui/core";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid lightgrey;
  margin: 2px 0;
  border-radius: 2px;
  padding: 2px 4px;
  cursor: pointer;
`;

const DetailsBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

interface SwapShiftOfferRowProps {
  leftSide?: {
    top: string;
    bottom: string;
  };
  rightSide: {
    top: string;
    bottom: string;
  };
  onClick: () => void;
}

export default function SwapShiftOfferRow({
  leftSide,
  rightSide,
  onClick,
}: SwapShiftOfferRowProps) {
  return (
    <Container onClick={onClick}>
      <Grid container spacing={1}>
      {leftSide && 
      <>
        <Grid item xs={5}>
          <DetailsBlock>
            <Typography variant="body2" align="center">
              {leftSide.top}
            </Typography>
            <Typography variant="body2" align="center">
              {leftSide.bottom}
            </Typography>
          </DetailsBlock>
        </Grid>
        <Grid item xs={2}>
          <ArrowsContainer>
            <SwapHorizIcon color="action" />
          </ArrowsContainer>
        </Grid>
        </>}
        <Grid item xs={5}>
          <DetailsBlock>
            <Typography variant="body2" align="center">
              {rightSide.top}
            </Typography>
            <Typography variant="body2" align="center">
              {rightSide.bottom}
            </Typography>
          </DetailsBlock>
        </Grid>
      </Grid>
    </Container>
  );
}
