import React, { useState } from "react";
import useStyles from "./styles";
import styles from "../../../css/style.module.css";
import CalendarBase, { CalendarBaseDay } from "../CalendarBase";
import moment, { Moment } from "moment";
import { Typography } from "@material-ui/core";
interface YearTabMonthCalendarProps {
  year: number;
  month: number;
  shift?: any;
  onMonthNameClick: (month: number) => void;
  onDayClick: (day: CalendarBaseDay) => void;
  status?: "complete" | "published" | "unpublished";
}

const YearTabMonthCalendar = ({
  month,
  year,
  shift,
  status,
  onMonthNameClick,
  onDayClick,
}: YearTabMonthCalendarProps) => {
  const classes = useStyles();

  const [currentMoment] = useState<Moment>(
    moment(`${year}-${month}`, "YYYY-MM")
  );
  let activeFlag = false;
  const _getLabelColor = () => {
    if (!status || status === "complete") return classes.greyed;
    else if (status === "published") return classes.green;
    else if (status === "unpublished") return classes.red;
    else return classes.greyed;
  };

  return (
    <div>
      <div className={styles.calenderheader}>
        <Typography
          variant="h6"
          className={styles.monthname}
          onClick={() => onMonthNameClick(month)}
        >
          {currentMoment.format("MMMM YYYY")}
        </Typography>
        <Typography
          className={`${classes.calendarstatuslabel} ${_getLabelColor()}`}
        >
          {status || "unopened"}
        </Typography>
      </div>
      <CalendarBase
        year={year}
        month={month}
        cellComponent={(d) => (
          <>
            {(activeFlag = false)}
            {shift && shift?.map((item: any) => {
              if (
                d.day === Number(item.slice(-2)) &&
                month === Number(item.slice(5, -3)) &&
                year === Number(item.slice(0, -6))
              ) {
                return (activeFlag = true);
              }
            })}

            <div
              className={activeFlag && d.type === "current" ? styles.activecell : styles.cell}
              onClick={() => onDayClick(d)}
            >
              <Typography
                className={
                  d.type === "current"
                    ? classes.numbercurrent
                    : classes.numbergrayed
                }
              >
                {`${d.day}`.padStart(2, "0")}
              </Typography>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default YearTabMonthCalendar;
