import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import styles from "../../../../css/style.module.css";
import { FiMoreVertical } from "react-icons/fi";
import { makeStyles } from "@material-ui/core/styles";

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  menuitem: {
    "& .MuiMenuItem-root": {
      fontSize: 16,
      fontWeight: 600,
      color: "#282828",
    },
    "& .MuiPaper-root": {
      borderRadius: 6,
      minHeight: "auto",
      marginTop: theme.spacing(1),
      minWidth: 100,
      border: "1px solid #e3e3e3",
      "& .MuiMenu-list": {
        padding: "8px 0",
      },
      "& .MuiMenuItem-root": {
        "&:active": {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
}));

export default function RoosterMenu({ archiveRoster, deleteRoster }: any) {

  const c = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <IconButton
        className={styles.menuicon}
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FiMoreVertical />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        className={`${styles.menuitem} ${c.menuitem}`}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={() => { setAnchorEl(null); deleteRoster(); }}>Delete</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); archiveRoster(); }}>Unarchive</MenuItem>
      </Menu>
    </div >
  );
}
