import { authorizedRequest } from "../httprequests";
import { User } from "../screensV3/StaffScreen";

interface AuditType {
  name: string;
  description?: string;
}

export interface AuditResponseItem {
  id: number;
  taskId: string;
  sourceType: string;
  sourceId: number;
  columnName: string;
  oldValue?: string | null;
  newValue?: string | null;
  comment?: string | null;
  createdAt: string;
  user?: User | null;
  auditType: AuditType;
  search: string;
}

export interface GetAuditResponse {
  auditData: Array<AuditResponseItem>;
  count: number;
}
export function getAudit(
  order: "DESC" | "ASC",
  skip: number,
  take: number,
  auditType: string,
  sourceType: string,
  startDate?: string | null,
  endDate?: string | null,
  search?: string
) {
  return authorizedRequest.get<GetAuditResponse>(
    `audit/start-period/${startDate}/end-period/${endDate}/order/${order}/skip/${skip}/take/${take}/auditType/${auditType}/sourceType/${sourceType}?search=${search}`
  );
}
