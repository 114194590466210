import React from "react";
import styles from "../../../css/style.module.css"
import { Grid, Paper } from "@material-ui/core";
import YearTabMonthCalendar from "../YearTabMonthCalendar";
import { useHistory } from "react-router-dom";

enum MonthStatus {
  published = "published",
  unpublished = "unpublished",
  complete = "complete",
}
export interface YearCalendarMonthStatuses {
  [key: number]: MonthStatus;
} 
interface YearCalendarProps {
  year: number;
  shift?:any;
  statuses: YearCalendarMonthStatuses;
  path?:string
}

// array [1,2,...12]

const arr = Array.from(Array(12).keys()).map((e) => e + 1);

const YearCalendar = ({ year, statuses, path, shift }: YearCalendarProps) => {

  const history = useHistory();
   
  return (
    
    <Paper className={styles.calenderpapercontainer} elevation={0}>
      <Grid container justify="center">
        {arr.map((monthNumber) => (
          <Grid item key={monthNumber} className={styles.calenderbox} xs={12} sm={6} md={5} lg={4} xl={4} >
            <YearTabMonthCalendar
              onMonthNameClick={(month) =>
                history.push(`/${path}/year/${year}/month/${month}`)
              }
              onDayClick={(d) =>
                
                history.push(
                  `/${path}/year/${year}/month/${monthNumber}/day/${d.day}`
                )
              }
              shift={shift}
              year={year}
              month={monthNumber}
              status={statuses[monthNumber]}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default YearCalendar;
