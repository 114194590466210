import React, { useEffect } from "react";
import { FieldProps } from "formik";
import { InputBase, Select, MenuItem, Chip, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../style.module.css";

interface Option {
  value: number | string;
  fullName: number | string;
}

interface Options {
  options: Array<Option>;
  onSelectedValueChange: (value?: string | number) => void;
}

const useStyles = makeStyles((theme) => ({
  outer: {
    border: "1px solid var(--greyish)",
    borderRadius: theme.shape.borderRadius,
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  control: {
    border: "0.5px solid #9e9e9e",
  },
  chipValue: {
    width: "max-content",
  },
  transparentBackground: {
    "& .MuiSelect-select": {
      backgroundColor: "transparent",
      paddingRight: "12px",
    },
  },
  menuPaper: {
    "& .MuiList-padding": {
      padding: "2px",
    },
  },
}));
const MultipleSelectFormik = ({
  field: { value, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
  onSelectedValueChange,
}: FieldProps & Options) => {
  const classes = useStyles();
  const [roosterNames, setRoosterNames] = React.useState<Number[]>([]);

  useEffect(() => {
    if (value && value.length > 0) {
      const filterData = value.filter((v: any) => v.deletedAt === null);
      if (typeof value[0] === "object")
        setRoosterNames(filterData.map((e: any) => e.id));
    }
  }, [value]);

  const roosterHandleChange = (event: any) => {
    setRoosterNames(event.target.value);
    onSelectedValueChange(event.target.value);
  };
  return (
    <Select
      {...field}
      className={`${styles.outer} ${classes.transparentBackground}`}
      fullWidth
      input={<InputBase style={{ height: "auto" }} />}
      multiple
      value={roosterNames}
      onChange={roosterHandleChange}
      renderValue={(selected: any) => (
        <Box className={classes.chips}>
          {selected.map((value: any) => {
            const item = options.find((item) => item.value === value);
            return (
              <div key={value} style={{ padding: 2 }}>
                <Chip
                  key={value}
                  label={item?.fullName}
                  className={classes.chipValue}
                />
              </div>
            );
          })}
        </Box>
      )}
      MenuProps={{
        classes: {
          paper: classes.menuPaper,
        },
      }}
    >
      {options.map((item) => (
        <MenuItem
          style={{ borderRadius: 8, margin: 1 }}
          key={item.value}
          value={item.value}
        >
          {item.fullName}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultipleSelectFormik;
