import { authorizedRequest } from "../../httprequests";
import { Shift } from ".";

export interface GetTemplateShift {
  id: number;
  templateId: number;
  name: string;
  onCall: boolean;
  startTime: string;
  duration: number;
  shiftType?: number;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  // tenant_id: number;
  // template_name: string;
  // id: number;
  // template_id: number;
  // shift_name: string;
  // on_call: boolean;
  // start_time: string;
  // duration: number;
  // shift_type?: null | string; //currently unknown
  // shift_location?: null | string; //currently unknown
  // monday: boolean;
  // tuesday: boolean;
  // wednesday: boolean;
  // thursday: boolean;
  // friday: boolean;
  // saturday: boolean;
  // sunday: boolean;
  // created_dts: Date;
}
export interface GetTemplateResponse {
  // templateName: string;
  // templateShifts: Array<GetTemplateShift>;
  id: number;
  tenantId: number;
  name: string;
  createdDTS: Date;
  updatedDTS: Date;
  expiryDTS?: Date;
  templateShifts: Array<GetTemplateShift>;
}

export const getTemplate = (
  templateId?: string
): Promise<GetTemplateResponse> => {
  if (!templateId) return Promise.reject(new Error("invalid templateId"));
  return authorizedRequest.get(`/templates/${templateId}`);
};

export const postTemplateShifts = (
  templateId: number,
  shifts: Array<Shift>
) => {
  if (!shifts || !shifts.length) return Promise.resolve(false);
  return authorizedRequest.post(`/templates/${templateId}/shifts`, { shifts });
};

export const putTemplateShifts = (templateId: number, shifts: Array<Shift>) => {
  if (!shifts || !shifts.length) return Promise.resolve(false);
  return authorizedRequest.put(`/templates/${templateId}/shifts`, { shifts });
};

export const deleteTemplateShifts = (
  templateId: number,
  ids: Array<number>
) => {
  if (!ids || !ids.length) return Promise.resolve(false);
  const withParams = ids.map((id: number, index: number) => {
    return index ? `&id=${id}` : `id=${id}`;
  });
  const stringified = withParams.join("");
  return authorizedRequest.delete(
    `/templates/${templateId}/shifts?${stringified}`
  );
};
