import {
  Box,
  Button,
  Collapse,
  Typography, makeStyles
} from "@material-ui/core";
import { AxiosError } from "axios";
import { getIn, useField, useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, { Fragment, InputHTMLAttributes, useState } from "react";
import MaskedInput from "react-input-mask";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import StandardButton from "../../../../../componentsV3/Buttons/StandardButton";
import ModalContainer from "../../../../../componentsV3/modals/ModalContainer";
import ModalWindowCenter from "../../../../../componentsV3/modals/ModalWindowCenter";
import { RectangleBadgeFilled } from "../../../../../componentsV3/styled-components/badges";
import styles from "../../../../../css/model.module.css";
import { Offer, deleteOffer } from "../../../../../requests/offers";
import { getUserDetails } from "../../../../../requests/user";
import { styledTheme } from "../../../../../themesV3/styledTheme";
import { Shift } from "../../../../RosterScreen/requests";
import { useShift } from "../../Provider";
import ShiftEditOfferRow from "./shift-edit-offer-row";

const useStyles = makeStyles(() => ({
  button: {
    "&.MuiButtonBase-root": {
      backgroundColor: "#1890ff",
      color: '#fff',
      borderRadius: 8,
      fontSize: 16,
      padding: '10px 12px'
    },
    "&:disabled": {
      color: '#a6a6a6',
      backgroundColor: "#e0e0e0",
    },
  },
}));

const Tr = styled.tr`
  // height: 32px;
`;

const Td = styled.td<{ padding?: string }>`
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  padding: ${({ padding }) => padding || "4px"};
  background-color: ${({ theme }) => theme.colors.background.paper};
  box-sizing: border-box;
`;

const StyledInput = styled.input<{ error?: boolean }>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.greyish};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  padding: 1px 2px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  // &:disabled {
  //   border: none;
  // }
  ${({ error, theme }) =>
    error ? `border: 1px solid ${theme.colors.deepRed};` : ""}
`;

const CheckboxRectangle = styled.div<{ active?: boolean }>`
  height: 20px;
  width: 20px;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  border: ${({ active, theme }) =>
    active ? "none" : `1px solid ${theme.colors.greyish}`};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.deepSkyBlue : "transparent"};
  transition: all 0.2s;
  box-sizing: border-box;
`;


function CheckboxRect(
  props: InputHTMLAttributes<HTMLInputElement> & { name: string }
) {
  const [, { value }, { setValue }] = useField(props);
  return <CheckboxRectangle active={Boolean(value)} />;
}

function Input(
  props: InputHTMLAttributes<HTMLInputElement> & { name: string }
) {
  const [field, meta] = useField(props);
  return (
    <StyledInput
      {...field}
      {...props}
      error={Boolean(meta.error && meta.touched)}
    />
  );
}

function TimeInput(
  props: InputHTMLAttributes<HTMLInputElement> & { name: string }
) {
  const [field, meta] = useField(props);

  return (
    <MaskedInput mask="99:99" {...field} {...props}>
      {(inputProps: any) => {
        return (
          <StyledInput
            {...inputProps}
            disabled={props.disabled}
            error={Boolean(meta.error && meta.touched)}
          />
        );
      }}
    </MaskedInput>
  );
}

interface RosterDayViewFormRowProps {
  // shift: Shift;
  index: number;
  onRightClick: (det: {
    id?: number;
    index: number;
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>;
  }) => void;
  refetchShifts: () => void;
  selectedData: number[];
  setSelectedData: (arg0: number[]) => void;
}
function RosterDayViewFormRow({
  index,
  onRightClick,
  refetchShifts,
  selectedData,
  setSelectedData,
}: RosterDayViewFormRowProps) {


  const c = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { shifts, setShifts } = useShift();
  const { values } = useFormikContext<{
    shifts: Array<Shift>;
  }>();
  const shift = values.shifts[index];
  const [active, setActive] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const offersOut = getIn(values, `shifts.${index}.offersOut`) || [];
  const offersIn = getIn(values, `shifts.${index}.offersIn`) || [];


  const { data } = useQuery(["getUserDetails"], async () => getUserDetails());

  const { mutate: removeOffer } = useMutation<unknown, AxiosError, number>(
    (offerId) => deleteOffer(offerId),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to cancel swap offer`,
          { variant: "error" }
        );
      },
      onSuccess: () => {
        refetchShifts();
        enqueueSnackbar(`Swap offer cancelled`, {
          variant: "success",
        });
      },
    }
  );

  return (
    <Fragment>
      <Tr
        onContextMenu={(event) => {
          event.preventDefault();
          onRightClick({ id: shift.id, index, event });
        }}
      >
        <Td>
          <Button
            title="Request"
            className={c.button}
            color={isOpen ? "primary" : undefined}
            variant="contained" disableElevation
            style={{ textTransform: "none" }}
            disabled={
              shift.user === null ||
              shift.user?.fullName === data?.fullName ||
              (shifts.length && shifts.includes(shift))
            }
            onClick={() => setIsOpen(true)}
          >
            Request
          </Button>
        </Td>


        <Td>
          <Input name={`shifts.${index}.roster.name`} disabled={true} />
        </Td>

        <Td>
          <Input name={`shifts.${index}.owner.fullName`} value={`${shift.owner?.fullName ? shift.owner?.fullName : ''}`} disabled={true} />
        </Td>
        <Td>
          <Input name={`shifts.${index}.user.fullName`} value={`${shift.user?.fullName ? shift.user?.fullName : ''}`} disabled={true} />
        </Td>
        <Td>
          <Input name={`shifts.${index}.shiftName`} disabled={true} />
        </Td>
        <Td>
          <CheckboxRect name={`shifts.${index}.available`} />
        </Td>
        <Td>
          <CheckboxRect name={`shifts.${index}.onCall`} />
        </Td>
        <Td>
          <TimeInput name={`shifts.${index}.startTime`} disabled={true} />
        </Td>
        <Td>
          <TimeInput name={`shifts.${index}.endTime`} disabled={true} />
        </Td>
        <Td>
          <RectangleBadgeFilled
            backgroundColor={
              shift.status === "published"
                ? "green"
                : styledTheme.colors.deepRed
            }
          >
            {shift.status}
          </RectangleBadgeFilled>
        </Td>
      </Tr>

      <Tr>
        <Td colSpan={10} padding="0">
          <Collapse in={active} timeout="auto" mountOnEnter>
            {offersOut.map((offer: Offer) => (
              <ShiftEditOfferRow
                key={offer.id}
                offer={offer}
                deleteOffer={removeOffer}
              />
            ))}
            {offersIn.map((offer: Offer) => (
              <ShiftEditOfferRow
                key={offer.id}
                offer={offer}
                deleteOffer={removeOffer}
              />
            ))}
          </Collapse>
        </Td>
      </Tr>
      <ModalContainer open={isOpen} onClose={() => setIsOpen(false)} >
        <ModalWindowCenter>

          <Box className={styles.new_container}>
            <Box>
              <Box className={styles.titlebox}>
                <Typography className={styles.title} variant="h5">Request shift swap?</Typography>
              </Box>
              <Typography variant="body1"> Are you sure you would like to request to take this shift?</Typography>
            </Box>
            <Box>
              <Box className={styles.buttonsholder}>
                <Box className={`${styles.submitbuttonsholder} ${styles.fillbtn}`} >
                  <Box className={styles.cancelbuttonmargin}>
                    <StandardButton className={'secondary'} title="Close" onClick={() => setIsOpen(false)} />
                  </Box>
                  <StandardButton
                    title="Confirm"
                    accent
                    onClick={() => {
                      setShifts((val: any) => {
                        history.push("/request-swap/request-shift-swap");
                        return [...val, shift];
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalWindowCenter>
      </ModalContainer>
    </Fragment>
  );
}

export default RosterDayViewFormRow;
