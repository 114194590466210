import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import MaskedInput from "react-input-mask";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";
import { Grid } from "@material-ui/core";
import {
  getUserPreferences,
  GetUserPreferencesResponse,
  putUserPreferences,
} from "../../../requests/user";
import { useSnackbar } from "notistack";
import RegularCheckbox from "../../../componentsV3/CheckBoxes/RegularCheckbox";
import styles from "../../../css/style.module.css";


const Form = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Input = styled.input<{ errored?: boolean }>`
  border: 1px solid
    ${({ theme, errored }) =>
      errored ? theme.colors.deepRed : theme.colors.greyish};
  padding: ${({ theme }) => theme.gap[1]};
  margin-right: ${({ theme }) => theme.gap[1]};

`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.greyishBrown};
  margin-bottom: ${({ theme }) => theme.gap[1]};
`;

const InputOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputsHolder = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonHolder = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

interface FormInputs {
  doNotDisturbStartTime?: string | null;
  doNotDisturbEndTime?: string | null;
  smsShiftReminders: boolean;
}

function DoNotDisturbForm() {
  const { enqueueSnackbar } = useSnackbar();

  const [doNotDisturbStartTime, setDoNotDisturbStartTime] = useState<string>(
    ""
  );
  const [doNotDisturbEndTime, setDoNotDisturbEndTime] = useState<string>("");
  const [smsShiftReminders, setSmsShiftReminders] = useState<boolean>(false);

  const { data, refetch } = useQuery<
    GetUserPreferencesResponse,
    AxiosError
  >("user-preferences", getUserPreferences, {
    refetchOnWindowFocus: false,
    onError: (err) => {
      enqueueSnackbar(
        err.response && err.response.data.message
          ? err.response.data.message
          : `Unable to get user preferences`,
        { variant: "warning" }
      );
    },
  });

  useEffect(() => {
    if (data) {
      const ds = data.doNotDisturbStartTime
        ? data.doNotDisturbStartTime.split(":")
        : undefined;
      const de = data.doNotDisturbEndTime
        ? data.doNotDisturbEndTime.split(":")
        : undefined;
      setDoNotDisturbStartTime(ds ? `${ds[0]}:${ds[1]}` : "");
      setDoNotDisturbEndTime(de ? `${de[0]}:${de[1]}` : "");
      setSmsShiftReminders(data.smsShiftReminders || false);
    }
  }, [data]);

  const { mutate } = useMutation<unknown, AxiosError, FormInputs>(
    (values) => putUserPreferences(values),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to save preferences`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        await refetch();
        enqueueSnackbar(`Preferences saved`, {
          variant: "success",
        });
      },
    }
  );

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    mutate({
      doNotDisturbStartTime: doNotDisturbStartTime || null,
      doNotDisturbEndTime: doNotDisturbEndTime || null,
      smsShiftReminders,
    });
  }

  return (
    <Form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item>
          <InputOuterContainer>
            <Label>Do not disturb time</Label>
            <InputsHolder>
              <MaskedInput
                autoComplete="off"
                id="start-time"
                name="doNotDisturbStartTime"
                className={styles.smsinput}
                placeholder="HH:mm"
                mask="99:99"
                value={doNotDisturbStartTime}
                onChange={(e) => setDoNotDisturbStartTime(e.target.value)}
              >
                {(inputProps: any) => <Input {...inputProps} />}
              </MaskedInput>
              <MaskedInput
                autoComplete="off"
                id="end-time"
                className={styles.smsinput}
                name="doNotDisturbEndTime"
                placeholder="HH:mm"
                mask="99:99"
                value={doNotDisturbEndTime}
                onChange={(e) => setDoNotDisturbEndTime(e.target.value)}
              >
                {(inputProps: any) => <Input {...inputProps} />}
              </MaskedInput>
            </InputsHolder>
          </InputOuterContainer>
        </Grid>
        <Grid item>
          <InputOuterContainer>
            <Label>SMS reminders enabled</Label>
            <InputsHolder>
              <RegularCheckbox
                sizeOuter={32}
                sizeInner={16}
                checked={smsShiftReminders}
                onClick={() => setSmsShiftReminders((previous) => !previous)}
              />
            </InputsHolder>
          </InputOuterContainer>
        </Grid>
          <ButtonHolder>
            <StandardButton type="submit" title="Save" accent />
          </ButtonHolder> 
      </Grid>
    </Form>
  );
}

export default DoNotDisturbForm;
