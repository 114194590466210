import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useProgressBar } from "../../../../componentsV3/bars/ProgressBarGlobal";
import {
  getPublishedAndAvailableShiftsForMonth,
  getPublishedAndDraftShiftsForMonth
} from "../../../../requests/shifts";
import { Shift } from "../../../RosterScreen/requests";
import MonthlyRoster from "./MonthlyRoster";

export interface Day {
  day: number;
  year: number;
  month: number;
}

export default function UserMonthlyRoster() {
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  const [date, setdate] = useState<Moment | undefined>(undefined);
  const [allShifts, setAllShifts] = useState<{
    [key: string]: Array<Shift>;
  }>({});
  const [today, settoday] = useState<Day | undefined>(undefined);
  const [allShiftsCount, setAllShiftsCount] = useState<{
    [key: string]: Array<any>;
  }>({});
  useEffect(() => {
    const currentMoment = moment();
    setdate(currentMoment);
    const year = Number(currentMoment.format("YYYY"));
    const month = Number(currentMoment.format("M"));
    const day = Number(currentMoment.format("D"));
    settoday({ year, month, day });
  }, []);

  // const goToPreviousMonth = () => {
  //   setdate(date?.clone().subtract(1, "month"));
  // };

  // const goToNextMonth = () => {
  //   setdate(date?.clone().add(1, "month"));
  // };

  const retrieveShifts = useCallback(async () => {
    try {
      barActivate();
      if (today) {
        setAllShifts({});
        const result = await getPublishedAndAvailableShiftsForMonth(
          today.year,
          today.month
        );
        setAllShifts(result);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Unable to retrieve shifts`, { variant: "error" });
    } finally {
      barStop();
    }
  }, [today, barActivate, barStop, enqueueSnackbar]);

  const retrieveShiftsCount = useCallback(async () => {
    try {
      barActivate();
      if (today) {
        setAllShiftsCount({});
        const result = await getPublishedAndDraftShiftsForMonth(
          today.year,
          today.month
        );
        setAllShiftsCount(result);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Unable to retrieve shifts Count`, { variant: "error" });
    } finally {
      barStop();
    }
  }, [today, barActivate, barStop, enqueueSnackbar]);

  useEffect(() => {
    retrieveShiftsCount();
  }, [retrieveShiftsCount]);

  useEffect(() => {
    retrieveShifts();
  }, [retrieveShifts]);

  return (
    <Fragment>
      <MonthlyRoster
        shifts={allShifts}
        shiftsCount={allShiftsCount}
        date={date}
        today={today}
      />
    </Fragment>
  );
}
