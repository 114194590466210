import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  cellContainer: {
    display: "flex",
    flexDirection: "column",
    height: "7em",
    padding: "0.5em 0.7em",
    alignItems: "center",
    cursor: "pointer",
    overflowY: "scroll",
    scrollbarWidth: "none", // Firefox
    msOverflowStyle: "none", // Internet Explorer 10+
    "&::-webkit-scrollbar": {
      width: 0,
    },
    backgroundColor: ({
      status,
    }: {
      status?: string | "unpublished" | "worked" | "published";
    }) => {
      try {
        if (status === "unpublished") {
          return "#FFEBED"; // light red
        } else if (status === "published") {
          return "#F2F5E1";
        } else if (status === "worked") {
          return theme.palette.grey[200];
        } else {
          return theme.palette.background.paper;
        }
      } catch (err) {
        return theme.palette.background.paper;
      }
    },
    transition: "all 0.2s",
  },
  cellItem: {
    margin: theme.spacing(0.2),
  },
  cellItemsContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  cellday: {},
  postRosterButtonHolder: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
