import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import * as Yup from "yup";
import Switch from "../../../componentsV3/Buttons/Switch";
import { useProgressBar } from "../../../componentsV3/bars/ProgressBarGlobal";
import { default as style } from "../../../css/style.module.css";
import {
  getUserDetails,
  getUserQRCode,
  postVerifyQRCode,
  stop2FA,
} from "../../../requests/user";

interface QRFormProps {
  refetchUserDetail: any;
}

const QRForm: React.FC<QRFormProps> = ({ refetchUserDetail }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading } = useQuery("getUserQRCode", getUserQRCode, {
    refetchOnWindowFocus: false,
  });

  const [code, setCode] = useState<number>(0);

  const { mutateAsync } = useMutation(() => postVerifyQRCode({ code }), {
    onError: () => {
      refetchUserDetail();
    },
    onSuccess: async (data: any) => {
      if (data.verified) {
        enqueueSnackbar(
          `Successfully enabled Two-Factor Authentication (2FA)`,
          { variant: "success" }
        );
        refetchUserDetail();
      } else {
        enqueueSnackbar(`Invalid code`, { variant: "error" });
      }
    },
  });

  const validation = Yup.object().shape({
    number: Yup.string()
      .min(0, "Too Short!")
      .max(6, "Too Long!")
      .required("Required"),
  });

  const onSubmitHandler = (value: any) => {
    setCode(value.number);
    mutateAsync();
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box>
      <img src={data.qr_code} alt="2FA" />

      <Formik
        initialValues={{ number: "" }}
        validationSchema={validation}
        onSubmit={onSubmitHandler}
      >
        {({ errors, isValid, dirty, isSubmitting, touched }) => (
          <Form className={style.form} noValidate>
            <Field
              type="number"
              name="number"
              className={`${style.input} ${errors.number && touched.number ? "input-error" : null
                }`}
              placeholder="••••••"
            />

            {errors.number && touched.number && (
              <div className={`${style.errorblock}`}>{errors.number}</div>
            )}

            <motion.button
              type="submit"
              style={{ width: "auto" }}
              className={style.button}
              disabled={!dirty || !isValid}
            >
              <span className={style.buttonText}>Enable 2FA</span>
            </motion.button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

function TwoFAForm() {
  const { enqueueSnackbar } = useSnackbar();

  const { barActivate, barStop } = useProgressBar();
  const [enable2FA, setEnable2FA] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState(false);
  const [pass, setPass] = useState("");

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  const {
    data: userDetail,
    isLoading,
    isFetching,
    refetch: refetchUserDetail,
  } = useQuery("getUserDetails", getUserDetails, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setEnable2FA(data.TwoFAEnabled ?? false);
    },
  });

  const { mutateAsync } = useMutation(() => stop2FA({ pass }), {
    onError: (err: any) => {
      enqueueSnackbar(err.response.data, {
        variant: "error",
      });
    },
    onSuccess: async () => {
      enqueueSnackbar(`Successfully disabled Two-Factor Authentication (2FA)`, {
        variant: "success",
      });
      refetchUserDetail();
    },
  });

  const validation = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be at least 6 chars")
      .required("Required"),
  });

  const onSubmit = (value: any) => {
    setPass(value.password);
    mutateAsync();
  };

  if (isLoading || isFetching) {
    barActivate();
  } else {
    barStop();
  }

  if (!userDetail) {
    return <>Enable to retrieve user detail</>;
  }

  if (!userDetail.TwoFAEnabled && enable2FA) {
    return <QRForm refetchUserDetail={refetchUserDetail} />;
  }

  // if (userDetail.TwoFAEnabled && !enable2FA) {
  //   return (
  //     <>
  //       <Switch
  //         active={false}
  //         onToggle={() =>
  //           setEnable2FA((val) => {
  //             return !val;
  //           })
  //         }
  //       />

  //       <label htmlFor="Password">Password</label>
  //       <div className={style.showpassword}>
  //         <input
  //           type={showPassword ? "text" : "password"}
  //           name="password"
  //           className={`${style.input}`}
  //           placeholder="•••••••••••"
  //           autoComplete="current-password"
  //           onChange={(e) => setPass(e.target.value)}
  //         />
  //         <span onClick={toggleShowPassword}>
  //           {showPassword ? (
  //             <span className={style.showbtn}>
  //               <MdOutlineVisibility />
  //             </span>
  //           ) : (
  //             <span className={style.showbtn}>
  //               <AiOutlineEyeInvisible />
  //             </span>
  //           )}
  //         </span>
  //       </div>

  //       <Box className={styles.logobtn}>
  //         <StandardButton
  //           title="Disable 2FA"
  //           accent
  //           onClick={async () => {
  //             await mutateAsync();
  //           }}
  //         />
  //       </Box>
  //     </>
  //   );
  // }

  return (
    <>
      {!enable2FA ? (
        <Switch
          active={enable2FA}
          onToggle={() => {
            setEnable2FA((val) => {
              return !val;
            });
          }}
        />
      ) : null}

      {userDetail.TwoFAEnabled ? (
        <>
          <Alert severity="warning" style={{ fontSize: 15 }}>
            To disable Multi-Factor Authentication (MFA), please enter your
            account password for authentication purposes.
          </Alert>

          <Formik
            initialValues={{ password: "" }}
            validationSchema={validation}
            onSubmit={onSubmit}
          >
            {({ errors, isValid, dirty, isSubmitting, touched }) => (
              <Form className={style.form} noValidate>
                <label htmlFor="Password">Password</label>
                <div className={style.showpassword}>
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className={`${style.input} ${errors.password && touched.password ? "input-error" : null
                      }`}
                    placeholder="•••••••••••"
                  />
                  <span onClick={toggleShowPassword}>
                    {showPassword ? (
                      <span className={style.showbtn}>
                        <MdOutlineVisibility />
                      </span>
                    ) : (
                      <span className={style.showbtn}>
                        <AiOutlineEyeInvisible />
                      </span>
                    )}
                  </span>
                </div>

                {errors.password && touched.password && (
                  <div className={`${style.errorblock}`}>{errors.password}</div>
                )}

                <motion.button
                  type="submit"
                  style={{ width: "auto" }}
                  className={style.button}
                  disabled={!dirty || !isValid}
                >
                  <span className={style.buttonText}>Disable 2FA</span>
                </motion.button>
              </Form>
            )}
          </Formik>
        </>
      ) : null}
    </>
  );
}

export default TwoFAForm;
