import React from "react";
import styles from "./styles.module.css";

interface ErrorBlockProps {
  message?: string;
}

export default ({ message }: ErrorBlockProps) => {
  return (
    <div className={`${styles.container} ${message ? styles.opacity1 : ""}`}>
      <span className={styles.text}>{message}</span>
    </div>
  );
};
