import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  
  calendarstatuslabel: {
    color: "#FFFFFF",
    borderRadius: theme.shape.borderRadius,
    padding: "0 1em",
    textTransform: "capitalize",
  },
  greyed: {
    backgroundColor: theme.palette.grey[400],
  },
  green: {
    backgroundColor: theme.palette.success.main,
  },
  red: {
    backgroundColor: theme.palette.error.main,
  },
  numbercurrent: {
    color: theme.palette.text.primary,
  },
  numbergrayed: {
    color: theme.palette.text.hint,
  },
}));
