import { authorizedRequest } from "../httprequests";

export async function getMessageEmail(name: string) {
  return authorizedRequest.get<{ subject?: string; body: string }>(
    `/settings/message-template/${name}`
  );
}

interface PostMessageEmailProps {
  subject?: string | null;
  body: string;
}
export async function postMessageEmail(
  name: string,
  data: PostMessageEmailProps
) {
  return authorizedRequest.post<PostMessageEmailProps, unknown>(
    `/settings/message-template/${name}`,
    data
  );
}

export async function putMessageEnabled(name: string, enabled: boolean) {
  return authorizedRequest.put(`/settings/message-template/${name}`, {
    enabled,
  });
}

export interface GetSettingResponse {
  smsReminderOffset: number;
}
export function getSettings() {
  return authorizedRequest.get<GetSettingResponse>(`/settings/`);
}

export function putSmsReminderTimeOffset(offset: number) {
  return authorizedRequest.put(`/settings/sms-reminder-time-offset`, {
    offset,
  });
}

export async function postImage(userID: number, formData: any) {
  return authorizedRequest.post<any, unknown>(
    `/settings/image-uploaded/${userID}`,
    formData
  );
}

export const deleteLogo = async (userID: number): Promise<any> => {
  return authorizedRequest.delete(`/settings/logo/${userID}`);
};

export interface ContactSupportResponse {
  supportType: string;
  description?: string;
  name: string;
  email: string;
  mobile: string;
  subject: string;
}
export async function requestPostContactSupport(data: ContactSupportResponse) {
  return authorizedRequest.post<ContactSupportResponse, unknown>(
    `/settings/contact`,
    data
  );
}

export enum TicketStatus {
  PENDING = "Pending",
  INPROGRESS = "InProgress",
  RESOLVED = "Resolved",
  CLOSED = "Closed",
}
export interface ContactResponseItem {
  id?: number;
  supportType: string;
  description?: string;
  name: string;
  email: string;
  mobile: string;
  subject: string;
  comment: string;
  status: TicketStatus;
  search: string;
}

export interface GetContactResponseItem {
  contactData: Array<ContactResponseItem>;
  count: number;
}

export async function getContactTicket(
  order: "DESC" | "ASC",
  skip: number,
  take: number,
  supportType?: string,
  search?: string
) {
  return authorizedRequest.get<GetContactResponseItem>(
    `settings/contact-details/order/${order}/skip/${skip}/take/${take}/supportType/${supportType}?search=${search}`
  );
}

interface putContactTicket {
  description?: string;
  status: string;
  comment: string;
}

export const putTicket = (ticketId: number, ticket: putContactTicket) =>
  authorizedRequest.put(`settings/ticket/${ticketId}`, {
    ticket,
  });
