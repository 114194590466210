import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { BsArrowLeftShort } from 'react-icons/bs';
import styles from "../../../css/style.module.css";
import ChangePasswordForm, { ChangePasswordSubmitValues } from "./ChangePasswordForm";
import { requestChangePassword } from "./requests";
import { useProgressBar } from "../../../componentsV3/bars/ProgressBarGlobal";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import { Typography, Box } from "@material-ui/core";

const ChangePasswordScreen = () => {
  const history = useHistory();
  const [apiError, setapiError] = useState("");
  const { barActivate, barStop } = useProgressBar();

  let timeout: any;

  const submit = async (values: ChangePasswordSubmitValues) => {
    if (!values.oldPassword || !values.newPassword || !values.confirmPassword)
      return;
    try {
      barActivate();
      await requestChangePassword(
        values.oldPassword,
        values.newPassword,
        values.confirmPassword
      );
      history.push("/settings");
    } catch (error) {
      localStorage.clear();
      clearTimeout(timeout);
      timeout = setTimeout(() => setapiError(""), 3000);
      
      if (error.response && error.response.status === 400) {
        setapiError(error.response.data.message);
      } else {
        setapiError("Something went wrong. Please try again later");
      }
    } finally {
      barStop();
    }
  };

  return (
    <RWrapper>
        <Box className={styles.pagetitlebox}>
          <Box display='flex' alignItems='center'>
            <BsArrowLeftShort className={styles.backicon} onClick={() => history.push(`/`)}/>
            <Typography variant="h4"  className={styles.title}>Change Password</Typography>
          </Box>
        </Box>
        <Box className={styles.commonbox}><ChangePasswordForm onSubmit={submit} externalError={apiError} /></Box>
    </RWrapper>
  );
};

export default ChangePasswordScreen;
