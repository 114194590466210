export const emailRegex = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i); // i for case-insensitive matching
export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const hoursMinutesRegex = RegExp(
  /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
);

export const pastelPalette = [
  "#0583f2",
  "#A6C7EA",
  "#6899AA",
  "#A9DCD3",
  "#88BDAB",
  "#BAC9B2",
  "#ACA60C",
  "#DFE88D",
  "#CDAB8F",
  "#575757",
  "#F8B2BD",
];

export const pastelPaletteV3 = [
  "linear-gradient(109.89deg, #FFD45D 36.72%, #F4BE27 96.07%)",
  "linear-gradient(109.89deg, #B5EDC8 36.72%, #3CC069 96.07%)",
  "linear-gradient(109.89deg, #DF5DFF 36.72%, #9C28B9 96.07%)",
  "linear-gradient(90deg, #FF9A9E 0%, #FAD0C4 99%, #FAD0C4 100%)",
  "linear-gradient(90deg, #FBC2EB 0%, #A6C1EE 100%)",
  "linear-gradient(90deg, #F77062 0%, #FE5196 100%)",
  "linear-gradient(90deg, #667EEA 0%, #764BA2 100%)",
  "linear-gradient(90deg, #2AF598 0%, #009EFD 100%)",
  "linear-gradient(90deg, #2B5876 0%, #4E4376 100%)",
  "linear-gradient(90deg, #868F96 0%, #596164 100%)",
  "linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)",
];
