import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { FormEvent, useState } from "react";
import styled from "styled-components";
import StandardButton from "../../../components/Buttons/StandardButton";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { PutThemeVersion } from "../../../requests/user";
import { useThemeVersion } from "../../../context/ThemeVersionProvider";

const useStyles = makeStyles((theme) => {
  return {
    radio: {
      "& .Mui-checked": {
        color: "#0583f2",
      },
    },
  };
});

const Form = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const InputOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Spacer = styled.div`
  width: ${({ theme }) => theme.gap[1]};
`;

const ButtonHolder = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

type ThemeValue = "v2" | "v3";

interface ThemeInputs {
  themeVersion: ThemeValue;
}

function RoosterTheme() {
  const style = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { roosterVersionV3 } = useThemeVersion();
  const themeInitialValue: ThemeValue = roosterVersionV3 ? "v3" : "v2";

  const [themeValue, setThemeValue] = useState<ThemeValue>(themeInitialValue);

  const { mutate } = useMutation<unknown, AxiosError, ThemeInputs>(
    (values) => PutThemeVersion(values),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to Change Theme`,
          { variant: "error" }
        );
      },
      onSuccess: () => {
        window.location.reload();
        enqueueSnackbar(`Theme Successfully Changed`, {
          variant: "success",
        });
      },
    }
  );

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    mutate({
      themeVersion: themeValue,
    });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThemeValue(event.target.value as ThemeValue);
  };

  return (
    <Form onSubmit={onSubmit} style={{ alignItems: "center" }}>
      <InputOuterContainer>
        <RadioGroup
          style={{ flexDirection: "row" }}
          aria-labelledby="theme-controlled-radio-buttons-group"
          name="theme-controlled-radio-buttons-group"
          value={themeValue}
          onChange={handleChange}
        >
          <FormControlLabel
            value="v2"
            control={<Radio />}
            label="V2"
            className={style.radio}
          />
          <FormControlLabel
            value="v3"
            control={<Radio />}
            label="V3"
            className={style.radio}
          />
        </RadioGroup>
      </InputOuterContainer>
      <Spacer />
      <Spacer />
      <Spacer />
      <ButtonHolder>
        <StandardButton
          type="submit"
          title="Save"
          accent
          {...(themeValue === themeInitialValue && { disabled: true })}
        />
      </ButtonHolder>
    </Form>
  );
}

export default RoosterTheme;
