import { authorizedRequest } from "../httprequests";
import { User } from "../screens/StaffScreen";
export interface GetUserPreferencesResponse {
  id: number;
  doNotDisturbStartTime?: string;
  doNotDisturbEndTime?: string;
  smsShiftReminders: boolean;
  user?: User;
}
export function getUserPreferences() {
  return authorizedRequest.get<GetUserPreferencesResponse>("/user/preferences");
}

export const getUserDetails = () =>
  authorizedRequest.get<User>("/user/userDetails");

export const getUserQRCode = () => authorizedRequest.get<any>("/user/qr");

export const postVerifyQRCode = (body: { code: number }) =>
  authorizedRequest.post("/user/verify-qr", body);

export const stop2FA = (body: { pass: string }) =>
  authorizedRequest.post("/user/stop-2fa", body);

interface PutUserDetailsRequest {
  code?: string | null;
  mobileNumber?: string | null;
}

type ThemeValue = "v2" | "v3";
interface PutThemeVersionRequest {
  themeVersion: ThemeValue;
}

export const PutThemeVersion = (body: PutThemeVersionRequest) => {
  return authorizedRequest.put("/user/setting-theme-version", body);
};

export function putUserDetails(body: PutUserDetailsRequest) {
  return authorizedRequest.put("/user/userDetails", body);
}

interface PutUserPreferencesRequest {
  doNotDisturbStartTime?: string | null;
  doNotDisturbEndTime?: string | null;
  smsShiftReminders?: boolean;
}

export function putUserPreferences(body: PutUserPreferencesRequest) {
  return authorizedRequest.put("/user/preferences", body);
}

export function deleteUser(id: number) {
  return authorizedRequest.delete(`/user/${id}`);
}

export function putRestoreUser(id: number) {
  return authorizedRequest.put(`/user/restore/${id}`, {});
}
