import React from "react";
import { User } from "../../../StaffScreen";
import { Shift } from "../../../RosterScreen/requests";

interface OfferSwapCardProps {
  shift: Shift;
  user?: User;
}
function OfferSwapCard({ shift }: OfferSwapCardProps) {
  return (
    <>
      <div>{shift.roster?.location?.name}</div>
    </>
  );
}
export default OfferSwapCard;
