import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Hidden } from "@material-ui/core";

interface NavContainerProps {
  children?: Array<JSX.Element> | JSX.Element;
  sideNav: JSX.Element;
}

const useStyles = makeStyles((theme) => {
  const navWidth = 240;
  return {
    container: {
      minHeight: "100%",
      width: "100%",
      display: "flex",
      backgroundColor: theme.palette.background.default,
      position: "relative",
    },
    nav: {
      [theme.breakpoints.up("md")]: {
        width: navWidth,
        flexShrink: 0,
      },
    },
    content: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      position: "relative",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${navWidth}px)`,
      },
    },
    drawerPaper: {
      border: "none",
      boxShadow: theme.shadows[2],
    },
    topBar: {
      display: "flex",
      height: 50,
      width: "100%",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      position: "sticky",
      top: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: 999,
      fallbacks: [{ position: "-webkit-sticky" }],
    },
    hamburger: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: 40,
      height: 40,
      boxSizing: "border-box",
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    hamburgerLine: {
      height: 2,
      width: "70%",
      marginTop: 2,
      marginBottom: 2,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 1,
    },
  };
});

export default function NavContainer({ children, sideNav }: NavContainerProps) {
  const c = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={c.container}>
      <nav className={c.nav}>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={open}
            anchor="left"
            onClose={() => setOpen(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            classes={{
              paper: c.drawerPaper,
            }}
          >
            {sideNav}
          </Drawer>
        </Hidden>

        <Hidden smDown>
          <Drawer
            variant="permanent"
            open={open}
            anchor="left"
            classes={{
              paper: c.drawerPaper,
            }}
          >
            {sideNav}
          </Drawer>
        </Hidden>
      </nav>

      <main className={c.content}>
        <Hidden mdUp>
          <div className={c.topBar}>
            <div className={c.hamburger} onClick={() => setOpen(!open)}>
              <div className={c.hamburgerLine} />
              <div className={c.hamburgerLine} />
              <div className={c.hamburgerLine} />
            </div>
          </div>
        </Hidden>
        {children}
      </main>
    </div>
  );
}
