import { Moment } from "moment";
import { authorizedRequest } from "../httprequests";
import { Shift } from "../screensV3/RosterScreen/requests";
import { User } from "../screensV3/StaffScreen";

interface DateRange {
  start: Moment;
  end: Moment;
}

interface ScheduledSms {
  id: number;
  uuid: string;
  scheduledAt: Date;
  sentAt?: Date;
  message?: string;
  user?: User;
  shift?: Shift;
  status: "scheduled" | "stopped" | "success" | "error" | "all";
  error?: string;
  providerResponse?: string;
  failedParent?: this;
  search: string;
}
export interface GetScheduledSmsResponse {
  scheduled: Array<ScheduledSms>;
  count: number;
}
export function getScheduledSms(
  range: DateRange,
  order: "DESC" | "ASC",
  take: number,
  skip: number,
  status: "scheduled" | "stopped" | "error" | "success" | "all",
  roster?: number,
  recipient?: number,
  search?: string
) {
  return authorizedRequest.get<GetScheduledSmsResponse>(
    `/sms/start-period/${range.start.format(
      "YYYY-MM-DD"
    )}/end-period/${range.end.format(
      "YYYY-MM-DD"
    )}/take/${take}/skip/${skip}/order/${order}/status/${status}/roster/${roster}/recipient/${recipient}?search=${search}`
  );
}
