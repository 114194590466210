import React, { ReactNode, createRef, RefObject } from "react";
import { SnackbarProvider } from "notistack";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    btn: {
      color: theme.palette.common.white,
    },
  };
});

interface SnackbarProviderCustomizedProps {
  children: ReactNode;
}
export default function SnackbarProviderCustomized({
  children,
}: SnackbarProviderCustomizedProps) {
  const c = useStyles();
  const ref: RefObject<SnackbarProvider> = createRef();

  const onClickDismiss = (key: number | string) => () => {
    if (ref.current && ref.current.closeSnackbar) {
      ref.current.closeSnackbar(key);
    }
  };

  return (
    <SnackbarProvider
      autoHideDuration={3000}
      maxSnack={2}
      ref={ref}
      action={(key) => (
        <Button onClick={onClickDismiss(key)} className={c.btn}>
          Dismiss
        </Button>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}
