import React from "react";
import styles from "./styles.module.css";
import { Formik, Form, Field, FormikProps } from "formik";
import ErrorBlockThin from "../../../components/ErrorBlockThin";
import StandardButton from "../../../components/Buttons/StandardButton";

export interface AddUserTypeModalSubmitValues {
  typeName: string;
  description: string;
}

interface AddUserTypeModalProps {
  onSubmit: (values: AddUserTypeModalSubmitValues) => void;
  onCancel: () => void;
  apiError?: string;
  typeName?: string;
  description?: string;
}

interface ValidationProps {
  typeName: string;
}

interface ValidationErrors {
  [key: string]: string;
}

const validation = ({ typeName }: ValidationProps) => {
  const errors: ValidationErrors = {};
  if (!typeName) {
    errors.typeName = "User type can not be empty";
  }
  return errors;
};

export default ({
  onSubmit,
  onCancel,
  apiError,
  typeName,
  description,
}: AddUserTypeModalProps) => {
  return (
    <div className={styles.container}>
      <Formik
        initialValues={{
          typeName: typeName || "",
          description: description || "",
        }}
        validate={validation}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          isValid,
          dirty,
          touched,
          isSubmitting,
        }: FormikProps<any>) => (
          <Form noValidate autoComplete="off">
            <div className={styles.title}>Create user type</div>
            <label className={styles.label} htmlFor="typeName">
              User type name
            </label>
            <Field name="typeName" type="text" className={styles.input} />
            <label className={styles.label} htmlFor="description">
              Description
            </label>
            <Field
              as="textarea"
              name="description"
              type="text"
              className={`${styles.input} ${styles.textarea}`}
            />
            <ErrorBlockThin
              message={apiError ? apiError : errors.typeName?.toString()}
            />
            <div className={styles.buttonsholder}>
              <div />
              <div className={styles.submitbuttonsholder}>
                <div className={styles.cancelbuttonmargin}>
                  <StandardButton title="Cancel" onClick={onCancel} />
                </div>
                <StandardButton
                  title="Create"
                  type="submit"
                  accent
                  disabled={isSubmitting || !isValid || !dirty}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
