import React from "react";
import styles from "../../../css/style.module.css";

interface OptionsDropdownButtonProps {
  color?: string;
  width?: string | number;
  onClick?: () => void;
}

export default ({ onClick, color, width }: OptionsDropdownButtonProps) => {

  
  return (
    <div className={styles.mainoptionsbuttoncontainer}>
      <div className={styles.optionsbuttoncontainer} style={{ width: width }} onClick={onClick}>
        <div className={styles.dot} style={{ backgroundColor: color }} />
        <div className={styles.dot} style={{ backgroundColor: color }} />
        <div className={styles.dot} style={{ backgroundColor: color }} />
      </div>
    </div>
  );
};
