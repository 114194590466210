import React from "react";
import styles from "../../../css/style.module.css";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import styled from "styled-components";
import { Typography, Grid, Box } from "@material-ui/core";
import EmailTemplateForm from "./template-form";
import SmsTimeOffsetForm from "./sms-time-offset-form";
import TenantLogoForm from "./tenant-logo";
import RoosterTheme from "./RoosterTheme";
import TwoFAForm from "./two-factor-auth-form";

const Description = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.default};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  padding: ${({ theme }) => theme.gap[1]};
  margin-bottom: ${({ theme }) => theme.gap[1]};
  color: ${({ theme }) => theme.colors.grey};
`;
function SettingsScreen() {
  return (
    <RWrapper>
      <Box className={styles.pagetitlebox}>
        <Typography variant="h4" color="textPrimary">
          Settings
        </Typography>
      </Box>

      <Grid container spacing={2} style={{ marginBottom: 8 }}>
        <Grid
          item
          lg={4}
          md={6}
          sm={6}
          xs={12}
          className={styles.settingboxgrid}
        >
          <Box className={styles.settingbox}>
            <Typography variant="h4" color="textPrimary">
              Tenant logo
            </Typography>
            <TenantLogoForm />
          </Box>
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          sm={6}
          xs={12}
          className={styles.settingboxgrid}
          style={{ overflow: "hidden !important" }}
        >
          <Box className={styles.settingbox}>
            <Typography variant="h4" color="textPrimary">
              Notifications
            </Typography>
            <Box className={styles.smsreminderbox}>
              <Box className={styles.smsreminder}>
                <Box>
                  <Typography variant="h6" color="textPrimary">
                    Shift reminder sms
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    Description of the reminder
                  </Typography>
                </Box>
                <EmailTemplateForm
                  templateName="shift_soon_reminder_sms"
                  userFriendlyTemplateName=""
                  toggleVisible
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full the recipient
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_name}}"}</span> - Shift name
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_dts}}"}</span> - Shift start dts
                        (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_date}}"}</span> - Shift start date
                        (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_time}}"}</span> - Shift start time
                        (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_dts}}"}</span> - Shift end dts (full
                        date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_date}}"}</span> - Shift end date
                        (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_time}}"}</span> - Shift end time
                        (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{location}}"}</span> - Shift location
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{minutes_to_shift_start}}"}</span> - Amount of
                        minutes left before the shift starts
                      </Typography>
                    </Description>
                  }
                />
              </Box>
              <Box className={styles.smsreminder}>
                <Typography variant="h6" color="textPrimary">
                  SMS reminder offset
                </Typography>
                <SmsTimeOffsetForm />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item container spacing={2}>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={styles.settingboxgrid}
          >
            <Box className={styles.settingbox}>
              <Typography variant="h4" color="textPrimary">
                Theme Setting
              </Typography>
              <RoosterTheme />
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={styles.settingboxgrid}
          >
            <Box className={styles.settingbox}>
              <Typography variant="h4" color="textPrimary">
                Enable 2FA Authentication
              </Typography>
              <TwoFAForm />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 12 }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={styles.settingboxgrid}
        >
          <Box className={styles.settingbox}>
            <Typography variant="h4" color="textPrimary">
              System emails
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="welcome_email"
                  userFriendlyTemplateName="Welcome email"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{reset_password_link}}"}</span> - URL to the
                        reset password page
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - name of the new
                        user
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="reset_password_email"
                  userFriendlyTemplateName="Reset password email"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{reset_password_link}}"}</span> - URL to the
                        reset password page
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 12 }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={styles.settingboxgrid}
        >
          <Box className={styles.settingbox}>
            <Typography variant="h4" color="textPrimary">
              Shifts emails
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="shift_became_available_issuer_email"
                  userFriendlyTemplateName="Shift became available email"
                  TemplateIssuer="Issuer"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full name of the
                        owner of the shift
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_available_status}}"}</span> - Shift
                        availability status
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_name}}"}</span> - Shift name
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_dts}}"}</span> - Shift start dts
                        (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_date}}"}</span> - Shift start date
                        (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_time}}"}</span> - Shift start time
                        (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_dts}}"}</span> - Shift end dts (full
                        date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_date}}"}</span> - Shift end date
                        (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_time}}"}</span> - Shift end time
                        (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{location}}"}</span> - Shift location
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="shift_became_unavailable_issuer_email"
                  userFriendlyTemplateName="Shift became unavailable email"
                  TemplateIssuer="Issuer"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full name of the
                        owner of the shift
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_available_status}}"}</span> - Shift
                        availability status
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_name}}"}</span> - Shift name
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_dts}}"}</span> - Shift start dts
                        (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_date}}"}</span> - Shift start date
                        (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_time}}"}</span> - Shift start time
                        (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_dts}}"}</span> - Shift end dts (full
                        date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_date}}"}</span> - Shift end date
                        (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_time}}"}</span> - Shift end time
                        (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{location}}"}</span> - Shift location
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="shift_available_taken_recipient_email"
                  userFriendlyTemplateName="Available shift taken email"
                  TemplateRecipient="Recipient"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full name of the
                        recipient of the email (user who took the shift)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_available_status}}"}</span> - Shift
                        availability status
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_name}}"}</span> - Shift name
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_dts}}"}</span> - Shift start dts
                        (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_date}}"}</span> - Shift start date
                        (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_start_time}}"}</span> - Shift start time
                        (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_dts}}"}</span> - Shift end dts (full
                        date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_date}}"}</span> - Shift end date
                        (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_end_time}}"}</span> - Shift end time
                        (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{location}}"}</span> - Shift location
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={styles.settingboxgrid}
        >
          <Box className={styles.settingbox}>
            <Typography variant="h4" color="textPrimary">
              Swap request emails
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="swap_request_placed_issuer_email"
                  userFriendlyTemplateName="Swap request placed email"
                  TemplateIssuer="Issuer"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full name of the
                        recipient of the email (issuer of the offer)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_name}}"}</span> - Shift name
                        which is going to be swapped
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_dts}}"}</span> - Start dts
                        of the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_date}}"}</span> - Start
                        date of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_time}}"}</span> - Start
                        time of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_dts}}"}</span> - End dts of
                        the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_date}}"}</span> - End date
                        of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_time}}"}</span> - End time
                        of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_location}}"}</span> - Locations
                        of the shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_owner}}"}</span> - Owner of the
                        shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_name}}"}</span> - Name of
                        the shift to swap with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_dts}}"}</span> -
                        Start dts of the shift to be swapped with (full date and
                        time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_date}}"}</span> -
                        Start date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_time}}"}</span> -
                        Start time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_dts}}"}</span> - End
                        dts of the shift to be swapped with (full date and time
                        string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_date}}"}</span> - End
                        date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_time}}"}</span> - End
                        time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_location}}"}</span> -
                        Locations of the shift to be swapped with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_owner}}"}</span> - Owner of
                        the shift to be swapped with
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="swap_request_placed_recipient_email"
                  userFriendlyTemplateName="Swap request placed email"
                  TemplateRecipient="Recipient"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full name of the
                        recipient of the email (user who was offered the swap)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_name}}"}</span> - Shift name
                        which is going to be swapped
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_dts}}"}</span> - Start dts
                        of the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_date}}"}</span> - Start
                        date of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_time}}"}</span> - Start
                        time of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_dts}}"}</span> - End dts of
                        the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_date}}"}</span> - End date
                        of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_time}}"}</span> - End time
                        of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_location}}"}</span> - Locations
                        of the shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_owner}}"}</span> - Owner of the
                        shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_name}}"}</span> - Name of
                        the shift to swap with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_dts}}"}</span> -
                        Start dts of the shift to be swapped with (full date and
                        time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_date}}"}</span> -
                        Start date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_time}}"}</span> -
                        Start time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_dts}}"}</span> - End
                        dts of the shift to be swapped with (full date and time
                        string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_date}}"}</span> - End
                        date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_time}}"}</span> - End
                        time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_location}}"}</span> -
                        Locations of the shift to be swapped with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_owner}}"}</span> - Owner of
                        the shift to be swapped with
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="swap_request_cancelled_issuer_email"
                  userFriendlyTemplateName="Swap request cancelled email"
                  TemplateIssuer="Issuer"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full name of the
                        recipient of the email (issuer of the offer)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_name}}"}</span> - Shift name
                        which is going to be swapped
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_dts}}"}</span> - Start dts
                        of the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_date}}"}</span> - Start
                        date of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_time}}"}</span> - Start
                        time of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_dts}}"}</span> - End dts of
                        the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_date}}"}</span> - End date
                        of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_time}}"}</span> - End time
                        of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_location}}"}</span> - Locations
                        of the shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_owner}}"}</span> - Owner of the
                        shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_name}}"}</span> - Name of
                        the shift to swap with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_dts}}"}</span> -
                        Start dts of the shift to be swapped with (full date and
                        time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_date}}"}</span> -
                        Start date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_time}}"}</span> -
                        Start time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_dts}}"}</span> - End
                        dts of the shift to be swapped with (full date and time
                        string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_date}}"}</span> - End
                        date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_time}}"}</span> - End
                        time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_location}}"}</span> -
                        Locations of the shift to be swapped with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_owner}}"}</span> - Owner of
                        the shift to be swapped with
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="swap_request_cancelled_recipient_email"
                  userFriendlyTemplateName="Swap request cancelled email"
                  TemplateRecipient="Recipient"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full name of the
                        recipient of the email (issuer of the offer)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_name}}"}</span> - Shift name
                        which is going to be swapped
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_dts}}"}</span> - Start dts
                        of the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_date}}"}</span> - Start
                        date of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_time}}"}</span> - Start
                        time of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_dts}}"}</span> - End dts of
                        the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_date}}"}</span> - End date
                        of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_time}}"}</span> - End time
                        of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_location}}"}</span> - Locations
                        of the shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_owner}}"}</span> - Owner of the
                        shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_name}}"}</span> - Name of
                        the shift to swap with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_dts}}"}</span> -
                        Start dts of the shift to be swapped with (full date and
                        time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_date}}"}</span> -
                        Start date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_time}}"}</span> -
                        Start time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_dts}}"}</span> - End
                        dts of the shift to be swapped with (full date and time
                        string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_date}}"}</span> - End
                        date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_time}}"}</span> - End
                        time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_location}}"}</span> -
                        Locations of the shift to be swapped with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_owner}}"}</span> - Owner of
                        the shift to be swapped with
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="swap_request_accepted_recipient_email"
                  userFriendlyTemplateName="Swap request accepted email"
                  TemplateRecipient="Recipient"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full name of the
                        recipient of the email (user accepted the offer)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_name}}"}</span> - Shift name
                        which is going to be swapped
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_dts}}"}</span> - Start dts
                        of the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_date}}"}</span> - Start
                        date of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_time}}"}</span> - Start
                        time of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_dts}}"}</span> - End dts of
                        the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_date}}"}</span> - End date
                        of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_time}}"}</span> - End time
                        of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_location}}"}</span> - Locations
                        of the shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_owner}}"}</span> - Owner of the
                        shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_name}}"}</span> - Name of
                        the shift to swap with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_dts}}"}</span> -
                        Start dts of the shift to be swapped with (full date and
                        time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_date}}"}</span> -
                        Start date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_time}}"}</span> -
                        Start time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_dts}}"}</span> - End
                        dts of the shift to be swapped with (full date and time
                        string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_date}}"}</span> - End
                        date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_time}}"}</span> - End
                        time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_location}}"}</span> -
                        Locations of the shift to be swapped with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_owner}}"}</span> - Owner of
                        the shift to be swapped with
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="swap_request_declined_recipient_email"
                  userFriendlyTemplateName="Swap request declined email"
                  TemplateRecipient="Recipient"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full name of the
                        recipient of the email (user declined the offer)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_name}}"}</span> - Shift name
                        which is going to be swapped
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_dts}}"}</span> - Start dts
                        of the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_date}}"}</span> - Start
                        date of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_time}}"}</span> - Start
                        time of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_dts}}"}</span> - End dts of
                        the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_date}}"}</span> - End date
                        of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_time}}"}</span> - End time
                        of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_location}}"}</span> - Locations
                        of the shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_owner}}"}</span> - Owner of the
                        shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_name}}"}</span> - Name of
                        the shift to swap with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_dts}}"}</span> -
                        Start dts of the shift to be swapped with (full date and
                        time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_date}}"}</span> -
                        Start date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_time}}"}</span> -
                        Start time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_dts}}"}</span> - End
                        dts of the shift to be swapped with (full date and time
                        string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_date}}"}</span> - End
                        date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_time}}"}</span> - End
                        time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_location}}"}</span> -
                        Locations of the shift to be swapped with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_owner}}"}</span> - Owner of
                        the shift to be swapped with
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="swap_request_accepted_issuer_email"
                  userFriendlyTemplateName="Swap request accepted email"
                  TemplateIssuer="Issuer"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full name of the
                        recipient of the email (user who created the offer)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_name}}"}</span> - Shift name
                        which is going to be swapped
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_dts}}"}</span> - Start dts
                        of the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_date}}"}</span> - Start
                        date of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_time}}"}</span> - Start
                        time of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_dts}}"}</span> - End dts of
                        the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_date}}"}</span> - End date
                        of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_time}}"}</span> - End time
                        of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_location}}"}</span> - Locations
                        of the shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_owner}}"}</span> - Owner of the
                        shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_name}}"}</span> - Name of
                        the shift to swap with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_dts}}"}</span> -
                        Start dts of the shift to be swapped with (full date and
                        time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_date}}"}</span> -
                        Start date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_time}}"}</span> -
                        Start time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_dts}}"}</span> - End
                        dts of the shift to be swapped with (full date and time
                        string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_date}}"}</span> - End
                        date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_time}}"}</span> - End
                        time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_location}}"}</span> -
                        Locations of the shift to be swapped with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_owner}}"}</span> - Owner of
                        the shift to be swapped with
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <EmailTemplateForm
                  templateName="swap_request_declined_issuer_email"
                  userFriendlyTemplateName="Swap request declined email"
                  TemplateIssuer="Issuer"
                  description={
                    <Description>
                      <Typography variant="body1" className={styles.actitle}>
                        Interpolation values for the body of the email:
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{recipient_name}}"}</span> - Full name of the
                        recipient of the email (issuer of the offer)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_name}}"}</span> - Shift name
                        which is going to be swapped
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_dts}}"}</span> - Start dts
                        of the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_date}}"}</span> - Start
                        date of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_start_time}}"}</span> - Start
                        time of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_dts}}"}</span> - End dts of
                        the shift to swap (full date and time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_date}}"}</span> - End date
                        of the shift to swap (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_end_time}}"}</span> - End time
                        of the shift to swap (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_location}}"}</span> - Locations
                        of the shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_owner}}"}</span> - Owner of the
                        shift to swap
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_name}}"}</span> - Name of
                        the shift to swap with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_dts}}"}</span> -
                        Start dts of the shift to be swapped with (full date and
                        time string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_date}}"}</span> -
                        Start date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_start_time}}"}</span> -
                        Start time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_dts}}"}</span> - End
                        dts of the shift to be swapped with (full date and time
                        string)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_date}}"}</span> - End
                        date of the shift to be swapped with (YYYY/MM/DD)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_end_time}}"}</span> - End
                        time of the shift to be swapped with (HH:mm)
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_location}}"}</span> -
                        Locations of the shift to be swapped with
                      </Typography>
                      <Typography
                        variant="body1"
                        className={styles.actitlelight}
                      >
                        <span>{"{{shift_to_swap_with_owner}}"}</span> - Owner of
                        the shift to be swapped with
                      </Typography>
                    </Description>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </RWrapper>
  );
}

export default SettingsScreen;
