import React from "react";
import { Modal, Fade, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ModalBaseProps } from "../interfaces";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    outline: 0,
  },
}));

export default ({ children, onClose, open = false }: ModalBaseProps) => {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box className={classes.box}>{open ? children : null}</Box>
      </Fade>
    </Modal>
  );
};
