import React, { useState } from "react";
import styles from "../../../css/model.module.css";
import { Box, Typography } from "@material-ui/core";
import ModalWindowCenter from "../../../componentsV3/modals/ModalWindowCenter";
import TextInputFormik from "../../../componentsV3/inputs/TextInputFormik";
import { Formik, Field, Form, FormikProps } from "formik";
import ErrorBlockThin from "../../../componentsV3/ErrorBlockThin";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";

export interface CreateTemplateWindowSubmitValues {
  name: string;
  id?: number;
}

interface CreateTemplateWindowProps {
  onSubmit: (values: CreateTemplateWindowSubmitValues) => void;
  onDelete: (id: number) => void;
  onCancel: () => void;
  apiError?: string;
  templateName?: string;
  templateId?: number;
}

interface ValidationProps {
  name: string;
}

interface ValidationErrors {
  [key: string]: string;
}

const validation = ({ name }: ValidationProps) => {
  const errors: ValidationErrors = {};
  if (!name) {
    errors.name = "Template name can not be empty";
  }
  return errors;
};

export default ({
  onSubmit,
  onDelete,
  onCancel,
  apiError,
  templateName,
  templateId,
}: CreateTemplateWindowProps) => {
  const [deleteMode, setdeleteMode] = useState<boolean>(false);

  if (deleteMode && templateId && templateName)
    return (
      <ModalWindowCenter>

        <Box className={styles.centercontainer}>

          <Box className={styles.titlebox}>
            <Typography variant="h5" align="center" className={styles.title}>
              Delete template "{templateName}"?
            </Typography>
          </Box>

          <Box className={styles.buttonsholder}>
            <Box className={styles.submitbuttonsholder}>
              <Box className={styles.cancelbuttonmargin}>
                <StandardButton
                  title="Cancel"
                  className={'secondary'}
                  onClick={() => {
                    setdeleteMode(false);
                    onCancel();
                  }}
                />
              </Box>
              <StandardButton
                title="Delete"
                className={'danger'}
                onClick={() => onDelete(templateId)}
              />
            </Box>
          </Box>
        </Box>
      </ModalWindowCenter>
    );

  return (
    <ModalWindowCenter>
      <Formik
        initialValues={{ name: templateName || "" }}
        validate={validation}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          isValid,
          dirty,
          touched,
          isSubmitting,
        }: FormikProps<any>) => (
          <Form noValidate>
            <Box className={styles.centercontainer}>

              <Box>
                <Box className={styles.titlebox}>
                  <Typography variant="h5" align="center" className={styles.title}>
                    {templateName && templateId ? "Edit Template" : "New Template"}
                  </Typography>
                </Box>

                <Field
                  name="name"
                  type="text"
                  placeholder="Template Name"
                  component={TextInputFormik}
                />
                <ErrorBlockThin
                  message={apiError ? apiError : errors.name?.toString()}
                />
              </Box>

              <Box>
                <Box className={styles.buttonsholder}>
                  <Box className={`${styles.submitbuttonsholder} ${styles.fillbtn}`}>
                    {templateId && (
                      <Box className={styles.cancelbuttonmargin}>
                          <StandardButton
                            title="Delete"
                            className={'danger'}
                            onClick={() => setdeleteMode(true)}
                          />
                      </Box>
                    )}
                    <Box className={styles.cancelbuttonmargin}>
                      <StandardButton  className={'secondary'} title="Cancel" onClick={onCancel} />
                    </Box>
                    <StandardButton
                      title={templateName ? "Edit" : "Create"}
                      type="submit"
                      accent
                      disabled={isSubmitting || !isValid || !dirty}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </ModalWindowCenter>
  );
};
