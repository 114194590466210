import React, {
  useCallback,
  useRef,
  Fragment,
  useState,
  useEffect,
} from "react";
import RangeCaledar, {
  OnRangeCalendarSelectedProps,
} from "../../calendars/RangeCalendar";
import ModalContainer from "../../modals/ModalContainer";
import ModalWindow from "../../modals/ModalWindow";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Today } from "@material-ui/icons";
import StandardButton from "../../Buttons/StandardButton";
import { useField } from "formik";
import moment from "moment";

interface DatePickerFormikProps {
  name: string;
  date?: string;
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flex: 1,
      height: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },

    calendarHolder: {
      maxWidth: 400,
    },
    title: {
      width: "100%",
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
    buttonHolder: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      // justifyContent: "space-between",
    },
    input: {
      borderRadius: theme.shape.borderRadius,
      border: ({ bordered }: { bordered: boolean }) => {
        return bordered ? `1px solid ${theme.palette.grey[300]}` : "none";
      },
      height: "100%",
      width: "100%",
    },
    icon: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(1),
      cursor: "pointer",
      borderRadius: theme.shape.borderRadius,
      transition: "background-color 0.2s ease-out",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  };
});

export default function DatePickerFormik({
  name,
  date,
}: DatePickerFormikProps) {
  const [modalOpen, setmodalOpen] = useState<boolean>(false);
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const setValueRef = useRef(setValue);
  const dateFromPropsProcessed = useRef(false);
  const c = useStyles({ bordered: meta.value ? false : true });

  const [inputValue, setinputValue] = useState<string>("");

  const [yearToShow, setyearToShow] = useState<number | undefined>(undefined);
  const [monthToShow, setmonthToShow] = useState<number | undefined>(undefined);

  // const dateRegex = /^\d{4}-\d{1,2}-\d{1,2}$/;
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  useEffect(() => {
    if (date && date.match(dateRegex) && !dateFromPropsProcessed.current) {
      dateFromPropsProcessed.current = true;
      setValueRef.current(date);
    }
  }, [date, dateRegex]);

  useEffect(() => {
    setinputValue(meta.value ?? "");
    if (meta.value.match(dateRegex)) {
      const [year, month] = meta.value.split("-"); //day can be added
      setyearToShow(Number(year));
      setmonthToShow(Number(month));
    } else {
      setyearToShow(undefined);
      setmonthToShow(undefined);
    }
  }, [meta.value, dateRegex]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setinputValue(event.target.value);
    if (!event.target.value.length) {
      setValueRef.current(undefined);
    } else if (event.target.value.match(dateRegex)) {
      const m = moment(event.target.value, "YYYY-MM-DD");
      if (m.isValid()) {
        setValueRef.current(m.format("YYYY-MM-DD"));
      }
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (meta.value !== inputValue) {
      setinputValue(meta.value ?? "");
    }
  };

  const handleSelect = useCallback(
    (v: OnRangeCalendarSelectedProps | undefined) => {
      if (v && v.single) {
        const { single } = v;
        const d = moment(
          `${single.year}-${single.month}-${single.day}`,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD");
        setValueRef.current(d);
        setinputValue(d);
      } else {
        setValueRef.current(undefined);
      }
    },
    []
  );

  return (
    <Fragment>
      <div className={c.container}>
        <input
          autoComplete="off"
          className={c.input}
          {...field}
          placeholder="YYYY-MM-DD"
          onChange={onInputChange}
          value={inputValue}
          onBlur={handleBlur}
        />
        <div onClick={() => setmodalOpen(true)} className={c.icon}>
          <Today color="action" />
        </div>
      </div>

      <ModalContainer open={modalOpen} onClose={() => setmodalOpen(false)}>
        <ModalWindow className={c.calendarHolder}>
          <Typography variant="h5" color="textSecondary" className={c.title}>
            {meta.value
              ? moment(meta.value, "YYYY-MM-DD").format("dddd Do MMMM")
              : "Pick a Date"}
          </Typography>
          <RangeCaledar
            selectSingle={true}
            onSelected={handleSelect}
            year={yearToShow}
            month={monthToShow}
          />
          <div className={c.buttonHolder}>
            {/* <StandardButton
              title="Clear"
              onClick={() => {
                handleSelect(undefined);
              }}
            /> */}
            <StandardButton
              title="Done"
              accent
              onClick={() => setmodalOpen(false)}
            />
          </div>
        </ModalWindow>
      </ModalContainer>
    </Fragment>
  );
}
