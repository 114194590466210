import styles from "../../../../../css/style.module.css";
import { AxiosError } from "axios";
import { FieldArray, Form, Formik } from "formik";
import moment, { Moment } from "moment";
import { Box, Divider } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import {
  DateNavButtonLeft,
  DateNavButtonRight,
  DateNavButtonText,
  DateNavText,
} from "../../../../../componentsV3/Buttons/DateNavigationButtons";
import { useUserSelections } from "../../../../../context/userSelectionsProvider";
import { hoursMinutesRegex } from "../../../../../GLOBALS";
import { deleteShifts, postShifts } from "../../../../../requests/shifts";
import {
  getRostersShifts,
  GetRostersShiftsResponse,
  Shift,
} from "../../../../RosterScreen/requests";
import RosterDayViewFormRow from "./roster-day-view-form-row";

const DateHolder = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;

const DateTextHolder = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
`;

const Table = styled.table`
  width: 100%;
`;

const Th = styled.th<{ width?: string }>`
  width: ${({ width }) => width};
  text-align:left;
`;

const validationSchema = yup.object({
  shifts: yup.array(
    yup.object({
      shiftName: yup.string().required("required"),
      rosterId: yup.number().required("required"),
      startTime: yup
        .string()
        .required("required")
        .matches(hoursMinutesRegex, "wrong format"),
      endTime: yup
        .string()
        .required("required")
        .matches(hoursMinutesRegex, "wrong format"),
    })
  ),
});

const RosterDayView = () => {
  const { year, month, day } = useParams<{
    year?: string;
    month?: string;
    day?: string;
  }>();
  const history = useHistory();
  const { selectedRosters } = useUserSelections();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedData, setSelectedData] = useState<Array<any>>([]);

  const [currentMoment, setCurrentMoment] = useState<Moment | undefined>(
    undefined
  );

  useEffect(() => {
    if (year && month && day) {
      setCurrentMoment(moment(`${year}-${month}-${day}`, "YYYY-MM-DD"));
    }
  }, [year, month, day]);

  const { data: shifts, refetch } = useQuery<
    GetRostersShiftsResponse,
    AxiosError
  >(
    ["get-shifts-for-the-day", currentMoment, selectedRosters],
    async () => {
      return await getRostersShifts({
        rosterIds: selectedRosters.map((r) => r.id),
        date: currentMoment?.format("YYYY-MM-DD") as string,
      });
    },
    {
      keepPreviousData: true,
      initialData: [],
      enabled: Boolean(currentMoment && selectedRosters.length),
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get shifts`,
          { variant: "error" }
        );
      },
    }
  );

  const { mutate } = useMutation<
    unknown,
    AxiosError,
    { shifts: Array<Shift>; deleted: Array<number> }
  >(
    (data) => {
      const promises = [];
      if (data.shifts.length) {
        promises.push(postShifts(data.shifts));
      }
      if (data.deleted && data.deleted.length) {
        promises.push(deleteShifts(data.deleted));
      }
      return Promise.all(promises);
    },
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to save changes`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        await refetch();
        enqueueSnackbar(`Saved`, {
          variant: "success",
        });
      },
    }
  );

  const onRightClick = (details: {
    id?: number;
    index: number;
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>;
  }) => {

  };

  const _submit = async (form: { shifts: Array<Shift> }) => {
    if (currentMoment) {
      const withDts = form.shifts?.map((s) => {
        const [sh, sm] = s.startTime!.split(":");
        const [eh, em] = s.endTime!.split(":");

        console.log("Steve123 start time = ", s.startTime);
        console.log("Steve123 end time = ", s.endTime);

        //Start time
        let st = currentMoment.clone();
        if (s.startDts) st = moment(s.startDts);
        st.set("hours", Number(sh));
        st.set("minutes", Number(sm));

        //end time
        console.log("Steve123 current moment = ", currentMoment);
        let en = st.clone();

        //logic to set end date
        if (s.startTime && s.endTime && s.startTime > s.endTime) {
          en.add(1, "day");
        }

        en.set("hours", Number(eh));
        en.set("minutes", Number(em));

        console.log("Steve123 start dts = ", st.toDate());
        console.log("Steve123 end dts = ", en.toDate());

        s.startDts = st.toDate();
        s.endDts = en.toDate();
        return s;
      });
      const existingIds: Array<number> = [];
      withDts!.forEach((s) => {
        if (s.id) {
          existingIds.push(s.id);
        }
      });
      const deleted = shifts
        ?.filter((s) => !existingIds.includes(s.id!))
        .map((s) => s.id);

      mutate({ shifts: withDts, deleted: deleted as Array<number> });
    }
  };

  const goToPreviousDay = useCallback(() => {
    if (currentMoment) {
      const prevDay = currentMoment
        .clone()
        .subtract(1, "day")
        .format("[year/]YYYY/[month/]M/[day/]D");
      history.push(`/request-swap/${prevDay}`);
    }
  }, [currentMoment, history]);

  const goToNextDay = useCallback(() => {
    if (currentMoment) {
      const nextDay = currentMoment
        .clone()
        .add(1, "day")
        .format("[year/]YYYY/[month/]M/[day/]D");
      history.push(`/request-swap/${nextDay}`);
    }
  }, [currentMoment, history]);

  return (
    <Fragment>
      <Divider className={styles.rsspace} />
      {currentMoment && (
        <Box className={styles.monthcalenderbox}>
          <Box
            className={styles.calenderbox}
            display="flex"
            justifyContent="center"
          >
            <DateHolder className={styles.calenderbutton}>
              <DateNavButtonLeft onClick={goToPreviousDay} />
              <DateTextHolder>
                <DateNavButtonText
                  onClick={() =>
                    history.push(
                      `/request-swap/year/${currentMoment.format("YYYY")}`
                    )
                  }
                >
                  {currentMoment.format("YYYY")}
                </DateNavButtonText>
                <DateNavButtonText
                  onClick={() =>
                    history.push(
                      `/request-swap/year/${currentMoment.format(
                        "YYYY/[month/]M"
                      )}`
                    )
                  }
                >
                  {currentMoment.format("MMM")}
                </DateNavButtonText>
                <DateNavText>{currentMoment.format("dddd DD")}</DateNavText>
              </DateTextHolder>
              <DateNavButtonRight onClick={goToNextDay} />
            </DateHolder>
          </Box>
        </Box>
      )}
      {shifts && (
        <Formik
          initialValues={{ shifts }}
          onSubmit={_submit}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({ values, dirty, isSubmitting, resetForm, isValid }) => (
            <Form autoComplete="off">
              <div className={styles.tablecontainer} style={{ marginTop: 25 }}>
                <Table className={styles.shifttable}>
                  <thead>
                    <tr>
                      <Th width={"8%"}>Request Shift</Th>
                      <Th width={"15%"}>Roster</Th>
                      <Th width={"15%"}>Owner Name</Th>
                      <Th width={"15%"}>Assignee</Th>
                      <Th width={"15%"}>Shift name</Th>
                      <Th width={"1%"}>Avail.</Th>
                      <Th width={"1%"}>Call</Th>
                      <Th width={"70px"}>Start</Th>
                      <Th width={"70px"}>End</Th>
                      <Th width={"5%"}>Status</Th>
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name="shifts">
                      {({ push, remove }) => (
                        <Fragment>
                          {values.shifts &&
                            values.shifts.map((shift, index) => (
                              <RosterDayViewFormRow
                                refetchShifts={refetch}
                                index={index}
                                key={index}
                                onRightClick={onRightClick}
                                selectedData={selectedData}
                                setSelectedData={setSelectedData}
                              />
                            ))}
                        </Fragment>
                      )}
                    </FieldArray>
                  </tbody>
                </Table>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  );
};

export default RosterDayView;
