import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { BsArrowLeftShort } from 'react-icons/bs';
import ModalWindowCenter from "../../../../../componentsV3/modals/ModalWindowCenter";
import styles from "../../../../../css/style.module.css";
import modalStyle from "../../../../../css/model.module.css"

interface ViewTicketModalProps {
    onCancel: () => void;
    TicketDetails: any;
}

export default ({ TicketDetails, onCancel }: ViewTicketModalProps) => {

    return (
        <ModalWindowCenter className={styles.tiketmodals}>
            <Box className={modalStyle.titlebox}>
                <BsArrowLeftShort
                    className={styles.backicon}
                    onClick={onCancel}
                />
                <Typography variant="h5" className={modalStyle.title}>
                    Ticket Details
                </Typography>
            </Box>

            <Grid container spacing={2}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Box className={styles.ticketdetails}>
                        <Typography variant="h6">
                            Ticket Id : <span>{TicketDetails.id}</span>
                        </Typography>
                        <Typography variant="h6">
                            Support Type : {TicketDetails.supportType && TicketDetails.supportType.length ? (<span>{TicketDetails.supportType}</span>) : null}
                        </Typography>
                        <Typography variant="h6">
                            Description : {TicketDetails.description && TicketDetails.description.length ? (<span>{TicketDetails.description}</span>) : null}
                        </Typography>
                        <Typography variant="h6">
                            Name : {TicketDetails.name && TicketDetails.name.length ? (<span>{TicketDetails.name}</span>) : null}
                        </Typography>
                        <Typography variant="h6">
                            Email : {TicketDetails.email && TicketDetails.email.length ? (<span>{TicketDetails.email}</span>) : null}
                        </Typography>
                        <Typography variant="h6">
                            Mobile : {TicketDetails.mobile && TicketDetails.mobile.length ? (<span>{TicketDetails.mobile}</span>) : null}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Box className={styles.ticketdetails}>
                        <Typography variant="h6">
                            Subject : {TicketDetails.subject && TicketDetails.subject.length ? (<span>{TicketDetails.subject}</span>) : null}
                        </Typography>
                        <Typography variant="h6">
                            Comment : {TicketDetails.comment && TicketDetails.comment.length ? (<span>{TicketDetails.comment}</span>) : null}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </ModalWindowCenter >
    )
} 