import React from "react";
import moment from "moment";
import { User } from "../../../StaffScreen";
import { Shift } from "../../../RosterScreen/requests";

interface OfferDateCardProps {
  shift: Shift;
  user?: User;
}
function OfferDateCard({ shift, user }: OfferDateCardProps) {
  const start = moment(shift.startDts);
  return <div>{`${start.format("DD/MM/YYYY")}`}</div>;
}
export default OfferDateCard;
