import React, { useState, useEffect, useCallback } from "react";
import { Box, BoxProps } from "@material-ui/core";
import styles from "./styles.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { getUserDetails } from "../../requests/user";
import { UserDropdown } from "./UserDropdown";
import { UserMobileDropdown } from "./UserMobileDropdown";
import { useProgressBar } from "../bars/ProgressBarGlobal";
import { PUBLIC_API_URL } from "../../ENDPOINTS";
import { Drawer, Hidden } from "@material-ui/core";
import TenantLogo from "../../assets/images/rooster-logo.png";

interface headerProps {
  children?: Array<JSX.Element> | JSX.Element;
  sideNav: JSX.Element;
}

const useStyles = makeStyles((theme) => {
  const navWidth = 250;
  return {
    container: {
      paddingTop: "77px",
      minHeight: "100%",
      width: "100%",
      display: "flex",
      backgroundColor: theme.palette.background.default,
      position: "relative",
    },
    nav: {
      [theme.breakpoints.up("md")]: {
        width: navWidth,
        flexShrink: 0,
      },
    },
    content: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      position: "relative",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${navWidth}px)`,
      },
    },
  };
});

export const headerHeight = 65;

const headernavProps: BoxProps = {
  component: "header",
  position: "fixed",
  width: "100",
  top: "0",
  left: "0",
  right: "0",
  padding: "6px 0",
  height: headerHeight,
  bgcolor: "white",
  border: "none",
  boxShadow: " 0px 7px 31px rgba(0, 0, 0, 0.06)",
  display: "flex",
  flexDirection: "row",
  zIndex: 1,
};

export default function Header({ children, sideNav }: headerProps) {
  const c = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const { barStop } = useProgressBar();

  const [userFirstLetter, setUserFirstLetter] = useState<string>("");
  const [userName, setUserName] = useState<any>();
  const [userRole, setUserRole] = useState<any>();
  const [userCompanyLogo, setUserCompanyLogo] = useState<any>();
  const [userCompanyName, setUserCompanyName] = useState<any>();


  const getUser = useCallback(async () => {
    try {
      const u = await getUserDetails();

      setUserCompanyLogo("");
      if (u?.tenants![0].companyLogo) {
        setUserCompanyLogo(u?.tenants![0].companyLogo);
      }
      setUserCompanyName(u?.tenants![0].name);

      if (u?.fullName! && u.fullName !== undefined) {
        const firstLastLatter = `${u?.fullName!.split(" ")[0][0]}${u?.fullName!.split(" ")[1][0]
          }`;
        if (u?.isAdministrator) {
          setUserRole("Administrator");
        } else {
          setUserRole("User");
        }
        setUserFirstLetter(firstLastLatter);
      }
      setUserName(u?.fullName);
    } catch (error) {
      console.error(error);
    } finally {
      barStop();
    }
  }, [barStop]);

  useEffect(() => {
    getUser();
  }, [getUser]);


  return (
    <Box {...headernavProps}>
      <Box className={styles.headercontainer}>
        <Box className={styles.mobilemenucontainer}>
          <nav className={c.nav}>
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                open={open}
                anchor="left"
                onClose={() => setOpen(false)}
                ModalProps={{
                  keepMounted: true,
                }}
                classes={{
                  paper: styles.drawerPaper,
                }}
              >
                {sideNav}
              </Drawer>
            </Hidden>

            <Hidden smDown>
              <Drawer
                variant="permanent"
                open={open}
                anchor="left"
                classes={{
                  paper: styles.drawerPaper,
                }}
              >
                {sideNav}
              </Drawer>
            </Hidden>
          </nav>
          <main className={c.content}>
            <Hidden mdUp>
              <div className={styles.topBar}>
                <div
                  className={styles.hamburger}
                  onClick={() => setOpen(!open)}
                >
                  <div className={styles.hamburgerLine} />
                  <div className={styles.hamburgerLine} />
                  <div className={styles.hamburgerLine} />
                </div>
              </div>
            </Hidden>
            {children}
          </main>
        </Box>
        <Box className={styles.companyLogo}>
          {userCompanyLogo ? (
            <img
              src={
                userCompanyLogo && `${PUBLIC_API_URL}/logo/${userCompanyLogo}`
              }
              alt="rooster"
            />
          ) : (
            <img src={TenantLogo} alt="Rooster" />
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {/* <Box className={styles.SearchWrapper}>
          <SearchBox 
            variant="outlined" 
            placeholder="Search Anything"
            // style={{borderRadius:'5px'}}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchOutlined className={styles.SearchIcon} />
                </IconButton>
              ),
            }}
          />
        </Box>
        <Box className={styles.iconContainer}>
          <Box className={styles.bellicon}> 
            <IconButton style={{padding:0,fontSize:'inherit',}}>
            <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.555 14.162L20.655 7.326C20.0981 5.32333 18.8878 3.56428 17.2164 2.32847C15.545 1.09265 13.5084 0.450982 11.4305 0.505462C9.35253 0.559942 7.35242 1.30744 5.74808 2.62914C4.14373 3.95085 3.02722 5.7709 2.57601 7.8L1.10501 14.415C0.942528 15.1459 0.946284 15.904 1.11601 16.6333C1.28573 17.3626 1.61708 18.0445 2.08559 18.6285C2.55411 19.2126 3.14783 19.684 3.82292 20.008C4.49801 20.3319 5.23724 20.5 5.98601 20.5H7.10001C7.32953 21.6303 7.94274 22.6465 8.83575 23.3764C9.72877 24.1063 10.8467 24.505 12 24.505C13.1534 24.505 14.2713 24.1063 15.1643 23.3764C16.0573 22.6465 16.6705 21.6303 16.9 20.5H17.738C18.5088 20.5001 19.2692 20.3219 19.9598 19.9794C20.6503 19.637 21.2524 19.1395 21.7188 18.5259C22.1853 17.9122 22.5036 17.199 22.6488 16.442C22.794 15.685 22.7623 14.9047 22.556 14.162H22.555ZM12 22.5C11.3818 22.4974 10.7794 22.3039 10.2753 21.9459C9.77124 21.5879 9.3901 21.0829 9.18401 20.5H14.816C14.6099 21.0829 14.2288 21.5879 13.7247 21.9459C13.2206 22.3039 12.6183 22.4974 12 22.5ZM20.126 17.315C19.8474 17.6847 19.4863 17.9843 19.0716 18.19C18.6569 18.3957 18.1999 18.5018 17.737 18.5H5.98601C5.5368 18.4999 5.09333 18.399 4.68835 18.2046C4.28337 18.0102 3.92722 17.7273 3.64617 17.3769C3.36513 17.0265 3.16637 16.6174 3.06457 16.1798C2.96277 15.7423 2.96053 15.2875 3.05801 14.849L4.52801 8.233C4.88237 6.6392 5.75932 5.2096 7.01947 4.17144C8.27962 3.13329 9.85064 2.54617 11.4828 2.50341C13.1149 2.46065 14.7145 2.96471 16.0273 3.93546C17.3401 4.90622 18.2907 6.28794 18.728 7.861L20.628 14.697C20.7536 15.1424 20.7736 15.6109 20.6864 16.0653C20.5993 16.5198 20.4074 16.9477 20.126 17.315Z" fill="#9A9A9A"/>
            </svg>
            </IconButton>
          </Box>
        </Box> */}
          <Box
            className={`${styles.userContainer} ${styles.usermobileContainer}`}
          >
            <UserMobileDropdown
              userName={userFirstLetter}
              userFullName={userName}
              userRole={userRole}
            />
          </Box>
          <Box className={styles.userContainer}>
            <UserDropdown
              userName={userFirstLetter}
              userFullName={userName}
              userRole={userRole}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
