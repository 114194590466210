import React, { createContext, useContext, useEffect, useState } from "react";
import Spinner from "../componentsV3/Spinner";
import { getUserDetails } from "../requests/user";

export const ThemeVersionContext = createContext<{ roosterVersionV3: boolean }>(
  {
    roosterVersionV3: false,
  }
);

export const ThemeVersionProvider: React.FC = ({ children }) => {
  const [roosterVersionV3, setRoosterVersionV3] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  try {
    const UserDetails = async () => {
      let token = localStorage.getItem("token");
      if (!token) {
        setRoosterVersionV3(true);
        setIsLoading(false);
      } else {
        const userData = await getUserDetails();
        const roosterVersion = userData?.themeVersion === "v3" ? true : false;
        setRoosterVersionV3(roosterVersion);
        setIsLoading(false);
      }
    };

    useEffect(() => {
      UserDetails();
    }, [UserDetails]);
  } catch (error) {
    setRoosterVersionV3(false);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ThemeVersionContext.Provider value={{ roosterVersionV3 }}>
      {children}
    </ThemeVersionContext.Provider>
  );
};

export const useThemeVersion = () => {
  return useContext(ThemeVersionContext);
};
