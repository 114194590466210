import React from "react";
import style from "./style.module.css";
import OptionsButton from "../../../componentsV3/Buttons/OptionsButton";


interface TemplateListItemProps {
  title: string;
  id: number;
  onClick?: (id: number) => number | void;
  onOptionsClick?: (id: number) => number | void;
}

export default ({ title, id, onClick, onOptionsClick }: TemplateListItemProps) => {
  return (
    <div className={style.container}>
      <div className={style.inner} >
        <div className={style.titleholder} onClick={() => (onClick ? onClick(id) : null)}>
          <span className={style.title}>{title}</span>
        </div>
        <OptionsButton width={25} color="#282828" onClick={() => onOptionsClick ? onOptionsClick(id) : null}/>
      </div>
    </div>
  );
};
