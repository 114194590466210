import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  cell: {
    height: theme.typography.fontSize * 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: theme.spacing(0.5),
  },

  inner: {
    height: theme.typography.fontSize * 2.2,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // "&:hover": {
    //   backgroundColor: theme.palette.primary.light,
    // },
    transition: "background-color 0.2s, border-radius 0.2s, width 0.2s",
  },
  numbercurrent: {
    color: theme.palette.text.primary,
  },
  numbergrayed: {
    color: theme.palette.text.hint,
  },
  circleLeft: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: (theme.typography.fontSize * 2.2) / 2,
    borderBottomLeftRadius: (theme.typography.fontSize * 2.2) / 2,
    borderTopRightRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
  },
  circleRight: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "0 !important",
    borderBottomLeftRadius: "0 !important",
    borderTopRightRadius: (theme.typography.fontSize * 2.2) / 2,
    borderBottomRightRadius: (theme.typography.fontSize * 2.2) / 2,
  },
  highligted: {
    backgroundColor: theme.palette.primary.main,
  },
  circle: {
    height: theme.typography.fontSize * 2.2,
    width: theme.typography.fontSize * 2.2,
    borderRadius: "50%",
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  activeGrayText: {
    color: theme.palette.grey[400],
  },
  header: {
    display: "flex",
    justifyContent: "center",
    border: '1px solid #9A9A9A',
    borderRadius:8,
    background:'#fff',
    padding: '8px 12px',
  },
  arrow: {
    display: "flex",
    justifyContent: "center",
    minWidth: 40,
    height: "100%",
    margin: `0 ${theme.spacing(1)}px`,
    borderRadius: 2,
    transition: "background-color 0.2s",
  },
  month: {
    minWidth: 120,
    textAlign: "center",
  },
}));
