import React, { useState } from "react";
import useStyles from "./styles";
import CalendarBase, { CalendarBaseDay } from "../CalendarBase";
import moment, { Moment } from "moment";
import { Typography } from "@material-ui/core";

interface YearTabMonthCalendarProps {
  year: number;
  month: number;
  onMonthNameClick: (month: number) => void;
  onDayClick: (day: CalendarBaseDay) => void;
  status?: "complete" | "published" | "unpublished";
}

const YearTabMonthCalendar = ({
  month,
  year,
  status,
  onMonthNameClick,
  onDayClick,
}: YearTabMonthCalendarProps) => {
  const classes = useStyles();

  const [currentMoment] = useState<Moment>(
    moment(`${year}-${month}`, "YYYY-MM")
  );

  const _getLabelColor = () => {
    if (!status || status === "complete") return classes.greyed;
    else if (status === "published") return classes.green;
    else if (status === "unpublished") return classes.red;
    else return classes.greyed;
  };

  return (
    <div>
      <div className={classes.header}>
        <Typography
          variant="h6"
          className={classes.monthname}
          onClick={() => onMonthNameClick(month)}
        >
          {currentMoment.format("MMMM")}
        </Typography>
        <Typography
          className={`${classes.calendarstatuslabel} ${_getLabelColor()}`}
        >
          {status || "unopened"}
        </Typography>
      </div>
      <CalendarBase
        year={year}
        month={month}
        cellComponent={(d) => (
          <div className={classes.cell} onClick={() => onDayClick(d)}>
            <Typography
              className={
                d.type === "current"
                  ? classes.numbercurrent
                  : classes.numbergrayed
              }
            >
              {`${d.day}`.padStart(2, "0")}
            </Typography>
          </div>
        )}
      />
    </div>
  );
};

export default YearTabMonthCalendar;
