import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import moment from "moment";
import { Shift } from "../../RosterScreen/requests";

const useStyles = makeStyles((theme) => {
  return {
    card: {
      padding: theme.spacing(1),
    },
    cardDateContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(2),
    },
    cardInfoRow: {
      display: "flex",
      flex: 1,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    cardInfoTitleBlock: {
      display: "flex",
      flex: 2,
    },
    cardInfoValueBlock: {
      display: "flex",
      flex: 3,
    },
    cardDateText: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.fontSize * 1.3,
    },
  };
});

export default function SwapShiftCard({
  shift,
  outlined = false,
}: {
  shift: Shift;
  outlined?: boolean;
}) {
  const c = useStyles();
  return (
    <Paper className={c.card} variant={outlined ? "outlined" : "elevation"}>
      <div className={c.cardDateContainer}>
        <Typography color="textPrimary" className={c.cardDateText}>
          {moment(shift.startDts).format("ddd DD MMMM YYYY") ?? ""}
        </Typography>
      </div>

      <div className={c.cardInfoRow}>
        <div className={c.cardInfoTitleBlock}>
          <Typography color="textPrimary">Time</Typography>
        </div>
        <div className={c.cardInfoValueBlock}>
          <Typography color="textPrimary">{`${moment(shift.startDts).format(
            "HH:mm"
          )} - ${moment(shift.endDts).format("HH:mm")}`}</Typography>
        </div>
      </div>

      <div className={c.cardInfoRow}>
        <div className={c.cardInfoTitleBlock}>
          <Typography color="textPrimary">Shift name</Typography>
        </div>
        <div className={c.cardInfoValueBlock}>
          <Typography color="textPrimary">{shift.shiftName}</Typography>
        </div>
      </div>

      <div className={c.cardInfoRow}>
        <div className={c.cardInfoTitleBlock}>
          <Typography color="textPrimary">Location</Typography>
        </div>
        <div className={c.cardInfoValueBlock}>
          <Typography color="textPrimary">
            {shift.roster?.location?.name ?? ""}
          </Typography>
        </div>
      </div>

      <div className={c.cardInfoRow}>
        <div className={c.cardInfoTitleBlock}>
          <Typography color="textPrimary">Shift owner</Typography>
        </div>
        <div className={c.cardInfoValueBlock}>
          <Typography color="textPrimary">{shift.user?.fullName}</Typography>
        </div>
      </div>
    </Paper>
  );
}
