import { Box, Divider, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { AxiosError } from "axios";
import SearchBar from "material-ui-search-bar";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import Spinner from "../../../componentsV3/Spinner";
import Dropdown from "../../../componentsV3/inputs/Dropdown";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import styles from "../../../css/style.module.css";
import {
  GetScheduledSmsResponse,
  getScheduledSms,
} from "../../../requests/sms";
import {
  requestGetLocations,
  requestGetRosters
} from "../../RosterScreen/requests";
import { requestGetStaff } from "../../StaffScreen/requests";
import ScheduledSmsListItem from "./ScheduledSmsListItem";

type StatusItem =
  | { title: "Scheduled"; value: "scheduled" }
  | { title: "Stopped"; value: "stopped" }
  | { title: "Error"; value: "error" }
  | { title: "Sent"; value: "success" }
  | { title: "All"; value: "all" };

type Order = { title: string; value: string };

type Recipient = { title: string; value: number | undefined };

type Roster = { title: string; value: number | undefined };

function ScheduledSmsScreen() {
  const [search, setSearch] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState<StatusItem>({
    title: "Scheduled",
    value: "scheduled",
  });
  const [order, setOrder] = useState<Order>({ title: "Desc", value: "DESC" });
  const [take, setTake] = useState<number>(50);
  const [pages, setPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [recipient, setRecipient] = useState<Recipient>({
    title: "All Recipients",
    value: undefined,
  });

  const [roster, setRoster] = useState<Roster>({
    title: "All Rosters",
    value: undefined,
  });

  const { data, isLoading } = useQuery<GetScheduledSmsResponse, AxiosError>(
    ["get-scheduled-sms", status, take, order, page, recipient, roster, search],
    () => {
      const skip = (page - 1) * take;

      return getScheduledSms(
        {
          start: moment().subtract(1, "month"),
          end: moment().add(1, "month"),
        },
        order.value as "ASC" | "DESC",
        take,
        skip,
        status.value,
        recipient.value,
        roster.value,
        search
      );
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get scheduled SMS`,
          { variant: "error" }
        );
      },
    }
  );

  const [userList, setUserDropdown] = React.useState<Array<Recipient>>([]);

  const [rosterList, setRosterDropdown] = React.useState<Array<Roster>>([]);

  const retrieveUsers = useCallback(async () => {
    try {
      const ut = await requestGetStaff(undefined);

      let userDropdownOptions = [];

      userDropdownOptions.push({ title: "All Recipients", value: undefined });

      for (var i = 0; i < ut.length; i++) {
        if (!ut[i].deletedAt)
          userDropdownOptions.push({
            title: ut[i].fullName || "",
            value: ut[i].id,
          });
      }

      setUserDropdown(userDropdownOptions);
    } catch (err) {
      enqueueSnackbar("Unable to get users", { variant: "error" });
    } finally {
    }
  }, [enqueueSnackbar]);

  const retrieveRosters = useCallback(async () => {
    try {
      const locations = await requestGetLocations();

      const rosters = [];

      rosters.push({ title: "All Rosters", value: undefined });

      for (var i = 0; i < locations.length; i++) {
        const locID = locations[i].id;
        if (locID) {
          const rosterResult = await requestGetRosters(locID);
          if (rosterResult) {
            for (var l = 0; l < rosterResult.length; l++) {
              rosters.push({
                title: rosterResult[l].name || "",
                value: rosterResult[l].id,
              });
            }
          }
        }
      }
      setRosterDropdown(rosters);
    } catch (err) {
      enqueueSnackbar("Unable to get users", { variant: "error" });
    } finally {
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      const total = Math.ceil(data.count / take);
      retrieveUsers();
      retrieveRosters();
      setPages(total);
    }
  }, [data, take, retrieveUsers, retrieveRosters]);

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setPage(value);
  }

  if (isLoading) {
    return <Spinner />;
  }

  const requestSearch = (searchVal: string) => {
    if (data) {
      setSearch(searchVal);
      setPage(1);
    }
  };

  const cancelSearch = () => {
    setSearch("");
  };


  return (
    <RWrapper>
      <Box className={styles.pagetitlebox}>
        <Box mr={2}>
          <Typography variant="h4" color="textPrimary">
            Scheduled SMS
          </Typography>
        </Box>
        <Box className={`${styles.rightSide} ${styles.searchbarwrap}`}>
          <SearchBar
            className={styles.searchbar}
            value={search}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            cancelOnEscape
          />
        </Box>
      </Box>
      <Divider />

      <Box className={`${styles.pagetitlebox} ${styles.secondarytitlebox}`}>
        <Box className={`${styles.rightSide} ${styles.titleboxdropdownbtn}`}>
          <Box className={styles.dropdownholdercontainer}>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={`${take}`}
                onSelect={(v) => setTake(v.value as number)}
                options={[
                  { title: "5", value: 5 },
                  { title: "10", value: 10 },
                  { title: "20", value: 20 },
                  { title: "50", value: 50 },
                  { title: "100", value: 100 },
                  { title: "200", value: 200 },
                ]}
              />
            </Box>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={order.title}
                onSelect={(v) =>
                  setOrder({ title: v.title, value: v.value as string })
                }
                options={[
                  { title: "Desc", value: "DESC" },
                  { title: "Asc", value: "ASC" },
                ]}
              />
            </Box>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={status.title}
                options={[
                  { title: "All", value: "all" },
                  { title: "Scheduled", value: "scheduled" },
                  { title: "Stopped", value: "stopped" },
                  { title: "Error", value: "error" },
                  { title: "Sent", value: "success" },
                ]}
                onSelect={(item) => setStatus(item as StatusItem)}
              />
            </Box>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={roster.title}
                options={rosterList}
                onSelect={(value) => {
                  setPage(1);
                  setRoster(value as Roster);
                }}
              />
            </Box>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={recipient.title}
                options={userList}
                onSelect={(value) => {
                  setPage(1);
                  setRecipient(value as Recipient);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {data?.count !== 0 ? (
        <Box>
          {data?.scheduled.map((sms) => (
            <ScheduledSmsListItem
              key={sms.id}
              uuid={sms.uuid}
              userName={sms.user?.fullName || ""}
              rosterName={sms.shift?.roster?.name || ""}
              status={sms.status}
              sentDateTime={
                sms.sentAt
                  ? moment(sms.sentAt).format("DD/MM/YYYY HH:mm")
                  : undefined
              }
              shiftName={sms.shift?.shiftName || ""}
              scheduledDateTime={moment(sms.scheduledAt).format(
                "DD/MM/YYYY HH:mm"
              )}
              startDateTime={
                sms.shift
                  ? moment(sms.shift!.startDts).format("DD/MM/YYYY")
                  : ""
              }
              endDatetime={
                sms.shift
                  ? moment(sms.shift!.endDts).format("DD/MM/YYYY HH:mm")
                  : ""
              }
              startTime={
                sms.shift ? moment(sms.shift!.startDts).format("HH:mm") : ""
              }
              endTime={
                sms.shift ? moment(sms.shift!.endDts).format("HH:mm") : ""
              }
              error={sms.error}
              message={sms.message}
            />
          ))}
        </Box>
      ) : (
        <Box className={styles.commonbox}>
          <h3 style={{ margin: 0 }}>No records found</h3>
        </Box>
      )}

      {data?.count !== 0 ? (
        <Pagination
          className={styles.pagination}
          count={pages}
          page={page}
          onChange={handlePageChange}
        />
      ) : null}
    </RWrapper>
  );
}

export default ScheduledSmsScreen;
function barActivate() {
  throw new Error("Function not implemented.");
}

function barStop() {
  throw new Error("Function not implemented.");
}

