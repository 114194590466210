import {
  Box,
  Checkbox,
  DialogContentText,
  Divider,
  Paper,
  Popover,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import isEqual from "lodash.isequal";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Day } from ".";
import arrowLeft from "../../../assets/images/arrow/arrow-left.svg";
import arrowRight from "../../../assets/images/arrow/arrow-right.svg";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import SwitchButton from "../../../componentsV3/Buttons/SwitchButton";
import CalendarBase from "../../../componentsV3/calendars/CalendarBase";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import ModalContainer from "../../../componentsV3/modals/ModalContainer";
import ModalWindowCenter from "../../../componentsV3/modals/ModalWindowCenter";
import style from "../../../css/model.module.css";
import styles from "../../../css/style.module.css";
import { Shift } from "../../RosterScreen/requests";

const useStyles = makeStyles((theme) => {
  const subTitleBoxHeight = theme.typography.fontSize * 3;

  return {
    pagetitlebox: {
      margin: `${theme.spacing(1)}px 0`,
    },
    titleDateContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: subTitleBoxHeight,
    },
    titleDateText: {
      fontSize: theme.typography.fontSize * 1.4,
    },
    titleDateMonthText: {
      width: 160,
      textAlign: "center",
    },
    arrowButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      width: 40,
      borderRadius: theme.shape.borderRadius,
      transition: `all ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeOut}`,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    calendarContainer: {
      marginTop: theme.spacing(1),
    },
    dayNamesContainer: {
      height: subTitleBoxHeight,
      display: "flex",
    },
    checkboxButton: {
      justifyContent: "left",
      backgroundColor: "white",
      padding: "18px",
      width: "20px",
      "&.Mui-checked": {
        color: "black",
      },
    },
    dayNameBlock: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
    },
    cell: ({ available, own }: { available?: boolean; own?: boolean }) => ({
      minHeight: 175,
      display: "flex",
      padding: theme.spacing(1),
      width: "100%",
      flexDirection: "column",
      backgroundColor: available && !own ? "lightgreen" : undefined,
    }),

    currentDay: ({
      available,
      own,
    }: {
      available?: boolean;
      own?: boolean;
    }) => ({
      backgroundColor:
        available && !own ? "lightgreen" : "rgba(5, 131, 242, 0.1)",
    }),
    cellNumberIndicatorContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },

    cellIndicator: ({
      available,
      own,
    }: {
      available?: boolean;
      own?: boolean;
    }) => ({
      display: available ? "flex" : "none",
      height: theme.typography.fontSize * 1,
      width: theme.typography.fontSize * 1,
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      backgroundColor:
        available && !own
          ? theme.palette.success.main
          : available && own
          ? theme.palette.warning.main
          : undefined,
    }),
    cellShiftsContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    cellShiftsDetailsArrowContainer: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      flex: 1,
      borderRadius: theme.shape.borderRadius,
      transition: `all ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeOut}`,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    cellShiftsDetailsArrowPlaceholder: {
      flex: 1,
    },
    cellShiftsDetailsContainer: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      flex: 5,
      height: "100%",
    },
    cellShiftsDetailsTimeText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    popoverContainer: {
      height: "max-content",
      width: 300,
      maxWidth: "80vw",
      padding: theme.spacing(2),
    },
    popoverDateContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1),
    },
    popoverTextSplitContainer: {
      display: "flex",
      flex: 1,
    },
    popoverSplitText: {
      flex: 1,
    },
    popoverDateText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    popoverButtonContainer: {
      marginTop: theme.spacing(1),
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },
    popoverButtonContainerInner: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    popover: {
      "& .MuiPopover-paper": {
        borderRadius: 8,
      },
    },
  };
});

interface CellProps {
  current: boolean;
  number: number;
  shifts?: Array<Shift>;
  onAvailableChange: (shift: Shift) => void;
  buttonDisabled: boolean;
}
const Cell = ({
  number,
  shifts,
  current,
  onAvailableChange,
  buttonDisabled,
}: CellProps) => {
  const history = useHistory();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "shiftDetails",
  });

  const [dialogOpen, setdialogOpen] = useState<boolean>(false);

  const [shiftIdx, setshiftIdx] = useState<number>(0);
  useEffect(() => {
    setshiftIdx(0);
  }, [shifts]);

  const currentShift =
    shifts && shifts[shiftIdx] ? shifts[shiftIdx] : undefined;

  const prevShift = () => {
    const newIdx = shiftIdx - 1;
    if (shifts && shifts[newIdx]) {
      setshiftIdx(newIdx);
    }
  };

  const nextShift = () => {
    const newIdx = shiftIdx + 1;
    if (shifts && shifts[newIdx]) {
      setshiftIdx(newIdx);
    }
  };

  const onMakeAvailableClick = () => {
    if (currentShift) {
      onAvailableChange(currentShift);
    }
  };

  const c = useStyles({
    available: currentShift && currentShift.available ? true : undefined,
    own: currentShift && currentShift.own,
  });

  return (
    <Paper className={`${c.cell} ${current ? c.currentDay : ""}`} elevation={0}>
      <div className={styles.cellday}>
        <Typography color="textPrimary">{number}</Typography>
        <div className={c.cellIndicator} />
      </div>
      {shifts && shifts.length && currentShift ? (
        <div className={c.cellShiftsContainer}>
          {shifts.length > 1 && shifts[shiftIdx - 1] ? (
            <div
              className={c.cellShiftsDetailsArrowContainer}
              onClick={prevShift}
            >
              <Typography color="textPrimary">{"<"}</Typography>
            </div>
          ) : (
            <div className={c.cellShiftsDetailsArrowPlaceholder} />
          )}
          <div
            className={c.cellShiftsDetailsContainer}
            {...bindTrigger(popupState)}
          >
            {shifts[shiftIdx].startDts && shifts[shiftIdx].endDts ? (
              <Typography
                align="left"
                color="textPrimary"
                className={c.cellShiftsDetailsTimeText}
              >
                Time:
                {moment(currentShift.startDts).format("HH:mm")} -{" "}
                {moment(currentShift.endDts).format("HH:mm")}
              </Typography>
            ) : null}
            <Typography color="textPrimary" align="left">
              Shift Name: {currentShift.shiftName}
            </Typography>
            <Typography color="textPrimary" align="left">
              Location: {currentShift.roster?.location?.name}
            </Typography>
            <Typography color="textPrimary" align="left">
              Owner: {currentShift.user?.fullName}
            </Typography>
          </div>
          {shifts.length > 1 && shifts[shiftIdx + 1] ? (
            <div
              className={c.cellShiftsDetailsArrowContainer}
              onClick={nextShift}
            >
              <Typography color="textPrimary">{">"}</Typography>
            </div>
          ) : (
            <div className={c.cellShiftsDetailsArrowPlaceholder} />
          )}
        </div>
      ) : null}
      <Popover
        className={c.popover}
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        BackdropProps={{}}
      >
        {currentShift ? (
          <Paper className={c.popoverContainer} elevation={0}>
            <div className={c.popoverDateContainer}>
              <Typography className={c.popoverDateText} color="textPrimary">
                {moment(currentShift.startDts).format("ddd DD MMMM YYYY")}
              </Typography>
              <div className={c.cellIndicator} />
            </div>
            <div className={c.popoverTextSplitContainer}>
              <Typography className={c.popoverSplitText} color="textPrimary">
                Time
              </Typography>
              <Typography className={c.popoverSplitText} color="textPrimary">
                {`${moment(currentShift.startDts).format("HH:mm")} - ${moment(
                  currentShift.endDts
                ).format("HH:mm")}`}
              </Typography>
            </div>
            <div className={c.popoverTextSplitContainer}>
              <Typography className={c.popoverSplitText} color="textPrimary">
                Shift name
              </Typography>
              <Typography className={c.popoverSplitText} color="textPrimary">
                {currentShift.shiftName}
              </Typography>
            </div>
            <div className={c.popoverTextSplitContainer}>
              <Typography className={c.popoverSplitText} color="textPrimary">
                Location
              </Typography>
              <Typography className={c.popoverSplitText} color="textPrimary">
                {currentShift.roster?.location?.name}
              </Typography>
            </div>
            <div className={c.popoverTextSplitContainer}>
              <Typography className={c.popoverSplitText} color="textPrimary">
                Owner
              </Typography>
              <Typography className={c.popoverSplitText} color="textPrimary">
                {currentShift.user?.fullName}
              </Typography>
            </div>

            {new Date(currentShift.startDts) > new Date() ? (
              <div className={c.popoverButtonContainer}>
                {currentShift.own ? (
                  <div className={c.popoverButtonContainerInner}>
                    <SwitchButton
                      title={"Swap"}
                      onClick={() => {
                        history.push("/swap", { shift: currentShift });
                      }}
                    />
                    <SwitchButton
                      title={
                        currentShift.available
                          ? "Make unavailable"
                          : "Make available"
                      }
                      color={currentShift.available ? "warning" : undefined}
                      onClick={onMakeAvailableClick}
                      disabled={buttonDisabled}
                    />
                  </div>
                ) : (
                  <SwitchButton
                    title={"Take Shift"}
                    color={currentShift.available ? "success" : undefined}
                    onClick={() => setdialogOpen(true)}
                  />
                )}
              </div>
            ) : null}
          </Paper>
        ) : null}
      </Popover>

      <ModalContainer open={dialogOpen} onClose={() => setdialogOpen(false)}>
        <ModalWindowCenter>
          <Box className={style.new_container}>
            <Box>
              <Box className={style.titlebox}>
                <Typography variant="h5" className={style.title}>
                  Accept shift?
                </Typography>
              </Box>
              <DialogContentText>
                Confirm that you want to take this shift
              </DialogContentText>
            </Box>
            <Box>
              <Box className={style.buttonsholder}>
                <Box
                  className={`${style.submitbuttonsholder} ${style.fillbtn}`}
                >
                  <Box className={style.cancelbuttonmargin}>
                    <StandardButton
                      className={"secondary"}
                      title="Close"
                      onClick={() => setdialogOpen(false)}
                    />
                  </Box>
                  <StandardButton
                    title="Confirm"
                    accent
                    onClick={async () => {
                      await onMakeAvailableClick();
                      await setdialogOpen(false);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalWindowCenter>
      </ModalContainer>
    </Paper>
  );
};

interface UserMonthlyRosterDesktopProps {
  date?: Moment;
  shifts: {
    [key: string]: Array<Shift>;
  };
  today?: Day;
  selectedDay?: Day;
  setselectedDay: (d: Day | undefined) => void;
  onPrevMonthClick: () => void;
  onNextMonthClick: () => void;
  onAvailableChange: (shift: Shift) => void;
  onHandleCheckBox: (available: boolean) => void;
}

export default function UserMonthlyRosterDesktop({
  date,
  shifts,
  today,
  selectedDay,
  setselectedDay,
  onPrevMonthClick,
  onNextMonthClick,
  onAvailableChange,
  onHandleCheckBox,
}: UserMonthlyRosterDesktopProps) {
  const c = useStyles();
  if (!date) {
    return null;
  }

  const handleClick = async (e: any) => {
    onHandleCheckBox(e.target.checked);
  };

  return (
    <RWrapper>
      <Box className={styles.pagetitlebox}>
        <Typography variant="h4">My Roster</Typography>
      </Box>
      <Divider className={styles.rsspace} />
      <Box className={styles.framecontainer}>
        <Box className={styles.monthcalenderbox}>
          <Box
            className={styles.calenderbox}
            justifyContent="center"
            display="flex"
          >
            <Box
              className={styles.calenderbutton}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Box onClick={onPrevMonthClick} style={{ cursor: "pointer" }}>
                <img alt="arrow-right" src={arrowLeft} />
              </Box>
              <Box className={c.titleDateMonthText}>
                <Typography className={c.titleDateText} color="textPrimary">
                  {date.format("YYYY MMMM")}
                </Typography>
              </Box>
              <Box onClick={onNextMonthClick} style={{ cursor: "pointer" }}>
                <img alt="arrow-right" src={arrowRight} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={styles.dayNamesWrapper}>
          <Box className={styles.dayNamesContainer}>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Mon</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Tue</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Wed</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Thu</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Fri</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Sat</Typography>
            </Box>
            <Box className={styles.dayNameBlock}>
              <Typography color="textPrimary">Sun</Typography>
            </Box>
          </Box>
          <CalendarBase
            month={Number(date.format("M"))}
            year={Number(date.format("YYYY"))}
            spacing={1}
            cellComponent={(d) =>
              d.type === "current" ? (
                <Cell
                  onAvailableChange={onAvailableChange}
                  number={d.day}
                  current={isEqual(
                    { year: d.year, month: d.month, day: d.day },
                    today
                  )}
                  shifts={
                    shifts[
                      `${d.year}.${d.month.toString().padStart(2, "0")}.${d.day
                        .toString()
                        .padStart(2, "0")}`
                    ]
                  }
                  buttonDisabled={false}
                />
              ) : null
            }
          />
        </Box>
        <Box>
          <Checkbox onClick={handleClick} color="primary" />
          Show Available Shifts
        </Box>
      </Box>
    </RWrapper>
  );
}
