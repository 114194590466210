import React from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";
import { GetSettingResponse, getSettings, putSmsReminderTimeOffset } from "../../../requests/settings";
import { useSnackbar } from "notistack";
import styles from "../../../css/style.module.css";

const SectionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  box-sizing: border-box;
`;

const LeftSide = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const Input = styled.input<{ errored?: boolean }>`
  padding: ${({ theme }) => theme.gap[1]};
  `;
interface FormInputs {
  offset: string;
}

function convertToHHmm(minutes: number) {
  const hours = minutes / 60;
  const roundedHours = Math.floor(hours);
  const min = (hours - roundedHours) * 60;
  const roundedMinutes = Math.round(min);
  return `${roundedHours
    .toString()
    .padStart(2, "0")}:${roundedMinutes.toString().padStart(2, "0")}`;
}

function convertToMinutes(HHmm: string) {
  const [HH, mm] = HHmm.split(":");
  return Number(HH) * 60 + Number(mm);
}

function SmsTimeOffsetForm() {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, refetch } = useQuery<GetSettingResponse, AxiosError>(
    "tenant-settings-sms-reminder",
    getSettings,
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get SMS reminder time offset`,
          { variant: "error" }
        );
      },
    }
  );

  const { mutate } = useMutation<unknown, AxiosError, number>(
    (offset) => putSmsReminderTimeOffset(offset),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to set new offset`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        await refetch();
        reset();
        enqueueSnackbar(`New offset saved`, {
          variant: "success",
        });
      },
    }
  );

  const { register, handleSubmit, errors, reset } = useForm<FormInputs>();

  function onSubmit(v: FormInputs) {
    mutate(convertToMinutes(v.offset));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SectionContainer>
        <LeftSide>
            {!isLoading && (
            <InputMask
              autoComplete="off"
              id="sms-reminder-time-offset"
              name="offset" className={styles.smsinput}
              placeholder="HH:MM"
              mask="99:99"
              defaultValue={data && convertToHHmm(data?.smsReminderOffset)}
            >
              {(inputProps: any) => (
                <Input
                  {...inputProps}
                  errored={errors.offset}
                  ref={register({
                    pattern: /^(\d)+:[0-5][0-9]$/,
                  })}
                />
              )}
            </InputMask>
          )}
        </LeftSide>
        <StandardButton type="submit" title="Save" accent />
      </SectionContainer>
    </form>
  );
}

export default SmsTimeOffsetForm;
