import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { PUBLIC_API_URL } from "../../ENDPOINTS";
import accountPic from "../../assets/images/pictograms/account.svg";
import calendarPic from "../../assets/images/pictograms/calendar.svg";
import requestsPic from "../../assets/images/pictograms/requests.svg";
import templatesPic from "../../assets/images/pictograms/templates.svg";
import roosterLogo from "../../assets/images/rooster.svg";
import { getUserDetails } from "../../requests/user";
import { useProgressBar } from "../bars/ProgressBarGlobal";
import proaxiomLogo from "./ProaxiomLogo.png";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      minWidth: 240,
      maxWidth: "80vw",
      backgroundColor: theme.palette.background.paper,
      boxSizing: "border-box",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    logoContainer: {
      marginTop: theme.spacing(63),
      height: 80,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    companyLogo: {
      marginBottom: theme.spacing(5),
      height: 80,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    proaxiomLogo: {
      height: 80,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    userRole: {
      fontWeight: 600,
      marginBottom: "10px",
    },
    logo: {
      height: "80px",
    },
    navlink: {
      width: "100%",
      marginBottom: theme.spacing(1),
      textDecoration: "none",
    },
    navlinkContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      color: theme.palette.text.primary,
    },
    active: {
      "& div div": {
        backgroundColor: theme.palette.primary.main,
      },
      "& div p": {
        fontWeight: theme.typography.fontWeightMedium,
      },
      "& div img": {
        filter: `brightness(100)`,
      },
    },
    iconContainer: {
      width: 40,
      height: 30,
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(1),
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: `background-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeOut}`,
    },
    icon: {
      height: "60%",
    },
  };
});

export default function SideNavUser() {
  const c = useStyles();

  const { barActivate, barStop } = useProgressBar();

  const [userCompanyLogo, setUserCompanyLogo] = useState<any>();
  const [userCompanyName, setUserCompanyName] = useState<any>();

  const getUser = useCallback(async () => {
    try {
      barActivate();
      const u = await getUserDetails();
      setUserCompanyLogo("");
      if (u?.tenants![0].companyLogo) {
        setUserCompanyLogo(u?.tenants![0].companyLogo);
      }
      setUserCompanyName(u?.tenants![0].name);
    } catch (error) {
      console.error(error);
    } finally {
      barStop();
    }
  }, [barActivate, barStop]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <div className={c.container}>
      <div className={c.companyLogo}>
        <img
          src={userCompanyLogo && `${PUBLIC_API_URL}/logo/${userCompanyLogo}`}
          alt={userCompanyName}
          width="190px"
        />
      </div>
      <NavLink exact to="/" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="roster_calendar" src={calendarPic} />
          </div>

          <Typography color="textPrimary">My Roster</Typography>
        </div>
      </NavLink>
      <NavLink to="/requests" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="roster_requests" src={requestsPic} />
          </div>

          <Typography color="textPrimary">Requests</Typography>
        </div>
      </NavLink>
      <NavLink to="/account" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="roster_account" src={accountPic} />
          </div>
          <Typography color="textPrimary">Account</Typography>
        </div>
      </NavLink>
      <NavLink to="/reports" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="reports" src={templatesPic} />
          </div>
          <Typography color="textPrimary">Reports</Typography>
        </div>
      </NavLink>
      <NavLink
        to="/request-swap"
        activeClassName={c.active}
        className={c.navlink}
      >
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="request_swap" src={requestsPic} />
          </div>

          <Typography color="textPrimary">Request Swap</Typography>
        </div>
      </NavLink>
      {/* <LogoutButton onClick={logout}>Logout</LogoutButton> */}
      <div className={c.logoContainer}>
        <img className={c.logo} alt="logo" src={roosterLogo} />
      </div>
      <div className={c.proaxiomLogo}>
        <img src={proaxiomLogo} alt="logo" width="190px" />
      </div>
      <Typography color="textPrimary" className={c.userRole}>
        &#169; 2022
      </Typography>
    </div>
  );
}
