import React from "react";
import { FieldProps } from "formik";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  outer: {
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid var(--greyish)",
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
  },
  inner: {
    width: 20,
    height: 20,
    transition: theme.transitions.create("background-color", {
      duration: theme.transitions.duration.shorter,
    }),
    borderRadius: theme.shape.borderRadius,
  },
  blue: {
    backgroundColor: "#282828",
  },
}));

const FormikCheckbox = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps) => {
  const classes = useStyles();
  return (
    <Box onClick={() => setFieldValue(name, !value)} className={classes.outer}>
      <Box className={`${classes.inner} ${value ? classes.blue : ""}`}></Box>
    </Box>
  );
};

export default FormikCheckbox;
