import React, { useState } from "react";
import { Grid, Box } from "@material-ui/core";
import styles from "../../../css/style.module.css";
import styled from "styled-components";
import Calenderimg from "./image/calender.svg";
import Revert from "./image/revert.svg";
import Uparrow from "./image/uparrow.svg";
import Downarrow from "./image/downarrow.svg";

const Text = styled.div`
  font-size: 12px;
`;

const StatusText = styled(Text)<{
  variant: string;
}>`
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  display: inline-block;
  border-radius: 35px;
  font-size: 16px;
  line-height: 1;
  padding: 7px 12px;
  text-transform: capitalize;
  border: 1px solid
    ${({ theme, variant }) =>
      variant === "success"
        ? "green"
        : variant === "error"
        ? theme.colors.deepRed
        : variant === "stopped"
        ? "orange"
        : variant === "scheduled"
        ? theme.colors.deepSkyBlue
        : theme.colors.greyishBrown};
  color: ${({ theme, variant }) =>
    variant === "success"
      ? "green"
      : variant === "error"
      ? theme.colors.deepRed
      : variant === "stopped"
      ? "orange"
      : variant === "scheduled"
      ? theme.colors.deepSkyBlue
      : theme.colors.greyishBrown};
`;

interface ScheduledSmsListItemProps {
  uuid: string;
  shiftName: string;
  startDateTime: string;
  endDatetime: string;
  startTime?: string;
  endTime?: string;
  userName: string;
  rosterName: string;
  scheduledDateTime: string;
  status: string;
  sentDateTime?: string;
  error?: string;
  message?: string;
}
function ScheduledSmsListItem(props: ScheduledSmsListItemProps) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <Grid container className={styles.smscontainer}>
        <Grid item lg={3} md={2} sm={6} xs={12} className={styles.smsbox}>
          <Box className={styles.smscontent}>
            <p>
              <span>From Rooster:</span> {props.rosterName}
            </p>
            <p>
              <span>To:</span> {props.userName}{" "}
            </p>
          </Box>
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12} className={styles.smsbox}>
          <Box className={styles.smscontent}>
            <p className={styles.title}>
              <span>{props.shiftName}</span>
            </p>
            <div className={styles.iconbox}>
              <img alt="rooster" src={Calenderimg} />{" "}
              <p>{props.startDateTime}</p>
            </div>
            <div className={styles.iconbox}>
              <img alt="rooster" src={Revert} />{" "}
              <p>
                {props.startTime} - {props.endTime}
              </p>
            </div>
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12} className={styles.smsbox}>
          <Box className={styles.smscontent}>
            <p>
              <span>Message</span>
            </p>

            {props.message?.length ? (
              <p style={{ color: "#686868" }}>
                {showMore
                  ? props.message
                  : `${props.message?.substring(0, 60)}`}
              </p>
            ) : null}

            {props.message?.length ? (
              <button
                className={styles.btn}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Show Less
                    <img alt="rooster" src={Uparrow} />
                  </div>
                ) : (
                  <div>
                    Show More
                    <img alt="rooster" src={Downarrow} />
                  </div>
                )}
              </button>
            ) : null}
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12} className={styles.smsbox}>
          <Box className={`${styles.smscontent} ${styles.smscontentright}`}>
            <div className={styles.error}>
              <p>{props.error}</p>{" "}
              <StatusText variant={props.status}>{props.status}</StatusText>
            </div>
            <p>
              <span>Send At:</span> {props.scheduledDateTime}
            </p>

            <p>
              {props.sentDateTime ? <span>Send At:</span> : null}{" "}
              {props.sentDateTime}
            </p>

            <div className={styles.iconbox}>
              <img alt="rooster" src={Calenderimg} />{" "}
              <p>{props.scheduledDateTime}</p>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ScheduledSmsListItem;
