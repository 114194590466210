import { Checkbox, ClickAwayListener, Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DownArrow from "@material-ui/icons/KeyboardArrowDownRounded";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import { AxiosError } from "axios";
import { getIn, useField, useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, {
  Fragment,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from "react";
import MaskedInput from "react-input-mask";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { RectangleBadgeFilled } from "../../../../componentsV3/styled-components/badges";
import { useUserSelections } from "../../../../context/userSelectionsProvider";
import styles from "../../../../css/style.module.css";
import { Offer, deleteOffer } from "../../../../requests/offers";
import {
  GetShiftAssigneesResponse,
  getShiftAssignees,
} from "../../../../requests/shifts";
import { styledTheme } from "../../../../themesV3/styledTheme";
import {
  requestGetUser,
  requestReportsToUserData,
} from "../../../StaffEditScreen/requests";
import { User } from "../../../StaffScreen/index";
import { Shift } from "../../requests";
import ShiftEditOfferRow from "./shift-edit-offer-row";

const useStyles = makeStyles((theme) => {
  return {
    checked: {
      "&.Mui-checked": {
        color: "#282828",
      },
    },
  };
});
const Tr = styled.tr`
  // height: 32px;
`;

const ArrowHolder = styled.div<{ active?: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ active }) => (active ? "transform: rotate(180deg)" : "")};
  transition: all 0.2s;
`;

const Td = styled.td<{ padding?: string }>`
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  padding: ${({ padding }) => padding || "10px 4px"};
  background-color: ${({ theme }) => theme.colors.background.paper};
  box-sizing: border-box;
`;

const StyledInput = styled.input<{ error?: boolean }>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.greyish};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  padding: 1px 2px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  ${({ error, theme }) =>
    error ? `border: 1px solid ${theme.colors.deepRed};` : ""}
`;

const AutocompleteBox = styled.div`
  position: relative;
  height: 100%;
`;

const BadgeClickBox = styled(AutocompleteBox)`
  cursor: pointer;
`;

const Ul = styled.ul`
  position: absolute;
  z-index: 999;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.greyish};
  box-sizing: border-box;
  min-width: 100%;
  max-height: 120px;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Li = styled.li`
  padding: 6px 16px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 8px;
  transition: all 0.2s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const CheckboxRectangle = styled.div<{ active?: boolean }>`
  height: 20px;
  width: 20px;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  border: ${({ active, theme }) =>
    active ? "none" : `1px solid ${theme.colors.greyish}`};
  background-color: ${({ active, theme }) =>
    active ? "#282828" : "transparent"};
  transition: all 0.2s;
  box-sizing: border-box;
  cursor: pointer;
`;

function CheckboxRect(
  props: InputHTMLAttributes<HTMLInputElement> & { name: string }
) {
  const [, { value }, { setValue }] = useField(props);
  return (
    <CheckboxRectangle
      active={Boolean(value)}
      onClick={() => setValue(!value)}
    />
  );
}

function Input(
  props: InputHTMLAttributes<HTMLInputElement> & { name: string }
) {
  const [field, meta] = useField(props);
  return (
    <StyledInput
      {...field}
      {...props}
      error={Boolean(meta.error && meta.touched)}
    />
  );
}

function TimeInput(
  props: InputHTMLAttributes<HTMLInputElement> & { name: string }
) {
  const [field, meta] = useField(props);
  return (
    <MaskedInput mask="99:99" {...field} {...props}>
      {(inputProps: any) => {
        return (
          <StyledInput
            {...inputProps}
            disabled={props.disabled}
            error={Boolean(meta.error && meta.touched)}
          />
        );
      }}
    </MaskedInput>
  );
}
interface AutocompleteInputProps<T> {
  name: string;
  options: Array<T>;
  label: (option: T) => string;
  onValueSet?: (option: T | null) => void;
  disabled?: boolean;
  // value: (option: T) => any;
  sideFieldName: string;
}
export function AutocompleteInput<T>(props: AutocompleteInputProps<T>) {
  const [field, meta, { setValue }] = useField(props);
  const [, { error }] = useField(props.sideFieldName);
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: props.name,
    options: props.options,
    getOptionLabel: props.label,
    value: field.value || null,
    onChange: (event, value) => {
      setValue(value);
      if (props.onValueSet) {
        props.onValueSet(value);
      }
    },
  });

  return (
    <AutocompleteBox {...getRootProps()}>
      <StyledInput
        {...getInputProps()}
        disabled={props.disabled}
        error={Boolean(error && meta.touched)}
      />
      {groupedOptions.length ? (
        <Ul {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <Li {...getOptionProps({ option, index })}>
              {props.label(option)}
            </Li>
          ))}
        </Ul>
      ) : null}
    </AutocompleteBox>
  );
}

function BadgeClicker({ name }: { name: string }) {
  const [, field, { setValue }] = useField(name);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <BadgeClickBox onClick={() => setOpen(!open)}>
        <RectangleBadgeFilled
          backgroundColor={
            field.value === "published"
              ? "lightGreen"
              : styledTheme.colors.lightYellow
          }
          color={
            field.value === "published"
              ? "green"
              : styledTheme.colors.darkYellow
          }
        >
          {field.value}
        </RectangleBadgeFilled>
        {open && (
          <Ul>
            <Li
              onClick={() => {
                setValue("draft");
                setOpen(false);
              }}
            >
              Draft
            </Li>
            <Li
              onClick={() => {
                setValue("published");
                setOpen(false);
              }}
            >
              Published
            </Li>
          </Ul>
        )}
      </BadgeClickBox>
    </ClickAwayListener>
  );
}

interface RosterDayViewFormRowProps {
  // shift: Shift;
  index: number;
  onRightClick: (det: {
    id?: number;
    index: number;
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>;
  }) => void;
  refetchShifts: () => void;
  selectedData: number[];
  setSelectedData: (arg0: number[]) => void;
}
function RosterDayViewFormRow({
  index,
  onRightClick,
  refetchShifts,
  selectedData,
  setSelectedData,
}: RosterDayViewFormRowProps) {
  const c = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { selectedRosters } = useUserSelections();
  const { values, setFieldValue } = useFormikContext<{
    shifts: Array<Shift>;
  }>();
  const shift = values.shifts[index];
  const [active, setActive] = useState<boolean>(false);

  const offersOut = getIn(values, `shifts.${index}.offersOut`) || [];
  const offersIn = getIn(values, `shifts.${index}.offersIn`) || [];
  const available = getIn(values, `shifts.${index}.available`);
  const editable = !offersOut.length && !offersIn.length && !available;


  const [assigneeData, setAssigneeData] = useState<User[] | undefined>();
  const [user, setUser] = useState<User[] | undefined>(undefined);

  const { data } = useQuery<GetShiftAssigneesResponse, AxiosError>(
    [`get-assignees-for-the-shift`, shift.roster?.id],
    () => {
      //  enabled: Boolean(shift.roster?.id) - keeps roster id existing
      return getShiftAssignees(shift.roster?.id as number);
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(shift.roster?.id),
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get assignees`,
          { variant: "error" }
        );
      },
    }
  );

  const getUser = useCallback(async (userID: string) => {
    try {
      const reportsTo = await requestReportsToUserData(userID);
      reportsTo.sort(function (a, b) {
        return a.first_name.localeCompare(b.first_name);
      });
      if (reportsTo && reportsTo.length > 0) {
        const newArr = reportsTo.map((item) => {
          return {
            deletedAt: item.deleted_at,
            dob: null,
            email: item.users_email,
            firstName: item.first_name,
            lastName: item.last_name,
            fullName: `${item.first_name} ${item.last_name}`,
            gender: null,
            isAdministrator: item.is_administrator,
            mobileCode: item.mobile_country_code,
            mobileNumber: item.mobile,
            mobileFull: `${item.mobile_country_code} ${item.mobile}`,
            preferredName: null,
            title: item.users_title,
            id: item.user_reports_to_user_id,
          };
        });
        setUser(newArr);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, []);

  useEffect(() => {
    if (data && data.assignees.length > 0) {
      setAssigneeData(data.assignees);
      if (data.assignees[0].id) {
        if (!user) getUser(data.assignees[0].id.toString());
      }
    }
  }, [data, user, getUser]);

  useEffect(() => {
    if (data && user && user.length > 0) {
      const newArray = user.concat(data?.assignees);
      const uniqueIds: any[] = [];
      const unique = newArray.filter((element) => {
        const isDuplicate = uniqueIds.includes(element.id);
        if (!isDuplicate) {
          uniqueIds.push(element.id);
          return true;
        }
        return false;
      });
      setAssigneeData(unique);
    }
  }, [user, data]);

  const { mutate: removeOffer } = useMutation<unknown, AxiosError, number>(
    (offerId) => deleteOffer(offerId),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to cancel swap offer`,
          { variant: "error" }
        );
      },
      onSuccess: () => {
        refetchShifts();
        enqueueSnackbar(`Swap offer cancelled`, {
          variant: "success",
        });
      },
    }
  );

  const handleClick = (e: any) => {
    const { value, checked } = e.target;

    if (checked) {
      setSelectedData([...selectedData, Number(value)]);
    } else {
      const selectedShift = selectedData.filter(
        (data: any) => data !== Number(value)
      );
      setSelectedData(selectedShift);
    }
  };

  return (
    <Fragment>
      <Tr
        onContextMenu={(event) => {
          event.preventDefault();
          onRightClick({ id: shift.id, index, event });
        }}
      >
        <Td>
          {offersIn.length || offersOut.length ? (
            <ArrowHolder onClick={() => setActive(!active)} active={active}>
              <DownArrow />
            </ArrowHolder>
          ) : null}
        </Td>
        <Td>
          <Checkbox
            value={shift.id}
            className={`${styles.checkbox} ${c.checked}`}
            checked={selectedData.includes(Number(shift.id))}
            disabled={!shift.id}
            onClick={handleClick}
            color="default"
          />
        </Td>
        <Td />
        <Td>
          <AutocompleteInput
            name={`shifts.${index}.roster`}
            options={selectedRosters}
            label={(o) => o.name}
            onValueSet={(o) => {
              setFieldValue(`shifts.${index}.rosterId`, o?.id);
            }}
            disabled={!editable}
            sideFieldName={`shifts.${index}.rosterId`}
          />
        </Td>
        <Td>
          <AutocompleteInput
            name={`shifts.${index}.owner`}
            options={data ? data.assignees : []}
            label={(o) => o.fullName || `${o.firstName} ${o.lastName}`}
            onValueSet={(o) => {
              setFieldValue(`shifts.${index}.ownerName`, o?.id);
              if (o && o.id) getUser(o.id.toString());
            }}
            disabled={!editable}
            sideFieldName={`shifts.${index}.ownerName`}
          />
        </Td>
        <Td>
          <AutocompleteInput
            name={`shifts.${index}.user`}
            options={
              assigneeData && assigneeData.length > 0 ? assigneeData : []
            }
            label={(o) => o.fullName || `${o.firstName} ${o.lastName}`}
            onValueSet={(o) => {
              setFieldValue(`shifts.${index}.rosteredUser`, o?.id);
            }}
            disabled={!editable}
            sideFieldName={`shifts.${index}.rosteredUser`}
          />
        </Td>
        <Td>
          <Input name={`shifts.${index}.shiftName`} disabled={!editable} />
        </Td>
        <Td>
          <CheckboxRect name={`shifts.${index}.available`} />
        </Td>
        <Td>
          <CheckboxRect name={`shifts.${index}.onCall`} disabled={!editable} />
        </Td>
        <Td>
          <TimeInput name={`shifts.${index}.startTime`} disabled={!editable} />
        </Td>
        <Td>
          <TimeInput name={`shifts.${index}.endTime`} disabled={!editable} />
        </Td>
        <Td>
          <BadgeClicker name={`shifts.${index}.status`} />
        </Td>

        {/* <Td>
          <RectangleBadgeFilled
            backgroundColor={
              shift.status === "published"
                ? "green"
                : styledTheme.colors.deepRed
            }
          >
            true
          </RectangleBadgeFilled>
        </Td> */}
        {/* <Td>
          <TimelineHolder height="0">
            <Timeline24
              time={{ start, end }}
              current={current}
              previous={!current}
              color={accent}
            />
          </TimelineHolder>
        </Td> */}
      </Tr>
      {/* <Tr>
        <Td colSpan={10} padding="0">
          <Collapse in={active} timeout="auto" unmountOnExit mountOnEnter>
            <TimeLineHeader>
              {Array.from(Array(24).keys()).map((item) => (
                <TimeHeaderCell key={item}>
                  <Typography variant="body2" color="textSecondary">
                    {`${item}`.padStart(2, "0")}
                  </Typography>
                </TimeHeaderCell>
              ))}
            </TimeLineHeader>
            <TimelineHolder>
              <Timeline24
                time={{ start, end }}
                current={current}
                previous={!current}
                color={accent}
              />
            </TimelineHolder>
          </Collapse>
        </Td>
      </Tr> */}
      <Tr className={styles.tablespace}>
        <Td colSpan={13}>
          <Collapse in={active} timeout="auto" mountOnEnter>
            {offersOut.map((offer: Offer) => (
              <ShiftEditOfferRow
                key={offer.id}
                offer={offer}
                deleteOffer={removeOffer}
              />
            ))}
            {offersIn.map((offer: Offer) => (
              <ShiftEditOfferRow
                key={offer.id}
                offer={offer}
                deleteOffer={removeOffer}
              />
            ))}
          </Collapse>
        </Td>
      </Tr>
    </Fragment>
  );
}

export default RosterDayViewFormRow;
