import styled from "styled-components";

export const OffersTable = styled.table`
  width: 100%;
`;

export const OffersThead = styled.thead<{ backgroundColor?: string }>`
  background-color: ${({ theme }) => theme.colors.background.paper};
`;

export const OffersTr = styled.tr<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const OffersTh = styled.th<{ width?: string }>`
  width:'auto';
`;

export const OffersTd = styled.td`
  text-align: center;
`;
