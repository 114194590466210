import React, { useState } from "react";
import styles from "./styles.module.css";

interface Option {
  title: string;
  act: () => void;
  color?: string;
}

interface OptionsDropdownButtonProps {
  options?: Array<Option>;
  color?: string;
  width?: string | number;
}

export default ({ options, color, width }: OptionsDropdownButtonProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const toggle = () => {
    setVisible(!visible);
  };

  return (
    <div className={styles.main}>
      <div className={styles.optionsbuttoncontainer} style={{ width: width }} onClick={toggle}>
        <div className={styles.dot} style={{ backgroundColor: color }} />
        <div className={styles.dot} style={{ backgroundColor: color }} />
        <div className={styles.dot} style={{ backgroundColor: color }} />
      </div>
      <div
        className={`${styles.dropdowncontainer} ${
          visible ? "" : styles.hidden
        }`}
      >
        {options &&
          options.map((o: Option, index: number) => (
            <div
              key={`${index}`}
              className={styles.option}
              style={{ color: o.color }}
              onClick={() => {
                o.act();
                toggle();
              }}
            >
              {o.title}
            </div>
          ))}
      </div>
    </div>
  );
};
