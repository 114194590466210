import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import styled from "styled-components";
import { PUBLIC_API_URL } from "../../../ENDPOINTS";
import StandardButton from "../../../components/Buttons/StandardButton";
import { useProgressBar } from "../../../components/bars/ProgressBarGlobal";
import { deleteLogo, postImage } from "../../../requests/settings";
import { getUserDetails } from "../../../requests/user";

const SectionContainer = styled.div`
  padding: ${({ theme }) => theme.gap[1]};
  background-color: ${({ theme }) => theme.colors.base.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  box-sizing: border-box;
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.greyishBrown};
  display: flex;
  align-items: center;
`;

interface FormInputs {
  offset: string;
}

function TenantLogoForm() {
  const [selectedImage, setSelectedImage] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>();
  const [userCompanyLogo, setUserCompanyLogo] = useState<any>();
  const [userCompanyName, setUserCompanyName] = useState<any>();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      const formData = new FormData();
      formData.append("myImage", selectedImage);
      imageUpload({ userID, formData });
      getUser();
    }
  }, [selectedImage, setImageUrl]);

  const [userID, setUserID] = useState<any>();

  const getUser = useCallback(async () => {
    try {
      barActivate();
      const u = await getUserDetails();
      setUserID(u?.tenants![0].id);
      setUserCompanyLogo("");
      if (u?.tenants![0].companyLogo) {
        setUserCompanyLogo(u?.tenants![0].companyLogo);
      }
      setUserCompanyName(u?.tenants![0].name);
    } catch (error) {
      console.error(error);
    } finally {
      barStop();
    }
  }, [barActivate, barStop]);
  useEffect(() => {
    getUser();
  }, [getUser]);

  const { mutate: imageUpload } = useMutation<unknown, AxiosError, any>(
    ({ userID, formData }) => postImage(userID, formData),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to Uploaded Logo`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        getUser();
        enqueueSnackbar(`Logo Uploded`, {
          variant: "success",
        });
      },
    }
  );

  const handleDelete = async () => {
    try {
      barActivate();
      const deletedId: any = await deleteLogo(userID);
      setUserCompanyName(deletedId);
      setUserCompanyLogo(null);
      enqueueSnackbar("Logo deleted", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to deleted logo", { variant: "error" });
    } finally {
      barStop();
    }

  };

  return (
    <Box style={{ backgroundColor: "#fff", padding: 15 }}>
      <form encType="multipart/form-data">
        <Box style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center'
        }}>
          <SectionContainer>
            < Title > Tenant Logo</Title>
          </SectionContainer >
          <Grid container style={{ width: 'auto', alignItems: 'center' }}>
            <Grid >
              <label htmlFor="select-image">
                <input
                  accept="image/*"
                  id="select-image"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => setSelectedImage(e.target.files![0])}
                />
                <Button
                  variant="contained"
                  component="span"
                  disableRipple
                  disableElevation
                  style={{
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                    padding: '4px 7px',
                    textTransform: 'capitalize'
                  }}
                >
                  Upload new logo
                </Button>
              </label>
            </Grid>
            <Grid style={{ marginLeft: 10 }}><StandardButton type="submit" title="Save" accent />
            </Grid>
            <Grid style={{ marginLeft: 10 }}>
              <StandardButton onClick={handleDelete} title="Delete" accent disabled={!userCompanyLogo} />
            </Grid>
          </Grid>
        </Box >
        {
          imageUrl && selectedImage ? (
            <Box mt={2} textAlign="center">
              <img src={imageUrl} alt={selectedImage.name} width="100px" />
            </Box>
          ) : (

            <Box mt={2} textAlign="center">
              {userCompanyLogo ? (
                <img
                  src={
                    userCompanyLogo && `${PUBLIC_API_URL}/logo/${userCompanyLogo}`
                  } width="100px"
                  alt={userCompanyName}
                />
              ) : (
                <>
                  <div style={{ textAlign: 'center' }}>Upload New Logo</div>
                </>
              )}
            </Box>
          )
        }
      </form ></Box >
  );
}

export default TenantLogoForm;
