import React, { useState } from "react";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getSingleSwapOffer, Offer } from "../../../../requests/offers";
import OfferCardsBlock from "../../../adminView/swapOffersScreen/offer-cards-block";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import StandardButton from "../../../../components/Buttons/StandardButton";

const Container = styled.div`
  font-size: 14px;
`;

const DeleteButton = styled(StandardButton)`
  color: #aa0000;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 8px;
`;

interface ShiftEditOffersProps {
  offer: Offer;
  deleteOffer: (offerId: number) => void;
}
function ShiftEditOfferRow({ offer, deleteOffer }: ShiftEditOffersProps) {
  const [offerToDelete, setOfferToDelete] = useState<number | undefined>(
    undefined
  );
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useQuery<Offer, AxiosError>(
    [`get-single-offer-${offer.id}`],
    () => getSingleSwapOffer(offer.id),
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get swap offer details`,
          { variant: "error" }
        );
      },
    }
  );

  const close = () => {
    setOfferToDelete(undefined);
  };

  if (!data) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }
  return (
    <Container>
      <Dialog open={Boolean(offerToDelete)} onClose={close}>
        <DialogTitle>Cancel offer?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm that you want to cancel offer "{offerToDelete}"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StandardButton title="Close" onClick={close} />
          <DeleteButton
            title="Delete"
            onClick={() => {
              deleteOffer(offer.id);
              close();
            }}
          />
        </DialogActions>
      </Dialog>
      <OfferCardsBlock
        offer={data}
        onCancelClick={(offerId) => setOfferToDelete(offerId)}
      />
    </Container>
  );
}

export default ShiftEditOfferRow;
