import React from "react";
import { FieldProps } from "formik";
import { InputBase, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Option {
  value: string | number;
  title: string | number;
}

interface Options {
  options: Array<Option>;
}

const useStyles = makeStyles((theme) => ({
  outer: {
    border: "1px solid var(--greyish)",
    borderRadius: theme.shape.borderRadius,
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
  },
}));

const SelectFormik = ({
  field: { value, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
}: FieldProps & Options) => {
  const classes = useStyles();
  return (
    <Select
      {...field}
      value={value || ""}
      className={classes.outer}
      fullWidth
      input={<InputBase />}
    >
      <MenuItem value={""}>None</MenuItem>
      {options.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.title}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectFormik;
