import React from "react";
import styled from "styled-components";
import styles from "../../../css/style.module.css";
import OfferSwapCard from "./offer/offer-swap-card";
import OfferDateCard from "./offer/offer-date-card";
import OfferTimeCard from "./offer/offer-time-card";
import { Offer, OfferStatus } from "../../../requests/offers";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import SwapArrow from "../../../assets/images/arrow/swap.svg";

const ActionButtonsContainer = styled.div`
  display: flex;
  float: right;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 25px 25px 0;
  width: fit-content;
`;
interface Props {
  offer: Offer;
  onCancelClick: (offerId: number) => void;
}
function OfferCardsBlock({ offer, onCancelClick }: Props) {
  return (
    <>
      <div className={styles.swapoffercard}>
        <div className={styles.swapcardrow}>
          <OfferDateCard
            shift={offer.outboundShift}
            user={offer.outboundUser}
          />
          <div className={styles.swapicon}>
            <img src={SwapArrow} alt="arrow" />
          </div>
          <OfferDateCard shift={offer.inboundShift} user={offer.inboundUser} />
        </div>

        <div className={styles.swapcardrow}>
          <OfferTimeCard
            shift={offer.outboundShift}
            user={offer.outboundUser}
          />
          <div className={styles.swapicon}>
            <img src={SwapArrow} alt="arrow" />
          </div>
          <OfferTimeCard shift={offer.inboundShift} user={offer.inboundUser} />
        </div>

        <div className={styles.swapcardrow}>
          <OfferSwapCard
            shift={offer.outboundShift}
            user={offer.outboundUser}
          />
          <div className={styles.swapicon}>
            <img src={SwapArrow} alt="arrow" />
          </div>
          <OfferSwapCard shift={offer.inboundShift} user={offer.inboundUser} />
        </div>
      </div>
      {offer.status === OfferStatus.PENDING ? (
        <ActionButtonsContainer>
          <StandardButton
            title={`Cancel Offer`}
            className={`danger ${styles.btnSwapOffers}`}
            onClick={() => onCancelClick(offer.id)}
          />
        </ActionButtonsContainer>
      ) : null}
    </>
  );
}

export default OfferCardsBlock;
