import React, { useState, useEffect } from "react";
import styles from "../../../css/style.module.css";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import styled from "styled-components";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Pagination } from "@material-ui/lab";
import Dropdown from "../../../componentsV3/inputs/Dropdown";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import SearchBar from "material-ui-search-bar";
import {
  getListEmail,
  GetListEmailResponse,
  ListEmail,
} from "../../../requests/email";
import Spinner from "../../../componentsV3/Spinner";
import { useDebounce } from "../../../hooks/useDebounce";
import ReactHtmlParser from "react-html-parser";

const ContentContainer = styled.div`
  margin-top: 8px;
`;

const Spacer = styled.div`
  height: ${({ theme }) => theme.gap[1]};
`;
type StatusItem =
  | { title: "All"; value: undefined }
  | { title: "Sent"; value: "sent" }
  | { title: "Failed"; value: "failed" }
  | { title: "Queued"; value: "queued" };
type Order = { title: string; value: string };

function SentEmailsScreen() {
  const [order, setOrder] = useState<Order>({ title: "Desc", value: "DESC" });
  const [status, setStatus] = useState<StatusItem>({
    title: "All",
    value: undefined,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [limit, setLimit] = useState<number>(10);
  const [pages, setPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const { data, isLoading } = useQuery<GetListEmailResponse, AxiosError>(
    ["get-emails", order, status, limit, page, search],
    () => {
      return getListEmail(
        limit,
        page,
        search,
        status.value,
        order.value as "ASC" | "DESC"
      );
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get sent Emails`,
          { variant: "error" }
        );
      },
    }
  );

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setPage(value);
  }

  useEffect(() => {
    if (data) {
      let total = Math.ceil(data.searchedCount / limit);
      setPages(total);
    }
  }, [data, limit]);

  const requestSearch = (searchVal: string) => {
    if (data) {
      setSearch(searchVal);
      setPage(1);
    }
  };
  const onChangeDebounced = useDebounce(requestSearch, 500);

  const cancelSearch = () => {
    setSearch("");
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <RWrapper>
      <Box className={styles.pagetitlebox}>
        <Box mr={2}>
          <Typography variant="h4" color="textPrimary">
            Sent Emails
          </Typography>
        </Box>
        <Box className={`${styles.rightSide} ${styles.searchbarwrap}`}>
          <SearchBar
            className={styles.searchbar}
            value={search}
            onChange={(searchVal) => onChangeDebounced(searchVal)}
            onCancelSearch={() => cancelSearch()}
            cancelOnEscape
          />
        </Box>
      </Box>
      <Divider />
      <Spacer />
      <ContentContainer>
        <Box className={styles.dropdownholdercontainer}>
          <Box className={styles.dropdownholder}>
            <Dropdown
              textShown={`${limit}`}
              onSelect={(v) => {
                setLimit(v.value as number);
                setPage(1);
              }}
              options={[
                { title: "10", value: 10 },
                { title: "20", value: 20 },
                { title: "50", value: 50 },
                { title: "100", value: 100 },
                { title: "200", value: 200 },
              ]}
            />
          </Box>
          <Box className={styles.dropdownholder}>
            <Dropdown
              textShown={order.title}
              options={[
                { title: "Desc", value: "DESC" },
                { title: "Asc", value: "ASC" },
              ]}
              onSelect={(v) =>
                setOrder({ title: v.title, value: v.value as string })
              }
            />
          </Box>
          <Box className={styles.dropdownholder}>
            <Dropdown
              textShown={status.title}
              options={[
                { title: "All", value: undefined },
                { title: "Sent", value: "sent" },
                { title: "Queued", value: "queued" },
                { title: "Failed", value: "failed" },
              ]}
              onSelect={(item) => setStatus(item as StatusItem)}
            />
          </Box>
        </Box>
        <Spacer />
        <Spacer />

        {!data?.data.length && search ? (
          <>
            <Box className={styles.commonbox}>
              <h3 style={{ margin: 0 }}>No search Result Found</h3>
            </Box>
          </>
        ) : null}

        {data?.totalData !== 0 ? (
          <Box>
            {data &&
              data.data.map((email: ListEmail, index: number) => (
                <div key={index}>
                  <Accordion
                    expanded={expanded === `${index}`}
                    onChange={handleChange(`${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Grid container spacing={1}>
                        <Grid item lg={4} md={3} sm={6} xs={12}>
                          <Typography>To: {email.toEmail}</Typography>
                        </Grid>
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                          <Typography style={{ fontWeight: "bold" }}>
                            {email.subject}
                          </Typography>
                        </Grid>
                        <Grid item lg={2} md={2} sm={6} xs={12}>
                          <Typography
                            style={{
                              fontWeight: "normal",
                              color: "#5b5b5b",
                            }}
                          >
                            {moment(email.sentAt).format(
                              "DD/MM/YYYY hh:mm:ssA"
                            )}
                          </Typography>
                        </Grid>
                        <Grid item lg={1} md={1} sm={6} xs={12} container>
                          <Chip
                            style={{
                              marginInline: 5,
                              color: `${email.status === "sent"
                                ? "green"
                                : email.status === "failed"
                                  ? "red"
                                  : email.status === "queued"
                                    ? "orange"
                                    : "default"
                                }`,
                              fontSize: "0.85rem",
                              padding: "10px",
                              fontWeight: "normal",
                            }}
                            size="small"
                            variant="outlined"
                            label={
                              email.status.charAt(0).toUpperCase() +
                              email.status.slice(1)
                            }
                          />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        backgroundColor: `#f9f9f9`,
                      }}
                    >
                      <Box>
                        <Typography
                          component="div"
                          style={{ marginLeft: 5, fontWeight: "bold" }}
                          gutterBottom
                        >
                          From:
                          <Chip
                            style={{
                              marginLeft: 5,
                              fontSize: "0.85rem",
                              fontWeight: "normal",
                            }}
                            size="small"
                            label={email.fromEmail}
                            variant="outlined"
                          />
                        </Typography>
                        <Typography
                          component="div"
                          style={{ marginLeft: 5, fontWeight: "bold" }}
                          gutterBottom
                        >
                          To:
                          <Chip
                            style={{
                              marginLeft: 5,
                              fontSize: "0.85rem",
                              fontWeight: "normal",
                            }}
                            size="small"
                            label={email.toEmail}
                            variant="outlined"
                          />
                        </Typography>
                        <Typography
                          style={{ marginTop: 10 }}
                          variant="body1"
                          gutterBottom
                        >
                          {ReactHtmlParser(email.body)}
                        </Typography>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
          </Box>
        ) : (
          <Box className={styles.commonbox}>
            <h3 style={{ margin: 0 }}>No records found</h3>
          </Box>
        )}

        {data?.totalData !== 0 ? (
          <Pagination
            className={styles.pagination}
            count={pages}
            page={page}
            showFirstButton
            showLastButton
            onChange={handlePageChange}
          />
        ) : null}
      </ContentContainer>
    </RWrapper>
  );
}
export default SentEmailsScreen;
