import React from "react";
import style from "./styles.module.css";
import styles from "../../../../css/style.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Typography, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CalendarBaseDay } from "../../../../componentsV3/calendars/CalendarBase";
import { Shift } from "../../requests";

const useStyles = makeStyles((theme) => {
  return {
    currentDay: ({
      available,
      own,
    }: {
      available?: boolean;
      own?: boolean;
    }) => ({
      borderTop: "1px solid #1890FF",
      backgroundColor: available && !own ? "#F9F9F9" : "#F9F9F9",
      color: available && !own ? "#1890FF" : "#282828",
    }),
  };
});

interface CellProps {
  day: CalendarBaseDay;
  current: boolean;
  number: number;
  shifts?: Array<Shift>;
  shiftsCount?: any;
}

const Cell = ({ number, day: d, shifts, current, shiftsCount }: CellProps) => {
  const history = useHistory();

  const currentShift = shifts && shifts[0];
  console.log(currentShift && currentShift.own, "currentShift");

  const c = useStyles({
    available: currentShift && currentShift.available ? true : undefined,
    own: currentShift && currentShift.own,
  });

  const navToDay = (d: CalendarBaseDay) => {
    history.push(`/roster/year/${d.year}/month/${d.month}/day/${d.day}`);
  };

  return (
    <Paper
      className={`${style.cell} ${current ? c.currentDay : ""}`}
      elevation={0}
    >
      <div className={`${style.cellday} ${current ? style.cellcurrent : ""}`}>
        <div className={style.addshiftbtn}>
          <IconButton disableRipple onClick={() => navToDay(d)}>
            {" "}
            +{" "}
          </IconButton>
        </div>
        {current ? (
          <Typography style={{ fontSize: "inherit" }}>Today</Typography>
        ) : null}
        <Typography style={{ fontSize: "inherit" }}>{number}</Typography>
      </div>
      {shiftsCount && (
        <Box className={styles.monthstatusbox}>
          <Typography variant="body1" className={styles.publish}>
            Completed <span>{shiftsCount.published}</span>
          </Typography>
          <Typography variant="body1" className={styles.draft}>
            Unpublished <span>{shiftsCount.draft}</span>
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default Cell;
