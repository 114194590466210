import React from "react";
import styles from "./styles.module.css";

interface TabButtonProps {
  title: string;
  active?: boolean;
  onClick: () => string | void;
}

export default ({ title, active, onClick }: TabButtonProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${styles.container} ${active && styles.active}`}
    >
      {title}
    </button>
  );
};
