import React, { useState, useEffect } from "react";
import { timeOneLaterTimeTwo, calculateDuration } from "../../utils/time";
import { hoursMinutesRegex } from "../../GLOBALS";
import { makeStyles } from "@material-ui/core/styles";

interface TimeOptions {
  start?: string; // HH:mm
  end?: string; // HH:mm
}

interface Timeline24Props {
  previous?: boolean;
  current?: boolean;
  time: TimeOptions;
  color?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    height: "100%",
    alignItems: "center",
    backgroundColor: '#f9f9f9',
  },
  timeline: {
    height: 5,
    borderRadius: 5,
    transition: "flex 0.2s",
    "&:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "&:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  colored: {
    backgroundColor: ({ color }: { color?: string }) => {
      try {
        if (!color) {
          return theme.palette.primary.main;
        } else {
          return color;
        }
      } catch (err) {
        return theme.palette.primary.main;
      }
    },
  },
}));

export default ({ time, previous, current, color }: Timeline24Props) => {
  const c = useStyles({ color });
  const [one, setone] = useState<{ filled?: boolean; flex: number }>({
    flex: 0,
  });
  const [two, settwo] = useState<{ filled?: boolean; flex: number }>({
    flex: 0,
  });
  const [three, setthree] = useState<{ filled?: boolean; flex: number }>({
    flex: 0,
  });

  useEffect(() => {
    if (time && (previous || current)) {
      _calculateFlexes(time, current, previous);
    }
  }, [previous, current, time]);

  const _calculateFlexes = (t: TimeOptions, c?: boolean, p?: boolean) => {
    try {
      if (
        !t ||
        !t.start ||
        !t.end ||
        !hoursMinutesRegex.test(t.start) ||
        !hoursMinutesRegex.test(t.end)
      ) {
        return;
      }
      const shiftTwoDays = timeOneLaterTimeTwo(t.start, t.end);
      if (shiftTwoDays) {
        const fOne = calculateDuration("00:00", t.end);
        setone({ filled: p ? true : false, flex: fOne });
        const fTwo = calculateDuration(t.end, t.start);
        settwo({ flex: fTwo });
        const fThree = calculateDuration(t.start, "23:59");
        setthree({ filled: c ? true : false, flex: fThree });
      } else {
        const fOne = calculateDuration("00:00", t.start);
        setone({ flex: fOne });
        const fTwo = calculateDuration(t.start, t.end);
        settwo({ filled: true, flex: fTwo });
        const fThree = calculateDuration(t.end, "23:59");
        setthree({ flex: fThree });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!current && !previous) {
    return null;
  }

  return (
    <div className={c.container}>
      <div
        style={{ flex: one.flex }}
        className={`${c.timeline} ${one.filled ? c.colored : ""}`}
      />
      <div
        style={{ flex: two.flex }}
        className={`${c.timeline} ${two.filled ? c.colored : ""}`}
      />  
      <div
        style={{ flex: three.flex }}
        className={`${c.timeline} ${three.filled ? c.colored : ""}`}
      />
    </div>
  );
};
