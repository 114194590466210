import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Location } from "../screens/RosterScreen";
import { Roster } from "../screens/RosterScreen/requests";

interface SelectedRosters {
  [key: number]: Array<Roster>;
}

interface SelectedDate {
  year?: number;
  month?: number;
  day?: number;
}

const UserSelectionsContext = createContext<{
  selectedLocation?: Location;
  setLocation: (loc?: Location) => void;
  selectedRosters: Array<Roster>;
  setRosters: (ros: Array<Roster>) => void;
  selectedDate: SelectedDate;
  setSelectedDate: (date?: SelectedDate) => void;
  initialized: boolean;
}>({
  selectedLocation: undefined,
  setLocation: (loc?: Location) => { },
  selectedRosters: [],
  setRosters: (ros: Array<Roster>) => { },
  selectedDate: {},
  setSelectedDate: (date?: SelectedDate) => { },
  initialized: false,
});

export default function UserSelectionContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >(undefined);

  const [allSelectedRosters, setAllSelectedRosters] = useState<SelectedRosters>(
    {}
  );

  const [selectedRosters, setSelectedRosters] = useState<Array<Roster>>([]);

  const [initialized, setInitialized] = useState<boolean>(false);

  const [selectedDate, setSelectedDate] = useState<SelectedDate>({
    // year: new Date().getFullYear(),
    // month: new Date().getMonth() + 1,
    // day: new Date().getDate(),
  });

  useEffect(() => {
    const s = localStorage.getItem("selectedLocation");
    const l: Location | undefined = s ? JSON.parse(s) : undefined;

    const sr = localStorage.getItem("selectedRosters");
    const r: SelectedRosters = sr ? JSON.parse(sr) : {};

    setSelectedLocation(l);
    setAllSelectedRosters(r);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (selectedLocation) {
      const currentRosters =
        allSelectedRosters[selectedLocation.id as number] ?? [];
      setSelectedRosters(currentRosters);
    }
  }, [selectedLocation, allSelectedRosters]);

  const setLocation = useCallback((loc) => {
    localStorage.setItem("selectedLocation", JSON.stringify(loc));
    setSelectedLocation(loc);
  }, []);

  const setRosters = useCallback(
    (ros) => {
      if (selectedLocation) {
        const ar = {
          ...allSelectedRosters,
          [selectedLocation.id as number]: ros,
        };
        localStorage.setItem("selectedRosters", JSON.stringify(ar));
        setAllSelectedRosters(ar);
      }
    },
    [selectedLocation, allSelectedRosters]
  );

  const changeDate = useCallback((date?: SelectedDate) => { }, []);

  return (
    <UserSelectionsContext.Provider
      value={{
        selectedLocation,
        setLocation,
        selectedRosters,
        setRosters,
        selectedDate,
        setSelectedDate: changeDate,
        initialized,
      }}
    >
      {children}
    </UserSelectionsContext.Provider>
  );
}

export function useUserSelections() {
  return useContext(UserSelectionsContext);
}
