import React from "react";
import styles from "../../../../css/model.module.css";
import { Formik, Form, Field, FormikProps } from "formik";
import StandardButton from "../../../../componentsV3/Buttons/StandardButton";
import { Typography, InputLabel, Box } from "@material-ui/core";
import ModalWindow from "../../../../componentsV3/modals/ModalWindow";
import TextInputFormik from "../../../../componentsV3/inputs/TextInputFormik";
import { BsArrowLeftShort } from "react-icons/bs";
export interface AddLocationModalSubmitValues {
  locationName: string;
  id?: number;
}

interface AddLocationModalProps {
  onSubmit: (values: AddLocationModalSubmitValues) => void;
  onCancel: () => void;
  locationName?: string;
  id?: number;
}

interface ValidationProps {
  locationName: string;
}

interface ValidationErrors {
  [key: string]: string;
}

const validation = ({ locationName }: ValidationProps) => {
  const errors: ValidationErrors = {};
  if (!locationName) {
    errors.locationName = "Location name can not be empty";
  }
  return errors;
};

// const [country, setCountry] = useState("");

export default ({
  onSubmit,
  onCancel,
  locationName,
  id,
}: AddLocationModalProps) => {
  return (
    <ModalWindow>
      <Formik
        initialValues={{ locationName: locationName || "" }}
        validate={validation}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          isValid,
          dirty,
          touched,
          isSubmitting,
        }: FormikProps<any>) => (
          <Form noValidate autoComplete="off">
            <Box className={styles.container}>
              <Box>
                <Box className={styles.titlebox}>
                  <BsArrowLeftShort
                    className={styles.backicon}
                    onClick={onCancel}
                  />
                  <Typography
                    variant="h5"
                    align="center"
                    className={styles.title}
                  >
                    {id ? "Edit Location Name" : "Add Location"}
                  </Typography>
                </Box>

                <InputLabel color="primary" className={styles.label}>
                  Location Name
                </InputLabel>
                <Field
                  name="locationName"
                  component={TextInputFormik}
                  placeholder="Location Name"
                  type="text"
                />
              </Box>
              <Box>
                <Box className={styles.buttonsholder}>
                  <Box
                    className={`${styles.submitbuttonsholder} ${styles.fillbtn}`}
                  >
                    <Box className={styles.cancelbuttonmargin}>
                      <StandardButton
                        className={"secondary"}
                        title="Cancel"
                        onClick={onCancel}
                      />
                    </Box>
                    <StandardButton
                      title="Save"
                      type="submit"
                      accent
                      disabled={isSubmitting || !isValid || !dirty}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </ModalWindow>
  );
};
