import { unauthorizedRequest } from "../../httprequests";

export interface DoLoginParams {
  email: string;
  password: string;
}

interface DoLoginRequest {
  email: string;
  password: string;
}

interface DoLoginResponse {
  isVerified: boolean;
  twoFA: boolean;
}

export interface DoLoginReturn {}

export const requestLogin = ({ email, password }: DoLoginParams) => {
  return unauthorizedRequest.post<DoLoginRequest, DoLoginResponse>(
    "/auth/login",
    { email, password }
  );
};

export interface VerifyCode {
  email: string;
  code: string;
}

export interface VerifyCodeResponse {}

export const requestVerifyCode = ({ email, code }: VerifyCode) => {
  return unauthorizedRequest.post<VerifyCode, VerifyCodeResponse>("/auth/2fa", {
    email,
    code,
  });
};
