import { Grid, Typography, Box } from "@material-ui/core";
import React from "react";
import styles from "../../../css/style.module.css";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import DoNotDisturbForm from "./do-not-disturb-time-form";
import UserDetailsForm from "./user-details-form";
import RoosterTheme from "../../adminView/settings/RoosterTheme";
import TwoFAForm from "../../adminView/settings/two-factor-auth-form";

function UserAccountScreen() {
  return (
    <RWrapper>
      <Box className={styles.pagetitlebox}>
        <Typography variant="h4" color="textPrimary">
          Account
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        className={styles.useraccount}
        style={{ marginBottom: 8 }}
      >
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={styles.settingbox}>
            <Typography variant="h4" color="textPrimary">
              Sms reminders
            </Typography>
            <Box className={styles.smsreminderbox}>
              <DoNotDisturbForm />
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={styles.settingbox}>
            <Typography variant="h4" color="textPrimary">
              Contact Information
            </Typography>
            <Box className={styles.smsreminderbox}>
              <UserDetailsForm />
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={styles.settingbox}>
            <Typography variant="h4" color="textPrimary">
              Theme Settings
            </Typography>
            <Box className={styles.smsreminderbox}>
              <RoosterTheme />
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={styles.settingbox}>
            <Typography variant="h4" color="textPrimary">
              Enable 2FA Authentication
            </Typography>
            <TwoFAForm />
          </Box>
        </Grid>
      </Grid>
    </RWrapper>
  );
}

export default UserAccountScreen;
