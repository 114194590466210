import { Box, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useProgressBar } from "../../../components/bars/ProgressBarGlobal";
import FloatingButton from "../../../components/Buttons/FloatingButton";
import LetterIcon from "../../../components/dynamicIcons/LetterIcon";
import Dropdown from "../../../components/inputs/Dropdown";
import SelectDefault from "../../../components/inputs/SelectDefault";
import { RWrapper } from "../../../components/layout/RWrapper";
import { useUserSelections } from "../../../context/userSelectionsProvider";
import {
  requestGetLocations,
  requestGetRosters,
  Roster,
} from "../../RosterScreen/requests";
import UserRequestShiftSwapScreen from "../UserRequestShiftSwapScreen";
import { ShiftProvider } from "./Provider";
import styles from "./styles.module.css";
import RosterDayView from "./views/RosterDayView";
import RosterMonthView from "./views/RosterMonthView";
import RosterYearView from "./views/RosterYearView";

export interface Location {
  id?: number;
  locationName: string;
}

const useStyles = makeStyles((theme) => ({
  snackGreen: {
    backgroundColor: theme.palette.success.main,
  },
  snackRed: {
    backgroundColor: theme.palette.error.main,
  },
  cross: {
    color: "#ffffff",
  },
  floatingButtonTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  mr: {
    marginRight: theme.spacing(1),
  },
  rightSide: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  dropdownContainer: {
    width: 100,
    backgroundColor: "#ffffff",
    borderRadius: 2,
  },
}));

const UserRequestSwapScreenPage = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { barActivate, barStop } = useProgressBar();
  const {
    selectedLocation: locationSelected,
    setLocation,
    selectedRosters: rostersSelected,
    setRosters: setRostersSelected,
    initialized: selectedInitialized,
  } = useUserSelections();

  const [rosters, setRosters] = useState<Array<Roster>>([]);

  const [locations, setLocations] = useState<Array<Location>>([]);

  const [calendarViewSelectorText, setCalendarViewSelectorText] =
    useState<string>("");

  const adjustCalendarViewSelectorText = useCallback(() => {
    if (pathname.includes("day")) {
      setCalendarViewSelectorText("Day");
    } else if (pathname.includes("month")) {
      setCalendarViewSelectorText("Month");
    } else {
      setCalendarViewSelectorText("Year");
    }
  }, [pathname]);

  useEffect(() => {
    adjustCalendarViewSelectorText();
  }, [adjustCalendarViewSelectorText]);

  const retrieveRosters = useCallback(async () => {
    if (locationSelected && locationSelected.id) {
      try {
        const rn = await requestGetRosters(locationSelected.id);
        setRosters(rn);
      } catch (error) {
        enqueueSnackbar("Unable to retrieve rosters", { variant: "error" });
      }
    }
  }, [locationSelected, enqueueSnackbar]);

  useEffect(() => {
    retrieveRosters();
  }, [retrieveRosters]);

  const getLocations = useCallback(async () => {
    try {
      barActivate();
      const result = await requestGetLocations();
      setLocations(result);
      if (locationSelected && result.length) {
        const found = result.find((e) => {
          return e.id === locationSelected.id;
        });
        if (found) {
          if (found.locationName !== locationSelected.locationName) {
            setLocation(found);
          }
        } else {
          setLocation(result[0]);
        }
      } else if (!locationSelected && result.length) {
        setLocation(result[0]);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to retrieve locations", { variant: "error" });
    } finally {
      barStop();
    }
  }, [locationSelected, setLocation, enqueueSnackbar, barStop, barActivate]);

  useEffect(() => {
    if (selectedInitialized) {
      // GET LOCATIONS
      getLocations();
      // GET USER TYPES
      // getUserTypes();
    }
  }, [selectedInitialized, getLocations]);

  const _floatingButtonActive = (id: number) => {
    if (!rostersSelected.length) return false;
    else if (rostersSelected.find((e) => e.id === id)) return true;
    else return false;
  };

  const _handleRosterNameButtonToggle = (roster: Roster) => {
    if (_floatingButtonActive(roster.id)) {
      const without = rostersSelected.filter((i) => i.id !== roster.id);
      setRostersSelected(without);
    } else {
      setRostersSelected([...rostersSelected, roster]);
    }
  };

  const handleCalendarViewSelectChange = useCallback(
    ({ value }: { title: string; value?: number | string }) => {
      const [, , , year, , month] = pathname.split("/");
      if (value === "year") {
        // go current year or year in the pathname
        history.push(`/request-swap/year/${year ? year : ""}`);
      } else if (value === "month") {
        // go to current month or month in the pathname
        const d = new Date();
        history.push(
          `/request-swap/year/${year ? year : d.getFullYear()}/month/${
            month ? month : ""
          }`
        );
      } else if (value === "day") {
        // go to today
        const d = new Date();
        history.push(
          `/request-swap/year/${d.getFullYear()}/month/${
            d.getMonth() + 1
          }/day/${d.getDate()}`
        );
      }
    },
    [history, pathname]
  );

  return (
    <RWrapper>
      {pathname !== "/request-swap/request-shift-swap" && (
        <>
          <Box className={styles.pagetitlebox}>
            <Typography variant="h4" color="textPrimary">
              Roster
            </Typography>
            <Box className={styles.marginleft1}>
              <SelectDefault
                id="roster-shift-loc-select-1"
                onChange={(loc) => {
                  setLocation(locations.find((l) => l.id === loc.value));
                }}
                value={locationSelected ? locationSelected.id : ""}
                options={locations.map((l) => ({
                  value: l.id || 0,
                  title: l.locationName,
                }))}
              />
            </Box>
            <Box className={classes.rightSide}>
              <Box className={classes.dropdownContainer}>
                <Dropdown
                  textShown={calendarViewSelectorText}
                  onSelect={handleCalendarViewSelectChange}
                  options={[
                    { title: "Year", value: "year" },
                    { title: "Month", value: "month" },
                    { title: "Day", value: "day" },
                  ]}
                />
              </Box>
            </Box>
          </Box>

          {rosters.length ? <Divider /> : null}

          <Box className={styles.pagetitlebox1}>
            {rosters.map((r) => (
              <Box className={styles.marginright03} key={r.id}>
                <FloatingButton
                  title={
                    <div className={classes.floatingButtonTitle}>
                      <div className={classes.mr}>
                        <LetterIcon
                          color={r.color}
                          letter={r.name.charAt(0).toUpperCase()}
                        />
                      </div>
                      {r.name}
                    </div>
                  }
                  width="5em"
                  active={_floatingButtonActive(r.id)}
                  onClick={() => _handleRosterNameButtonToggle(r)}
                />
              </Box>
            ))}
          </Box>
        </>
      )}

      <Divider />
      {!locationSelected ||
      !locationSelected.id ||
      !rostersSelected[0] ? null : (
      <Switch>
        <Route exact path={`/request-swap/year/:year?`}>
          <RosterYearView />
        </Route>
        <Route exact path={`/request-swap/year/:year/month/:month?`}>
          <RosterMonthView />
        </Route>
        <Route exact path={`/request-swap/year/:year/month/:month/day/:day`}>
          <RosterDayView />
        </Route>
        <Route exact path={`/request-swap/request-shift-swap`}>
          <UserRequestShiftSwapScreen />
        </Route>
        <Route>
          <Redirect to="/request-swap/year" />
        </Route>
      </Switch>
      )}
    </RWrapper>
  );
};

const UserRequestSwapScreen = () => (
  <ShiftProvider>
    <UserRequestSwapScreenPage />
  </ShiftProvider>
);
export default UserRequestSwapScreen;
