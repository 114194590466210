import { Divider, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { RWrapper } from "../../components/layout/RWrapper";
import { reportData } from "./Reports";
import SelectedReport from "./SelectedReport";

const TitleBox = styled.div`
  margin: 0.5em 0;
`;

const ReportScreen = () => {
  return (
    <RWrapper>
      <TitleBox>
        <Typography variant="h4" color="textPrimary">
          Reports
        </Typography>
      </TitleBox>
      <Divider />
      {reportData.map((report) => (
        <SelectedReport data={report} />
      ))}
    </RWrapper>
  );
};
export default ReportScreen;
