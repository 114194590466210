import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  Typography
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { AxiosError } from "axios";
import SearchBar from "material-ui-search-bar";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { useQuery } from "react-query";
import styled from "styled-components";
import StandardButton from "../../../components/Buttons/StandardButton";
import Dropdown from "../../../components/inputs/Dropdown";
import { RWrapper } from "../../../components/layout/RWrapper";
import { useDebounce } from "../../../hooks/useDebounce";
import { GetAuditResponse, getAudit } from "../../../requests/audit";
import AuditTableRow from "./auditTableRow";

const TitleBox = styled.div`
  margin: 0.5em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.gap[1]};
`;

const FlexFiller = styled.div`
  display: flex;
  flex: 1;
`;

const DropdownHolder = styled.div`
  width: 100px;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.background.paper};
  margin-right: ${({ theme }) => theme.gap[1]};
  &:last-child {
    margin-right: 0;
  }
`;

const ContentContainer = styled.div`
  margin-top: 8px;
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  margin-bottom: ${({ theme }) => theme.gap[1]};
`;

const Th = styled.th<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}%` : "")};
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.size.default};
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: ${({ theme }) => theme.colors.background.paper};
`;

type Order = { title: string; value: string };

function AuditHistoryScreen() {
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>({ title: "Desc", value: "DESC" });
  const [take, setTake] = useState<number>(50);
  const [auditType, setAudiType] = useState<string>("All");
  const [sourceType, setSourceType] = useState<string>("All");
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);
  const [clear, setClear] = useState<boolean>();
  const [search, setSearch] = useState<string>("");

  const [dateFilter, setDateFilter] = React.useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const startDate = clear
    ? moment(dateFilter[0].startDate).format("YYYY-MM-DD").toLocaleString()
    : undefined;
  const endDate = clear
    ? moment(dateFilter[0].endDate).format("YYYY-MM-DD").toLocaleString()
    : undefined;

  const { data } = useQuery<GetAuditResponse, AxiosError>(
    [
      "get-audit",
      order,
      take,
      page,
      auditType,
      sourceType,
      startDate,
      endDate,
      search,
    ],
    () => {
      const skip = (page - 1) * take;

      return getAudit(
        order.value as "ASC" | "DESC",
        skip,
        take,
        auditType,
        sourceType,
        startDate,
        endDate,
        search
      );
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get audit data`,
          { variant: "error" }
        );
      },
    }
  );

  useEffect(() => {
    if (data) {
      const total = Math.ceil(data.count / take);
      setPages(total);
    }
  }, [data, take]);

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setPage(value);
  }

  const handleSelect = (range: any) => {
    setDateFilter([range.selection]);
    setClear(true);
  };

  const handleClick = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleClear = () => {
    setDialogOpen(false);
    setClear(false);
  };

  const requestSearch = (searchVal: string) => {
    if (data) {
      setSearch(searchVal);
      setPage(1);
    }
  };
  const onChangeDebounced = useDebounce(requestSearch, 500);

  const cancelSearch = () => {
    setSearch("");
  };

  return (
    <RWrapper>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent style={{ padding: 20 }}>
          <DialogContentText>
            <DateRange
              onChange={handleSelect}
              ranges={dateFilter}
              moveRangeOnFirstSelection={false}
            />
          </DialogContentText>
          <Box display="flex">
            <Box mr={1}>
              <StandardButton
                accent
                title="Done"
                onClick={() => setDialogOpen(false)}
              />
            </Box>
            <StandardButton title="Clear" onClick={handleClear} />
          </Box>
        </DialogContent>
      </Dialog>
      <TitleBox>
        <Typography variant="h4" color="textPrimary">
          Audit history
        </Typography>
        <SearchBar
          value={search}
          onChange={(searchVal) => onChangeDebounced(searchVal)}
          onCancelSearch={() => cancelSearch()}
          cancelOnEscape
        />
      </TitleBox>
      <Divider />
      <ContentContainer>
        <OptionsContainer>
          <Pagination count={pages} page={page} onChange={handlePageChange} />
          <FlexFiller />
          <DropdownHolder style={{ width: "auto" }}>
            <StandardButton title="Date" onClick={handleClick} />
          </DropdownHolder>
          <DropdownHolder>
            <Dropdown
              textShown={`${take}`}
              onSelect={(v) => setTake(v.value as number)}
              options={[
                { title: "5", value: 5 },
                { title: "10", value: 10 },
                { title: "20", value: 20 },
                { title: "50", value: 50 },
                { title: "100", value: 100 },
                { title: "200", value: 200 },
              ]}
            />
          </DropdownHolder>
          <DropdownHolder>
            <Dropdown
              textShown={`${auditType}`}
              onSelect={(v) => setAudiType(v.value as string)}
              options={[
                { title: "All", value: "All" },

                { title: "shift_update", value: "shift_update" },
                {
                  title: "shift_available_taken",
                  value: "shift_available_taken",
                },
                { title: "shift_available", value: "shift_available" },
                { title: "user_delete", value: "user_delete" },
                {
                  title: "shift_offer_cancelled",
                  value: "shift_offer_cancelled",
                },
                { title: "shift_deleted", value: "shift_deleted" },
                { title: "shift_insert", value: "shift_insert" },
                { title: "shift_offer_placed", value: "shift_offer_placed" },
                { title: "sms_sent", value: "sms_sent" },
                {
                  title: "shift_offer_accepted",
                  value: "shift_offer_accepted",
                },
                { title: "user_update", value: "user_update" },
              ]}
            />
          </DropdownHolder>
          <DropdownHolder>
            <Dropdown
              textShown={`${sourceType}`}
              onSelect={(v) => setSourceType(v.value as string)}
              options={[
                { title: "All", value: "All" },
                { title: "shift_offers", value: "shift_offers" },
                { title: "shifts", value: "shifts" },
              ]}
            />
          </DropdownHolder>
          <DropdownHolder>
            <Dropdown
              textShown={order.title}
              onSelect={(v) =>
                setOrder({ title: v.title, value: v.value as string })
              }
              options={[
                { title: "Desc", value: "DESC" },
                { title: "Asc", value: "ASC" },
              ]}
            />
          </DropdownHolder>
        </OptionsContainer>

        <Table>
          <thead>
            <tr>
              <Th width={5}>ID</Th>
              <Th width={15}>Task ID</Th>
              <Th width={10}>Audit type</Th>
              <Th width={10}>Source type</Th>
              <Th width={10}>Source ID</Th>
              <Th width={10}>Column name</Th>
              <Th width={10}>Old value</Th>
              <Th width={10}>New Value</Th>
              <Th width={10}>User name</Th>
              <Th width={10}>Created at</Th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.auditData.map((o) => <AuditTableRow key={o.id} {...o} />)}
          </tbody>
        </Table>
        <Pagination count={pages} page={page} onChange={handlePageChange} />
      </ContentContainer>
    </RWrapper>
  );
}

export default AuditHistoryScreen;
