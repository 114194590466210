import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/style.module.css";
import PageHeader from "../../componentsV3/PageHeader";
import FloatingButton from "../../componentsV3/Buttons/FloatingButton";
import IconButton from "@material-ui/core/IconButton";
import ModalContainer from "../../componentsV3/modals/ModalContainer";
import { useQuery } from "react-query";
import AddUserTypeModalWindow, {
  AddUserTypeModalSubmitValues,
} from "./AddUserTypeModalWindow";
import {
  requestPostUserType,
  requestGetUserTypes,
  requestGetUsers,
} from "./requests";
import { Grid, Paper, Typography, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { RWrapper } from "../../componentsV3/layout/RWrapper";
import { useProgressBar } from "../../componentsV3/bars/ProgressBarGlobal";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import { RectangleBadgeFilled } from "../../componentsV3/styled-components/badges";
import SearchBar from "material-ui-search-bar";
import Spinner from "../../componentsV3/Spinner";
export interface UserType {
  id: number;
  name: string;
  description?: string;
  tenantId?: number;
}

export interface UserLocation {
  id: number;
  userId?: number;
  locationId: number;
  name: string;
}
export interface ReportTo {
  id: number;
  fullName: string;

}
export interface Tenants {
  name: string;
  description?: string;
  companyLogo?: string;
  id?: number;
  uuid?: string;
}
export interface User {
  id?: number;
  userType?: UserType;
  userLocation?: UserLocation;
  reportTo?: any;
  tenants?: Tenants[];
  title: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  mobileCode?: number;
  mobileNumber?: number;
  mobileFull?: string;
  isAdministrator: boolean;
  deletedAt?: string;
}

const AdminMark = styled.div<{ active?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  box-sizing: border-box;
  border: ${({ active }) => (active ? "none" : "1px solid #919191")};
  background-color: ${({ active }) => (active ? "#282828" : "transparent")};
`;

export default () => {

  const history = useHistory();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>("");

  const [userTypes, setUserTypes] = useState<Array<UserType>>([]);
  const [users, setUsers] = useState<Array<User>>([]);

  const style = { minWidth: '3em', borderRadius: '8px', backgroundColor: '#FFFFFF', borderColor: 'transparent', color: 'black' }
  const styleSelect = { minWidth: '3em', borderRadius: '8px', backgroundColor: '#282828', borderColor: 'transparent', color: '#fff' }

  const [currentUserType, setCurrentUserType] = useState<
    number | "admin" | undefined
  >(undefined);
  const [searchedRows, setsearchedRows] = useState<Array<User>>(users);
  const [searched, setSearched] = useState<string>("");

  const { isLoading } = useQuery(
    "requestGetUserTypes",
    async () => requestGetUserTypes(),
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar({ variant: "error" });
      },
    }
  );

  useEffect(() => {
    let timeout: any;
    if (apiError)
      timeout = setTimeout(() => {
        setApiError("");
      }, 2000);
    return () => clearTimeout(timeout);
  }, [apiError]);

  const retrieveUserTypes = useCallback(async () => {
    try {
      barActivate();
      const ut = await requestGetUserTypes();
      setUserTypes(ut);
    } catch (err) {
      enqueueSnackbar("Unable to get user types", { variant: "error" });
    } finally {
      barStop();
    }
  }, [barActivate, barStop, enqueueSnackbar]);

  useEffect(() => {
    retrieveUserTypes();
  }, [retrieveUserTypes]);

  const retrieveUsers = useCallback(
    async (userType?: number | "admin") => {
      try {
        barActivate();
        const ut = await requestGetUsers(userType);
        const trueFirst = ut.sort((a, b) => Number(b.isAdministrator) - Number(a.isAdministrator));
        setUsers(trueFirst);
        setsearchedRows(ut);
      } catch (err) {
        enqueueSnackbar("Unable to get users", { variant: "error" });
      } finally {
        barStop();
      }
    },
    [barActivate, barStop, enqueueSnackbar]
  );

  useEffect(() => {
    retrieveUsers(currentUserType);
  }, [currentUserType, retrieveUsers]);

  const _openModal = () => {
    setModalOpen(true);
  };

  const _closeModal = () => {
    setModalOpen(false);
  };

  const _submitUserType = async (values: AddUserTypeModalSubmitValues) => {
    try {
      barActivate();
      const newType = await requestPostUserType(
        values.typeName,
        values.description
      );
      setUserTypes([...userTypes, newType]);
      _closeModal();
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setApiError(`User type "${values.typeName}" already exists`);
      } else {
        console.error(error);
      }
    } finally {
      barStop();
    }
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = searchedRows.filter((row) => {
      return (
        row.firstName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.lastName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.email.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.title.toLowerCase().includes(searchedVal.toLowerCase())
      )
    });
    setUsers(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <RWrapper>
      {/* <ModalContainer onClose={_closeModalStaff} open={modalStaffOpen} >
        <FormUser 
        />
      </ModalContainer> */}
      <Box className={styles.pagetitlebox}>
        <Box mr={2}><Typography variant="h4" color="textPrimary">Staff Profiles</Typography></Box>
        <Box className={`${styles.rightSide} ${styles.searchbarwrap}`}>
          <SearchBar className={styles.searchbar}
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
          />
        </Box>
      </Box>
      <Box className={styles.staffwrapper}>
        <PageHeader>
          <Box className={styles.headercontentcontainer}>
            <Box className={styles.usertypesholder}>
              <Box className={styles.flotBtn}>
                <Box className={styles.fillbtn}>
                  <FloatingButton
                    title="All"
                    active={currentUserType === undefined}
                    width="3em"
                    style={!currentUserType ? styleSelect : style}
                    onClick={() => {
                      setCurrentUserType(undefined);
                    }}
                  />
                </Box>
                <Box className={styles.fillbtn}>
                  <FloatingButton
                    title="Admin"
                    active={currentUserType === "admin"}
                    width="3em"
                    style={currentUserType === "admin" ? styleSelect : style}
                    onClick={() => {
                      setCurrentUserType("admin");
                    }}
                  />
                </Box>
                {userTypes.map((t) => (
                  <Box className={styles.fillbtn} key={t.id}>
                    <FloatingButton
                      title={t.name}
                      active={t.id === currentUserType}
                      width="3em"
                      style={currentUserType === t.id ? styleSelect : style}
                      onClick={() => {
                        setCurrentUserType(t.id);
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className={styles.staffmodelbtn}>
              <Box className={styles.fillbtn}>
                <IconButton onClick={_openModal} >
                  <svg width="12" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z" fill="white" />
                  </svg> Add type
                </IconButton>
              </Box>
              <Box className={styles.fillbtn}>
                <IconButton /*onClick={_openModalStaff}*/ onClick={() => history.push("/staff/e")}>
                  <svg width="12" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z" fill="white" />
                  </svg> Add Staff
                </IconButton>
              </Box>
            </Box>
          </Box>
        </PageHeader>
        <ModalContainer onClose={_closeModal} open={modalOpen}>
          <AddUserTypeModalWindow
            onSubmit={_submitUserType}
            onCancel={_closeModal}
            apiError={apiError}
          />
        </ModalContainer>
        <Grid container className={styles.userheadrowcontainer}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Typography variant="body2" color="textPrimary">
              Name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Typography variant="body2" color="textPrimary">
              Email
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2}>
            <Typography variant="body2" color="textPrimary">
              Employee Type
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Typography variant="body2" color="textPrimary">
              Mobile
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={1}>
            <Typography variant="body2" color="textPrimary">
              Admin
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <Typography variant="body2" color="textPrimary">
              Status
            </Typography>
          </Grid>
        </Grid>
        {users.map((user) => (
          <Paper
            elevation={0}
            square
            key={user.id}
            className={styles.userpaper}
            onClick={() => history.push(`/staff/e/${user.id}`)}
          >
            <Grid container className={styles.userrowcontainer}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography>
                  {user.title ? `${user.title} ` : ""}
                  {user.firstName} {user.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography>{user.email}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <Typography>{user.userType && user.userType.name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography>{user.mobileFull}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={1}>
                <AdminMark active={user.isAdministrator} />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <RectangleBadgeFilled
                  width="143px" className={styles.statusbox}
                  backgroundColor={
                    user.deletedAt ? "#FEF1D1" : "#E7FCD8"
                  }
                  color={
                    user.deletedAt ? "#A77C12" : "#46C438"
                  }
                >
                  {user.deletedAt ? "Deactivated" : "Active"}
                </RectangleBadgeFilled>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Box>
    </RWrapper>
  );
};
