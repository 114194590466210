import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";
import styles from "../../../css/model.module.css";
interface ButtonToggleProps {
  title: string | JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  active?: boolean;
  selectedActive?: boolean;
}

interface StylesProps {
  active?: boolean;
  selectedActive?: boolean;
}

const useStyles = makeStyles((theme) => {
  const textPrimary = theme.palette.text.primary;
  const textHint = theme.palette.text.hint;
  return {
    container: ({ active }: StylesProps) => ({
      width: "fit-content",
      minWidth: 100,
      maxWidth: 200,
      backgroundColor: theme.palette.background.paper,
      height: "3em",
      borderRadius: theme.shape.borderRadius,
      border: `1px solid`,
      borderColor: active ? textPrimary : textHint,
      borderStyle: "solid",
      color: active ? textPrimary : textHint,
      "&:active": {
        border: `1px solid`,
      },
    }),
  };
});

const ButtonToggle = ({
  title,
  onClick,
  disabled,
  className,
  active,
  selectedActive,
}: ButtonToggleProps) => {
  const classes = useStyles({ active });
  return (
    <ButtonBase
      className={`${classes.container} ${className ? className : ""} ${
        selectedActive ? styles.setactivebutton : ""
      }`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </ButtonBase>
  );
};

export default ButtonToggle;
