import { Box, Grid, InputLabel, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AxiosError } from "axios";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { emailRegex } from "../../GLOBALS";
import StandardButton from "../../componentsV3/Buttons/StandardButton";
import ErrorBlockThin from "../../componentsV3/ErrorBlockThin";
import Spinner from "../../componentsV3/Spinner";
import { useProgressBar } from "../../componentsV3/bars/ProgressBarGlobal";
import CheckBox from "../../componentsV3/inputs/FormikCheckbox";
import SelectFormik from "../../componentsV3/inputs/SelectFormik";
import MultipleSelectFormik from "../../componentsV3/inputs/Selectors/MultipleSelectFormik";
import TextInputFormik from "../../componentsV3/inputs/TextInputFormik";
import { RWrapper } from "../../componentsV3/layout/RWrapper";
import styles from "../../css/style.module.css";
import { deleteUser, putRestoreUser } from "../../requests/user";
import { requestGetLocations } from "../RosterScreen/requests";
import { User, UserType } from "../StaffScreen";
import { requestGetUserTypes, requestGetUsers } from "../StaffScreen/requests";
import { requestGetUser, requestPostUser, requestPutUser } from "./requests";

export interface FormUser
  extends Omit<
    User,
    "mobileCode" | "mobileNumber" | "fullName" | "userType" | "locationId" | "reportTo"
  > {
  mobileCode?: number | "";
  mobileNumber?: number | "";
  userType?: number;
  locationId?: any;
  reportTo?: string;
}
interface ValidationProps {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  isAdministrator: boolean;
  userType?: number;
  locationId?: any;
  mobileCode?: number | "";
  mobileNumber?: number | "";
  reportTo?: string;
}

interface ValidationErrorsProps {
  title?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isAdministrator?: string;
  userType?: string;
  locationId?: any;
  mobileCode?: string;
  mobileNumber?: string;
  reportTo?: string;
}

// interface ValidationTouchedProps {
//   title?: boolean;
//   firstName?: boolean;
//   lastName?: boolean;
//   email?: boolean;
//   isAdministrator?: boolean;
//   userType?: boolean;
//   location?: boolean;
//   mobileCode?: boolean;
//   mobileNumber?: boolean;
//   reportTo?: boolean;
// }

export interface Location {
  id?: number;
  locationName: string;
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.fontSize,
    marginBottom: "0.3em",
  },
  margincontainer: {
    marginTop: "0.5em",
  },
  spacer1: {
    marginRight: 5,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  control: {
    minWidth: 320,
    border: "0.5px solid #9e9e9e",
    borderBottom: "0.5px solid #9e9e9e",
  },
}));

const validation = ({
  title,
  firstName,
  lastName,
  email,
  userType,
  locationId,
  isAdministrator,
  mobileCode,
  mobileNumber,
  reportTo,
}: ValidationProps) => {
  let errors: ValidationErrorsProps = {};
  if (!title) errors.title = "Title required";
  if (!firstName) errors.firstName = "First Name required";
  if (!lastName) errors.lastName = "Last Name required";
  if (!email) {
    errors.email = "Email required";
  } else if (!emailRegex.test(email)) errors.email = "Email doesn't look right";
  if (!isAdministrator && !userType) errors.userType = "User type is required";
  // if (!locationId) errors.locationId = "Location is required"
  if (!reportTo) errors.reportTo = "Reports To is required";
  if (mobileCode && !mobileNumber)
    errors.mobileNumber = "Please enter mobile number";
  if (mobileNumber && !mobileCode)
    errors.mobileCode = "Please enter country code";
  return errors;
};

export default () => {
  const classes = useStyles();
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();

  const [reportTo, setreportTo] = useState<Array<User>>([]);
  const [user, setuser] = useState<User | undefined>(undefined);
  const [formError, setFormError] = useState<string>("");
  const [userTypes, setuserTypes] = useState<Array<UserType>>([]);

  const { isLoading } = useQuery(
    "requestGetUserTypes",
    async () => requestGetUserTypes(),
    {
      keepPreviousData: true,
      enabled: false,
      onError: (err) => {
        enqueueSnackbar({ variant: "error" });
      },
    }
  );

  const getUser = useCallback(
    async (userID: string) => {
      try {
        barActivate();
        const u = await requestGetUser(userID);
        setuser(u);
      } catch (error) {
        history.push("/staff");
        console.error(error);
      } finally {
        barStop();
      }
    },
    [history, barActivate, barStop]
  );

  useEffect(() => {
    if (id) {
      getUser(id);
    }
  }, [id, getUser]);





  const retrieveUsers = useCallback(
    async (reportTo?: number | "admin") => {
      try {
        barActivate();
        const ut = await requestGetUsers(reportTo);
        const activeUsers = ut.filter((u) => u.deletedAt === null);
        setreportTo(activeUsers);
      } catch (err) {
        enqueueSnackbar("Unable to get users", { variant: "error" });
      } finally {
        barStop();
      }
    },
    [barActivate, barStop, enqueueSnackbar]
  );

  useEffect(() => {
    retrieveUsers();
  }, [retrieveUsers]);

  const _getUserTypes = useCallback(async () => {
    try {
      barActivate();
      const ut = await requestGetUserTypes();
      setuserTypes(ut);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to get user types. Try again later", {
        variant: "error",
      });
    } finally {
      barStop();
    }
  }, [barActivate, barStop, enqueueSnackbar]
  )

  useEffect(() => {
    _getUserTypes();
  }, [_getUserTypes]);

  const [locations, setLocations] = useState<Array<Location>>([]);

  const getLocations = useCallback(async () => {
    try {
      barActivate();
      const result = await requestGetLocations();
      setLocations(result);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to retrieve locations", { variant: "error" });
    } finally {
      barStop();
    }
  }, [enqueueSnackbar, barStop, barActivate]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);


  const submitUser = async (userDetails: FormUser) => {
    try {
      barActivate();
      const check = await validation(userDetails);
      if (check && check.firstName) {
        setFormError(check.firstName);
        return;
      }
      if (check && check.lastName) {
        setFormError(check.lastName);
        return;
      }
      if (check && check.email) {
        setFormError(check.email);
        return;
      }
      if (check && check.userType) {
        setFormError(check.userType);
        return;
      }
      if (check && check.locationId) {
        setFormError(check.locationId);
        return;
      }
      if (check && check.mobileNumber) {
        setFormError(check.mobileNumber);
        return;
      }
      if (check && check.mobileCode) {
        setFormError(check.mobileCode);
        return;
      }
      if (user && user.id === Number(id)) {
        await requestPutUser({
          ...userDetails,
          id: user.id,
          mobileCode: userDetails.mobileCode || undefined,
          mobileNumber: userDetails.mobileNumber || undefined,
        });
      } else {
        await requestPostUser({
          ...userDetails,
          mobileCode: userDetails.mobileCode || undefined,
          mobileNumber: userDetails.mobileNumber || undefined,
        });
      }
      history.push("/staff");
      enqueueSnackbar("Saved", { variant: "success" });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        enqueueSnackbar("User with such email exists", { variant: "warning" });
      } else {
        console.error(error);
        enqueueSnackbar("Submit user failed", { variant: "error" });
      }
    } finally {
      barStop();
    }
  };

  const { mutate: deactivateUser, isLoading: isDeactivating } = useMutation<
    unknown,
    AxiosError,
    number
  >((id) => deleteUser(id), {
    onError: (err) => {
      enqueueSnackbar(
        err.response && err.response.data.message
          ? err.response.data.message
          : `Unable to deactivate the user`,
        { variant: "error" }
      );
    },
    onSuccess: async () => {
      if (id) {
        getUser(id);
      }
      enqueueSnackbar(`Deactivated`, {
        variant: "success",
      });
    },
  });

  const { mutate: activateUser, isLoading: isActivating } = useMutation<
    unknown,
    AxiosError,
    number
  >((id) => putRestoreUser(id), {
    onError: (err) => {
      enqueueSnackbar(
        err.response && err.response.data.message
          ? err.response.data.message
          : `Unable to activate the user`,
        { variant: "error" }
      );
    },
    onSuccess: async () => {
      if (id) {
        getUser(id);
      }
      enqueueSnackbar(`Activated`, {
        variant: "success",
      });
    },
  });
  const cancelForm = () => {
    history.push("/staff");
  };


  if (isLoading) {
    return <Spinner />;
  }

  return (
    <RWrapper>
      <Formik
        enableReinitialize
        initialValues={useMemo<FormUser>(() => {
          return {
            title: user && user.title ? user.title : "",
            firstName: user && user.firstName ? user.firstName : "",
            lastName: user && user.lastName ? user.lastName : "",
            email: user && user.email ? user.email : "",
            isAdministrator:
              user && user.isAdministrator ? user.isAdministrator : false,
            userType: user && user.userType ? user.userType.id : 0,
            locationId: user && user.userLocation ? user.userLocation.id : 0,
            mobileCode: user && user.mobileCode ? user.mobileCode : "",
            mobileNumber: user && user.mobileNumber ? user.mobileNumber : "",
            reportTo: user?.reportTo,

          };
        }, [user])}
        onSubmit={submitUser}
      >
        {({
          isValid,
          dirty,
          errors,
          isSubmitting,
          touched,
          resetForm,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Box className={styles.staff_form_container}>
              <Box>
                <Box className={styles.pagetitlebox}>
                  <BsArrowLeftShort
                    className={styles.backicon}
                    onClick={() => history.push(`/staff`)}
                  />
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    className={styles.title}
                  >
                    {id ? "Edit Staff Member" : "Add staff"}
                  </Typography>
                </Box>
                <Paper className={styles.paper} elevation={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <InputLabel color="primary" className={classes.label}>
                        Title
                      </InputLabel>
                      <Field
                        component={TextInputFormik}
                        name="title"
                        placeholder="Mr/Mrs"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <InputLabel color="primary" className={classes.label}>
                        First Name
                      </InputLabel>
                      <Field
                        component={TextInputFormik}
                        name="firstName"
                        placeholder="Type name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <InputLabel color="primary" className={classes.label}>
                        Last Name
                      </InputLabel>
                      <Field
                        component={TextInputFormik}
                        name="lastName"
                        placeholder="Type last name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <InputLabel color="primary" className={classes.label}>
                        Email
                      </InputLabel>
                      <Field
                        component={TextInputFormik}
                        name="email"
                        placeholder="Type email"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <InputLabel color="primary" className={classes.label}>
                        User Type
                      </InputLabel>
                      <Field
                        component={SelectFormik}
                        name="userType"
                        options={userTypes.map((e) => ({
                          value: e.id,
                          title: e.name,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <InputLabel color="primary" className={classes.label}>
                        Location
                      </InputLabel>
                      <Field
                        component={SelectFormik}
                        name="locationId"
                        options={locations.map((l) => ({
                          value: l.id,
                          title: l.locationName,
                        }))}
                      />
                    </Grid>
                    <Grid item container xs={12} sm={6} md={6} lg={3}>
                      <Grid item xs={3}>
                        <InputLabel color="primary" className={classes.label}>
                          Code
                        </InputLabel>
                        <Field
                          component={SelectFormik}
                          name="mobileCode"
                          options={[
                            { value: 64, title: "+64" },
                            { value: 61, title: "+61" },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={8}>
                        <InputLabel color="primary" className={classes.label}>
                          Mobile Number
                        </InputLabel>
                        <Field
                          component={TextInputFormik}
                          name="mobileNumber"
                          type="number"
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={6} md={6} lg={3}>
                      <InputLabel color="primary" className={classes.label}>
                        Report To
                      </InputLabel>
                      <Field
                        component={MultipleSelectFormik}
                        name="reportTo"
                        options={reportTo.map((e) => ({
                          value: e.id,
                          fullName: e.fullName,
                        }))}
                        onSelectedValueChange={(rosterId: any) => {
                          setFieldValue("reportTo", rosterId);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <InputLabel color="primary" className={classes.label}>
                        Administartor
                      </InputLabel>
                      <Field component={CheckBox} name="isAdministrator" />
                    </Grid>
                    {user?.id ? (
                      <Grid item xs={12} sm={6} md={6} lg={3}>
                        {user?.deletedAt ? (
                          <Fragment>
                            <InputLabel
                              color="primary"
                              className={classes.label}
                            >
                              Deactivated at:
                              {moment(user.deletedAt).format(
                                "YYYY/MM/DD HH:mm"
                              )}
                            </InputLabel>
                            <StandardButton
                              type="button"
                              title="Activate"
                              disabled={isActivating}
                              onClick={() => activateUser(user.id as number)}
                            />
                          </Fragment>
                        ) : (
                          <Fragment>
                            <InputLabel
                              color="primary"
                              className={classes.label}
                            >
                              Active
                            </InputLabel>
                            <StandardButton
                              type="button"
                              title="Deactivate"
                              disabled={isDeactivating}
                              onClick={() => deactivateUser(user.id as number)}
                            />
                          </Fragment>
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid item lg={5} md={12} sm={12} xs={12}>
                    <Box
                      className={styles.errortext}
                      style={{ margin: "15px 0" }}
                    >
                      <ErrorBlockThin message={formError} />
                    </Box>
                  </Grid>
                  <Box className={styles.cancelbuttonmargin}>
                    <StandardButton
                      title="Cancel"
                      className={"secondary"}
                      onClick={() => cancelForm()}
                    />
                    <StandardButton
                      title="Reset"
                      className={"secondary"}
                      onClick={() => resetForm()}
                    />
                    <StandardButton
                      title="Submit"
                      type="submit"
                      accent
                      disabled={isSubmitting || !dirty || !isValid}
                    />
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </RWrapper >
  );
};
