import { Hidden } from "@material-ui/core";
import moment, { Moment } from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  getUserShifts,
  postTakeAvailableShift,
  putShiftAvailable,
} from "../../../requests/shifts";
import { Shift } from "../../RosterScreen/requests";

import { useSnackbar } from "notistack";
import { useProgressBar } from "../../../componentsV3/bars/ProgressBarGlobal";
import UserMonthlyRosterDesktop from "./UserMonthlyRosterDesktop";
import UserMonthlyRosterMobile from "./UserMonthlyRosterMobile";

export interface Day {
  day: number;
  year: number;
  month: number;
}

export default function UserMonthlyRoster() {
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  const [date, setdate] = useState<Moment | undefined>(undefined);
  const [allShifts, setAllShifts] = useState<{
    [key: string]: Array<Shift>;
  }>({});
  const [selectedDay, setselectedDay] = useState<Day | undefined>(undefined);
  const [today, settoday] = useState<Day | undefined>(undefined);
  const [available, setAvailable] = useState<boolean>(false);

  useEffect(() => {
    const currentMoment = moment();
    setdate(currentMoment);
    const year = Number(currentMoment.format("YYYY"));
    const month = Number(currentMoment.format("M"));
    const day = Number(currentMoment.format("D"));
    settoday({ year, month, day });
  }, []);

  const goToPreviousMonth = () => {
    setdate(date?.clone().subtract(1, "month"));
  };

  const goToNextMonth = () => {
    setdate(date?.clone().add(1, "month"));
  };

  const retrieveShifts = useCallback(async () => {
    try {
      barActivate();
      if (today) {
        setAllShifts({});
        const result = await getUserShifts(today.year, today.month, !available);
        setAllShifts(result);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Unable to retrieve shifts`, { variant: "error" });
    } finally {
      barStop();
    }
  }, [today, available, barActivate, barStop, enqueueSnackbar]);

  let updating = false;
  const handleAvailableChange = async (shift: Shift) => {
    if (shift.id && !updating) {
      try {
        barActivate();
        updating = true;
        // const s = { ...shift, available: !shift.available };
        if (shift.own) {
          // await postShifts([s]);
          await putShiftAvailable(shift.id);
        } else {
          await postTakeAvailableShift(shift.id);
        }
        if (today) {
          const result = await getUserShifts(
            today.year,
            today.month,
            !available
          );
          setAllShifts(result);
        }
        updating = false;
      } catch (err) {
        updating = false;
        if (err && err.response && err.response.data) {
          enqueueSnackbar(err.response.data.message, { variant: "error" });
        } else {
          enqueueSnackbar("Unable to process shift. Please try again later", {
            variant: "error",
          });
        }
      } finally {
        barStop();
      }
    }
  };

  const handleCheckBox = async (available: boolean) => {
    setAvailable(available);
    if (today) {
      const result = await getUserShifts(today.year, today.month, !available);
      setAllShifts(result);
    }
  };

  useEffect(() => {
    retrieveShifts();
  }, [retrieveShifts]);

  return (
    <Fragment>
      <Hidden smUp>
        <UserMonthlyRosterMobile
          date={date}
          shifts={allShifts}
          today={today}
          selectedDay={selectedDay}
          setselectedDay={setselectedDay}
          onPrevMonthClick={goToPreviousMonth}
          onNextMonthClick={goToNextMonth}
          onAvailableChange={handleAvailableChange}
          onHandleCheckBox={handleCheckBox}
        />
      </Hidden>
      <Hidden xsDown>
        <UserMonthlyRosterDesktop
          date={date}
          shifts={allShifts}
          today={today}
          selectedDay={selectedDay}
          setselectedDay={setselectedDay}
          onPrevMonthClick={goToPreviousMonth}
          onNextMonthClick={goToNextMonth}
          onAvailableChange={handleAvailableChange}
          onHandleCheckBox={handleCheckBox}
        />
      </Hidden>
    </Fragment>
  );
}
