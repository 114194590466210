import styled from "styled-components";

export const RectangleBadgeFilled = styled.div<{
  color?: string;
  backgroundColor?: string;
  width?: string;
}>`
  font-size: 14px;border-radius:35px;
  color: ${({ theme, color }) => color || theme.colors.base.white};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.brownGrey};
  padding:6px 20px;
  box-sizing: border-box;
  text-transform: capitalize;
  text-align: center;
  width: ${({ width }) => width};
  min-width: 70px;
`;

export const RectangleBadgeOutlined = styled.div<{
  color?: string;
  backgroundColor?: string;
  width?: string;
}>`
  font-size: 14px;border-radius:35px;
  border: 1px solid ${({ theme, color }) => color || theme.colors.greyish};
  color: ${({ theme, color }) => color || theme.colors.brownGrey};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding:6px 20px;
  min-width: 70px;
  box-sizing: border-box;
  text-transform: capitalize;
  width: ${({ width }) => width};
`;
