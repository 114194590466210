import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { AxiosError } from "axios";
import SearchBar from "material-ui-search-bar";
import moment from "moment";
import { useSnackbar } from "notistack";
import { default as React, useCallback, useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { useQuery } from "react-query";
import styled from "styled-components";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import Spinner from "../../../componentsV3/Spinner";
import Dropdown from "../../../componentsV3/inputs/Dropdown";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import styles from "../../../css/style.module.css";
import { useDebounce } from "../../../hooks/useDebounce";
import { GetAuditResponse, getAudit } from "../../../requests/audit";
import AuditTableRow from "./auditTableRow";

const Table = styled.table``;

const Th = styled.th<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}%` : "")};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fonts.size.default};
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f2f2f2;
`;

type Order = { title: string; value: string };

function AuditHistoryScreen() {
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>({ title: "Desc", value: "DESC" });
  const [take, setTake] = useState<number>(50);
  const [auditType, setAudiType] = useState<string>("All");
  const [sourceType, setSourceType] = useState<string>("All");
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);
  const [clear, setClear] = useState<boolean>();
  const [search, setSearch] = useState<string>("");

  const Spacer = styled.div`
    height: ${({ theme }) => theme.gap[1]};
  `;

  const [dateFilter, setDateFilter] = React.useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const startDate = clear
    ? moment(dateFilter[0].startDate).format("YYYY-MM-DD").toLocaleString()
    : undefined;
  const endDate = clear
    ? moment(dateFilter[0].endDate).format("YYYY-MM-DD").toLocaleString()
    : undefined;

  const { data, isLoading } = useQuery<GetAuditResponse, AxiosError>(
    [
      "get-audit",
      order,
      take,
      page,
      auditType,
      sourceType,
      startDate,
      endDate,
      search,
    ],
    () => {
      const skip = (page - 1) * take;

      return getAudit(
        order.value as "ASC" | "DESC",
        skip,
        take,
        auditType,
        sourceType,
        startDate,
        endDate,
        search
      );
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get audit data`,
          { variant: "error" }
        );
      },
    }
  );

  useEffect(() => {
    if (data) {
      const total = Math.ceil(data.count / take);
      setPages(total);
    }
  }, [data, take]);

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setPage(value);
  }

  const handleSelect = (range: any) => {
    setDateFilter([range.selection]);
    setClear(true);
  };

  const handleClick = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleClear = () => {
    setDialogOpen(false);
    setClear(false);
  };

  const requestSearch = (searchVal: string) => {
    if (data) {
      setSearch(searchVal);
      setPage(1);
    }
  };
  const onChangeDebounced = useDebounce(requestSearch, 500);

  const cancelSearch = () => {
    setSearch("");
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <RWrapper>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent style={{ padding: 20 }}>
          <DialogContentText>
            <DateRange
              onChange={handleSelect}
              ranges={dateFilter}
              moveRangeOnFirstSelection={false}
            />
          </DialogContentText>
          <Box display="flex">
            <Box mr={1}>
              <StandardButton
                accent
                title="Done"
                onClick={() => setDialogOpen(false)}
              />
            </Box>
            <StandardButton
              className={"secondary"}
              title="Clear"
              onClick={handleClear}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Box className={styles.pagetitlebox}>
        <Box mr={2}>
          <Typography variant="h4" color="textPrimary">
            Activity History
          </Typography>
        </Box>
        <Box className={`${styles.rightSide} ${styles.searchbarwrap}`}>
          <SearchBar
            className={styles.searchbar}
            value={search}
            onChange={(searchVal) => onChangeDebounced(searchVal)}
            onCancelSearch={() => cancelSearch()}
            cancelOnEscape
          />
        </Box>
      </Box>
      <Divider />
      <Spacer />

      <Box className={`${styles.pagetitlebox} ${styles.secondarytitlebox}`}>
        <Box className={`${styles.rightSide} ${styles.titleboxdropdownbtn}`}>
          <Box className={styles.dropdownholdercontainer}>
            <Box className={styles.dropdownholder}>
              <Button
                className={styles.date}
                variant="contained"
                onClick={handleClick}
                disableElevation
                disableFocusRipple
                disableTouchRipple
              >
                Date
              </Button>
            </Box>

            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={`${take}`}
                onSelect={(v) => setTake(v.value as number)}
                options={[
                  { title: "5", value: 5 },
                  { title: "10", value: 10 },
                  { title: "20", value: 20 },
                  { title: "50", value: 50 },
                  { title: "100", value: 100 },
                  { title: "200", value: 200 },
                ]}
              />
            </Box>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={`${auditType}`}
                onSelect={(v) => setAudiType(v.value as string)}
                options={[
                  { title: "All", value: "All" },

                  { title: "shift_update", value: "shift_update" },
                  {
                    title: "shift_available_taken",
                    value: "shift_available_taken",
                  },
                  { title: "shift_available", value: "shift_available" },
                  { title: "user_delete", value: "user_delete" },
                  {
                    title: "shift_offer_cancelled",
                    value: "shift_offer_cancelled",
                  },
                  { title: "shift_deleted", value: "shift_deleted" },
                  { title: "shift_insert", value: "shift_insert" },
                  {
                    title: "shift_offer_placed",
                    value: "shift_offer_placed",
                  },
                  { title: "sms_sent", value: "sms_sent" },
                  {
                    title: "shift_offer_accepted",
                    value: "shift_offer_accepted",
                  },
                  { title: "user_update", value: "user_update" },
                ]}
              />
            </Box>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={`${sourceType}`}
                onSelect={(v) => setSourceType(v.value as string)}
                options={[
                  { title: "All", value: "All" },
                  { title: "shift_offers", value: "shift_offers" },
                  { title: "shifts", value: "shifts" },
                ]}
              />
            </Box>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={order.title}
                onSelect={(v) =>
                  setOrder({ title: v.title, value: v.value as string })
                }
                options={[
                  { title: "Desc", value: "DESC" },
                  { title: "Asc", value: "ASC" },
                ]}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {data?.count !== 0 ? (
        <div className={styles.tablecontainer}>
          <Table className={styles.audittable}>
            <thead>
              <tr>
                <Th width={5}>Audit ID</Th>
                <Th width={10}>Audit type</Th>
                <Th width={10}>Source type</Th>
                <Th width={10}>Source ID</Th>
                <Th width={10}>Column name</Th>
                <Th width={10}>Old value</Th>
                <Th width={10}>New Value</Th>
                <Th width={10}>User name</Th>
                <Th width={10}>Created at</Th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.auditData.map((o) => <AuditTableRow key={o.id} {...o} />)}
            </tbody>
          </Table>
        </div>
      ) : (
        <Box className={styles.commonbox}>
          <h3 style={{ margin: 0 }}>No records found</h3>
        </Box>
      )}
      {data?.count !== 0 ? (
        <Pagination
          className={styles.pagination}
          count={pages}
          page={page}
          onChange={handlePageChange}
        />
      ) : null}
    </RWrapper>
  );
}

export default AuditHistoryScreen;
