import { Typography } from "@material-ui/core";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useProgressBar } from "../../../components/bars/ProgressBarGlobal";
import {
  getUserAllShifts,
  postSwapShifts,
  postTakeShift,
} from "../../../requests/shifts";
import { Shift } from "../../RosterScreen/requests";
import { useShift } from "../UserRequestSwapScreen/Provider";
import SwapModal from "./SwapModal";
import SwapShiftComponentDesktop from "./swapShiftComponentDesktop";

export default function UserRequestShiftSwapScreen() {
  const history = useHistory();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  const { shifts } = useShift();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [startMoment, setStartMoment] = useState<Moment>(moment());
  const [shiftsToSwap, setShiftsToSwap] = useState<{
    [key: string]: Array<Shift>;
  }>({});
  const [selectedShift, setSelectedShift] = useState<Shift | undefined>(
    undefined
  );

  const retrieveShiftsForSwap = useCallback(async () => {
    try {
      barActivate();
      if (shifts.length) {
        const result = await getUserAllShifts();
        setShiftsToSwap(result);
      }
    } catch (err) {
      console.error(err);
    } finally {
      barStop();
    }
  }, [shifts, barActivate, barStop]);

  useEffect(() => {
    retrieveShiftsForSwap();
  }, [retrieveShiftsForSwap]);

  function onSelectSwapClick() {
    setModalOpen(true);
  }
  async function TakeShiftRequest(p: { swapShift: Shift }) {
    try {
      barActivate();
      await postTakeShift(p.swapShift.id!);
      setModalOpen(false);
      enqueueSnackbar("Swap request sent", { variant: "success" });
      history.push("/request-swap");
    } catch (err) {
      if (err.response) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Swap request failed", { variant: "error" });
      }
    } finally {
      barStop();
    }
  }

  async function submitSwapRequest(p: {
    currentShift: Shift;
    swapShift: Shift;
  }) {
    try {
      barActivate();
      await postSwapShifts(p.currentShift.id!, p.swapShift.id!);
      setModalOpen(false);
      enqueueSnackbar("Swap request sent", { variant: "success" });
      history.push("/requests");
    } catch (err) {
      if (err.response) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Swap request failed", { variant: "error" });
      }
    } finally {
      barStop();
    }
  }

  return (
    <>
      <Typography variant="h4">Request Shift Swap</Typography>
      <SwapModal
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        swapShift={shifts}
        currentShift={
          selectedShift && shifts.includes(selectedShift) === false
            ? selectedShift
            : undefined
        }
        onSubmit={submitSwapRequest}
        onTakeShift={TakeShiftRequest}
      />

      {shifts && (
        <SwapShiftComponentDesktop
          requestShift={shifts}
          selectedShift={selectedShift}
          setselectedShift={setSelectedShift}
          setstartMoment={setStartMoment}
          startMoment={startMoment}
          shiftsToSwap={shiftsToSwap}
          onSelectSwapClick={onSelectSwapClick}
        />
      )}
    </>
  );
}
