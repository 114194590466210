import React, { useState, useEffect } from "react";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import {
  Divider,
  withStyles,
  makeStyles,
  Box,
  Typography,
  Table,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import styles from "../../../css/style.module.css";
import { AxiosError } from "axios";
import {
  GetContactResponseItem,
  getContactTicket,
  putTicket,
} from "../../../requests/settings";
import { useQuery } from "react-query";
import Spinner from "../../../componentsV3/Spinner";
import styled from "styled-components";
import { Pagination } from "@material-ui/lab";
import Dropdown from "../../../componentsV3/inputs/Dropdown";
import SearchBar from "material-ui-search-bar";
import { useDebounce } from "../../../hooks/useDebounce";
import { BsStickies } from "react-icons/bs";
import CreateTicketModal, {
  CreateTicketModalInnerSubmitValues,
} from "./modals/CreateTicketModal";
import { FaRegEdit } from "react-icons/fa";
import { BiShowAlt } from "react-icons/bi";
import ModalContainer from "../../../componentsV3/modals/ModalContainer";
import { useSnackbar } from "notistack";
import { requestPostContactSupport } from "../../../requests/settings";
import { useProgressBar } from "../../../componentsV3/bars/ProgressBarGlobal";
import { styledTheme } from "../../../themesV3/styledTheme";
import { RectangleBadgeFilled } from "../../../componentsV3/styled-components/badges";
import EditTicketModal, { EditTicketModalInnerSubmitValues } from './modals/EditTicketModal'
import { useMutation } from "react-query";
import ViewTicketDetails from "./modals/ViewTicketDetails";

const bg = {
  Pending: "#DFE6FF",
  Closed: "#FFE3D5",
  InProgress: styledTheme.colors.lightYellow,
  Resolved: "lightGreen",
};

const colors = {
  Pending: "#1E2C93",
  Closed: "#9A1214",
  InProgress: styledTheme.colors.darkYellow,
  Resolved: "green",
};

const useStyles = makeStyles(() => ({
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-disabled": {
      "& svg": { fill: "#9e9e9e" }
    },
  },
}));


const Th = styled.th<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}%` : "")};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fonts.size.default};
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f2f2f2;
`;

const Td = styled.td`
  background-color: ${({ theme }) => theme.colors.background.paper};
  min-height: 20px;
  box-sizing: border-box;
  padding: 2px 4px;
`;

const Text = styled.span`
  display: block;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: text;
`;
const HtmlTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 12,
  },
}))(Tooltip);

const Spacer = styled.div`
  height: ${({ theme }) => theme.gap[1]};
`;

type Order = { title: string; value: string };

function ContactHistoryScreen() {
  const c = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { barActivate, barStop } = useProgressBar();

  const [order, setOrder] = useState<Order>({ title: "Desc", value: "DESC" });
  const [take, setTake] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);
  const [search, setSearch] = useState<string>("");


  const [supportType, setSupportType] = useState<string>("All");

  const { data, isLoading, refetch } = useQuery<GetContactResponseItem, AxiosError>(
    ["get-contact-details", order, take, page, supportType, search],
    () => {
      const skip = (page - 1) * take;

      return getContactTicket(
        order.value as "ASC" | "DESC",
        skip,
        take,
        supportType,
        search
      );
    },
    {
      keepPreviousData: true,

      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get contact data`,
          { variant: "error" }
        );
      },
    }
  );

  useEffect(() => {
    if (data) {
      const total = Math.ceil(data.count / take);
      setPages(total);
    }
  }, [data, take]);

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setPage(value);
  }

  const requestSearch = (searchVal: string) => {
    if (data) {
      setSearch(searchVal);
      setPage(1);
    }
  };
  const onChangeDebounced = useDebounce(requestSearch, 500);

  const cancelSearch = () => {
    setSearch("");
  };


  const [selectedTicketData, setSelectedTicketData] = useState<any>();
  const [modalTicketOpen, setModalTicketOpen] = useState<boolean>(false);

  const _closeModalTicket = () => setModalTicketOpen(false);
  const _openModalTicket = () => setModalTicketOpen(true);

  const [modalEditTicketOpen, setModalEditTicketOpen] = useState<boolean>(false);
  const _closeModalEditTicket = () => setModalEditTicketOpen(false);

  const _openModalEditTicket = (ticketId: number) => {
    const ticketdata = data!.contactData.filter((u) => u.id === ticketId);
    if (ticketdata && ticketdata.length > 0) {
      setSelectedTicketData(ticketdata[0]);
    }
    setModalEditTicketOpen(true);

  }

  const [modalTicketDetailsOpen, setModalTicketDetailsOpen] = useState<boolean>(false);
  const _closeModalTicketDetails = () => setModalTicketDetailsOpen(false);

  const _openModalTicketDetails = (ticketDetailsId: number) => {
    const ticketdetails = data!.contactData.filter((u) => u.id === ticketDetailsId);
    if (ticketdetails && ticketdetails.length > 0) {
      setSelectedTicketData(ticketdetails[0]);
    }
    setModalTicketDetailsOpen(true);

  }

  const _submitTicket = async (value: CreateTicketModalInnerSubmitValues) => {
    try {
      barActivate();
      const result = await requestPostContactSupport(value);
      console.log(result);
      setModalTicketOpen(false);
      enqueueSnackbar("Ticket successfully created", { variant: "success" });
      refetch();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to submit ticket", { variant: "error" });
    } finally {
      barStop();
    }
  };

  const { mutateAsync } = useMutation<unknown, AxiosError, any>(
    (ticket) => putTicket(selectedTicketData.id, ticket),
    {
      onError: (err) => {
        enqueueSnackbar(
          "Unable to update Ticket",
          { variant: "error" }
        );
      },
      onSuccess: (response) => {
        enqueueSnackbar("Ticket Updated", { variant: "success" });
        refetch();
      },
    }
  );

  const _submitPutTicket = async (values: EditTicketModalInnerSubmitValues) => {
    await mutateAsync({

      description: values.description,
      status: values.status,
      comment: values.comment

    });
    _closeModalEditTicket();
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <RWrapper>
      <ModalContainer onClose={_closeModalTicket} open={modalTicketOpen}>
        <CreateTicketModal
          onSubmit={_submitTicket}
          onCancel={_closeModalTicket}
        />
      </ModalContainer>
      <ModalContainer onClose={_closeModalEditTicket} open={modalEditTicketOpen}>
        <EditTicketModal
          onSubmit={_submitPutTicket}
          onCancel={_closeModalEditTicket}
          selectTicket={selectedTicketData}
        />
      </ModalContainer>
      <ModalContainer onClose={_closeModalTicketDetails} open={modalTicketDetailsOpen}>
        <ViewTicketDetails TicketDetails={selectedTicketData} onCancel={_closeModalTicketDetails}
        />
      </ModalContainer>
      <Box className={styles.pagetitlebox}>
        <Typography variant="h4" color="textPrimary">
          Tickets
        </Typography>
        <Box className={`${styles.rightSide} ${styles.searchbarwrap}`}>

          <Box className={styles.fillbtn}>
            <IconButton onClick={_openModalTicket}>
              <BsStickies style={{ fontSize: 18, color: "#fff" }} />
              Create Support Ticket
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Spacer />
      <Box className={`${styles.pagetitlebox} ${styles.secondarytitlebox}`}>
        <Box className={`${styles.rightSide} ${styles.titleboxdropdownbtn}`}>
          <Box className={styles.dropdownholdercontainer}>
            <Box className={styles.dropdownholder}>
              <SearchBar
                className={styles.searchbar}
                value={search}
                onChange={(searchVal) => onChangeDebounced(searchVal)}
                onCancelSearch={() => cancelSearch()}
                cancelOnEscape
              /></Box>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={`${supportType}`}
                options={[
                  { title: "All", value: "All" },
                  { title: "Admin Support", value: "Admin Support" },
                  { title: "Project Support", value: "Project Support" },
                  { title: "Reporting Support", value: "Reporting Support" },
                  { title: "Timesheet Support", value: "Timesheet Support" },
                  {
                    title: "Other Technical Support",
                    value: "Other Technical Support",
                  },
                ]}
                onSelect={(v) => setSupportType(v.value as string)}
              />
            </Box>

            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={`${take}`}
                onSelect={(v) => setTake(v.value as number)}
                options={[
                  { title: "5", value: 5 },
                  { title: "10", value: 10 },
                  { title: "20", value: 20 },
                  { title: "50", value: 50 },
                  { title: "100", value: 100 },
                  { title: "200", value: 200 },
                ]}
              />
            </Box>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={order.title}
                onSelect={(v) =>
                  setOrder({ title: v.title, value: v.value as string })
                }
                options={[
                  { title: "Desc", value: "DESC" },
                  { title: "Asc", value: "ASC" },
                ]}
              />
            </Box>

          </Box>
        </Box>
      </Box>
      {data?.count !== 0 ? (
        <Box className={styles.tablecontainer}>
          <Table className={`${styles.audittable} ${styles.tickettable}`}>
            <thead>
              <tr>
                <Th style={{ width: "4%" }}>Ticket ID</Th>
                <Th style={{ width: "12%" }}>Support Type</Th>
                <Th style={{ width: "24%" }}>Description</Th>
                <Th style={{ width: "10%" }}>Name</Th>
                <Th>Email</Th>
                <Th style={{ width: "7%" }}>Mobile</Th>
                <Th style={{ width: "18%" }}>Comment</Th>
                <Th style={{ width: "8%" }}>Status</Th>
                <Th style={{ width: "7%" }}>Actions</Th>
              </tr>
            </thead>
            <tbody>

              {data &&
                data.contactData.map((d) => (
                  <tr>

                    <Td>
                      <HtmlTooltip title={`ID: ${d.id}`} arrow>
                        <Text style={{ textAlign: 'center' }}>{d.id}</Text>
                      </HtmlTooltip>
                    </Td>

                    <Td>
                      <HtmlTooltip title={`SupportType: ${d.supportType}`} arrow>
                        <Text>{d.supportType}</Text>
                      </HtmlTooltip>
                    </Td>

                    <Td>
                      <HtmlTooltip title={`Description: ${d.description}`} arrow>
                        <Text>{`${d.description ? d.description : "-"}`}</Text>
                      </HtmlTooltip>
                    </Td>

                    <Td>
                      <HtmlTooltip title={`Name: ${d.name}`} arrow>
                        <Text>{d.name}</Text>
                      </HtmlTooltip>
                    </Td>

                    <Td>
                      <HtmlTooltip title={`Email: ${d.email}`} arrow>
                        <Text>{d.email}</Text>
                      </HtmlTooltip>
                    </Td>

                    <Td>
                      <HtmlTooltip title={`Mobile: ${d.mobile}`} arrow>
                        <Text style={{ textAlign: 'center' }}>{d.mobile}</Text>
                      </HtmlTooltip>
                    </Td>
                    <Td>
                      <HtmlTooltip title={`Comment: ${d.comment}`} arrow>
                        <Text>{d.comment}</Text>
                      </HtmlTooltip>
                    </Td>
                    <Td>
                      <RectangleBadgeFilled backgroundColor={bg[d.status]} color={colors[d.status]}>
                        {d.status === "InProgress" ? "In-Progress" : `${d.status}`}
                      </RectangleBadgeFilled>
                    </Td>
                    <Td>
                      <Box className={`${styles.ticket_table_btn_container}`}>
                        <IconButton
                          onClick={() => _openModalTicketDetails(d.id!)}
                          className={`${c.button} ${styles.ticket_table_btn}`}
                          aria-label="edit"
                          size="medium"
                          disableTouchRipple
                          disableRipple
                          disableFocusRipple={true}
                        >
                          <BiShowAlt color="#1890ff" fontSize="22px" />
                        </IconButton>
                        <IconButton
                          onClick={() => _openModalEditTicket(d.id!)}
                          className={`${c.button} ${styles.ticket_table_btn}`}
                          aria-label="edit"
                          size="medium"
                          disableTouchRipple
                          disableRipple
                          disableFocusRipple={true}
                          disabled={d.status === 'Closed' || d.status === 'Resolved'}
                        >
                          <FaRegEdit color="#1890ff" fontSize="20px" />
                        </IconButton>
                      </Box>
                    </Td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Box>
      ) : (
        <Box className={styles.commonbox}>
          <h3 style={{ margin: 0 }}>No ticket found</h3>
        </Box>

      )
      }
      {
        data?.count !== 0 ? (
          <Pagination
            className={styles.pagination}
            count={pages}
            page={page}
            onChange={handlePageChange}
          />
        ) : null
      }
    </RWrapper >
  );
}

export default ContactHistoryScreen;
