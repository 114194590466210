import {
  Box,
  Checkbox,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import isEqual from "lodash.isequal";
import moment, { Moment } from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { Day } from ".";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import SwitchButton from "../../../componentsV3/Buttons/SwitchButton";
import CalendarBase, {
  CalendarBaseDay,
} from "../../../componentsV3/calendars/CalendarBase";
import Viewable from "../../../componentsV3/layout/Viewable";
import ModalContainer from "../../../componentsV3/modals/ModalContainer";
import ModalWindowCenter from "../../../componentsV3/modals/ModalWindowCenter";
import style from "../../../css/model.module.css";
import { Shift } from "../../RosterScreen/requests";
import ShiftCard from "./ShiftCard";

interface UserMonthlyRosterMobileProps {
  date?: Moment;
  shifts: {
    [key: string]: Array<Shift>;
  };
  today?: Day;
  selectedDay?: Day;
  setselectedDay: (d: Day | undefined) => void;
  onPrevMonthClick: () => void;
  onNextMonthClick: () => void;
  onAvailableChange: (shift: Shift) => void;
  onHandleCheckBox: (available: boolean) => void;
}

const useStyles = makeStyles((theme) => {
  return {
    calendar: {
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    calendarTitleContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      marginBottom: theme.spacing(2),
    },
    calendarTitleText: {
      fontSize: theme.typography.fontSize * 1.5,
      // fontWeight: theme.typography.fontWeightLight,
    },
    width300: {
      width: 180,
    },
    calendarTitleArrow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: 50,
      borderRadius: theme.shape.borderRadius,
      cursor: "pointer",
      transition: `all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeOut}`,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    shiftCarousel: {
      width: "100%",
      backgroundColor: "gold",
    },
    shiftDay: {
      display: "flex",
      flexDirection: "column",
      height: theme.typography.fontSize * 3,
      // backgroundColor: "gold",
      // border: "1px solid black",
      alignItems: "center",
      borderRadius: theme.shape.borderRadius,
      transition: `all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeOut}`,
    },
    currentDay: {
      backgroundColor: "rgba(5, 131, 242, 0.1)",
    },
    shiftDaySelected: {
      backgroundColor: theme.palette.grey[300],
    },
    shiftDayNumber: {
      fontSize: theme.typography.fontSize * 1.5,
      // fontWeight: theme.typography.fontWeightLight,
    },
    blueDot: {
      height: 5,
      width: 5,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
    },
    orangeDot: {
      height: 5,
      width: 5,
      borderRadius: "50%",
      backgroundColor: theme.palette.warning.main,
    },
    carouselContainer: {
      // backgroundColor: "lightgreen",
      width: "100%",
      height: "100%",
      maxHeight: 200,
      overflowX: "auto",
      display: "flex",
      flexWrap: "nowrap",
      WebkitOverflowScrolling: "touch",
      msOverflowStyle: "-ms-autohiding-scrollbar",
      scrollSnapType: "x mandatory",
      boxSizing: "border-box",
      scrollBehavior: "smooth",
    },
    carouselListItemContainer: {
      flexGrow: 0,
      flexShrink: 0,
      height: "100%",
      width: "80%",
      boxSizing: "border-box",
      // border: "1px solid black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      scrollSnapAlign: "center",
      scrollSnapStop: "always",
    },
    carouselCard: {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.grey[300]}`,
      boxSizing: "border-box",
      borderRadius: theme.shape.borderRadius,
      height: "80%",
      minHeight: 160,
      width: "95%",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
    },
    withShadow: {
      boxShadow: theme.shadows[3],
    },
    sideSpacer: { width: "10%", flexGrow: 0, flexShrink: 0 },
    carouselCardInfo: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    carouselCardInfoHalfTextContainer: {
      display: "flex",
    },
    carouselCardInfoHalfTextLeft: {
      flex: 1,
    },
    carouselCardInfoHalfTextRight: {
      flex: 2,
    },
    carouselCardText: {
      fontSize: theme.typography.fontSize * 1.2,
    },
    carouselCardTextDate: {
      marginBottom: theme.spacing(1),
    },
    carouselCardButtonHolder: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    carouselCardButtonHolderInner: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    checkboxButton: {
      justifyContent: "left",
      backgroundColor: "white",
      padding: "18px",
      width: "20px",
      "&.Mui-checked": {
        color: "black",
      },
    },
  };
});

const DayComponent = (
  props: CalendarBaseDay & {
    shifts?: Array<Shift>;
    selected?: boolean;
    current?: boolean;
    onClick: () => void;
  }
) => {
  const c = useStyles();

  const availableShift =
    props.shifts && props.shifts.find((s) => s.available) ? true : false;

  return props.type === "current" ? (
    <div
      className={`${c.shiftDay} ${props.current ? c.currentDay : ""} ${
        props.selected ? c.shiftDaySelected : ""
      }`}
      onClick={props.onClick}
    >
      <Typography color="textPrimary" className={c.shiftDayNumber}>
        {props.day.toString().padStart(2, "0")}
      </Typography>
      {props.shifts ? (
        <div className={availableShift ? c.orangeDot : c.blueDot} />
      ) : null}
    </div>
  ) : null;
};

export default function UserMonthlyRosterMobile({
  date,
  shifts,
  today,
  selectedDay,
  setselectedDay,
  onPrevMonthClick,
  onNextMonthClick,
  onAvailableChange,
  onHandleCheckBox,
}: UserMonthlyRosterMobileProps) {
  const c = useStyles();
  const history = useHistory();

  const [dialogOpen, setdialogOpen] = useState<boolean>(false);
  const [dialogShift, setdialogShift] = useState<Shift | undefined>(undefined);

  useEffect(() => {
    if (!dialogOpen) {
      setdialogShift(undefined);
    }
  }, [dialogOpen]);

  const cardRefsInitialObject: { [key: string]: any } = {};
  const cardRefs = useRef(cardRefsInitialObject);

  const [arrayOfShifts, setarrayOfShifts] = useState<Array<Shift>>([]);

  useEffect(() => {
    if (Object.keys(shifts).length) {
      const flat = Object.entries(shifts)
        .map(([k, v]) => v)
        .flat();
      setarrayOfShifts(flat);
    } else {
      setarrayOfShifts([]);
    }
  }, [shifts]);

  const handleDayClick = (d: Day) => {
    setselectedDay(d);
    if (
      shifts[
        `${d.year}.${d.month.toString().padStart(2, "0")}.${d.day
          .toString()
          .padStart(2, "0")}`
      ]
    ) {
      const { id: shiftId } =
        shifts[
          `${d.year}.${d.month.toString().padStart(2, "0")}.${d.day
            .toString()
            .padStart(2, "0")}`
        ][0];

      if (shiftId && cardRefs.current[shiftId]) {
        cardRefs.current[shiftId].scrollIntoView({
          inline: "center",
          behavior: "smooth",
        });
      }
    } else {
      if (!Object.keys(shifts).length) {
        return undefined;
      }
      const mom = `${d.year}.${d.month.toString().padStart(2, "0")}.${d.day
        .toString()
        .padStart(2, "0")}`;
      const relevantShiftDate = Object.keys(shifts).reduce((prev, next) => {
        if (next < mom) {
          return next;
        }
        return prev;
      });
      const { id: shiftId } = shifts[relevantShiftDate][0];
      if (shiftId && cardRefs.current[shiftId]) {
        cardRefs.current[shiftId].scrollIntoView({
          inline: "center",
          behavior: "smooth",
        });
      }
    }
  };

  const handleBecomeVisible = useCallback(
    (s: Shift) => {
      if (s.startDts) {
        const d = moment(s.startDts);
        const year = Number(d.format("YYYY"));
        const month = Number(d.format("M"));
        const day = Number(d.format("D"));
        const dayToSelect = { year, month, day };
        if (!isEqual(dayToSelect, selectedDay)) {
          setselectedDay(dayToSelect);
        }
      }
    },
    [selectedDay, setselectedDay]
  );

  const handleClick = async (e: any) => {
    onHandleCheckBox(e.target.checked);
  };

  return (
    <Fragment>
      {date ? (
        <div className={c.calendar}>
          <div className={c.calendarTitleContainer}>
            <div className={c.calendarTitleArrow} onClick={onPrevMonthClick}>
              <Typography className={c.calendarTitleText}>{"<"}</Typography>
            </div>
            <Typography
              className={`${c.calendarTitleText} ${c.width300}`}
              align="center"
            >
              {date.format("YYYY MMMM")}
            </Typography>
            <div className={c.calendarTitleArrow} onClick={onNextMonthClick}>
              <Typography className={c.calendarTitleText}>{">"}</Typography>
            </div>
          </div>
          <CalendarBase
            month={Number(date.format("M"))}
            year={Number(date.format("YYYY"))}
            cellComponent={(d) => (
              <DayComponent
                {...d}
                shifts={
                  shifts[
                    `${d.year}.${d.month.toString().padStart(2, "0")}.${d.day
                      .toString()
                      .padStart(2, "0")}`
                  ]
                }
                selected={isEqual(
                  { year: d.year, month: d.month, day: d.day },
                  selectedDay
                )}
                current={isEqual(
                  { year: d.year, month: d.month, day: d.day },
                  today
                )}
                onClick={() =>
                  handleDayClick({ year: d.year, month: d.month, day: d.day })
                }
              />
            )}
          />
        </div>
      ) : null}

      <div>
        <Checkbox onClick={handleClick} color="primary" />
        Show Available Shifts
      </div>

      {arrayOfShifts.length ? (
        <div className={c.carouselContainer}>
          <div className={c.sideSpacer} />
          {arrayOfShifts.map((s, i) => (
            <div
              ref={(el) => (cardRefs.current[s.id as number] = el)}
              key={s.id}
              className={c.carouselListItemContainer}
            >
              <Viewable onBecomeVisible={() => handleBecomeVisible(s)}>
                <ShiftCard shift={s}>
                  {new Date(s.startDts) > new Date() ? (
                    <div className={c.carouselCardButtonHolder}>
                      {s.own ? (
                        <div className={c.carouselCardButtonHolderInner}>
                          <SwitchButton
                            title={"Swap"}
                            onClick={() => {
                              history.push("/swap", { shift: s });
                            }}
                          />
                          <SwitchButton
                            title={
                              s.available
                                ? "Make Shift Unavailable"
                                : "Make Shift Available"
                            }
                            color={s.available ? "warning" : undefined}
                            onClick={() => onAvailableChange(s)}
                          />
                        </div>
                      ) : (
                        <SwitchButton
                          title={"Take Shift"}
                          color={s.available ? "success" : undefined}
                          onClick={() => {
                            setdialogShift(s);
                            setdialogOpen(true);
                          }}
                        />
                      )}
                    </div>
                  ) : null}
                </ShiftCard>
              </Viewable>
            </div>
          ))}
          <div className={c.sideSpacer} />
        </div>
      ) : null}

      <ModalContainer
        open={dialogShift && dialogOpen ? true : false}
        onClose={() => setdialogOpen(false)}
      >
        <ModalWindowCenter>
          <Box className={style.new_container}>
            <Box>
              <Box className={style.titlebox}>
                <Typography variant="h5" className={style.title}>
                  Accept shift?
                </Typography>
              </Box>
              <DialogContentText>
                Confirm that you want to take this shift
              </DialogContentText>
            </Box>
            <Box>
              <Box className={style.buttonsholder}>
                <Box
                  className={`${style.submitbuttonsholder} ${style.fillbtn}`}
                >
                  <Box className={style.cancelbuttonmargin}>
                    <StandardButton
                      className={"secondary"}
                      title="Close"
                      onClick={() => setdialogOpen(false)}
                    />
                  </Box>
                  <StandardButton
                    title="Confirm"
                    accent
                    onClick={async () => {
                      if (dialogShift) {
                        await onAvailableChange(dialogShift);
                        await setdialogOpen(false);
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalWindowCenter>
      </ModalContainer>
    </Fragment>
  );
}
