import React, { useState, useEffect, Fragment } from "react";
import styles from "./styles.module.css";
import PageHeader from "../../../components/PageHeader";
import StandardButton from "../../../components/Buttons/StandardButton";
import ShiftRow from "./ShiftRow";
import {
  Form,
  FieldArray,
  Field,
  Formik,
  FieldProps,
  FormikHelpers,
  getIn,
} from "formik";
import DayCheckbox from "../../../components/CheckBoxes/DayCheckbox";
import { Shift, Day } from "..";
import { hoursMinutesRegex } from "../../../GLOBALS";
import OptionsDropdownButton from "../../../components/Buttons/OptionsDropdownButton";
import Timeline24 from "../../../components/Timeline24";
import { timeOneLaterTimeTwo } from "../../../utils/time";
import MaskedInput from "react-input-mask";

interface OnSubmitValues {
  shifts: Array<Shift>;
}

type FHelpers = FormikHelpers<OnSubmitValues>;

export type OnTemplateFormSubmit = (
  values: OnSubmitValues,
  formikHelpers: FHelpers
) => void | Promise<any>;

interface TemplateFormProps {
  templateName: string;
  shifts: Array<Shift>;
  view: "week" | "day";
  onSubmit: OnTemplateFormSubmit;
}

interface ValidationFields {
  name?: string;
  start?: string;
  end?: string;
}

interface ValidationErrorsObject {
  shifts?: Array<ValidationFields>;
}

const daysArray: Array<Day> = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const newShift = (day?: Day): Shift => ({
  name: "",
  oncall: false,
  start: "",
  end: "",
  duration: 0,
  monday: day === "monday",
  tuesday: day === "tuesday",
  wednesday: day === "wednesday",
  thursday: day === "thursday",
  friday: day === "friday",
  saturday: day === "saturday",
  sunday: day === "sunday",
});

const validation = ({ shifts }: OnSubmitValues): ValidationErrorsObject => {
  let errors: ValidationErrorsObject = {};

  const insertError = (idx: number, value: { [key: string]: string }) => {
    if (!errors.shifts) errors.shifts = [];
    errors.shifts[idx] = { ...errors.shifts[idx], ...value };
  };

  if (shifts)
    shifts.forEach((shift: Shift, index: number) => {
      if (!shift.name) insertError(index, { name: "required" });
      if (!hoursMinutesRegex.test(shift.start))
        insertError(index, { start: "wrong format" });
      if (!hoursMinutesRegex.test(shift.end))
        insertError(index, { end: "wrong format" });
      if (shift.start === shift.end)
        insertError(index, { end: "same as start" });
    });
  return errors;
};

export default ({
  templateName,
  shifts,
  view,
  onSubmit,
}: TemplateFormProps) => {
  const [currentDay, setcurrentDay] = useState<Day>("monday");
  const [previousDay, setpreviousDay] = useState<Day>("sunday");

  useEffect(() => {
    setpreviousDay(_previousDay(currentDay));
  }, [currentDay]);

  const _previousDay = (curDay: Day): Day => {
    const i: number = daysArray.indexOf(curDay) - 1;
    return i < 0 ? daysArray[daysArray.length - 1] : daysArray[i];
  };

  return (
    <div className={styles.container}>
      <Formik
        initialValues={{ shifts }}
        validate={validation}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          dirty,
          errors,
          touched,
          isValid,
          isSubmitting,
          resetForm,
          validateForm,
          handleBlur,
          handleChange,
        }) => {
          return (
            <Form className={styles.form}>
              <FieldArray name="shifts">
                {({ push, remove }) => (
                  <Fragment>
                    <PageHeader title={templateName}>
                      <div className={styles.topbar}>
                        {view === "day" && (
                          <div className={styles.headerdayscontainer}>
                            {daysArray.map((day: Day) => (
                              <div key={day} className={styles.headerdaybutton}>
                                <DayCheckbox
                                  title={day.charAt(0).toUpperCase()}
                                  value={currentDay === day}
                                  onClick={() => {
                                    setcurrentDay(day);
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        <StandardButton
                          title="Add shift +"
                          onClick={async () => {
                            await push(
                              newShift(view === "week" ? undefined : currentDay)
                            );
                            await validateForm();
                          }}
                        />
                      </div>
                    </PageHeader>
                    {values.shifts && values.shifts.length > 0 && (
                      <div
                        className={styles.table}
                        role="table"
                        aria-label="shifts"
                      >
                        <ShiftRow
                          cell1="Shift Name"
                          cell2="On Call"
                          cell3="Start"
                          cell4="End"
                          cell5={
                            view === "week" ? (
                              "Days"
                            ) : (
                              <div className={styles.hourscontainer}>
                                {Array.from(Array(24).keys()).map((item) => (
                                  <div key={item} className={styles.hourblock}>
                                    {`${item}`.padStart(2, "0")}
                                  </div>
                                ))}
                                <div className={styles.filler20} />
                              </div>
                            )
                          }
                        />
                        <div className={styles.line} />
                      </div>
                    )}
                    {values.shifts &&
                      values.shifts.map((shift, index) => {
                        const hideDay = () => {
                          let twodays: boolean = true;
                          try {
                            twodays = timeOneLaterTimeTwo(
                              shift.start,
                              shift.end
                            );
                          } catch (error) {
                            console.log("invalid time");
                          }
                          return (
                            !shift[currentDay] &&
                            (!shift[previousDay] || !twodays)
                          );
                        };

                        return view === "day" && hideDay() ? null : (
                          <ShiftRow
                            key={index}
                            cell1={
                              <div
                                className={`${styles.fieldouter} ${
                                  getIn(touched, `shifts.${index}.name`) &&
                                  getIn(errors, `shifts.${index}.name`) &&
                                  styles.higlighted
                                }`}
                              >
                                <Field
                                  name={`shifts.${index}.name`}
                                  className={`${styles.field} ${
                                    !shift.name && styles.fieldborder
                                  }`}
                                />
                              </div>
                            }
                            cell2={
                              <div className={`${styles.fieldouter}`}>
                                <Field name={`shifts.${index}.oncall`}>
                                  {({ field, form }: FieldProps) => {
                                    const { setFieldValue } = form;
                                    const { value } = field;
                                    return (
                                      <div
                                        className={`${styles.field} ${
                                          !value && styles.fieldborder
                                        } ${styles.square} ${
                                          value && styles.blue
                                        }`}
                                        onClick={() => {
                                          setFieldValue(
                                            `shifts.${index}.oncall`,
                                            !value
                                          );
                                        }}
                                      />
                                    );
                                  }}
                                </Field>
                              </div>
                            }
                            cell3={
                              <div
                                className={`${styles.fieldouter} ${
                                  getIn(touched, `shifts.${index}.start`) &&
                                  getIn(errors, `shifts.${index}.start`) &&
                                  styles.higlighted
                                }`}
                              >
                                <Field
                                  name={`shifts.${index}.start`}
                                  // className={`${styles.field} ${
                                  //   !shift.start && styles.fieldborder
                                  // }`}
                                >
                                  {({ field }: FieldProps) => (
                                    <MaskedInput
                                      {...field}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      mask="99:99"
                                      placeholder="00:00"
                                      className={`${styles.field} ${
                                        !shift.start && styles.fieldborder
                                      }`}
                                    />
                                  )}
                                </Field>
                              </div>
                            }
                            cell4={
                              <div
                                className={`${styles.fieldouter} ${
                                  getIn(touched, `shifts.${index}.end`) &&
                                  getIn(errors, `shifts.${index}.end`) &&
                                  styles.higlighted
                                }`}
                              >
                                <Field
                                  name={`shifts.${index}.end`}
                                  className={`${styles.field} ${
                                    !shift.end && styles.fieldborder
                                  }`}
                                >
                                  {({ field }: FieldProps) => (
                                    <MaskedInput
                                      {...field}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      mask="99:99"
                                      placeholder="00:00"
                                      className={`${styles.field} ${
                                        !shift.end && styles.fieldborder
                                      }`}
                                    />
                                  )}
                                </Field>
                              </div>
                            }
                            cell5={
                              <div className={`${styles.daybuttonsholder}`}>
                                {view === "day" ? (
                                  <Timeline24
                                    time={{
                                      start: shift.start,
                                      end: shift.end,
                                    }}
                                    previous={shift[previousDay]}
                                    current={shift[currentDay]}
                                  />
                                ) : (
                                  daysArray.map((day) => (
                                    <Field
                                      key={`${index}-${day}`}
                                      name={`shifts.${index}.${day}`}
                                    >
                                      {({ field, form }: FieldProps) => {
                                        const { setFieldValue } = form;
                                        return (
                                          <div
                                            className={`${styles.daybuttoncontainer}`}
                                          >
                                            <DayCheckbox
                                              title={day
                                                .charAt(0)
                                                .toUpperCase()}
                                              value={field.value}
                                              onClick={(v) => {
                                                setFieldValue(
                                                  `shifts.${index}.${day}`,
                                                  v
                                                );
                                              }}
                                            />
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  ))
                                )}
                                <OptionsDropdownButton
                                  options={[
                                    {
                                      title: "Delete",
                                      color: "var(--deep-red)",
                                      act: async () => {
                                        await remove(index);
                                        await validateForm();
                                      },
                                    },
                                  ]}
                                />
                              </div>
                            }
                          />
                        );
                      })}
                    <div className={styles.submitbuttonholder}>
                      <div className={styles.submitbuttoncontainer}>
                        <StandardButton
                          title="Cancel"
                          // height="short"
                          // width="9em"
                          onClick={() => resetForm()}
                        />
                      </div>
                      <div className={styles.submitbuttoncontainer}>
                        <StandardButton
                          title="Save Template"
                          // height="short"
                          accent
                          // width="9em"
                          type="submit"
                          disabled={!dirty || !isValid || isSubmitting}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
