import { Box, Grid, InputLabel, Typography } from "@material-ui/core";
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from "formik";
import React from "react";
import { pastelPaletteV3 } from "../../../../GLOBALS";
import ButtonToggle from "../../../../componentsV3/Buttons/ButtonToggle";
import StandardButton from "../../../../componentsV3/Buttons/StandardButton";
import TextInputFormik from "../../../../componentsV3/inputs/TextInputFormik";
import ColorPickerPalette from "../../../../componentsV3/inputs/colorPickers/ColorPickerPalette";
import ModalWindowCenter from "../../../../componentsV3/modals/ModalWindowCenter";
import styles from "../../../../css/model.module.css";
import { UserType } from "../../../StaffScreen";

interface EditRosterModalInnerSubmitValues {
  name: string;
  userTypes: Array<UserTypeSelected>;
  color: string;
}

export interface EditRosterModalSubmitValues {
  name: string;
  userTypeIDs: Array<number>;
  color: string;
}

interface UserTypeSelected extends UserType {
  selected?: boolean;
}

interface EditRosterModalProps {
  onSubmit: (values: EditRosterModalSubmitValues) => void;
  onCancel: () => void;
  selectedRoster: any;
  userTypes?: Array<UserTypeSelected>;
}

interface ValidationProps {
  name: string;
  userTypes?: Array<UserTypeSelected>;
}

interface ValidationErrors {
  [key: string]: string;
}

const validation = ({ name, userTypes }: ValidationProps) => {
  try {
    const errors: ValidationErrors = {};
    if (!name) {
      errors.name = "Location name can not be empty";
    }
    const userTypeSelected = userTypes?.find((ut) => ut.selected);
    if (!userTypeSelected) errors.userTypes = "Select user type";
    return errors;
  } catch (error) {
    console.error(error);
  }
};

export default ({
  onSubmit,
  onCancel,
  userTypes = [],
  selectedRoster,
}: EditRosterModalProps) => {
  const submit = async (values: EditRosterModalInnerSubmitValues) => {
    const filtered = values.userTypes.filter((ut) => ut.selected);
    const uts = filtered.map((ut) => ut.id);
    const v: EditRosterModalSubmitValues = {
      name: values.name,
      userTypeIDs: uts,
      color: values.color,
    };
    return onSubmit(v);
  };

  return (
    <ModalWindowCenter>
      <Formik
        initialValues={{
          name: selectedRoster.name,
          userTypes: userTypes,
          color: selectedRoster.color,
        }}
        validate={validation}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          touched,
          isSubmitting,
        }: FormikProps<any>) => (
          <Form noValidate autoComplete="off">
            <Box className={styles.new_container}>
              <Box>
                <Box className={styles.titlebox}>
                  <Typography className={styles.title} variant="h5">
                    Edit Roster
                  </Typography>
                </Box>
                <InputLabel color="primary" className={styles.label}>
                  Roster name
                </InputLabel>
                <Field
                  name="name"
                  component={TextInputFormik}
                  placeholder="Rooster name"
                  type="text"
                />
                <InputLabel color="primary" className={styles.label}>
                  Position
                </InputLabel>
                {values.userTypes && values.userTypes.length ? (
                  <Grid
                    container
                    spacing={1}
                    className={styles.switchbuttonsgrid}
                  >
                    <FieldArray
                      name="userTypes"
                      render={() =>
                        values.userTypes.map(
                          (ut: UserTypeSelected, index: number) => (
                            <Grid item xs={12} sm={6} key={ut.id}>
                              <Field name={ut.id} value={ut.selected}>
                                {({ form: { setFieldValue } }: FieldProps) => (
                                  <ButtonToggle
                                    title={ut.name}
                                    active={ut.selected}
                                    selectedActive={
                                      selectedRoster.userTypes[0].id === ut.id
                                    }
                                    className={styles.buttonToggle}
                                    onClick={() =>
                                      setFieldValue(
                                        `userTypes.${index}.selected`,
                                        !ut.selected
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </Grid>
                          )
                        )
                      }
                    />
                  </Grid>
                ) : null}

                <div>
                  <InputLabel color="primary" className={styles.label}>
                    Color
                  </InputLabel>
                  <ColorPickerPalette
                    name="color"
                    colors={selectedRoster.color}
                    backgrounds={pastelPaletteV3}
                    selectedColor={selectedRoster.color}
                  />
                </div>
              </Box>

              <Box>
                <div className={styles.buttonsholder}>
                  <div
                    className={`${styles.submitbuttonsholder} ${styles.fillbtn}`}
                  >
                    <div className={styles.cancelbuttonmargin}>
                      <StandardButton
                        className={"secondary"}
                        title="Cancel"
                        onClick={onCancel}
                      />
                    </div>
                    <StandardButton
                      title="Save"
                      type="submit"
                      accent
                      disabled={isSubmitting || !dirty}
                    />
                  </div>
                </div>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </ModalWindowCenter>
  );
};
