import React, { useState, useCallback } from "react";
import { ClickAwayListener } from "@material-ui/core";
import styled from "styled-components";
import icon from "./newdropdown-icon.svg";
import styles from  "../../css/style.module.css";
import { Box } from "@material-ui/core";

const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

const Icon = styled.img`
  height: 50%;
`;

export interface SelectedOption<T> {
  title: string;
  value?: T;
}
interface ItemProps {
  title: string;
  value?: number | string;
}
interface DropdownProps {
  options: Array<ItemProps>;
  textShown?: string;
  onSelect: (value: ItemProps) => void;
}
export default function Dropdown({
  options,
  textShown,
  onSelect,
}: DropdownProps) {
  const [open, setOpen] = useState<boolean>(false);

  const select = useCallback(
    (value: ItemProps) => {
      onSelect(value);
      setOpen(false);
    },
    [onSelect]
  );

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container className={styles.dropdownbtn}>
        <Box onClick={() => setOpen(true)} className={styles.dropdowncontainer}>
          <Box className={styles.dropdowntext}>{textShown}</Box>
          <Icon  src={icon} alt="dropdown-triangle" />
        </Box>
        {open && (
          <Box className={styles.dropdowncard}>
            {options.length ? (
              options.map((o) => (
                <Box key={o.value} onClick={() => select(o)} className={styles.item}>
                  {o.title}
                </Box>
              ))
            ) : (
              <Box>No options available</Box>
            )}
          </Box>
        )}
      </Container>
    </ClickAwayListener>
  );
}
