import React from "react";
import styles from "./styles.module.css";

interface PageHeaderProps {
  title?: string;
  children?: JSX.Element;
  size?: "default" | "small" | "large"
}

export default ({ title = "", children, size }: PageHeaderProps) => {
  return (
    <div className={`${styles.outercontainer} `}>
      <div className={styles.innercontainer}>
        {title && (
          <div className={styles.titleholder}>
            <span className={styles.title}>{title}</span>
          </div>
        )}
        <div className={styles.contentholder}>{children}</div>
      </div>
    </div>
  );
};
