import React from "react";
import { Formik, Field, Form } from "formik";
import style from "../../../../css/style.module.css";
import { motion, AnimatePresence } from "framer-motion";
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export interface ChangePasswordSubmitValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface FormProps {
  onSubmit: ({
    oldPassword,
    newPassword,
    confirmPassword,
  }: ChangePasswordSubmitValues) => void;
  externalError: string;
  //   history: History;
}

const validation = ({
  oldPassword,
  newPassword,
  confirmPassword,
}: {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  passwordNotMatched?: string;
}) => {
  const errors: { [key: string]: string } = {};
  if (!oldPassword) {
    errors.oldPassword = "Old Password is required";
  } else if (oldPassword.length < 6) {
    errors.oldPassword = "Old Password is too short";
  } else if (!newPassword) {
    errors.newPassword = "New Password is required";
  } else if (newPassword.length < 6) {
    errors.newPassword = "New Password is too short";
  } else if (!confirmPassword) {
    errors.confirmPassword = "Confirm Password is required";
  } else if (confirmPassword.length < 6) {
    errors.confirmPassword = "Confirm Password is too short";
  } else if (newPassword !== confirmPassword) {
    errors.passwordNotMatched =
      "New Passwod and Confirm Password must be matched!";
  }
  return errors;
};

export default ({ onSubmit, externalError }: FormProps) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validate={validation}
          onSubmit={onSubmit}
        >
          {({ errors, isValid, dirty, touched, isSubmitting }) => (
            <Form className={style.form} noValidate>
              <Box className={style.changepassword}>
              <Box>
              <div
                className={`${
                  externalError ||
                  (errors.oldPassword && touched.oldPassword
                    ? style.erropacity1
                    : "") ||
                  (errors.newPassword && touched.newPassword
                    ? style.erropacity1
                    : "") ||
                  (errors.confirmPassword && touched.confirmPassword
                    ? style.erropacity1
                    : "") ||
                  (errors.passwordNotMatched && touched.passwordNotMatched
                    ? style.erropacity1
                    : "")
                }`}
              >
                <span className={style.errortext}>
                  {externalError ? (
                    <Alert severity="error" style={{ marginBottom: "13px" }}>
                      {externalError}
                    </Alert>
                  ) : (
                    ""
                  )}
                  {errors.oldPassword ? (
                    <Alert severity="error" style={{ marginBottom: "13px" }}>
                      {errors.oldPassword}
                    </Alert>
                  ) : (
                    ""
                  )}
                  {errors.newPassword ? (
                    <Alert severity="error" style={{ marginBottom: "13px" }}>
                      {errors.newPassword}
                    </Alert>
                  ) : (
                    ""
                  )}
                  {errors.confirmPassword ? (
                    <Alert severity="error" style={{ marginBottom: "13px" }}>
                      {errors.confirmPassword}
                    </Alert>
                  ) : (
                    ""
                  )}
                  {errors.passwordNotMatched ? (
                    <Alert severity="error" style={{ marginBottom: "13px" }}>
                      {errors.passwordNotMatched}
                    </Alert>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              </Box>
              <Box>
              <label htmlFor="Old Password">Old Password</label>
              <Field
                name="oldPassword"
                type="password"
                className={style.input}
                placeholder="Old Password"
                autoCorrect="off"
                autoCapitalize="none"
              />
              </Box>
              <Box>
              <label htmlFor="New Password">New Password</label>
              <Field
                  name="newPassword"
                  type="password"
                  placeholder="New Password"
                  className={style.input}
                  autoCorrect="off"
                  autoCapitalize="none"
              />
              </Box>
              <Box>
              <label htmlFor="ConfirmPassword">Confirm Password</label>
              <Field
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                className={style.input}
                autoCorrect="off"
                autoCapitalize="none"
              />
              </Box>

              <div className={style.fillbtn}>
                <motion.button style={{background:'#1890FF',width:'auto'}}
                  type="submit"
                  className={style.button}
                  disabled={isSubmitting || !dirty || !isValid}
                >
                  <span className={style.buttonText}>Submit</span>
                </motion.button>
              </div>
              </Box>
            </Form>
          )}
        </Formik>
      </motion.div>
    </AnimatePresence>
  );
};
