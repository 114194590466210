import React, { useState, useEffect, useCallback } from "react";
import styles from "./styles.module.css";
import PageHeader from "../../components/PageHeader";
import StandardButton from "../../components/Buttons/StandardButton";
import FloatingButton from "../../components/Buttons/FloatingButton";
import ModalContainer from "../../components/modals/ModalContainer";
import AddUserTypeModalWindow, {
  AddUserTypeModalSubmitValues,
} from "./AddUserTypeModalWindow";
import {
  requestPostUserType,
  requestGetUserTypes,
  requestGetUsers,
} from "./requests";
import { Grid, Paper, Typography, Divider, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { RWrapper } from "../../components/layout/RWrapper";
import { useProgressBar } from "../../components/bars/ProgressBarGlobal";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import { RectangleBadgeFilled } from "../../components/styled-components/badges";
import { styledTheme } from "../../themes/styledTheme";
import SearchBar from "material-ui-search-bar";
export interface UserType {
  id: number;
  name: string;
  description?: string;
  tenantId?: number;
}
export interface ReportTo {
  id: number;
  fullName: string;
}
export interface Tenants {
  name: string;
  description?: string;
  companyLogo?: string;
  id?: number;
}
export interface UserLocation {
  id: number;
  userId?: number;
  locationId: number;
  name: string;
}
export interface User {
  id?: number;
  userType?: UserType;
  reportTo?: any;
  userLocation?: UserLocation;
  tenants?: Tenants[];
  title: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  mobileCode?: number;
  mobileNumber?: number;
  mobileFull?: string;
  isAdministrator: boolean;
  deletedAt?: string;
  themeVersion?: string;
  TwoFAEnabled?: boolean;
  TwoFAAuthenticated?: boolean;
}

const AdminMark = styled.div<{ active?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  box-sizing: border-box;
  border: ${({ active }) => (active ? "none" : "1px solid #d1d1d1")};
  background-color: ${({ active }) => (active ? "#0583f2" : "transparent")};
`;

export default () => {
  const history = useHistory();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>("");

  const [userTypes, setUserTypes] = useState<Array<UserType>>([]);
  const [users, setUsers] = useState<Array<User>>([]);
  const [currentUserType, setCurrentUserType] = useState<
    number | "admin" | undefined
  >(undefined);
  const [searchedRows, setsearchedRows] = useState<Array<User>>(users);
  const [searched, setSearched] = useState<string>("");

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (apiError)
      timeout = setTimeout(() => {
        setApiError("");
      }, 2000);
    return () => clearTimeout(timeout);
  }, [apiError]);

  const retrieveUserTypes = useCallback(async () => {
    try {
      barActivate();
      const ut = await requestGetUserTypes();
      setUserTypes(ut);
    } catch (err) {
      enqueueSnackbar("Unable to get user types", { variant: "error" });
    } finally {
      barStop();
    }
  }, [barActivate, barStop, enqueueSnackbar]);

  useEffect(() => {
    retrieveUserTypes();
  }, [retrieveUserTypes]);

  const retrieveUsers = useCallback(
    async (userType?: number | "admin") => {
      try {
        barActivate();
        const ut = await requestGetUsers(userType);
        const trueFirst = ut.sort(
          (a, b) => Number(b.isAdministrator) - Number(a.isAdministrator)
        );
        setUsers(trueFirst);
        setsearchedRows(ut);
      } catch (err) {
        enqueueSnackbar("Unable to get users", { variant: "error" });
      } finally {
        barStop();
      }
    },
    [barActivate, barStop, enqueueSnackbar]
  );

  useEffect(() => {
    retrieveUsers(currentUserType);
  }, [currentUserType, retrieveUsers]);

  const _openModal = () => {
    setModalOpen(true);
  };

  const _closeModal = () => {
    setModalOpen(false);
  };

  const _submitUserType = async (values: AddUserTypeModalSubmitValues) => {
    try {
      barActivate();
      const newType = await requestPostUserType(
        values.typeName,
        values.description
      );
      setUserTypes([...userTypes, newType]);
      _closeModal();
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setApiError(`User type "${values.typeName}" already exists`);
      } else {
        console.error(error);
      }
    } finally {
      barStop();
    }
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = searchedRows.filter((row) => {
      return (
        (row.firstName &&
          row.firstName.toLowerCase().includes(searchedVal.toLowerCase())) ||
        (row.lastName &&
          row.lastName.toLowerCase().includes(searchedVal.toLowerCase())) ||
        (row.email &&
          row.email.toLowerCase().includes(searchedVal.toLowerCase())) ||
        (row.title &&
          row.title.toLowerCase().includes(searchedVal.toLowerCase()))
      );
    });
    setUsers(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  return (
    <RWrapper>
      <Box className={styles.pagetitlebox}>
        <Typography variant="h4" color="textPrimary">
          Staff Profiles
        </Typography>
        <Box m={0} className={styles.pagetitlebox}>
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
          />
        </Box>
      </Box>
      <Divider />
      <PageHeader>
        <div className={styles.headercontentcontainer}>
          <div className={styles.usertypesholder}>
            <div className={styles.usertypebuttonwrapper}>
              <FloatingButton
                title="All"
                active={currentUserType === undefined}
                width="3em"
                onClick={() => {
                  setCurrentUserType(undefined);
                }}
              />
            </div>
            <div className={styles.usertypebuttonwrapper}>
              <FloatingButton
                title="Admin"
                active={currentUserType === "admin"}
                width="3em"
                onClick={() => {
                  setCurrentUserType("admin");
                }}
              />
            </div>
            {userTypes.map((t) => (
              <div className={styles.usertypebuttonwrapper} key={t.id}>
                <FloatingButton
                  title={t.name}
                  active={t.id === currentUserType}
                  width="3em"
                  onClick={() => {
                    setCurrentUserType(t.id);
                  }}
                />
              </div>
            ))}
            <FloatingButton
              title={userTypes.length ? "Add type +" : "Add user types +"}
              active={false}
              onClick={_openModal}
            />
          </div>
          <StandardButton
            title="Add Staff +"
            onClick={() => history.push("/staff/e")}
          />
        </div>
      </PageHeader>
      <ModalContainer onClose={_closeModal} open={modalOpen}>
        <AddUserTypeModalWindow
          onSubmit={_submitUserType}
          onCancel={_closeModal}
          apiError={apiError}
        />
      </ModalContainer>
      <Grid container className={styles.userrowcontainer}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Typography variant="body2" color="textPrimary">
            Name
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Typography variant="body2" color="textPrimary">
            Email
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2} lg={2}>
          <Typography variant="body2" color="textPrimary">
            Employee Type
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Typography variant="body2" color="textPrimary">
            Mobile
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={1}>
          <Typography variant="body2" color="textPrimary">
            Admin
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={1}>
          <Typography variant="body2" color="textPrimary">
            Status
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      {users.map((user) => (
        <Paper
          elevation={0}
          square
          key={user.id}
          className={styles.userpaper}
          onClick={() => history.push(`/staff/e/${user.id}`)}
        >
          <Grid container className={styles.userrowcontainer}>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Typography>
                {user.title ? `${user.title} ` : ""}
                {user.firstName} {user.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Typography>{user.email}</Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={2} lg={2}>
              <Typography>{user.userType && user.userType.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Typography>{user.mobileFull}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1}>
              <AdminMark active={user.isAdministrator} />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1}>
              <RectangleBadgeFilled
                width="100px"
                backgroundColor={
                  user.deletedAt ? styledTheme.colors.deepRed : "green"
                }
              >
                {user.deletedAt ? "Deactivated" : "Active"}
              </RectangleBadgeFilled>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </RWrapper>
  );
};
