import React, { useState, useCallback } from "react";
import { ClickAwayListener } from "@material-ui/core";
import styled from "styled-components";
import icon from "./dropdown-icon.svg";

const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

const Input = styled.div`
  height: 25px;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  padding: 4px 7px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.img`
  height: 50%;
`;

const Drop = styled.div`
  position: absolute;
  width: 100%;
  max-height: 200px;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  z-index: 400;
  box-sizing: border-box;
  background-color: #fff;
`;

const Item = styled.div<{ capitalize?: boolean }>`
  font-size: 12px;
  padding: 2px 7px;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  text-transform: ${({ capitalize }) => (capitalize ? "capitalize" : "none")};
  &:hover {
    background-color: #efefef;
  }
`;

const Text = styled.span<{ capitalize?: boolean }>`
  text-transform: ${({ capitalize }) => (capitalize ? "capitalize" : "none")};
  font-size: 12px;
`;

export interface SelectedOption<T> {
  title: string;
  value?: T;
}
interface ItemProps {
  title: string;
  value?: number | string;
}
interface DropdownProps {
  options: Array<ItemProps>;
  textShown?: string;
  onSelect: (value: ItemProps) => void;
}
export default function Dropdown({
  options,
  textShown,
  onSelect,
}: DropdownProps) {
  const [open, setOpen] = useState<boolean>(false);

  const select = useCallback(
    (value: ItemProps) => {
      onSelect(value);
      setOpen(false);
    },
    [onSelect]
  );

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container>
        <Input onClick={() => setOpen(true)}>
          <Text capitalize>{textShown}</Text>
          <Icon src={icon} alt="dropdown-triangle" />
        </Input>
        {open && (
          <Drop>
            {options.length ? (
              options.map((o) => (
                <Item key={o.value} onClick={() => select(o)} capitalize>
                  {o.title}
                </Item>
              ))
            ) : (
              <Item>No options available</Item>
            )}
          </Drop>
        )}
      </Container>
    </ClickAwayListener>
  );
}
