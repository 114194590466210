import React from "react";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Grow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
} from "@material-ui/core";
import styled from "styled-components";
import zIndex from "@material-ui/core/styles/zIndex";
import { ExitToApp } from "@material-ui/icons";
import { useProgressBar } from "../bars/ProgressBarGlobal";
import { deleteLogout } from "../../requests/logout";
import { useHistory } from "react-router-dom";
import { deepOrange } from "@material-ui/core/colors";
import { RiKeyLine } from 'react-icons/ri';

const DropdownPaper = styled(Paper)`
  width: fit-content;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 40px;
`;

const useStyles = makeStyles((theme) => {
  return {
    logo: {
      height: "100%",
      objectFit: "contain",
    },
    title:{ 
      '& .MuiTypography-root':{
        fontWeight:600,
      }
    },
    navlinkContainer: {
      display: "flex",
      alignItems: "center",
      color: '#282828',
      cursor: "pointer",
    },
    iconContainer: {
      borderRadius: theme.shape.borderRadius,
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: `background-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeOut}`,
    },
    icon: {
      height: "60%",
    },
  };
});

const UserAvatar = styled(Avatar)`
  height:38px;
  width:38px;
  margin:0;
  background-color: #46c438;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
`;
interface UserMobileDropdownProps {
  userName: string;
  userFullName: string;
  userRole: string;
}
export const UserMobileDropdown: React.FC<UserMobileDropdownProps> = ({
  userName,
  userFullName,
  userRole,
}) => {
  const c = useStyles();
  const { barActivate, barStop } = useProgressBar();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

  const onOpen = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(currentTarget),
    []
  );

  const onClose = React.useCallback(() => setAnchorEl(undefined), []);

  async function logout() {
    try {
      barActivate();
      const rt = localStorage.getItem("rt");
      if (rt) {
        await deleteLogout(rt);
      }
    } catch (err) {
    } finally {
      localStorage.clear();
      history.replace("/login");
      barStop();
    }
  }
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: deepOrange[300],
      },
      children: name,
    };
  }

  return (
    <>
      <Box className={c.navlinkContainer}>
        <Box className={c.iconContainer} onClick={onOpen}>
          <UserAvatar
            {...stringAvatar(userName.toLocaleUpperCase())}
            style={{
              height: "40px",
              width: "40px",
              color:"#fff",
            }}
          />
        </Box>
      </Box>
      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        placement="bottom-end"
        transition
        style={{ zIndex: zIndex.tooltip }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <DropdownPaper>
              <ClickAwayListener onClickAway={onClose}>
                <List dense>
                  <ListItem>
                    <ListItemText className={c.title}>{userFullName}</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText className={c.title}>{userRole}</ListItemText>
                  </ListItem>
                  <ListItem button onClick={() => history.push("user/change-password")}>
                    <StyledListItemIcon>
                      <RiKeyLine />
                    </StyledListItemIcon>
                    <ListItemText primary="Change password" />
                  </ListItem>
                  <ListItem button onClick={logout}>
                    <StyledListItemIcon>
                    <ExitToApp />
                    </StyledListItemIcon>
                    <ListItemText primary="Log out" />
                  </ListItem>
                </List>
              </ClickAwayListener>
            </DropdownPaper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
