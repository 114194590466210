import { ThemeProvider } from "@material-ui/core/styles";
import { decode } from "jsonwebtoken";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import ProgressBarGlobalProvider from "../components/bars/ProgressBarGlobal";
import SnackbarProviderCustomized from "../components/bars/SnackbarProviderCustomized";
import NavContainer from "../components/containers/NavContainer";
import SideNavAdmin from "../components/nav/SideNavAdmin";
import SideNavUser from "../components/nav/SideNavUser";
import UserDetailContextProvider from "../context/userDetailProvider";
import UserSelectionsContextProvider from "../context/userSelectionsProvider";
import ReportScreen from "../screens/ReportScreen";
import RosterScreen from "../screens/RosterScreen";
import ShiftScreen from "../screens/ShiftScreen";
import StaffEditScreen from "../screens/StaffEditScreen";
import StaffScreen from "../screens/StaffScreen";
import TemplateBuilder from "../screens/TemplateBuilder";
import Templates from "../screens/Templates";
import SentEmailsScreen from "../screens/adminView/ListEmail";
import AuditHistoryScreen from "../screens/adminView/auditHistory";
import ScheduledSmsScreen from "../screens/adminView/scheduledSms";
import SettingsScreen from "../screens/adminView/settings";
import SwapOffersScreen from "../screens/adminView/swapOffersScreen";
import ChangePasswordScreen from "../screens/userView/ChangePassword";
import UserAccountScreen from "../screens/userView/UserAccountScreen";
import UserMonthlyRoster from "../screens/userView/UserMonthlyRoster";
import UserRequestSwapScreen from "../screens/userView/UserRequestSwapScreen";
import UserRequestsScreen from "../screens/userView/UserRequestsScreen";
import UserSwapScreen from "../screens/userView/UserSwapScreen";
import mainTheme from "../themes/mainTheme";
import { styledTheme } from "../themes/styledTheme";
import mainThemeV3 from "../themesV3/mainTheme";
import { styledTheme as styledThemeV3 } from "../themesV3/styledTheme";

import NavContainerV3 from "../componentsV3/containers/NavContainer";
import SideNavAdminV3 from "../componentsV3/nav/SideNavAdmin";
import SideNavUserV3 from "../componentsV3/nav/SideNavUser";
import { useThemeVersion } from "../context/ThemeVersionProvider";
import ResetPasswordV3 from "../screensV3/ForgotPassword";
import LoginV3 from "../screensV3/Login";
import ReportScreenV3 from "../screensV3/ReportScreen";
import RosterScreenV3 from "../screensV3/RosterScreen";
import ShiftScreenV3 from "../screensV3/ShiftScreen";
import StaffEditScreenV3 from "../screensV3/StaffEditScreen";
import StaffScreenV3 from "../screensV3/StaffScreen";
import TemplateBuilderV3 from "../screensV3/TemplateBuilder";
import TemplatesV3 from "../screensV3/Templates";
import SentEmailsScreenV3 from "../screensV3/adminView/ListEmail";
import AuditHistoryScreenV3 from "../screensV3/adminView/auditHistory";
import ContactHistoryScreenV3 from "../screensV3/adminView/contactHistory";
import ScheduledSmsScreenV3 from "../screensV3/adminView/scheduledSms";
import SettingsScreenV3 from "../screensV3/adminView/settings";
import SwapOffersScreenV3 from "../screensV3/adminView/swapOffersScreen";
import ChangePasswordScreenV3 from "../screensV3/userView/ChangePassword";
import UserAccountScreenV3 from "../screensV3/userView/UserAccountScreen";
import UserMonthlyRosterV3 from "../screensV3/userView/UserMonthlyRoster";
import UserRequestSwapScreenV3 from "../screensV3/userView/UserRequestSwapScreen";
import UserRequestsScreenV3 from "../screensV3/userView/UserRequestsScreen";
import UserSwapScreenV3 from "../screensV3/userView/UserSwapScreen";
import ArchiveRoster from "../screensV3/RosterScreen/ArchiveRoster";

interface ProtectedRouteProps extends RouteProps {
  adminComponent: JSX.Element | null;
  userComponent: JSX.Element | null;
}

const ProtectedRoute = ({
  adminComponent,
  userComponent,
  ...rest
}: ProtectedRouteProps) => {
  const { roosterVersionV3 } = useThemeVersion();

  const token: string | null = localStorage.getItem("token");
  const decoded = token ? decode(token, { complete: true }) : undefined;
  const userId = decoded ? (decoded as any).payload.bird : undefined;
  const isAdmin = decoded ? (decoded as any).payload.isa : false;
  // const tenantId = decoded ? (decoded as any).payload.nest : undefined;
  const verified: boolean = localStorage.getItem("isVerified") === "true";

  if (userId && isAdmin && verified) {
    return (
      <Route {...rest}>
        {roosterVersionV3 ? (
          <NavContainerV3 sideNav={<SideNavAdminV3 />}>
            {adminComponent || <></>}
          </NavContainerV3>
        ) : (
          <NavContainer sideNav={<SideNavAdmin />}>
            {adminComponent || <></>}
          </NavContainer>
        )}
      </Route>
    );
  }
  if (userId && isAdmin === false && verified) {
    return (
      <Route {...rest}>
        {roosterVersionV3 ? (
          <NavContainerV3 sideNav={<SideNavUserV3 />}>
            {userComponent || <></>}
          </NavContainerV3>
        ) : (
          <NavContainer sideNav={<SideNavUser />}>
            {userComponent || <></>}
          </NavContainer>
        )}
      </Route>
    );
  }
  return <Redirect to="/login" />;
};

const queryClient = new QueryClient();

export default () => {
  const { roosterVersionV3 } = useThemeVersion();
  return (
    <div className={`roos ${roosterVersionV3 ? "v3" : ""}`}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={roosterVersionV3 ? mainThemeV3 : mainTheme}>
          <StyledThemeProvider
            theme={roosterVersionV3 ? styledThemeV3 : styledTheme}
          >
            <SnackbarProviderCustomized>
              <ProgressBarGlobalProvider>
                <UserDetailContextProvider>
                  <UserSelectionsContextProvider>
                    <BrowserRouter>
                      {roosterVersionV3 ? (
                        <Switch>
                          <Route exact path="/login" component={LoginV3} />
                          {/* <Route exact path="/2FA" component={TwoFactor} /> */}
                          <Route
                            exact
                            path="/forgot-password"
                            component={ResetPasswordV3}
                          />
                          <Route
                            path="/forgot-password/:rkey"
                            component={ResetPasswordV3}
                          />

                          <ProtectedRoute
                            exact
                            path="/"
                            adminComponent={<Redirect to="/roster" />}
                            userComponent={<UserMonthlyRosterV3 />}
                          />

                          <ProtectedRoute
                            exact
                            path="/templates"
                            adminComponent={<TemplatesV3 />}
                            userComponent={<Redirect to="/" />}
                          />

                          <ProtectedRoute
                            exact
                            path="/requests"
                            adminComponent={<Redirect to="/roster" />}
                            userComponent={<UserRequestsScreenV3 />}
                          />

                          <ProtectedRoute
                            exact
                            path="/swap-offers"
                            adminComponent={<SwapOffersScreenV3 />}
                            userComponent={<Redirect to="/" />}
                          />

                          <ProtectedRoute
                            exact
                            path="/swap"
                            adminComponent={<Redirect to="/roster" />}
                            userComponent={<UserSwapScreenV3 />}
                          />

                          <ProtectedRoute
                            exact
                            path="/account"
                            adminComponent={<Redirect to="/roster" />}
                            userComponent={<UserAccountScreenV3 />}
                          />

                          <ProtectedRoute
                            exact
                            path="/templates/:id"
                            adminComponent={<TemplateBuilderV3 />}
                            userComponent={<Redirect to="/login" />}
                          />
                          <ProtectedRoute
                            exact
                            path="/staff"
                            adminComponent={<StaffScreenV3 />}
                            userComponent={<Redirect to="/login" />}
                          />

                          <ProtectedRoute
                            exact
                            path="/staff/e/:id?"
                            adminComponent={<StaffEditScreenV3 />}
                            userComponent={<Redirect to="/login" />}
                          />

                          <ProtectedRoute
                            path="/roster"
                            adminComponent={<RosterScreenV3 />}
                            userComponent={<Redirect to="/login" />}
                          />

                          <ProtectedRoute
                            path="/shift/:shiftId?"
                            adminComponent={<ShiftScreenV3 />}
                            userComponent={<Redirect to="/login" />}
                          />

                          <ProtectedRoute
                            path="/settings"
                            adminComponent={<SettingsScreenV3 />}
                            userComponent={<Redirect to="/roster" />}
                          />

                          <ProtectedRoute
                            path="/sms"
                            adminComponent={<ScheduledSmsScreenV3 />}
                            userComponent={<Redirect to="/roster" />}
                          />

                          <ProtectedRoute
                            path="/email"
                            adminComponent={<SentEmailsScreenV3 />}
                            userComponent={<Redirect to="/roster" />}
                          />

                          <ProtectedRoute
                            path="/audit"
                            adminComponent={<AuditHistoryScreenV3 />}
                            userComponent={<Redirect to="/roster" />}
                          />

                          <ProtectedRoute
                            path="/reports"
                            adminComponent={<ReportScreenV3 />}
                            userComponent={<ReportScreenV3 />}
                          />

                          <ProtectedRoute
                            path="/user/change-password"
                            adminComponent={<ChangePasswordScreenV3 />}
                            userComponent={<ChangePasswordScreenV3 />}
                          />

                          <ProtectedRoute
                            path="/request-swap"
                            adminComponent={<Redirect to="/roster" />}
                            userComponent={<UserRequestSwapScreenV3 />}
                          />

                          <ProtectedRoute
                            path="/contact"
                            adminComponent={<ContactHistoryScreenV3 />}
                            userComponent={<ContactHistoryScreenV3 />}
                          />
                          <ProtectedRoute
                            path="/archive"
                            adminComponent={<ArchiveRoster />}
                            userComponent={<Redirect to="/roster" />}
                          />

                          <Route render={() => <div>404</div>} />
                        </Switch>
                      ) : (
                        <Switch>
                          <Route exact path="/login" component={LoginV3} />

                          <Route
                            exact
                            path="/forgot-password"
                            component={ResetPasswordV3}
                          />
                          <Route
                            path="/forgot-password/:rkey"
                            component={ResetPasswordV3}
                          />

                          <ProtectedRoute
                            exact
                            path="/"
                            adminComponent={<Redirect to="/roster" />}
                            userComponent={<UserMonthlyRoster />}
                          />

                          <ProtectedRoute
                            exact
                            path="/templates"
                            adminComponent={<Templates />}
                            userComponent={<Redirect to="/" />}
                          />

                          <ProtectedRoute
                            exact
                            path="/requests"
                            adminComponent={<Redirect to="/roster" />}
                            userComponent={<UserRequestsScreen />}
                          />

                          <ProtectedRoute
                            exact
                            path="/swap-offers"
                            adminComponent={<SwapOffersScreen />}
                            userComponent={<Redirect to="/" />}
                          />

                          <ProtectedRoute
                            exact
                            path="/swap"
                            adminComponent={<Redirect to="/roster" />}
                            userComponent={<UserSwapScreen />}
                          />

                          <ProtectedRoute
                            exact
                            path="/account"
                            adminComponent={<Redirect to="/roster" />}
                            userComponent={<UserAccountScreen />}
                          />

                          <ProtectedRoute
                            exact
                            path="/templates/:id"
                            adminComponent={<TemplateBuilder />}
                            userComponent={<Redirect to="/login" />}
                          />
                          <ProtectedRoute
                            exact
                            path="/staff"
                            adminComponent={<StaffScreen />}
                            userComponent={<Redirect to="/login" />}
                          />

                          <ProtectedRoute
                            exact
                            path="/staff/e/:id?"
                            adminComponent={<StaffEditScreen />}
                            userComponent={<Redirect to="/login" />}
                          />

                          <ProtectedRoute
                            path="/roster"
                            adminComponent={<RosterScreen />}
                            userComponent={<Redirect to="/login" />}
                          />

                          <ProtectedRoute
                            path="/shift/:shiftId?"
                            adminComponent={<ShiftScreen />}
                            userComponent={<Redirect to="/login" />}
                          />

                          <ProtectedRoute
                            path="/settings"
                            adminComponent={<SettingsScreen />}
                            userComponent={<Redirect to="/roster" />}
                          />

                          <ProtectedRoute
                            path="/sms"
                            adminComponent={<ScheduledSmsScreen />}
                            userComponent={<Redirect to="/roster" />}
                          />

                          <ProtectedRoute
                            path="/email"
                            adminComponent={<SentEmailsScreen />}
                            userComponent={<Redirect to="/roster" />}
                          />

                          <ProtectedRoute
                            path="/audit"
                            adminComponent={<AuditHistoryScreen />}
                            userComponent={<Redirect to="/roster" />}
                          />

                          <ProtectedRoute
                            path="/reports"
                            adminComponent={<ReportScreen />}
                            userComponent={<ReportScreen />}
                          />

                          <ProtectedRoute
                            path="/user/change-password"
                            adminComponent={<ChangePasswordScreen />}
                            userComponent={<ChangePasswordScreen />}
                          />

                          <ProtectedRoute
                            path="/request-swap"
                            adminComponent={<Redirect to="/roster" />}
                            userComponent={<UserRequestSwapScreen />}
                          />

                          <Route render={() => <div>404</div>} />
                        </Switch>
                      )}
                    </BrowserRouter>
                  </UserSelectionsContextProvider>
                </UserDetailContextProvider>
              </ProgressBarGlobalProvider>
            </SnackbarProviderCustomized>
          </StyledThemeProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
};
