import { DefaultTheme } from "styled-components";

export const styledTheme: DefaultTheme = {
  fonts: {
    size: {
      default: "14px",
    },
  },
  colors: {
    background: {
      main: "#f7f7f7",
      paper: "#ffffff",
    },
    base: {
      black: "#000000",
      white: "#ffffff",
    },
    green: "#46c438", 
    lightGreen: "#E7FCD8",  
    lightYellow:"#FEF1D1",
    darkYellow:"#A77C12",
    paleGrey: "#f5f6fa",
    lightGrey: "#f7f7f7",
    greyish: "#d1d1d1",
    brownGrey: "#959595",
    grey: "#707070",
    greyishBrown: "#535353",
    deepSkyBlue: "#0583f2",
    veryLightPink: "#fff2f2",
    deepRed: "#aa0000",
    highlightYellow: "rgb(255, 246, 226)",
  },
  shape: {
    borderRadius: "2px",
  },
  gap: {
    1: "8px",
    2: "16px",
  },
};