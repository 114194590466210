import React from "react";
import styles from "../../../css/model.module.css";
import { Formik, Form, Field, FormikProps } from "formik";
import ErrorBlockThin from "../../../componentsV3/ErrorBlockThin";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import { BsArrowLeftShort } from 'react-icons/bs';
import { Typography, InputLabel, Box } from "@material-ui/core";
import ModalWindow from "../../../componentsV3/modals/ModalWindow";
import TextInputFormik from "../../../componentsV3/inputs/TextInputFormik";

export interface AddUserTypeModalSubmitValues {
  typeName: string;
  description: string;
}

interface AddUserTypeModalProps {
  onSubmit: (values: AddUserTypeModalSubmitValues) => void;
  onCancel: () => void;
  apiError?: string;
  typeName?: string;
  description?: string;
}

interface ValidationProps {
  typeName: string;
}

interface ValidationErrors {
  [key: string]: string;
}

const validation = ({ typeName }: ValidationProps) => {
  const errors: ValidationErrors = {};
  if (!typeName) {
    errors.typeName = "User type can not be empty";
  }
  return errors;
};

export default ({
  onSubmit,
  onCancel,
  apiError,
  typeName,
  description,
}: AddUserTypeModalProps) => {
  return (
    <div className={styles.container}>
      <ModalWindow>
        <Formik
          initialValues={{
            typeName: typeName || "",
            description: description || "",
          }}
          validate={validation}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            isValid,
            dirty,
            touched,
            isSubmitting,
          }: FormikProps<any>) => (
            <Form noValidate autoComplete="off">

              <Box className={styles.container}>
                <Box>
                  <Box className={styles.titlebox}>
                    <BsArrowLeftShort className={styles.backicon} onClick={onCancel} />
                    <Typography variant="h5" align="center" className={styles.title}>
                      Create user type
                    </Typography>
                  </Box>

                  <InputLabel color="primary" className={styles.label}>User type name</InputLabel>
                  <Field
                    name="typeName"
                    component={TextInputFormik}
                    placeholder="User type name"
                    type="text"
                  />

                  <InputLabel color="primary" className={styles.label}>Description</InputLabel>
                  <Field
                    as="textarea"
                    name="description"
                    type="text"
                    className={`${styles.input} ${styles.textarea}`}
                  />

                  <Box className={styles.errortext}>
                    <ErrorBlockThin
                      message={apiError ? apiError : errors.typeName?.toString()}
                    />
                  </Box>
                </Box>

                <Box>
                  <Box className={styles.buttonsholder}>
                    <Box className={`${styles.submitbuttonsholder} ${styles.fillbtn}`} >
                      <Box className={styles.cancelbuttonmargin}>
                        <StandardButton  className={'secondary'} title="Cancel" onClick={onCancel} />
                      </Box>
                      <StandardButton
                        title="Save"
                        type="submit"
                        accent
                        disabled={isSubmitting || !isValid || !dirty}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </ModalWindow>
    </div>
  );
};
