import React from "react";
import "./index.css";
import style from "./style.module.css";
import Slider from "react-slick";
import { Typography, Box } from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlideScreenImg from "../../assets/images/SlideScreenImg.svg";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';

export default function SimpleSlider() {

  var settings = {

    className: 'screen_slick_wrapper',
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover:true,
    speed: 1500,
    dots: true,
    Arrow: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <AiOutlineArrowRight />,
    prevArrow: <AiOutlineArrowLeft />
  };

  return (
    <>
    <Box className={style.slidercomponent}>
      <Box>
        <img className={style.sliderImage} src={SlideScreenImg} alt="logo" />
      </Box>
      <Slider {...settings} >
        <Box className={style.contentBox}>
          <Typography variant="h3" className={style.heading}>New ways of rostering doctors for your medical center</Typography>
          <Typography variant="subtitle1" className={style.subtext}>Risus eget velit risus et ultrices erat. Morbi amet, at nec dignissim donestyle.</Typography>
        </Box>
        <Box className={style.contentBox}>
          <Typography variant="h3" className={style.heading}>New ways of rostering doctors for your medical center</Typography>
          <Typography variant="subtitle1" className={style.subtext}>Risus eget velit risus et ultrices erat. Morbi amet, at nec dignissim donestyle.</Typography>
        </Box>
        <Box className={style.contentBox}>
          <Typography variant="h3" className={style.heading}>New ways of rostering doctors for your medical center</Typography>
          <Typography variant="subtitle1" className={style.subtext}>Risus eget velit risus et ultrices erat. Morbi amet, at nec dignissim donec.</Typography>
        </Box>
      </Slider>
      </Box>
    </>
  )
}