import React from "react";
import Router from "./Router";
import { ThemeVersionProvider } from "./context/ThemeVersionProvider";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export const App: React.FC = () => {
  return (
    <ThemeVersionProvider>
      <Router />
    </ThemeVersionProvider>
  );
};
