import { Box, Grid, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import tabImg from "../../assets/images/roosterbox.svg";
import StandardButton from "../../componentsV3/Buttons/StandardButton";
import Spinner from "../../componentsV3/Spinner";
import { useProgressBar } from "../../componentsV3/bars/ProgressBarGlobal";
import { RWrapper } from "../../componentsV3/layout/RWrapper";
import ImportTemplateModal from "../../componentsV3/modals/ImportTemplateModal";
import ModalContainer from "../../componentsV3/modals/ModalContainer";
import ModalWindowCenter from "../../componentsV3/modals/ModalWindowCenter";
import { useUserSelections } from "../../context/userSelectionsProvider";
import modal from "../../css/model.module.css";
import styles from "../../css/style.module.css";
import { UserType } from "../StaffScreen";
import { requestGetUserTypes } from "../StaffScreen/requests";
import RoosterMenu from "./menu/RoosterMenu";
import AddLocationModal, {
  AddLocationModalSubmitValues,
} from "./modals/AddLocationModal";
import CreateRosterModal, {
  CreateRosterModalSubmitValues,
} from "./modals/CreateRosterModal";
import EditRosterModal, {
  EditRosterModalSubmitValues,
} from "./modals/EditRosterModal";
import {
  Roster,
  archiveRoster,
  deleteRoster,
  putRoster,
  requestGetLocations,
  requestGetRosters,
  requestPostArchive,
  requestPostLocation,
  requestPostRoster,
} from "./requests";
import RosterDayView from "./views/RosterDayView";
import RosterMonthView from "./views/RosterMonthView";
import RosterWeekView from "./views/RosterWeekView";
import RosterYearView from "./views/RosterYearView";

export interface Location {
  id?: number;
  locationName: string;
}

const RosterScreen = () => {

  const history = useHistory();

  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { barActivate, barStop } = useProgressBar();
  const {
    selectedLocation: locationSelected,
    setLocation,
    selectedRosters: rostersSelected,
    setRosters: setRostersSelected,
    initialized: selectedInitialized,
  } = useUserSelections();

  const [rosters, setRosters] = useState<Array<Roster>>([]);
  const [importRoster, setImportRoster] = useState<any>([]);
  const [userTypes, setUserTypes] = useState<Array<UserType>>([]);
  const [locations, setLocations] = useState<Array<Location>>([]);
  const [allLocations, setAllLocations] = useState<boolean>(true);
  const [modalLocationsOpen, setModalLocationsOpen] = useState<boolean>(false);
  const [modalRosterOpen, setModalRosterOpen] = useState<boolean>(false);
  const [modalImportTemplateOpen, setModalImportTemplateOpen] =
    useState<boolean>(false);



  const [calendarViewSelectorText, setCalendarViewSelectorText] =
    useState<string>("");
  const _closeModalLocation = () => setModalLocationsOpen(false);
  const _openModalLocation = () => setModalLocationsOpen(true);
  const _closeModalRoster = () => setModalRosterOpen(false);
  const _openModalRoster = () => {
    if (userTypes.length) setModalRosterOpen(true);
  };
  const _closeModalImpTemplate = () => setModalImportTemplateOpen(false);
  const _openModalImpTemplate = (id: any) => {
    setImportRoster(id);
    setModalImportTemplateOpen(true);
  };

  const { isLoading } = useQuery(
    "requestGetLocations",
    async () => requestGetLocations(),
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar({ variant: "error" });
      },
    }
  );

  const adjustCalendarViewSelectorText = useCallback(() => {
    if (pathname.includes("day")) {
      setCalendarViewSelectorText("Day");
    } else if (pathname.includes("month")) {
      setCalendarViewSelectorText("Month");
    } else {
      setCalendarViewSelectorText("Year");
    }
  }, [pathname]);

  useEffect(() => {
    adjustCalendarViewSelectorText();
  }, [adjustCalendarViewSelectorText]);

  const retrieveRosters = useCallback(async () => {
    if (allLocations) {
      try {
        const rn = await requestGetRosters("all");
        setRosters(rn);
      } catch (error) {
        console.log("all roster");
        enqueueSnackbar("Unable to retrieve rosters", { variant: "error" });
      }
    } else if (locationSelected && locationSelected.id) {
      try {
        const rn = await requestGetRosters(locationSelected.id);
        setRosters(rn);
      } catch (error) {
        enqueueSnackbar("Unable to retrieve rosters", { variant: "error" });
      }
    }
  }, [locationSelected, allLocations, enqueueSnackbar]);

  useEffect(() => {
    retrieveRosters();
  }, [allLocations, retrieveRosters]);

  const getLocations = useCallback(async () => {
    try {
      barActivate();
      const result = await requestGetLocations();
      setLocations(result);
      if (locationSelected && result.length) {
        const found = result.find((e) => {
          return e.id === locationSelected.id;
        });
        if (found) {
          if (found.locationName !== locationSelected.locationName) {
            setLocation(found);
          }
        } else {
          setLocation(result[0]);
        }
      } else if (!locationSelected && result.length) {
        setLocation(result[0]);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to retrieve locations", { variant: "error" });
    } finally {
      barStop();
    }
  }, [locationSelected, setLocation, enqueueSnackbar, barStop, barActivate]);

  const getUserTypes = useCallback(async () => {
    try {
      barActivate();
      const ut = await requestGetUserTypes();
      setUserTypes(ut);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to retrieve user types", { variant: "error" });
    } finally {
      barStop();
    }
  }, [enqueueSnackbar, barActivate, barStop]);

  useEffect(() => {
    if (selectedInitialized) {
      // GET LOCATIONS
      getLocations();
      // GET USER TYPES
      getUserTypes();
    }
  }, [selectedInitialized, getLocations, getUserTypes]);

  const _submitLocation = async ({
    locationName,
    id,
  }: AddLocationModalSubmitValues) => {
    try {
      barActivate();
      const location = await requestPostLocation(locationName);
      setLocations([...locations, location]);
      setLocation(location);
      _closeModalLocation();
      enqueueSnackbar("Location added", { variant: "success" });
    } catch (error) {
      console.error(error);
      const response = error.response || {};
      const { status } = response;
      if (status === 409) {
        enqueueSnackbar("Location already exists", { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } finally {
      barStop();
    }
  };

  const _submitRoster = async (values: CreateRosterModalSubmitValues) => {
    try {
      barActivate();
      if (!locationSelected || !locationSelected.id)
        throw new Error("no location selected");
      const response = await requestPostRoster({
        ...values,
        locationId: locationSelected.id,
      });
      console.log("requestPostRoster response", response);
      const rn = await requestGetRosters(locationSelected.id);
      setRosters(rn);
      setRostersSelected([response]);
      _closeModalRoster();
      enqueueSnackbar("Roster created", { variant: "success" });
    } catch (error) {
      console.error(error);
      const response = error.response || {};
      const { status } = response;
      if (status === 409) {
        enqueueSnackbar(`Roster with the name ${values.name} already exists`, {
          variant: "error",
        });
      } else if (error.message === "no location selected") {
        enqueueSnackbar(`Please select location first`, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } finally {
      barStop();
    }
  };

  const _floatingButtonActive = (id: number) => {
    if (!rostersSelected.length) return false;
    else if (rostersSelected.find((e) => e.id === id)) return true;
    else return false;
  };

  const _handleRosterNameButtonToggle = (roster: Roster) => {
    if (_floatingButtonActive(roster.id)) {
      const without = rostersSelected.filter((i) => i.id !== roster.id);
      setRostersSelected(without);
    } else {
      setRostersSelected([...rostersSelected, roster]);
    }
  };

  const [selectedRosters, setSelectedRosters] = useState<any>();

  const [modalEditRosterOpen, setModalEditRosterOpen] =
    useState<boolean>(false);
  const _closeModalEditRoster = () => setModalEditRosterOpen(false);

  const _openModalEditRoster = (id: number) => {
    const selectedRoster = rosters.filter((e) => e.id === id);
    if (selectedRoster.length > 0) {
      setSelectedRosters(selectedRoster[0]);
      setModalEditRosterOpen(true);
    }
  };


  const [deleteRosterModal, setDeleteRosterModal] = useState<boolean>(false);
  const _closeDeleteRosterModal = () => setDeleteRosterModal(false);

  const _openDeleteRosterModal = (id: number) => {
    const selectedRoster = rosters.filter((e) => e.id === id);
    if (selectedRoster.length > 0) {
      setSelectedRosters(selectedRoster[0]);
      setDeleteRosterModal(true);
    }
  }

  const [archiveRosterModal, setArchiveRosterModal] = useState<boolean>(false);
  const _closeArchiveRosterModal = () => setArchiveRosterModal(false);

  const _openArchiveRosterModal = (id: number) => {
    const selectedRoster = rosters.filter((e) => e.id === id);
    if (selectedRoster.length > 0) {
      setSelectedRosters(selectedRoster[0]);
      setArchiveRosterModal(true);
    }
  }

  const { mutateAsync } = useMutation<unknown, AxiosError, any>(
    (roster) => putRoster(selectedRosters.id, roster),

    {
      onError: (err) => {
        enqueueSnackbar(
          "Unable to remove position due to shifts created for the position in this roster",
          { variant: "error" }
        );
      },
      onSuccess: (response) => {
        enqueueSnackbar("Roster Updated", { variant: "success" });
      },
    }
  );

  const _submitPutRoster = async (values: EditRosterModalSubmitValues) => {
    await mutateAsync({
      locationId: locationSelected!.id,
      name: values.name,
      color: values.color,
      userTypeIDs: values.userTypeIDs,
    });
    setModalEditRosterOpen(false);
  };

  const handleDelete = async () => {
    try {
      barActivate();
      await deleteRoster(selectedRosters.id);
      retrieveRosters();
      setDeleteRosterModal(false);
      setArchiveRosterModal(false);
      enqueueSnackbar("Roster deleted", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to deleted roster", { variant: "error" });
    } finally {
      barStop();
    }
  };

  const _submitArchive = async () => {
    try {
      await requestPostArchive(selectedRosters.id);
      retrieveRosters();
      setArchiveRosterModal(false)
      enqueueSnackbar("Roster archived", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to archived rosters", { variant: "error" });
    } finally {
      barStop();
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <RWrapper>

      <ModalContainer
        onClose={_closeModalEditRoster}
        open={modalEditRosterOpen}
      >
        <EditRosterModal
          onSubmit={_submitPutRoster}
          onCancel={_closeModalEditRoster}
          selectedRoster={selectedRosters}
          userTypes={userTypes.map((ut) => ({
            ...ut,
            selected: false,
          }))}
        />
      </ModalContainer>
      <ModalContainer onClose={_closeModalLocation} open={modalLocationsOpen}>
        <AddLocationModal
          onSubmit={_submitLocation}
          onCancel={_closeModalLocation}
        />
      </ModalContainer>
      <ModalContainer onClose={_closeModalRoster} open={modalRosterOpen}>
        <CreateRosterModal
          onSubmit={_submitRoster}
          onCancel={_closeModalRoster}
          userTypes={userTypes.map((ut) => ({
            ...ut,
            selected: false,
          }))}
        />
      </ModalContainer>
      <ImportTemplateModal
        onClose={_closeModalImpTemplate}
        open={modalImportTemplateOpen}
        rosterNames={rosters}
        selectedRoster={importRoster}
        onActionSuccess={() => {
          enqueueSnackbar("Template imported", { variant: "success" });
          setModalImportTemplateOpen(false);
        }}
        onActionError={(error) =>
          enqueueSnackbar("Template was not imported", { variant: "error" })
        }
      />
      <Box className={styles.pagetitlebox}>
        <Typography variant="h4" color="textPrimary">
          Roster
        </Typography>
        <Box className={styles.rightSide}>
          <Box className={styles.outlinebtn}>

            <IconButton onClick={() => history.push('/archive')}>
              <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 8H18C17.4696 8 16.9609 8.21071 16.5858 8.58579C16.2107 8.96086 16 9.46957 16 10C16 10.5304 15.7893 11.0391 15.4142 11.4142C15.0391 11.7893 14.5304 12 14 12H10C9.46957 12 8.96086 11.7893 8.58579 11.4142C8.21071 11.0391 8 10.5304 8 10C8 9.46957 7.78929 8.96086 7.41421 8.58579C7.03914 8.21071 6.53043 8 6 8H3C2.20435 8 1.44129 8.31607 0.87868 8.87868C0.31607 9.44129 0 10.2044 0 11L0 15C0.00158786 16.3256 0.528882 17.5964 1.46622 18.5338C2.40356 19.4711 3.6744 19.9984 5 20H19C20.3256 19.9984 21.5964 19.4711 22.5338 18.5338C23.4711 17.5964 23.9984 16.3256 24 15V11C24 10.2044 23.6839 9.44129 23.1213 8.87868C22.5587 8.31607 21.7956 8 21 8ZM22 15C22 15.7956 21.6839 16.5587 21.1213 17.1213C20.5587 17.6839 19.7956 18 19 18H5C4.20435 18 3.44129 17.6839 2.87868 17.1213C2.31607 16.5587 2 15.7956 2 15V11C2 10.7348 2.10536 10.4804 2.29289 10.2929C2.48043 10.1054 2.73478 10 3 10H6C6 11.0609 6.42143 12.0783 7.17157 12.8284C7.92172 13.5786 8.93913 14 10 14H14C15.0609 14 16.0783 13.5786 16.8284 12.8284C17.5786 12.0783 18 11.0609 18 10H21C21.2652 10 21.5196 10.1054 21.7071 10.2929C21.8946 10.4804 22 10.7348 22 11V15Z" fill="#1890FF" />
                <path d="M3 6H21C21.2652 6 21.5196 5.89464 21.7071 5.70711C21.8946 5.51957 22 5.26522 22 5C22 4.73478 21.8946 4.48043 21.7071 4.29289C21.5196 4.10536 21.2652 4 21 4H3C2.73478 4 2.48043 4.10536 2.29289 4.29289C2.10536 4.48043 2 4.73478 2 5C2 5.26522 2.10536 5.51957 2.29289 5.70711C2.48043 5.89464 2.73478 6 3 6V6Z" fill="#1890FF" />
                <path d="M3 2H21C21.2652 2 21.5196 1.89464 21.7071 1.70711C21.8946 1.51957 22 1.26522 22 1C22 0.734784 21.8946 0.48043 21.7071 0.292893C21.5196 0.105357 21.2652 0 21 0H3C2.73478 0 2.48043 0.105357 2.29289 0.292893C2.10536 0.48043 2 0.734784 2 1C2 1.26522 2.10536 1.51957 2.29289 1.70711C2.48043 1.89464 2.73478 2 3 2V2Z" fill="#1890FF" />
              </svg> View archive
            </IconButton>
          </Box>
          {locationSelected && locationSelected.id && userTypes.length ? (
            <Box className={styles.fillbtn}>
              <IconButton onClick={_openModalRoster}>
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z"
                    fill="white"
                  />
                </svg>
                Add roster
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box>
        <Box className={styles.roostertab}>
          <Box
            className={`${styles.roostertabbtn} ${rosters.length ? "tabbtnborder" : ""
              }`}
          >
            <IconButton
              className={styles.tabbtn}
              style={
                allLocations
                  ? { background: "#FFFFFF", color: "#282828" }
                  : { background: "#F2F2F2" }
              }
              onClick={() => {
                setAllLocations(true);
              }}
            >
              All Locations
            </IconButton>
            {locations.map((l) => (
              <IconButton
                key={l.id}
                className={styles.tabbtn}
                style={
                  l.id === locationSelected?.id && !allLocations
                    ? { background: "#FFFFFF", color: "#282828" }
                    : { background: "#F2F2F2" }
                }
                onClick={() => {
                  setAllLocations(false);
                  if (l.id !== locationSelected?.id) {
                    setLocation(locations.find((e) => e.id === l.id));
                    setRostersSelected([]);
                  }
                }}
              >
                {l.locationName}
              </IconButton>
            ))}
            <IconButton onClick={_openModalLocation} className={styles.tabbtn}>
              <svg
                width="12"
                height="20"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z"
                  fill="#9A9A9A"
                />
              </svg>
              Add Location
            </IconButton>
          </Box>

          {rosters.length ? (
            <Box className={styles.tabcontent}>
              <Grid container spacing={3}>
                {rosters.map((r) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={r.id}>
                    <Box
                      className={`${_floatingButtonActive(r.id)
                        ? styles.roosboxSelect
                        : styles.roosbox
                        }`}
                    >
                      <Box
                        className={styles.roosboxwrap}
                        onClick={() => _handleRosterNameButtonToggle(r)}
                      >
                        <Box className={styles.titlebox}>
                          <Box
                            className={styles.ricon}
                            style={{ background: `${r.color}` }}
                          >
                            <img src={tabImg} alt="rooster image" />
                          </Box>
                          <Box>
                            <Typography variant="h5">{r.name}</Typography>
                            <Box className={styles.locbox}>
                              <svg
                                width="22"
                                height="24"
                                viewBox="0 0 22 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11 6C10.2089 6 9.43552 6.2346 8.77772 6.67412C8.11993 7.11365 7.60723 7.73836 7.30448 8.46927C7.00173 9.20017 6.92252 10.0044 7.07686 10.7804C7.2312 11.5563 7.61216 12.269 8.17157 12.8284C8.73098 13.3878 9.44372 13.7688 10.2196 13.9231C10.9956 14.0775 11.7998 13.9983 12.5307 13.6955C13.2616 13.3928 13.8864 12.8801 14.3259 12.2223C14.7654 11.5645 15 10.7911 15 10C15 8.93913 14.5786 7.92172 13.8284 7.17157C13.0783 6.42143 12.0609 6 11 6ZM11 12C10.6044 12 10.2178 11.8827 9.88886 11.6629C9.55996 11.4432 9.30362 11.1308 9.15224 10.7654C9.00087 10.3999 8.96126 9.99778 9.03843 9.60982C9.1156 9.22186 9.30608 8.86549 9.58579 8.58579C9.86549 8.30608 10.2219 8.1156 10.6098 8.03843C10.9978 7.96126 11.3999 8.00087 11.7654 8.15224C12.1308 8.30362 12.4432 8.55996 12.6629 8.88886C12.8827 9.21776 13 9.60444 13 10C13 10.5304 12.7893 11.0391 12.4142 11.4142C12.0391 11.7893 11.5304 12 11 12Z"
                                  fill="#9B9FA1"
                                />
                                <path
                                  d="M11 24C10.158 24.0043 9.32712 23.8068 8.57707 23.4241C7.82703 23.0413 7.1796 22.4844 6.68901 21.8C2.87801 16.543 0.945007 12.591 0.945007 10.053C0.945007 7.38625 2.00437 4.82872 3.89005 2.94304C5.77573 1.05736 8.33326 -0.0019989 11 -0.0019989C13.6668 -0.0019989 16.2243 1.05736 18.11 2.94304C19.9956 4.82872 21.055 7.38625 21.055 10.053C21.055 12.591 19.122 16.543 15.311 21.8C14.8204 22.4844 14.173 23.0413 13.4229 23.4241C12.6729 23.8068 11.8421 24.0043 11 24V24ZM11 2.181C8.91242 2.18338 6.91102 3.01373 5.43488 4.48987C3.95873 5.96602 3.12839 7.96742 3.12601 10.055C3.12601 12.065 5.01901 15.782 8.45501 20.521C8.7467 20.9228 9.12938 21.2498 9.57172 21.4753C10.0141 21.7008 10.5035 21.8183 11 21.8183C11.4965 21.8183 11.986 21.7008 12.4283 21.4753C12.8706 21.2498 13.2533 20.9228 13.545 20.521C16.981 15.782 18.874 12.065 18.874 10.055C18.8716 7.96742 18.0413 5.96602 16.5651 4.48987C15.089 3.01373 13.0876 2.18338 11 2.181V2.181Z"
                                  fill="#9B9FA1"
                                />
                              </svg>
                              <Typography variant="body1">
                                {r.location.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className={styles.statusbox}>
                          <Typography
                            variant="body1"
                            className={styles.publish}
                          >
                            Completed <span>{r.publishedShiftsCount}</span>
                          </Typography>
                          <Typography variant="body1" className={styles.draft}>
                            Unpublished <span>{r.draftShiftsCount}</span>
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={styles.menusbutton}>
                        <RoosterMenu
                          openModal={() => _openModalImpTemplate(r.id)}
                          editRoster={() => _openModalEditRoster(r.id)}
                          deleteRoster={() => _openDeleteRosterModal(r.id)}
                          archiveRoster={() => _openArchiveRosterModal(r.id)}
                          shift={r.publishedShiftsCount !== 0}
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : null}
        </Box>
      </Box>

      <ModalContainer
        onClose={_closeDeleteRosterModal}
        open={deleteRosterModal}
      >
        <ModalWindowCenter>
          <Box className={modal.new_container}>
            <Box className={modal.titlebox}>
              <Typography className={modal.title} variant="h5">
                Delete The Roster
              </Typography>
            </Box>
            <Typography variant="h6">
              Are you sure you want to delete this Roster?
            </Typography>

            <Box className={modal.buttonsholder}>
              <Box className={`${modal.submitbuttonsholder} ${modal.fillbtn}`} >
                <Box className={modal.cancelbuttonmargin}>
                  <StandardButton
                    className={"secondary"}
                    title="Cancel"
                    onClick={_closeDeleteRosterModal}
                  />
                </Box>
                <StandardButton
                  className={"danger"}
                  title="Delete"
                  onClick={handleDelete}
                />
              </Box>
            </Box>
          </Box>
        </ModalWindowCenter>

      </ModalContainer>
      <ModalContainer
        onClose={_closeArchiveRosterModal}
        open={archiveRosterModal}
      >
        <ModalWindowCenter>
          <Box className={modal.new_container}>
            <Box className={modal.titlebox}>
              <Typography className={modal.title} variant="h5">
                Archive The Roster
              </Typography>
            </Box>
            <Typography variant="h6">
              Are you sure you want to archive this Roster?
            </Typography>

            <Box className={modal.buttonsholder}>
              <Box className={`${modal.submitbuttonsholder} ${modal.fillbtn}`} >
                <Box className={modal.cancelbuttonmargin}>
                  <StandardButton
                    className={"secondary"}
                    title="Cancel"
                    onClick={_closeArchiveRosterModal}
                  />
                </Box>
                <StandardButton
                  title="Archive"
                  className={"danger"}
                  onClick={_submitArchive}
                />
              </Box>
            </Box>
          </Box>
        </ModalWindowCenter>

      </ModalContainer>

      {!locationSelected ||
        !locationSelected.id ||
        !rostersSelected[0] ? null : (
        <Switch>
          <Route exact path={`/roster/year/:year?`}>
            <RosterYearView />
          </Route>
          <Route exact path={`/roster/year/:year/month/:month?`}>
            <RosterMonthView />
          </Route>
          <Route exact path={`/roster/year/:year/month/:month/week/:week?`}>
            <RosterWeekView />
          </Route>
          <Route exact path={`/roster/year/:year/month/:month/day/:day`}>
            <RosterDayView />
          </Route>
          <Route>
            <Redirect to="/roster/year" />
          </Route>
        </Switch>
      )}
    </RWrapper>
  );
};

export default RosterScreen;
