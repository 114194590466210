import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useProgressBar } from "../../componentsV3/bars/ProgressBarGlobal";
import RegularCheckbox from "../../componentsV3/CheckBoxes/RegularCheckbox";
import { getUserDetails } from "../../requests/user";
import { Location } from "../RosterScreen";
import {
  requestGetLocations,
  requestGetRosters,
  Roster,
} from "../RosterScreen/requests";
import { User } from "../StaffScreen";
import { requestGetStaff } from "../StaffScreen/requests";
import style from "../../css/style.module.css";
import DownloadImg from "../../assets/images/arrow/download.svg";
import Spinner from "../../componentsV3/Spinner";

const useStyles = makeStyles((theme) => {
  const {
    palette: { text },
    shape: { borderRadius },
  } = theme;

  return {
    input: {
      borderRadius: theme.shape.borderRadius,
      border: ({ bordered }: { bordered: boolean }) => {
        return bordered ? `1px solid ${theme.palette.grey[300]}` : "none";
      },
      height: "100%",
      width: "100%",
    },
    outer: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: text.hint,
      borderRadius: borderRadius,
    },
    label: {
      fontSize: theme.typography.fontSize,
      marginBottom: "0.3em",
    },
    chip: {
      marginInline: 2,
      marginBottom: 1,
    },
    selectdate: {
      "&.MuiTextField-root": {
        marginTop: 0,
      },
      "& :before ": {
        display: "none",
      },
      "& :after ": {
        display: "none",
      },
      "& .MuiInput-root": {
        background: "#F9F9F9",
        border: "1px solid #e6e6e6",
        borderRadius: 8,
        padding: "15px 0 15px 12px",
      },
      "& .MuiInputBase-input": {
        padding: 0,
      },
    },
    selectinput: {
      "&.MuiInput-formControl": {
        marginTop: 0,
      },
      "&:before ": {
        display: "none",
      },
      "& .MuiSelect-root": {
        background: "#F9F9F9",
        border: "1px solid #e6e6e6",
        borderRadius: 8,
        padding: "15px 0 15px 12px",
      },
      "& .MuiSvgIcon-root": {
        right: 10,
      },
    },
    selectmultiple: {
      "&.MuiInputBase-root": {
        marginTop: 0,
      },
      "&:before ": {
        display: "none",
      },
      "&:after ": {
        display: "none",
      },
      "& .MuiSelect-root": {
        background: "#F9F9F9",
        border: "1px solid #e6e6e6",
        borderRadius: 8,
        padding: "15px 0 15px 12px",
      },
      "& .MuiSvgIcon-root": {
        right: 10,
      },
    },
    menuPaper: {
      "& .MuiList-padding": {
        padding: "2px",
      },
    },
  };
});

const SelectedReport = ({ data }: any) => {
  const classes = useStyles({ bordered: true });
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  const [rostersData, setRostersData] = useState<Array<Roster>>([]);
  const [userTypes, setUserTypes] = React.useState<Array<User>>([]);
  const [locationData, setLocationData] = useState<Location[]>([]);
  const [selectedData, setSelectedData] = useState<any>({
    FromDate: null,
    ToDate: null,
    StaffMember: "",
    Roster: [],
    location: 0,
    format: "CSV",
    IsDraft: false,
  });

  const { data: userData, isLoading } = useQuery(["getUserDetails"], async () =>
    getUserDetails()
  );

  const username = "Rooster";
  const password = "DVGy5oFXDhJs";
  const userString = `https://${username}:${password}@`;
  const url = `${data.url}&Tid=${userData?.tenants?.[0].uuid}`;
  let reportUrl = url.replace("https://", userString!);

  if (data.Parameter.length) {
    const filteredParameter = data.Parameter.filter(
      (para: any) => para !== "format"
    );
    const params = filteredParameter
      .map((para: any) =>
        para === "Roster"
          ? `${selectedData[para].length
            ? selectedData[para]
              .map((ros: any) => `${para}=${ros}`)
              .join("&")
            : rostersData.map((ros) => `${para}=${ros.id}`).join("&")
          }`
          : para === "FromDate" || para === "ToDate"
            ? `${para}=${moment(selectedData[para]).format("YYYY-MM-DD")}`
            : `${para}=${selectedData[para]}`
      )
      .join("&");
    reportUrl = reportUrl.concat(`&${params}`);
  }

  const viewReport = () => {
    const viewUrl = reportUrl.concat(`&rc:Toolbar=false`);
    window.open(viewUrl, "", "width=1200,height=600,left=200,top=200");
  };

  const downloadReport = () => {
    const downloadUrl = reportUrl.concat(
      `&rs:format=${data.Parameter.includes("format") ? selectedData.format : "PDF"
      }`
    );
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.append(link);
    link.click();
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    classes: {
      paper: classes.menuPaper,
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };
  const retrieveUsers = useCallback(
    async (userType?: number | "admin") => {
      try {
        barActivate();
        const ut = await requestGetStaff(userType);
        setUserTypes(ut);
      } catch (err) {
        enqueueSnackbar("Unable to get users", { variant: "error" });
      } finally {
        barStop();
      }
    },
    [barActivate, barStop, enqueueSnackbar]
  );
  useEffect(() => {
    if (data) {
      retrieveUsers(undefined);
      setSelectedData({ ...selectedData, StaffMember: "" });
    }
  }, [data, retrieveUsers]);

  const getLocations = useCallback(async () => {
    try {
      barActivate();
      const result = await requestGetLocations();
      setLocationData(result);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to retrieve locations", { variant: "error" });
    } finally {
      barStop();
    }
  }, [setLocationData, enqueueSnackbar, barStop, barActivate]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  const handleChangeLocation = async (event: any) => {
    setSelectedData({ ...selectedData, location: event.target.value });
    if (event.target.value) {
      try {
        const rn = await requestGetRosters(event.target.value);
        setSelectedData({
          ...selectedData,
          location: event.target.value,
          Roster: [],
        });

        setRostersData(rn);
      } catch (error) {
        enqueueSnackbar("Unable to retrieve rosters", { variant: "error" });
      }
    } else {
      setSelectedData({ ...selectedData, Roster: [] });
      setRostersData([]);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {((userData?.isAdministrator === false && data.access.includes("user")) ||
        (userData?.isAdministrator && data.access.includes("admin"))) && (
          <>
            <Box className={style.reportbox}>
              <Box className={style.reporttitle}>
                <Box className={style.reporttitleobox}>
                  <Box className={style.icon}>
                    <img src={data.icon} alt="report" />
                  </Box>
                  <Typography variant="h6">{data.name}</Typography>
                </Box>
                <Box className={style.reportsubmit}>
                  <Box className={style.btnReport}>
                    <Button
                      onClick={viewReport}
                      disabled={
                        (data.Parameter.includes("FromDate") &&
                          selectedData.FromDate === null) ||
                        (data.Parameter.includes("ToDate") &&
                          selectedData.ToDate === null) ||
                        (data.Parameter.includes("Roster") &&
                          selectedData.location === 0)
                      }
                    >
                      View Report
                    </Button>
                    <Button
                      onClick={downloadReport}
                      className={style.fill}
                      disabled={
                        (data.Parameter.includes("FromDate") &&
                          selectedData.FromDate === null) ||
                        (data.Parameter.includes("ToDate") &&
                          selectedData.ToDate === null) ||
                        (data.Parameter.includes("Roster") &&
                          selectedData.location === 0)
                      }
                    >
                      <img src={DownloadImg} alt="download report" />
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box className={style.upcomingshiftreport}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container item>
                    {data.Parameter.includes("StaffMember") && (
                      <>
                        <Grid item lg={2} md={6} sm={12} xs={12}>
                          <FormControl
                            className={`${style.formControl} ${style.formControlstaff}`}
                          >
                            <label className={style.reportlabel}>
                              Staff Member
                            </label>
                            <Select
                              disableUnderline={true}
                              className={`${classes.selectinput}`}
                              id="users-select"
                              value={selectedData.StaffMember}
                              onChange={(e) =>
                                setSelectedData({
                                  ...selectedData,
                                  StaffMember: e.target.value,
                                })
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem
                                style={{ borderRadius: 8, margin: 1 }}
                                value=""
                              >
                                None
                              </MenuItem>
                              {userTypes.map((u) => (
                                <MenuItem
                                  style={{ borderRadius: 8, margin: 1 }}
                                  key={u.id}
                                  value={u.fullName}
                                >
                                  {u.fullName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}

                    {data.Parameter.includes("FromDate") && (
                      <Grid
                        item
                        lg={2}
                        md={6}
                        sm={12}
                        xs={12}
                        className={style.reportinput}
                      >
                        <Box mr={2}>
                          <label className={style.reportlabel}>From Date</label>
                          <KeyboardDatePicker
                            fullWidth={true}
                            variant="inline"
                            className={`${classes.selectdate}`}
                            // label={
                            //   data.Parameter.includes("ToDate")
                            //     ? "DD/MM/YYYY"
                            //     : "Select Date"
                            // }
                            value={selectedData.FromDate}
                            format="dd/MM/yyyy"
                            onChange={(date) =>
                              setSelectedData({
                                ...selectedData,
                                FromDate: date,
                              })
                            }
                            autoOk
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Box>
                      </Grid>
                    )}

                    {data.Parameter.includes("ToDate") && (
                      <Grid
                        item
                        lg={2}
                        md={6}
                        sm={12}
                        xs={12}
                        className={style.reportinput}
                      >
                        <Box mr={2}>
                          <label className={style.reportlabel}>To Date</label>
                          <KeyboardDatePicker
                            fullWidth={true}
                            className={`${classes.selectdate}`}
                            variant="inline"
                            value={selectedData.ToDate}
                            format="dd/MM/yyyy"
                            onChange={(date) =>
                              setSelectedData({
                                ...selectedData,
                                ToDate: date,
                              })
                            }
                            autoOk
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Box>
                      </Grid>
                    )}

                    {data.Parameter.includes("Roster") && (
                      <>
                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={12}
                          xs={12}
                          className={style.reportinput}
                        >
                          <Box mr={2}>
                            <FormControl className={style.formControl}>
                              <label className={style.reportlabel}>
                                Location
                              </label>
                              <Select
                                disableUnderline={true}
                                className={`${classes.selectinput}`}
                                id="locations-select"
                                value={selectedData.location}
                                onChange={handleChangeLocation}
                                MenuProps={MenuProps}
                              >
                                <MenuItem
                                  style={{ borderRadius: 8, margin: 1 }}
                                  value={0}
                                >
                                  None
                                </MenuItem>
                                {locationData.map((location) => (
                                  <MenuItem
                                    style={{ borderRadius: 8, margin: 1 }}
                                    key={location.id}
                                    value={location.id}
                                  >
                                    {location.locationName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={12}
                          xs={12}
                          className={style.reportinput}
                        >
                          <Box mr={2}>
                            <FormControl className={style.formControl}>
                              <label className={style.reportlabel}>Rosters</label>
                              <Select
                                id="roosters-mutiple-chip"
                                multiple
                                className={`${classes.selectmultiple}`}
                                value={selectedData.Roster}
                                onChange={(e) =>
                                  setSelectedData({
                                    ...selectedData,
                                    Roster: e.target.value,
                                  })
                                }
                                renderValue={(selected: any) => (
                                  <Box
                                    display="flex"
                                    flexWrap="wrap"
                                    marginRight={3}
                                  >
                                    {selected.map((value: any) => {
                                      const rooster = rostersData.find(
                                        (rooster) => rooster.id === value
                                      );
                                      return (
                                        <Chip
                                          key={value}
                                          label={rooster && rooster.name}
                                          className={classes.chip}
                                          style={{ margin: 2 }}
                                        />
                                      );
                                    })}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              >
                                {rostersData.map((rooster) => (
                                  <MenuItem
                                    style={{ borderRadius: 8, margin: 1 }}
                                    key={rooster.id}
                                    value={rooster.id}
                                  >
                                    {rooster.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      </>
                    )}

                    {data.Parameter.includes("format") && (
                      <Grid
                        item
                        lg={2}
                        md={6}
                        sm={12}
                        xs={12}
                        className={style.reportinput}
                      >
                        <Box mr={2}>
                          <FormControl className={style.formControl}>
                            <label className={style.reportlabel}>
                              Download Format
                            </label>
                            <Select
                              id="format-select"
                              disableUnderline={true}
                              className={`${classes.selectinput}`}
                              value={selectedData.format}
                              onChange={(e) =>
                                setSelectedData({
                                  ...selectedData,
                                  format: e.target.value,
                                })
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem
                                style={{ borderRadius: 8, margin: 1 }}
                                value={"CSV"}
                              >
                                CSV
                              </MenuItem>
                              <MenuItem
                                style={{ borderRadius: 8, margin: 1 }}
                                value={"PDF"}
                              >
                                PDF
                              </MenuItem>
                              <MenuItem
                                style={{ borderRadius: 8, margin: 1 }}
                                value={"EXCEL"}
                              >
                                EXCEL
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    )}
                    {data.Parameter.includes("IsDraft") && (
                      <Grid
                        item
                        lg={2}
                        md={6}
                        sm={12}
                        xs={12}
                        className={style.reportinput}
                      >
                        <Box mr={2}>
                          <Box className={style.checkbox}>
                            <Box mr={0.5}>
                              <RegularCheckbox
                                checked={selectedData.IsDraft}
                                onClick={(checked) =>
                                  setSelectedData({
                                    ...selectedData,
                                    IsDraft: checked,
                                  })
                                }
                                sizeOuter={16}
                                sizeInner={16}
                              />
                            </Box>
                            <Box m={0}>
                              <Typography style={{ textAlign: "left" }}>
                                Include Draft Shifts
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={`${style.reportsubmit} ${style.reportsubmitbottom}`}
                    >
                      <Box
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                      >
                        <Box className={style.btnReport}>
                          <Button
                            onClick={viewReport}
                            disabled={
                              (data.Parameter.includes("FromDate") &&
                                selectedData.FromDate === null) ||
                              (data.Parameter.includes("ToDate") &&
                                selectedData.ToDate === null) ||
                              (data.Parameter.includes("Roster") &&
                                selectedData.location === 0)
                            }
                          >
                            View Report
                          </Button>
                          <Button
                            onClick={downloadReport}
                            className={style.fill}
                            disabled={
                              (data.Parameter.includes("FromDate") &&
                                selectedData.FromDate === null) ||
                              (data.Parameter.includes("ToDate") &&
                                selectedData.ToDate === null) ||
                              (data.Parameter.includes("Roster") &&
                                selectedData.location === 0)
                            }
                          >
                            <img src={DownloadImg} alt="download report" />
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
          </>
        )}
    </>
  );
};

export default SelectedReport;
