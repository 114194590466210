import { Box, Divider } from "@material-ui/core";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  DateNavButtonLeft,
  DateNavButtonRight,
  DateNavText,
} from "../../../../componentsV3/Buttons/DateNavigationButtons";
import { useProgressBar } from "../../../../componentsV3/bars/ProgressBarGlobal";
import YearCalendar, {
  YearCalendarMonthStatuses,
} from "../../../../componentsV3/calendars/YearCalendar";
import Dropdown from "../../../../componentsV3/inputs/Dropdown";
import { useUserSelections } from "../../../../context/userSelectionsProvider";
import styles from "../../../../css/style.module.css";
import { getPublishedAndAvailableShiftsForMonth } from "../../../../requests/shifts";
import { Shift } from "../../../RosterScreen/requests";
import { getRosterStatusesYear } from "../../requests";
interface RosterYearView {
  month?: Moment | any;
  day: Number;
  year: any;
}

const RosterYearView = () => {
  const { year, month, day } = useParams<{
    year: string;
    month: string;
    day: string;
  }>();
  const [monthToShow, setMonthToShow] = useState<number | undefined>(undefined);
  const [yearToShow, setYearToShow] = useState<number | undefined>(undefined);
  const [currentMoment, setCurrentMoment] = useState<Moment | undefined>(
    undefined
  );
  const { selectedRosters } = useUserSelections();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [yearToDisplay, setYearToDisplay] = useState<number>(
    year ? Number(year) : new Date().getFullYear()
  );
  const [yearCounter, setYearCounter] = useState<number>(yearToDisplay);
  const [statuses, setStatuses] = useState<YearCalendarMonthStatuses>({});

  const [date, setdate] = useState<Moment | undefined>(undefined);
  const [allShifts, setAllShifts] = useState<{ [key: string]: Array<Shift> }>(
    {}
  );
  const [today, settoday] = useState<RosterYearView | undefined>(undefined);

  useEffect(() => {
    const currentMoment = moment();
    setdate(currentMoment);
    const year = Number(currentMoment.format("YYYY"));
    const month = Number(currentMoment.format("M"));
    const day = Number(currentMoment.format("D"));
    settoday({ year, month, day });
  }, []);

  const retrieveShifts = useCallback(async () => {
    try {
      barActivate();
      if (today) {
        setAllShifts({});
        const result = await getPublishedAndAvailableShiftsForMonth(
          today.year,
          today.month
        );
        setAllShifts(result);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Unable to retrieve shifts`, { variant: "error" });
    } finally {
      barStop();
    }
  }, [today, barActivate, barStop, enqueueSnackbar]);

  useEffect(() => {
    retrieveShifts();
  }, [retrieveShifts]);

  const shiftData = Object.keys(allShifts);

  const retrieveStatuses = useCallback(async () => {
    if (yearToDisplay && selectedRosters.length) {
      try {
        barActivate();
        const result = await getRosterStatusesYear({
          rosterIds: selectedRosters.map((r) => r.id),
          year: yearToDisplay,
        });
        setStatuses(result);
      } catch (err) {
        enqueueSnackbar("Unable to retrieve year statuses", {
          variant: "error",
        });
      } finally {
        barStop();
      }
    }
  }, [barActivate, barStop, enqueueSnackbar, selectedRosters, yearToDisplay]);

  useEffect(() => {
    retrieveStatuses();
  }, [retrieveStatuses]);

  useEffect(() => {
    const y = Number(year);
    if (y) {
      setYearToDisplay(y);
      setYearCounter(y);
    }
  }, [year]);

  useEffect(() => {
    if (yearCounter === yearToDisplay) {
      return;
    }
    history.push(`/roster/year/${yearCounter}`);
  }, [yearCounter, yearToDisplay, history]);

  useEffect(() => {
    if (yearToShow && monthToShow) {
      setCurrentMoment(moment(`${yearToShow}-${monthToShow}`, "YYYY-MM"));
    }
  }, [yearToShow, monthToShow]);

  useEffect(() => {
    const m = Number(month);
    const y = Number(year);
    if (year && y && month && m) {
      if (m && m > 0 && m <= 12) setMonthToShow(m);
      if (y && y >= 2000 && y <= 2100) setYearToShow(y);
    } else {
      const d = new Date();
      setMonthToShow(d.getMonth() + 1);
      setYearToShow(d.getFullYear());
    }
  }, [month, year]);

  const { pathname } = useLocation();

  const [calendarViewSelectorText, setCalendarViewSelectorText] =
    useState<string>("");

  const adjustCalendarViewSelectorText = useCallback(() => {
    if (pathname.includes("day")) {
      setCalendarViewSelectorText("Day");
    } else if (pathname.includes("month")) {
      setCalendarViewSelectorText("Month");
    } else {
      setCalendarViewSelectorText("Year");
    }
  }, [pathname]);

  useEffect(() => {
    adjustCalendarViewSelectorText();
  }, [adjustCalendarViewSelectorText]);

  const handleCalendarViewSelectChange = useCallback(
    ({ value }: { title: string; value?: number | string }) => {
      const [, , , year, , month] = pathname.split("/");
      if (value === "year") {
        // go current year or year in the pathname
        history.push(`/roster/year/${year ? year : ""}`);
      } else if (value === "month") {
        // go to current month or month in the pathname
        const d = new Date();
        history.push(
          `/roster/year/${year ? year : d.getFullYear()}/month/${
            month ? month : ""
          }`
        );
      } else if (value === "day") {
        // go to today
        const d = new Date();
        history.push(
          `/roster/year/${d.getFullYear()}/month/${
            d.getMonth() + 1
          }/day/${d.getDate()}`
        );
      }
    },
    [history, pathname]
  );

  if (!yearToShow || !monthToShow) {
    return null;
  }

  if (!currentMoment) {
    return null;
  }

  return (
    <Fragment>
      <Divider className={styles.rsspace} />
      <Box className={styles.framecontainer}>
        <Box className={styles.monthcalenderbox}>
          <Box className={styles.calenderbox}>
            <Box className={styles.calendermenuoverlay}>
              <Box className={styles.calendermenuoverlaybox}>
                <Box className={styles.calenderbutton}>
                  <DateNavButtonLeft
                    onClick={() => setYearCounter(yearCounter - 1)}
                  />
                  <DateNavText>{yearToDisplay}</DateNavText>
                  <DateNavButtonRight
                    onClick={() => setYearCounter(yearCounter + 1)}
                  />
                </Box>
                <Box className={styles.calendermenubtn}>
                  <Dropdown
                    textShown={calendarViewSelectorText}
                    onSelect={handleCalendarViewSelectChange}
                    options={[
                      { title: "Year", value: "year" },
                      { title: "Month", value: "month" },
                      { title: "Day", value: "day" },
                    ]}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <YearCalendar
          year={yearToDisplay}
          statuses={statuses}
          path="roster"
          shift={shiftData}
        />
      </Box>
    </Fragment>
  );
};

export default RosterYearView;
