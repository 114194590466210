import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";
import ChangePasswordForm, {
  ChangePasswordSubmitValues,
} from "./ChangePasswordForm";
import { requestChangePassword } from "./requests";
import { useProgressBar } from "../../../components/bars/ProgressBarGlobal";
import PageHeader from "../../../components/PageHeader";
import { RWrapper } from "../../../components/layout/RWrapper";

const ChangePasswordScreen = () => {
  const history = useHistory();
  const [apiError, setapiError] = useState("");
  const { barActivate, barStop } = useProgressBar();

  let timeout: NodeJS.Timeout;

  const submit = async (values: ChangePasswordSubmitValues) => {
    if (!values.oldPassword || !values.newPassword || !values.confirmPassword)
      return;
    try {
      barActivate();
      await requestChangePassword(
        values.oldPassword,
        values.newPassword,
        values.confirmPassword
      );
      history.push("/settings");
    } catch (error) {
      localStorage.clear();
      clearTimeout(timeout);
      timeout = setTimeout(() => setapiError(""), 3000);
      if (error.response && error.response.status === 400) {
        setapiError(error.response.data.message);
      } else {
        setapiError("Something went wrong. Please try again later");
      }
    } finally {
      barStop();
    }
  };

  return (
    <RWrapper>
      <div className={style.container}>
        <PageHeader title="Change Password" />
        <div className={style.inheadercontainer}>
          <ChangePasswordForm onSubmit={submit} externalError={apiError} />
        </div>
      </div>
    </RWrapper>
  );
};

export default ChangePasswordScreen;
