import React from "react";
import style from "./style.module.css";
import { motion } from "framer-motion";
import { FormProps } from "../ForgotPasswordForm";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";

interface EmailProp {
  email: string;
}

export default ({ onSubmit, history, email = "" }: FormProps & EmailProp) => {
  return (
    <motion.div
      className={style.container}
      initial={{ opacity: 0, translateX: 300, translateY: -30 }}
      animate={{ opacity: 1, translateX: 0, translateY: 0 }}
    >
      <div className={style.box}>
        <p className={style.title}>Reset Password</p>
        <p className={style.text}>
          We have sent a reset password link to your email address{" "}
          <b>{email}</b>. Follow the instructions in the email to reset your
          password.
        </p>
        <StandardButton
          title="Resend Email"
          onClick={() => onSubmit({ email })}
        />
      </div>
    </motion.div>
  );
};
