import React, { useState, useEffect } from "react";
import styles from "../../../css/style.module.css";
import { Formik, Field, Form } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { requestCheckKey } from "../requests";
import { History } from "history";
import { MdOutlineVisibility } from "react-icons/md";
import { AiOutlineEyeInvisible } from "react-icons/ai";

interface PasswordFormProps {
  onSubmit: ({ password, confirmPassword }: { password: string, confirmPassword:string }) => void;
  history: History;
  resetKey: string;
}

const validation = ({ password, confirmPassword }: { password: string, confirmPassword: string, passwordNotMatched?: string }) => {
  const errors: { [key: string]: string } = {};
  if (!password) {
    errors.password = "New Password is required";
  } else if (password.length < 6) {
    errors.password = "New Password is too short";
  } else if (!confirmPassword) {
    errors.confirmPassword = "Confirm Password is required";
  } else if (confirmPassword.length < 6) {
    errors.confirmPassword = "Confirm Password is too short";
  } else if (password !== confirmPassword) {
    errors.passwordNotMatched =
      "New Passwod and Confirm Password must be matched!";
  }
  return errors;
};

export default ({ onSubmit, history, resetKey }: PasswordFormProps) => {
  const [keyChecked, setkeyChecked] = useState(false);
  const [keyValid, setkeyValid] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  function toggleShowPassword2() {
    setshowConfirmPassword(!showConfirmPassword);
  }

  // WHEN RENDERS CHECK IF KEY IS VALID
  useEffect(() => {
    _checkKey(resetKey);
  }, [resetKey]);

  const _checkKey = async (resetKey: string) => {
    if (!resetKey) {
      setkeyChecked(true);
      return;
    }
    try {
      await requestCheckKey(resetKey);
      setkeyValid(true);
    } catch (err) {
      setkeyValid(false);
    } finally {
      setkeyChecked(true);
    }
  };

  if (!keyChecked)
    return (
      <motion.div
        className={styles.spinner}
        animate={{
          opacity: [0.3, 0.7, 1, 0.7, 0.3],
          scale: [1, 1.5, 1.5, 1, 1],
          rotate: [0, 0, 270, 270, 0],
          borderRadius: ["20%", "20%", "50%", "50%", "20%"],
        }}
        transition={{
          loop: Infinity,
          duration: 2,
        }}
      />
    );

  if (!keyValid)
    return (
      <div className={`${styles.errorblock} ${styles.erropacity1}`}>
        <span className={styles.errortext}>Link Is Invalid</span>
      </div>
    );

  return (
    <AnimatePresence>
      <motion.div
        className={`${styles.formcontainer} ${styles.loginform}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Formik
          initialValues={{ email: "", password: "", confirmPassword: "", }}
          validate={validation}
          onSubmit={onSubmit}
        >
          {({ errors, isValid, dirty, touched }) => (
            <Form className={styles.form} noValidate>
              {/* EMAIL FIELD IS HERE ONLY TO BROWSER'S UPDATE PASSWORD DIALOGUE */}
              <Field
                name="email"
                type="email"
                className={styles.input}
                autoComplete="email"
                hidden
              />
              <label htmlFor="New_password">New password</label>
              <div className={styles.showpassword}>
                <Field
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="New password"
                  className={`${styles.input} ${
                    errors.password && touched.password ? "input-error" : null
                  }`}
                  autoComplete="new-password"
                />
                <span onClick={toggleShowPassword}>
                  {showPassword ? (
                    <span className={styles.showbtn}>
                      <MdOutlineVisibility />
                    </span>
                  ) : (
                    <span className={styles.showbtn}>
                      <AiOutlineEyeInvisible />
                    </span>
                  )}
                </span>
                {errors.password && (
                  <div className={`${styles.errorblock}`}>
                    {errors.password}
                  </div>
                )}
                <ul className={styles.formlisting}>
                  <li>Must be at least 8 charactes</li>
                  <li>An upper case character</li>
                  <li>A number</li>
                  <li>A special symbol</li>
                </ul>
              </div>
              <label htmlFor="Confirm_password">Confirm password</label>
              <div className={styles.showpassword}>
                  <Field
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="•••••••••••"
                  className={`${styles.input} ${errors.confirmPassword && touched.confirmPassword ? "input-error" : null}`}
                  autoComplete="current-password"
                  />
                  <span onClick={toggleShowPassword2}>
                  {showConfirmPassword ? (
                    <span className={styles.showbtn}><MdOutlineVisibility /></span>

                  ) : (
                    <span className={styles.showbtn}><AiOutlineEyeInvisible /></span>
                  )}
                </span>
              </div>
              {errors.confirmPassword && touched.confirmPassword && (<div className={`${styles.errorblock}`}>{errors.confirmPassword}</div>)} 
              <div className={styles.buttonsrow}>
                <Link to="/login" className={`${styles.forgotpasswordtext} `}>
                  Login
                </Link>
              </div>
              <motion.button
                type="submit"
                className={styles.button}
                whileHover={!dirty || !isValid ? {} : { scale: 1.1 }}
                whileTap={!dirty || !isValid ? {} : { scale: 0.9 }}
                disabled={!dirty || !isValid}
              >
                <span className={styles.buttonText}>Save Password</span>
              </motion.button>
            </Form>
          )}
        </Formik>
        <Link
          to="/login"
          className={`${styles.forgotpasswordtext} hidewhenmedium ${styles.margin20em}`}
        >
          Login
        </Link>
      </motion.div>
    </AnimatePresence>
  );
};
