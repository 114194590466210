import React, { useState, useEffect } from "react";
import { RWrapper } from "../../../components/layout/RWrapper";
import styled from "styled-components";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Pagination } from "@material-ui/lab";
import Dropdown from "../../../components/inputs/Dropdown";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import SearchBar from "material-ui-search-bar";
import {
  getListEmail,
  GetListEmailResponse,
  ListEmail,
} from "../../../requests/email";
import { useDebounce } from "../../../hooks/useDebounce";
import ReactHtmlParser from "react-html-parser";

const TitleBox = styled.div`
  margin: 0.5em 0;
`;
const ContentContainer = styled.div`
  margin-top: 8px;
`;
const DropdownContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
const DropdownHolder = styled.div`
  width: 100px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  margin-right: ${({ theme }) => theme.gap[1]};
  height: fit-content;
  &:last-child {
    margin-right: 0;
  }
`;
const FlexFiller = styled.div`
  display: flex;
  flex: 1;
`;
const Spacer = styled.div`
  height: ${({ theme }) => theme.gap[1]};
`;
type StatusItem =
  | { title: "All"; value: undefined }
  | { title: "Sent"; value: "sent" }
  | { title: "Failed"; value: "failed" }
  | { title: "Queued"; value: "queued" };
type Order = { title: string; value: string };

function SentEmailsScreen() {
  const [order, setOrder] = useState<Order>({ title: "Desc", value: "DESC" });
  const [status, setStatus] = useState<StatusItem>({
    title: "All",
    value: undefined,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [limit, setLimit] = useState<number>(10);
  const [pages, setPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const { data, isLoading } = useQuery<GetListEmailResponse, AxiosError>(
    ["get-emails", order, status, limit, page, search],
    () => {
      return getListEmail(
        limit,
        page,
        search,
        status.value,
        order.value as "ASC" | "DESC"
      );
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get sent Emails`,
          { variant: "error" }
        );
      },
    }
  );

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setPage(value);
  }

  useEffect(() => {
    if (data) {
      let total = Math.ceil(data.searchedCount / limit);
      setPages(total);
    }
  }, [data, limit]);

  const requestSearch = (searchVal: string) => {
    if (data) {
      setSearch(searchVal);
      setPage(1);
    }
  };
  const onChangeDebounced = useDebounce(requestSearch, 500);

  const cancelSearch = () => {
    setSearch("");
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <RWrapper>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <TitleBox>
          <Typography variant="h4" color="textPrimary">
            Sent Emails
          </Typography>
        </TitleBox>
        <SearchBar
          value={search}
          onChange={(searchVal) => onChangeDebounced(searchVal)}
          onCancelSearch={() => cancelSearch()}
          cancelOnEscape
          style={{ width: "40%" }}
        />
      </Box>
      <Divider />
      <ContentContainer>
        <DropdownContainer>
          <Pagination
            count={pages}
            page={page}
            showFirstButton
            showLastButton
            onChange={handlePageChange}
          />
          <FlexFiller />
          <DropdownHolder>
            <Dropdown
              textShown={`${limit}`}
              onSelect={(v) => {
                setLimit(v.value as number);
                setPage(1);
              }}
              options={[
                { title: "10", value: 10 },
                { title: "20", value: 20 },
                { title: "50", value: 50 },
                { title: "100", value: 100 },
                { title: "200", value: 200 },
              ]}
            />
          </DropdownHolder>
          <DropdownHolder>
            <Dropdown
              textShown={order.title}
              options={[
                { title: "Desc", value: "DESC" },
                { title: "Asc", value: "ASC" },
              ]}
              onSelect={(v) =>
                setOrder({ title: v.title, value: v.value as string })
              }
            />
          </DropdownHolder>
          <DropdownHolder>
            <Dropdown
              textShown={status.title}
              options={[
                { title: "All", value: undefined },
                { title: "Sent", value: "sent" },
                { title: "Queued", value: "queued" },
                { title: "Failed", value: "failed" },
              ]}
              onSelect={(item) => setStatus(item as StatusItem)}
            />
          </DropdownHolder>
        </DropdownContainer>
        <Spacer />
        {isLoading ? (
          <Typography
            style={{ textAlign: "center" }}
            variant="h5"
            color="textPrimary"
          >
            Loading...
          </Typography>
        ) : (
          <>
            {data && data.searchedCount === 0 && (
              <>
                <Typography
                  style={{ textAlign: "center" }}
                  variant="h5"
                  color="textPrimary"
                >
                  No search Result Found
                </Typography>
              </>
            )}
            {data &&
              data.data.map((email: ListEmail, index: number) => (
                <div key={index}>
                  <Accordion
                    expanded={expanded === `${index}`}
                    onChange={handleChange(`${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={4} md={3}>
                          <Typography>To: {email.toEmail}</Typography>
                        </Grid>
                        <Grid item xs={5} md={6}>
                          <Typography style={{ fontWeight: "bold" }}>
                            {email.subject}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <Typography
                            style={{
                              fontWeight: "normal",
                              textAlign: "center",
                              color: "#5b5b5b",
                            }}
                          >
                            {moment(email.sentAt).format(
                              "DD/MM/YYYY hh:mm:ssA"
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} md={1} container justify="center">
                          <Chip
                            style={{
                              marginInline: 5,
                              color: `${
                                email.status == "sent"
                                  ? "green"
                                  : email.status == "failed"
                                  ? "red"
                                  : email.status == "queued"
                                  ? "orange"
                                  : "default"
                              }`,
                              fontSize: "0.85rem",
                              padding: "10px",
                              fontWeight: "normal",
                            }}
                            size="small"
                            variant="outlined"
                            label={
                              email.status.charAt(0).toUpperCase() +
                              email.status.slice(1)
                            }
                          />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        backgroundColor: `#f9f9f9`,
                      }}
                    >
                      <Box>
                        <Typography
                          component="div"
                          style={{ marginLeft: 5, fontWeight: "bold" }}
                          gutterBottom
                        >
                          From:
                          <Chip
                            style={{
                              marginLeft: 5,
                              fontSize: "0.85rem",
                              fontWeight: "normal",
                            }}
                            size="small"
                            label={email.fromEmail}
                            variant="outlined"
                          />
                        </Typography>
                        <Typography
                          component="div"
                          style={{ marginLeft: 5, fontWeight: "bold" }}
                          gutterBottom
                        >
                          To:
                          <Chip
                            style={{
                              marginLeft: 5,
                              fontSize: "0.85rem",
                              fontWeight: "normal",
                            }}
                            size="small"
                            label={email.toEmail}
                            variant="outlined"
                          />
                        </Typography>
                        <Typography
                          style={{ marginTop: 10 }}
                          variant="body1"
                          gutterBottom
                        >
                          {ReactHtmlParser(email.body)}
                        </Typography>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
          </>
        )}
        <Spacer />
        <Pagination
          count={pages}
          page={page}
          showFirstButton
          showLastButton
          onChange={handlePageChange}
        />
      </ContentContainer>
    </RWrapper>
  );
}
export default SentEmailsScreen;
