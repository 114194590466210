import { Box, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { useProgressBar } from "../../../componentsV3/bars/ProgressBarGlobal";
import Dropdown from "../../../componentsV3/inputs/Dropdown";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import { useUserSelections } from "../../../context/userSelectionsProvider";
import styles from "../../../css/style.module.css";
import { getUserDetails } from "../../../requests/user";
import {
  Roster,
  requestGetLocations,
  requestGetRosters,
} from "../../RosterScreen/requests";
import { User } from "../../StaffScreen";
import UserRequestShiftSwapScreen from "../UserRequestShiftSwapScreen";
import { ShiftProvider } from "./Provider";
import RosterDayView from "./views/RosterDayView";
import RosterMonthView from "./views/RosterMonthView";
import RosterYearView from "./views/RosterYearView";

export interface Location {
  id?: number;
  locationName: string;
}

const useStyles = makeStyles((theme) => ({
  snackGreen: {
    backgroundColor: theme.palette.success.main,
  },
  snackRed: {
    backgroundColor: theme.palette.error.main,
  },
  cross: {
    color: "#ffffff",
  },
  floatingButtonTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  mr: {
    marginRight: theme.spacing(1),
  },
  rightSide: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const UserRequestSwapScreenPage = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [allLocations, setAllLocations] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { barActivate, barStop } = useProgressBar();
  const {
    selectedLocation: locationSelected,
    setLocation,
    // selectedRosters: rostersSelected,
    setRosters: setRostersSelected,
    initialized: selectedInitialized,
  } = useUserSelections();

  const [locations, setLocations] = useState<Array<Location>>([]);

  const [calendarViewSelectorText, setCalendarViewSelectorText] =
    useState<string>("");

  const adjustCalendarViewSelectorText = useCallback(() => {
    if (pathname.includes("day")) {
      setCalendarViewSelectorText("Day");
    } else if (pathname.includes("month")) {
      setCalendarViewSelectorText("Month");
    } else {
      setCalendarViewSelectorText("Year");
    }
  }, [pathname]);

  useEffect(() => {
    adjustCalendarViewSelectorText();
  }, [adjustCalendarViewSelectorText]);

  const retrieveRosters = useCallback(async () => {
    if (locationSelected && locationSelected.id) {
      try {
        const rn = await requestGetRosters(locationSelected.id);
        setRostersSelected(rn);
      } catch (error) {
        enqueueSnackbar("Unable to retrieve rosters", { variant: "error" });
      }
    }
  }, [locationSelected, setRostersSelected, enqueueSnackbar]);

  useEffect(() => {
    retrieveRosters();
  }, [retrieveRosters]);

  const getLocations = useCallback(async () => {
    try {
      barActivate();
      const result = await requestGetLocations();
      setLocations(result);
      if (locationSelected && result.length) {
        const found = result.find((e) => {
          return e.id === locationSelected.id;
        });
        if (found) {
          if (found.locationName !== locationSelected.locationName) {
            setLocation(found);
          }
        } else {
          setLocation(result[0]);
        }
      } else if (!locationSelected && result.length) {
        setLocation(result[0]);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to retrieve locations", { variant: "error" });
    } finally {
      barStop();
    }
  }, [locationSelected, setLocation, enqueueSnackbar, barStop, barActivate]);

  useEffect(() => {
    if (selectedInitialized) {
      // GET LOCATIONS
      getLocations();
    }
  }, [selectedInitialized, getLocations]);

  const retrieveRostersAll = useCallback(async () => {
    if (allLocations) {
      let listRosters: Roster[] = [];
      await Promise.all(
        locations.map(async (loc) => {
          if (loc && loc.id) {
            try {
              const rn = await requestGetRosters(loc.id);
              rn.map((value) => {
                if (value) {
                  listRosters.push(value);
                }
                return value;
              });
              let allRosters = listRosters.slice();
              setRostersSelected(allRosters);
            } catch (error) {
              enqueueSnackbar("Unable to retrieve rosters", {
                variant: "error",
              });
            }
          }
        })
      );
    }
  }, [allLocations, locations, setRostersSelected, enqueueSnackbar]);

  useEffect(() => {
    retrieveRostersAll();
  }, [retrieveRostersAll]);

  const handleCalendarViewSelectChange = useCallback(
    ({ value }: { title: string; value?: number | string }) => {
      const [, , , year, , month] = pathname.split("/");
      if (value === "year") {
        // go current year or year in the pathname
        history.push(`/request-swap/year/${year ? year : ""}`);
      } else if (value === "month") {
        // go to current month or month in the pathname
        const d = new Date();
        history.push(
          `/request-swap/year/${year ? year : d.getFullYear()}/month/${month ? month : ""
          }`
        );
      } else if (value === "day") {
        // go to today
        const d = new Date();
        history.push(
          `/request-swap/year/${d.getFullYear()}/month/${d.getMonth() + 1
          }/day/${d.getDate()}`
        );
      }
    },
    [history, pathname]
  );

  const getUser = useCallback(async () => {
    try {
      const u = await getUserDetails();
      if (u?.userLocation) {
        const userLoc = { id: u.userLocation.id, locationName: u.userLocation.name };
        setLocation(userLoc)
      }
    } catch (error) {
      console.error(error);
    } finally {
      barStop();
    }
  }, [setLocation, barStop]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <RWrapper>
      {pathname !== "/request-swap/request-shift-swap" && (
        <>
          <Box className={styles.pagetitlebox}>
            <Typography variant="h4" color="textPrimary">
              Roster
            </Typography>
            <Box className={classes.rightSide}>
              <Dropdown
                textShown={calendarViewSelectorText}
                onSelect={handleCalendarViewSelectChange}
                options={[
                  { title: "Year", value: "year" },
                  { title: "Month", value: "month" },
                  { title: "Day", value: "day" },
                ]}
              />
            </Box>
          </Box>
          <Box className={styles.userroostertab}>
            <Box className={styles.roostertab}>
              <Box className={styles.roostertabbtn}>
                <IconButton
                  className={styles.tabbtn}
                  style={
                    allLocations
                      ? { background: "#FFFFFF", color: "#282828" }
                      : { background: "#F2F2F2" }
                  }
                  onClick={() => {
                    setAllLocations(true);
                  }}
                >
                  All Locations
                </IconButton>
                {locations.map((l) => (
                  <IconButton
                    key={l.id}
                    className={styles.tabbtn}
                    style={
                      l.id === locationSelected?.id && !allLocations
                        ? { background: "#FFFFFF", color: "#282828" }
                        : { background: "#F2F2F2" }
                    }
                    onClick={() => {
                      setAllLocations(false);
                      if (l.id !== locationSelected?.id) {
                        setLocation(locations.find((e) => e.id === l.id));
                      }
                    }}
                  >
                    {l.locationName}
                  </IconButton>
                ))}
              </Box>
            </Box>
          </Box>
        </>
      )}
      <Switch>
        <Route exact path={`/request-swap/year/:year?`}>
          <RosterYearView />
        </Route>
        <Route exact path={`/request-swap/year/:year/month/:month?`}>
          <RosterMonthView />
        </Route>
        <Route exact path={`/request-swap/year/:year/month/:month/day/:day`}>
          <RosterDayView />
        </Route>
        <Route exact path={`/request-swap/request-shift-swap`}>
          <UserRequestShiftSwapScreen />
        </Route>
        <Route>
          <Redirect to="/request-swap/year" />
        </Route>
      </Switch>
    </RWrapper>
  );
};

const UserRequestSwapScreen = () => (
  <ShiftProvider>
    <UserRequestSwapScreenPage />
  </ShiftProvider>
);
export default UserRequestSwapScreen;
