import React from "react";
import styled from "styled-components";
import OfferSwapCard from "./offer-swap-card";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { Offer, OfferStatus } from "../../../requests/offers";

const ExpandedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${({ theme }) => theme.gap[1]} 0;
`;

const SwapCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: ${({ theme }) => theme.gap[1]};
  width: 100px;
`;

const IconContainer = styled.div`
  margin: 0 ${({ theme }) => theme.gap[1]};
`;

const Button = styled.div<{ color?: string }>`
  border: 1px solid
    ${({ theme, color }) => (color ? color : theme.colors.greyish)};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  color: ${({ theme, color }) => (color ? color : theme.colors.brownGrey)};
  padding: ${({ theme }) => theme.gap[1]};
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
`;

interface Props {
  offer: Offer;
  onCancelClick: (offerId: number) => void;
}
function OfferCardsBlock({ offer, onCancelClick }: Props) {
  return (
    <ExpandedContainer>
      <SwapCardsContainer>
        <OfferSwapCard shift={offer.outboundShift} user={offer.outboundUser} />
        <IconContainer>
          <SwapHorizIcon color="action" />
        </IconContainer>
        <OfferSwapCard shift={offer.inboundShift} user={offer.inboundUser} />
      </SwapCardsContainer>
      <ActionButtonsContainer>
        {offer.status === OfferStatus.PENDING ? (
          <Button onClick={() => onCancelClick(offer.id)} color="#aa0000">
            Cancel offer
          </Button>
        ) : null}
      </ActionButtonsContainer>
    </ExpandedContainer>
  );
}

export default OfferCardsBlock;
