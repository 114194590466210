import React, { useState, useEffect, useCallback } from "react";
import { RWrapper } from "../../components/layout/RWrapper";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Snackbar,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { parse } from "qs";
import moment from "moment";
import { getLocation } from "../../requests/locations";
import { TenantLocation } from "../../types/locations";
import { requestGetRosters, Roster, Shift } from "../RosterScreen/requests";
import { getShift, postShifts } from "../../requests/shifts";
import { Formik, Form } from "formik";
import AutocompleteBorderedFormik from "../../components/inputs/AutocompleteBorderedFormik";
import { requestGetUsers } from "../StaffScreen/requests";
import { User } from "../StaffScreen";
import DatePickerFormik from "../../components/inputs/Dates/DatePickerFormik";
import TimePickerBorderedFormik from "../../components/inputs/Dates/TimePickerBorderedFormik";
import StandardButton from "../../components/Buttons/StandardButton";
import * as yup from "yup";
import TextInputBorderedFormik from "../../components/inputs/TextInputBorderedFormik";
import CheckBoxFormik from "../../components/inputs/CheckBoxFormik";

interface SelectedDate {
  year: number;
  month: number;
  day: number;
}

interface ShiftToEdit {
  id?: number;
  onCall: boolean;
  status: "draft" | "published";
  rosterId?: number;
  rosteredUser?: number;
  shiftName?: string;
  startDate?: string;
  startTime?: string;
  endTime?: string;
}

const useStyles = makeStyles((theme) => {
  const rowHeight = theme.typography.fontSize * 2.5;
  return {
    pagetitle: {
      margin: `0.5em 0`,
      display: "flex",
      alignItems: "center",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      // flex: 1,
      width: "100%",
      // backgroundColor: "gold",
      marginTop: theme.spacing(1),
    },
    fieldOuter: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: theme.shape.borderRadius,
      height: rowHeight,
      backgroundColor: theme.palette.background.paper,
      padding: "8px 10px",
      boxSizing: "border-box",
    },
    outerfieldOnCall: {
      display: "flex",
      height: rowHeight,
      width: rowHeight,
      padding: 7,
      boxSizing: "border-box",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      marginLeft: theme.spacing(1),
    },
    shiftNameOnCallHolder: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    shiftNameOnCallTitleHolder: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      marginRight: -3,
    },
    shiftNameOnCallInputsHolder: {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
    },
    locationText: {
      width: "100%",
      textAlign: "left",
    },
    saveButtonHolder: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
    },
    onCallText: {
      whiteSpace: "nowrap",
      textAlign: "center",
    },
    snackGreen: {
      backgroundColor: theme.palette.success.main,
    },
    snackRed: {
      backgroundColor: theme.palette.error.main,
    },
    cross: {
      color: theme.palette.common.white,
    },
    arrow: {
      marginRight: theme.spacing(1),
      height: "100%",
      width: 50,
      borderRadius: theme.shape.borderRadius,
      transition: "all 0.2s ease-out",
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
  };
});

const validationSchema = yup.object({
  id: yup.number(),
  onCall: yup.boolean(),
  rosterId: yup.number().required("roster is not selected"),
  rosteredUser: yup.number().notRequired(),
  shiftName: yup.string().required("required"),
  startDate: yup.string().required("reqired"),
  startTime: yup.string().required("required"),
  endTime: yup.string().required("required"),
});

const convertExistingShiftDetails = (s?: Shift): ShiftToEdit => {
  if (s) {
    return {
      id: s.id,
      onCall: s.onCall,
      status: s.status,
      rosterId: s.rosterId,
      rosteredUser: s.rosteredUser,
      shiftName: s.shiftName,
      startDate: moment(s.startDts).format("YYYY-MM-DD"),
      startTime: moment(s.startDts).format("HH:mm"),
      endTime: moment(s.endDts).format("HH:mm"),
    };
  } else {
    return {
      id: undefined,
      onCall: false,
      status: "draft",
      rosterId: undefined,
      rosteredUser: undefined,
      shiftName: "",
      startDate: "",
      startTime: "",
      endTime: "",
    };
  }
};

export default function ShiftScreen() {
  const c = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { shiftId } = useParams<{ shiftId: string }>();
  const [date, setdate] = useState<undefined | SelectedDate>(undefined);
  const [dateOfTheShift, setdateOfTheShift] = useState<string | undefined>(
    undefined
  );
  const [locationId, setlocationId] = useState<number | undefined>(undefined);
  const [locationDetails, setlocationDetails] = useState<
    TenantLocation | undefined
  >(undefined);
  const [rosters, setrosters] = useState<Array<Roster>>([]);
  const [shiftDetails, setshiftDetails] = useState<ShiftToEdit>(
    convertExistingShiftDetails()
  );
  const [possibleAssignees, setpossibleAssignees] = useState<Array<User>>([]);
  const [accent, setaccent] = useState<string | undefined>(undefined);

  const [snackMessage, setsnackMessage] = useState<string>("");
  const [snackOpen, setsnackOpen] = useState<boolean>(false);
  const [snackType, setsnackType] = useState<"success" | "error" | undefined>(
    undefined
  );

  useEffect(() => {
    if (shiftDetails) {
      const d = shiftDetails.startDate;
      if (d) {
        setdateOfTheShift(moment(d, "YYYY-MM-DD").format("YYYY/M/D"));
      }
    }
  }, [shiftDetails]);

  // effect to get query parameters from url
  useEffect(() => {
    const query = parse(location.search, { ignoreQueryPrefix: true });
    const l = Number(query.l);
    if (l) {
      setlocationId(l);
    }
    if (query.date) {
      const [y, m, d] = query.date.toString().split("-");
      const year = Number(y);
      const month = Number(m);
      const day = Number(d);
      if (year && month && day) {
        setdate({ year, month, day });
      }
      setdateOfTheShift(`${year}/${month}/${day}`);
    } else {
      const [y, m, d] = moment().format("YYYY-MM-DD").split("-");
      const year = Number(y);
      const month = Number(m);
      const day = Number(d);
      if (year && month && day) {
        setdate({ year, month, day });
      }
      setdateOfTheShift(`${year}/${month}/${day}`);
    }
  }, [location.search]);

  const retrieveLocation = useCallback(async () => {
    if (locationId) {
      try {
        const result = await getLocation(locationId);
        setlocationDetails(result);
      } catch (err) {
        console.error(err);
      }
    }
  }, [locationId]);

  const retrieveRosters = useCallback(async () => {
    if (locationId) {
      try {
        const result = await requestGetRosters(locationId);
        setrosters(result);
      } catch (err) {
        console.error(err);
        setsnackOpen(true);
        setsnackType("error");
        setsnackMessage("Unable to retrieve rosters");
      }
    }
  }, [locationId]);

  useEffect(() => {
    retrieveLocation();
    retrieveRosters();
  }, [retrieveLocation, retrieveRosters]);

  const retrieveShift = useCallback(async () => {
    if (rosters.length && shiftId && Number(shiftId)) {
      try {
        const result = await getShift(Number(shiftId));
        const s = convertExistingShiftDetails(result);
        setshiftDetails(s);
        if (s.rosterId && s.rosteredUser) {
          const selectedRoster = rosters.find((r) => r.id === s.rosterId);
          if (selectedRoster && selectedRoster.color) {
            setaccent(selectedRoster.color);
          }
          if (selectedRoster && selectedRoster.userTypes) {
            const userTypes = selectedRoster.userTypes.map((ut) => ut.id);
            if (userTypes.length) {
              const result = await requestGetUsers(userTypes);
              setpossibleAssignees(result);
            }
          }
        }

        // console.log("SHIFT DETAILS", s);
      } catch (err) {
        console.error(err);
        setsnackOpen(true);
        setsnackType("error");
        setsnackMessage("Failed to retrieve shift");
        // setshiftDetails(convertExistingShiftDetails());
      }
    }
  }, [shiftId, rosters]);

  useEffect(() => {
    retrieveShift();
  }, [retrieveShift]);

  const handleRosterChange = async (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void,
    rosteredUser?: number | null,
    rosterId?: number | string
  ) => {
    try {
      if (rosterId) {
        const selectedRoster = rosters.find((r) => r.id === rosterId);
        if (selectedRoster && selectedRoster.userTypes) {
          const userTypes = selectedRoster.userTypes.map((ut) => ut.id);
          const user = possibleAssignees.find((u) => u.id === rosteredUser);
          if (user && user.userType && !userTypes.includes(user.userType.id)) {
            setFieldValue("rosteredUser", null, true);
            setpossibleAssignees([]);
          }
          if (userTypes.length) {
            const result = await requestGetUsers(userTypes);
            setpossibleAssignees(result);
          }
        }
      } else {
        setFieldValue("rosteredUser", null, true);
        setpossibleAssignees([]);
      }
    } catch (err) {
      console.error(err);
      setsnackOpen(true);
      setsnackType("error");
      setsnackMessage("Unable to get assignees");
    }
  };

  const submit = async (v: ShiftToEdit) => {
    try {
      const startDts = moment(
        `${v.startDate} ${v.startTime}`,
        "YYYY-MM-DD HH:mm"
      );
      const endDts = moment(`${v.startDate} ${v.endTime}`, "YYYY-MM-DD HH:mm");
      if (endDts.isBefore(startDts)) {
        endDts.add(1, "day");
      }
      const s: Shift = {
        id: v.id,
        onCall: v.onCall,
        rosterId: v.rosterId as number,
        shiftName: v.shiftName as string,
        rosteredUser: v.rosteredUser,
        status: v.status,
        startDts: startDts.toDate(),
        endDts: endDts.toDate(),
      };
      const ids = await postShifts([s]);
      await retrieveShift();
      setsnackOpen(true);
      setsnackType("success");
      setsnackMessage("Saved");
      if (!shiftId && ids[0]) {
        history.replace(`/shift/${ids[0]}${location.search}`);
      }
    } catch (err) {
      setsnackOpen(true);
      setsnackType("error");
      setsnackMessage("Unable to save");
      console.error(err);
    }
  };

  if (!date || !locationDetails) {
    // loading
    return null;
  }

  return (
    <RWrapper>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setsnackOpen(false)}
        onExited={() => {
          setsnackMessage("");
          setsnackType(undefined);
        }}
        message={snackMessage}
        ContentProps={{
          className:
            snackType === "success"
              ? c.snackGreen
              : snackType === "error"
              ? c.snackRed
              : "",
        }}
        action={
          <Button
            size="small"
            onClick={() => setsnackOpen(false)}
            className={c.cross}
          >
            CLOSE
          </Button>
        }
      />

      <Box className={c.pagetitle}>
        {dateOfTheShift ? (
          <div className={c.arrow} onClick={() => history.goBack()}>
            <Typography variant="h4" color="textPrimary" align="center">
              {"<"}
            </Typography>
          </div>
        ) : (
          ""
        )}
        <Typography variant="h4" color="textPrimary">
          {shiftId ? "Shift" : "New Shift"}
        </Typography>
      </Box>

      <Divider />

      <div className={c.content}>
        <Formik
          onSubmit={submit}
          initialValues={shiftDetails}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            isSubmitting,
            isValid,
            dirty,
            setFieldValue,
          }) => {
            // console.log("values", values);
            // console.log("isValid", isValid);
            return (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Typography variant="body2" color="textSecondary">
                      Location
                    </Typography>
                    <div className={c.fieldOuter}>
                      <Typography
                        color="textSecondary"
                        className={c.locationText}
                      >
                        {locationDetails?.name}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Typography variant="body2" color="textSecondary">
                      Roster
                    </Typography>
                    <div className={c.fieldOuter}>
                      <AutocompleteBorderedFormik
                        name="rosterId"
                        options={rosters.map((r) => ({
                          title: r.name,
                          value: r.id,
                        }))}
                        onSelectedValueChange={(rosterId) => {
                          handleRosterChange(
                            setFieldValue,
                            values.rosteredUser,
                            rosterId
                          );
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Typography variant="body2" color="textSecondary">
                      Assign To
                    </Typography>
                    <div className={c.fieldOuter}>
                      <AutocompleteBorderedFormik
                        name="rosteredUser"
                        options={possibleAssignees.map((a) => ({
                          title:
                            a.firstName || a.lastName
                              ? `${a.firstName} ${a.lastName}`
                              : `Unknown User`,
                          value: a.id as number,
                        }))}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <div className={c.shiftNameOnCallHolder}>
                      <div className={c.shiftNameOnCallTitleHolder}>
                        <Typography variant="body2" color="textSecondary">
                          Shift Name
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={c.onCallText}
                        >
                          On Call
                        </Typography>
                      </div>
                      <div className={c.shiftNameOnCallInputsHolder}>
                        <div className={c.fieldOuter}>
                          <TextInputBorderedFormik name="shiftName" />
                        </div>
                        <div className={c.outerfieldOnCall}>
                          <CheckBoxFormik name="onCall" color={accent} />
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Typography variant="body2" color="textSecondary">
                      Date
                    </Typography>
                    <div className={c.fieldOuter}>
                      <DatePickerFormik
                        name="startDate"
                        date={
                          date
                            ? `${date.year}-${date.month
                                .toString()
                                .padStart(
                                  2,
                                  "0"
                                )}-${date.day.toString().padStart(2, "0")}`
                            : undefined
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={1}>
                    <Typography variant="body2" color="textSecondary">
                      Start Time
                    </Typography>
                    <div className={c.fieldOuter}>
                      <TimePickerBorderedFormik name="startTime" />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={1}>
                    <Typography variant="body2" color="textSecondary">
                      End Time
                    </Typography>
                    <div className={c.fieldOuter}>
                      <TimePickerBorderedFormik name="endTime" />
                    </div>
                  </Grid>
                </Grid>
                <div className={c.saveButtonHolder}>
                  <StandardButton
                    type="submit"
                    title="Save"
                    accent
                    disabled={!isValid || !dirty || isSubmitting}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </RWrapper>
  );
}
