import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./style.module.css";
import { Typography, ButtonBase } from "@material-ui/core";

interface StyleProps {
  accent?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => {
  return {
    primary: ({ accent }: StyleProps) => ({
      border: accent ? `1px solid transparent` : `1px solid #1890FF`,
      backgroundColor: accent ? theme.palette.primary.main : "#1890FF",
      color: accent ? "#FFFFFF" : "#FFFFFF",
      "&:hover": {
        backgroundColor: "transparent !important",
        color: "#1890FF !important",
        borderColor: "#1890FF",
        boxShadow: "none",
      },
      "&:focus, &:active": {
        border: accent ? `1px solid #1890FF` : `1px solid #1890FF`,
      },
      "&:disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
        border: "1px solid transparent",
        color: theme.palette.common.white,
        boxSizing: "border-box",
      },
    }),
    secondary: ({ accent }: StyleProps) => ({
      border: accent ? `1px solid #1890FF` : `1px solid #1890FF`,
      backgroundColor: accent ? theme.palette.primary.main : "transparent",
      color: accent ? "#1890FF" : "#1890FF",
      "&:hover": {
        backgroundColor: "#1890FF !important",
        color: "#FFFFFF !important",
        borderColor: "#1890FF",
        boxShadow: "none",
      },
      "&:focus, &:active": {
        border: accent ? `1px solid #1890FF` : `1px solid #1890FF`,
      },
      "&:disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
        border: "1px solid transparent",
        color: theme.palette.common.white,
        boxSizing: "border-box",
      },
    }),
    danger: ({ accent }: StyleProps) => ({
      border: accent ? `1px solid #FF0000` : `1px solid #FF3033`,
      backgroundColor: accent ? theme.palette.primary.main : "#FF3033",
      color: accent ? "#FFFFFF" : "#FFFFFF",
      "&:hover": {
        backgroundColor: "transparent !important",
        color: "#FF3033 !important",
        borderColor: "#FF3033",
        boxShadow: "none",
      },
      "&:focus, &:active": {
        border: accent ? `1px solid #FF3033` : `1px solid #FF3033`,
      },
      "&:disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
        border: "1px solid transparent",
        color: theme.palette.common.white,
        boxSizing: "border-box",
      },
    }),
  };
});

interface StandardButtonProps {
  title: string | JSX.Element;
  accent?: boolean;
  type?: "submit" | "button";
  disabled?: boolean;
  className?: string | any;
  onClick?: () => void;
}

export default ({
  title,
  type,
  onClick,
  disabled,
  className,
  accent,
}: StandardButtonProps) => {
  const c = useStyles({ accent, disabled });

  return (
    <ButtonBase
      className={` ${styles.container} 
      ${
        className?.toLowerCase().includes("primary")
          ? c.primary
          : className?.toLowerCase().includes("secondary")
          ? c.secondary
          : className?.toLowerCase().includes("danger")
          ? c.danger
          : c.primary
      }  
      ${className ?? ""}`}
      type={type ?? "button"}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography
        variant="body2"
        style={{ fontSize: "inherit", fontWeight: "inherit" }}
      >
        {title}
      </Typography>
    </ButtonBase>
  );
};
