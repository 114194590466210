import { Grid, Typography, Divider, Box } from "@material-ui/core";
import React from "react";
import { RWrapper } from "../../../components/layout/RWrapper";
import DoNotDisturbForm from "./do-not-disturb-time-form";
import UserDetailsForm from "./user-details-form";
import RoosterTheme from "../../adminView/settings/RoosterTheme";
import styled from "styled-components";
import TwoFAForm from "../../adminView/settings/two-factor-auth-form";

const PageTitleBox = styled.div`
  margin: ${({ theme }) => theme.gap[1]} 0;
`;

const SectionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  padding: ${({ theme }) => theme.gap[1]};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  box-sizing: border-box;
  min-height: 50px;
  height: 100%;
`;

const SectionTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.greyishBrown};
  display: flex;
  align-items: center;
`;

const Spacer = styled.div`
  height: ${({ theme }) => theme.gap[1]};
`;

function UserAccountScreen() {
  return (
    <RWrapper>
      <PageTitleBox>
        <Typography variant="h4" color="textPrimary">
          Account
        </Typography>
      </PageTitleBox>
      <Divider />
      <Spacer />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <SectionContainer>
            <SectionTitle>Sms reminders</SectionTitle>
            <Spacer />
            <DoNotDisturbForm />
          </SectionContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SectionContainer>
            <SectionTitle>Contact Information</SectionTitle>
            <Spacer />
            <UserDetailsForm />
          </SectionContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SectionContainer>
            <SectionTitle>Theme Settings</SectionTitle>
            <Spacer />
            <RoosterTheme />
          </SectionContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box style={{ backgroundColor: "white", padding: "8px" }}>
            <Typography
              variant="body1"
              color="textPrimary"
              style={{ marginBottom: 8 }}
            >
              Enable 2FA Authentication
            </Typography>
            <TwoFAForm />
          </Box>
        </Grid>
      </Grid>
    </RWrapper>
  );
}

export default UserAccountScreen;
