import React from "react";
import moment from "moment";
import { User } from "../../../StaffScreen";
import { Shift } from "../../../RosterScreen/requests";
interface OfferTimeCardProps {
  shift: Shift;
  user?: User;
}
function OfferTimeCard({ shift, user }: OfferTimeCardProps) {
  const start = moment(shift.startDts);
  const end = moment(shift.endDts);
  return (
    <div> {`${start.format("HH:mm")} - ${end.format("HH:mm")}`} </div>
  );
}
export default OfferTimeCard;
