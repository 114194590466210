import React from "react";
import { Formik, Field, Form } from "formik";
import style from "../../../css/style.module.css";
import { emailRegex } from "../../../GLOBALS";
import { motion, AnimatePresence } from "framer-motion";
import { History } from "history";

export interface SubmitValues {
  email: string;
}

export interface FormProps {
  onSubmit: ({ email }: SubmitValues) => void;
  history: History;
}

const validation = ({ email }: { email: string }) => {
  const errors: { [key: string]: string } = {};
  if (!email) {
    errors.email = "Email required";
  } else if (!emailRegex.test(email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export default ({ onSubmit, history }: FormProps) => {
  return (
    <AnimatePresence>
      <motion.div
        className={style.formcontainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={validation}
          onSubmit={onSubmit}
        >
          {({ errors, isValid, dirty, touched, isSubmitting }) => (
            <Form className={style.form} noValidate>
              
              <label htmlFor="Email">Email</label>
              <Field
                name="email"
                type="email"
                placeholder="@mail.com"
                className={`${style.input} ${errors.email && touched.email ? "input-error" : null}`}
                autoComplete="username"
                autoCorrect="off"
                autoCapitalize="none"
              />
              {errors.email && touched.email && (<div className={`${style.errorblock}`}>{errors.email}</div>)} 

              <div className={style.buttonsrow}>
                <motion.button style={{background:'#1890FF',}}
                  type="submit"
                  className={style.button}
                  disabled={isSubmitting || !dirty || !isValid}
                >
                <span className={style.buttonText}>Send reset instructions</span>
                </motion.button>
              </div>      

            </Form>
          )}
        </Formik>
      </motion.div>
    </AnimatePresence>
  );
};
