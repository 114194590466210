import { authorizedRequest } from "../../httprequests";

export interface TemplateMini {
  id: number;
  name: string;
}
export type GetTemplatesResponse = Array<TemplateMini>;

export const createNewTemplate = async (name: string): Promise<number> => {
  const response: {
    templateId: number;
  } = await authorizedRequest.post("/templates", { name });
  return response.templateId;
};

export const editTemplateName = async (
  id: number,
  name: string
): Promise<number> => {
  const { templateId } = await authorizedRequest.put(`/templates/${id}`, {
    name,
  });
  return templateId;
};

export const deleteTemplate = async (id: number): Promise<number> => {
  const { templateId } = await authorizedRequest.delete(`/templates/${id}`);
  return templateId;
};

export const requestGetTemplates = async (): Promise<GetTemplatesResponse> =>
  authorizedRequest.get("/templates");
