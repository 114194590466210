import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { getIn, useField, useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, { Fragment, InputHTMLAttributes, useState } from "react";
import MaskedInput from "react-input-mask";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import StandardButton from "../../../../../components/Buttons/StandardButton";
import Timeline24 from "../../../../../components/Timeline24";
import { RectangleBadgeFilled } from "../../../../../components/styled-components/badges";
import { Offer, deleteOffer } from "../../../../../requests/offers";
import { getUserDetails } from "../../../../../requests/user";
import { styledTheme } from "../../../../../themes/styledTheme";
import { Shift } from "../../../../RosterScreen/requests";
import { useShift } from "../../Provider";
import ShiftEditOfferRow from "./shift-edit-offer-row";
const Tr = styled.tr`
  // height: 32px;
`;

const Td = styled.td<{ padding?: string }>`
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  padding: ${({ padding }) => padding || "4px"};
  background-color: ${({ theme }) => theme.colors.background.paper};
  box-sizing: border-box;
`;

const StyledInput = styled.input<{ error?: boolean }>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.greyish};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  padding: 1px 2px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  &:disabled {
    border: none;
  }
  ${({ error, theme }) =>
    error ? `border: 1px solid ${theme.colors.deepRed};` : ""}
`;

const CheckboxRectangle = styled.div<{ active?: boolean }>`
  height: 20px;
  width: 20px;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  border: ${({ active, theme }) =>
    active ? "none" : `1px solid ${theme.colors.greyish}`};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.deepSkyBlue : "transparent"};
  transition: all 0.2s;
  box-sizing: border-box;
`;

const TimelineHolder = styled.div<{ height?: string }>`
  display: flex;
  flex: 1;
  align-items: center;
  min-height: ${({ height }) => height || "32px"};
`;

function CheckboxRect(
  props: InputHTMLAttributes<HTMLInputElement> & { name: string }
) {
  const [, { value }] = useField(props);
  return <CheckboxRectangle active={Boolean(value)} />;
}

function Input(
  props: InputHTMLAttributes<HTMLInputElement> & { name: string }
) {
  const [field, meta] = useField(props);
  return (
    <StyledInput
      {...field}
      {...props}
      error={Boolean(meta.error && meta.touched)}
    />
  );
}

function TimeInput(
  props: InputHTMLAttributes<HTMLInputElement> & { name: string }
) {
  const [field, meta] = useField(props);

  return (
    <MaskedInput mask="99:99" {...field} {...props}>
      {(inputProps: any) => {
        return (
          <StyledInput
            {...inputProps}
            disabled={props.disabled}
            error={Boolean(meta.error && meta.touched)}
          />
        );
      }}
    </MaskedInput>
  );
}


interface RosterDayViewFormRowProps {
  // shift: Shift;
  index: number;
  onRightClick: (det: {
    id?: number;
    index: number;
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>;
  }) => void;
  refetchShifts: () => void;
  selectedData: number[];
  setSelectedData: (arg0: number[]) => void;
}
function RosterDayViewFormRow({
  index,
  onRightClick,
  refetchShifts,
  selectedData,
  setSelectedData,
}: RosterDayViewFormRowProps) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { shifts, setShifts } = useShift();
  const { values } = useFormikContext<{
    shifts: Array<Shift>;
  }>();
  const shift = values.shifts[index];
  const [active, setActive] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const { current } = shift;
  const accent = getIn(values, `shifts.${index}.roster.color`);
  const offersOut = getIn(values, `shifts.${index}.offersOut`) || [];
  const offersIn = getIn(values, `shifts.${index}.offersIn`) || [];

  const start = getIn(values, `shifts.${index}.startTime`);
  const end = getIn(values, `shifts.${index}.endTime`);

  const { data } = useQuery(["getUserDetails"], async () => getUserDetails());

  const { mutate: removeOffer } = useMutation<unknown, AxiosError, number>(
    (offerId) => deleteOffer(offerId),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to cancel swap offer`,
          { variant: "error" }
        );
      },
      onSuccess: () => {
        refetchShifts();
        enqueueSnackbar(`Swap offer cancelled`, {
          variant: "success",
        });
      },
    }
  );

  return (
    <Fragment>
      <Tr
        onContextMenu={(event) => {
          event.preventDefault();
          onRightClick({ id: shift.id, index, event });
        }}
      >
        <Td>
          <Button
            title="Request"
            color={isOpen ? "primary" : undefined}
            variant="contained"
            style={{ textTransform: "none" }}
            disabled={
              shift.user === null ||
              shift.user?.fullName === data?.fullName ||
              (shifts.length && shifts.includes(shift))
            }
            onClick={() => setIsOpen(true)}
          >
            Request
          </Button>
        </Td>


        <Td>
          <Input name={`shifts.${index}.roster.name`} disabled={true} />
        </Td>

        <Td>
          <Input name={`shifts.${index}.owner.fullName`} value={`${shift.owner?.fullName ? shift.owner?.fullName : ''}`} disabled={true} />
        </Td>
        <Td>
          <Input name={`shifts.${index}.user.fullName`} value={`${shift.user?.fullName ? shift.user?.fullName : ''}`} disabled={true} />
        </Td>
        <Td>
          <Input name={`shifts.${index}.shiftName`} disabled={true} />
        </Td>
        <Td>
          <CheckboxRect name={`shifts.${index}.available`} />
        </Td>
        <Td>
          <CheckboxRect name={`shifts.${index}.onCall`} />
        </Td>
        <Td>
          <TimeInput name={`shifts.${index}.startTime`} disabled={true} />
        </Td>
        <Td>
          <TimeInput name={`shifts.${index}.endTime`} disabled={true} />
        </Td>
        <Td>
          <RectangleBadgeFilled
            backgroundColor={
              shift.status === "published"
                ? "green"
                : styledTheme.colors.deepRed
            }
          >
            {shift.status}
          </RectangleBadgeFilled>
        </Td>
        <Td>
          <TimelineHolder height="0">
            <Timeline24
              time={{ start, end }}
              current={current}
              previous={!current}
              color={accent}
            />
          </TimelineHolder>
        </Td>
      </Tr>

      <Tr>
        <Td colSpan={10} padding="0">
          <Collapse in={active} timeout="auto" mountOnEnter>
            {offersOut.map((offer: Offer) => (
              <ShiftEditOfferRow
                key={offer.id}
                offer={offer}
                deleteOffer={removeOffer}
              />
            ))}
            {offersIn.map((offer: Offer) => (
              <ShiftEditOfferRow
                key={offer.id}
                offer={offer}
                deleteOffer={removeOffer}
              />
            ))}
          </Collapse>
        </Td>
      </Tr>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Request shift swap?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you would like to request to take this shift?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StandardButton title="Close" onClick={() => setIsOpen(false)} />
          <StandardButton
            title="Confirm"
            accent
            onClick={() => {
              setShifts((val: any) => {
                history.push("/request-swap/request-shift-swap");
                return [...val, shift];
              });
            }}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default RosterDayViewFormRow;
