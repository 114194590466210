import React from "react";
import styles from "./style.module.css"
import { FieldProps } from "formik";
import { InputBase } from "@material-ui/core";

const TextInputFormik = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps) => {

  return (
    <InputBase
      type="text"
      {...field}
      {...props}
      className={styles.outer}
      fullWidth
    />
  );
};

export default TextInputFormik;
