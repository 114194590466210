import React, { useState, useEffect, useCallback } from "react";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { postImage } from "../../../requests/settings";
import { useSnackbar } from "notistack";
import { Box, Button, makeStyles } from "@material-ui/core";
import { getUserDetails } from "../../../requests/user";
import { deleteLogo } from "../../../requests/settings";
import { useProgressBar } from "../../../componentsV3/bars/ProgressBarGlobal";
import { PUBLIC_API_URL } from "../../../ENDPOINTS";
import styles from "../../../css/style.module.css";

const useStyles = makeStyles((theme) => {
  return {
    uploadbtn: {
      "&.Mui-disabled": {
        backgroundColor: '#e0e0e0 !important',
        border: "1px solid transparent !important",
        color: '#fff !important',
      }
    }
  };
});
interface FormInputs {
  offset: string;
}

function TenantLogoForm() {
  const c = useStyles();

  const [selectedImage, setSelectedImage] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>();
  const [userCompanyLogo, setUserCompanyLogo] = useState<any>();
  const [userCompanyName, setUserCompanyName] = useState<any>();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      const formData = new FormData();
      formData.append("myImage", selectedImage);
      imageUpload({ userID, formData });
      getUser();
    }
  }, [selectedImage, setImageUrl]);

  const [userID, setUserID] = useState<any>();

  const getUser = useCallback(async () => {
    try {
      barActivate();
      const u = await getUserDetails();
      setUserID(u?.tenants![0].id);
      setUserCompanyLogo("");
      if (u?.tenants![0].companyLogo) {
        setUserCompanyLogo(u?.tenants![0].companyLogo);
      }
      setUserCompanyName(u?.tenants![0].name);
    } catch (error) {
      console.error(error);
    } finally {
      barStop();
    }
  }, [barActivate, barStop]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const { mutate: imageUpload } = useMutation<unknown, AxiosError, any>(
    ({ userID, formData }) => postImage(userID, formData),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to Uploaded Logo`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        getUser();
        enqueueSnackbar(`Logo Uploaded`, {
          variant: "success",
        });
      },
    }
  );

  const handleDelete = async () => {
    try {
      barActivate();
      const deletedId = await deleteLogo(userID);
      setUserCompanyName(deletedId);
      setUserCompanyLogo(null);
      enqueueSnackbar("Logo deleted", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to deleted logo", { variant: "error" });
    } finally {
      barStop();
    }
  };

  return (
    <form encType="multipart/form-data">
      {imageUrl && selectedImage ? (
        <Box className={styles.tenantlogo}>
          <img src={imageUrl} alt={selectedImage.name} />
        </Box>
      ) : (
        <Box className={styles.tenantlogo}>
          {userCompanyLogo ? (
            <img
              src={
                userCompanyLogo && `${PUBLIC_API_URL}/logo/${userCompanyLogo}`
              }
              alt={userCompanyName}
            />
          ) : (
            <>
              <div className={styles.tenantlogoText}>Upload New Logo</div>
            </>
          )}
        </Box>
      )}
      <Box className={styles.logobtn}>
        <label htmlFor="select-image">
          <input
            accept="image/*"
            id="select-image"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => setSelectedImage(e.target.files![0])}
          />
          <Button
            variant="contained"
            component="span"
            className={styles.uploadbtn}
            disableRipple
            disableElevation
          >
            Upload new logo
          </Button>
        </label>
        <Button
          disabled={!userCompanyLogo}
          variant="contained"
          className={`${c.uploadbtn} ${styles.uploadbtn} ${styles.deletebtn}`}
          onClick={handleDelete}
          disableElevation
          disableRipple
        >
          Delete
        </Button>
      </Box>
    </form>
  );
}

export default TenantLogoForm;
