export const reportData = [
  {
    id: 1,
    name: "Upcoming Shifts Report",
    icon: "/icon/shiftreport.png",
    url: "https://ssrs.proaxiom.tech/ReportServer/Pages/ReportViewer.aspx?%2fRooster+PRD%2fUpcoming+Shift",
    Parameter: ["FromDate", "ToDate", "StaffMember"],
    access: ["admin", "user"],
  },
  {
    id: 2,
    name: "Roster Report",
    icon: "/icon/report.png",
    url: "https://ssrs.proaxiom.tech/ReportServer/Pages/ReportViewer.aspx?%2fRooster+PRD%2fRoster+Report",
    Parameter: ["FromDate", "ToDate", "Roster", "format", "IsDraft"],
    access: ["admin"],
  },
  {
    id: 3,
    name: "Available Shifts Report",
    icon: "/icon/swap.png",
    url: "https://ssrs.proaxiom.tech//ReportServer/Pages/ReportViewer.aspx?%2fRooster+PRD%2fAvailable+Shifts+Report",
    Parameter: ["FromDate", "ToDate"],
    access: [],
  },
  {
    id: 4,
    name: "Available Shift Swap Report",
    icon: "/icon/swap.png",
    url: "https://ssrs.proaxiom.tech/ReportServer/Pages/ReportViewer.aspx?%2fRooster+PRD%2fAvailable+Shift+Swap+report",
    Parameter: ["FromDate", "ToDate", "Roster", "format", "IsDraft"],
    access: ["admin"],
  },
  {
    id: 5,
    name: "Available Shift Swap Report",
    icon: "/icon/swap.png",
    url: "https://ssrs.proaxiom.tech/ReportServer/Pages/ReportViewer.aspx?%2fRooster+PRD%2fAvailable+Shift+Swap+For+User+Report",
    Parameter: ["FromDate", "ToDate"],
    access: ["user"],
  },
  {
    id: 6,
    name: "Shift and hours count",
    icon: "/icon/timer.png",
    url: "https://ssrs.proaxiom.tech/ReportServer/Pages/ReportViewer.aspx?%2fRooster+PRD%2fShifts+And+Hours+Count",
    Parameter: ["FromDate", "ToDate", "Roster", "format"],
    access: ["admin"],
  },

  {
    id: 7,
    name: "Daily Sign On sheet",
    icon: "/icon/sheet.png",
    url: "https://ssrs.proaxiom.tech/ReportServer/Pages/ReportViewer.aspx?%2fRooster+PRD%2fDaily+Sign+On+Sheet",
    Parameter: ["FromDate", "Roster", "format"],
    access: ["admin"],
  },
];
