import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalContainer from "../../../componentsV3/modals/ModalContainer";
import ModalWindowCenter from "../../../componentsV3/modals/ModalWindowCenter";
import SwapShiftCard from "./swapShiftCard";
import { Shift } from "../../RosterScreen/requests";
import { Grid } from "@material-ui/core";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import moment from "moment";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";

const useStyles = makeStyles((theme) => {
  return {
    arrowsContainer: {
      display: "flex",
      flex: 1,
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    buttonsHolder: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: theme.spacing(2),
      flexWrap: "wrap",
    },
    btn: {
      minWidth: 130,
      margin: theme.spacing(0.5),
    },
    btnWarn: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      border: "none",
      "&:focus, &:active": {
        border: "none",
      },
    },
  }
});

interface SwapModalProps {
  onClose: () => void;
  open: boolean;
  currentShift?: Shift;
  swapShift?: Shift[];
  onSubmit?: (p: { currentShift: Shift; swapShift: Shift }) => void;
  onDelete?: (id: number) => void;
  onAccept?: (id: number, swap: boolean) => void;
  offerId?: number;
}

export default function SwapModal({
  onClose,
  open,
  currentShift,
  swapShift,
  onSubmit,
  onDelete,
  onAccept,
  offerId,
}: SwapModalProps) {
  const c = useStyles();
  const currentShiftStartMoment = currentShift
    ? moment(currentShift.startDts)
    : undefined;
  const currentShiftEndMoment = currentShift
    ? moment(currentShift.endDts)
    : undefined;

  function shiftSwapWindow(currentShift?: Shift, swapShift?: Shift[]) {
    if (
      currentShift &&
      swapShift &&
      currentShiftStartMoment &&
      currentShiftEndMoment
    ) {
      return (
        <Grid container spacing={1}>
          {swapShift.map((shift: any) => {
            return (
              <>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <SwapShiftCard shift={currentShift} outlined />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <div className={c.arrowsContainer}>
                    <SwapHorizIcon color="action" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <SwapShiftCard shift={shift} outlined />
                </Grid>
              </>
            )
          })}
        </Grid>
      );
    } else if (swapShift) {
      {
        swapShift.map((shift: any) => {
          return (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <SwapShiftCard shift={shift} outlined />
                </Grid>
              </Grid>
            </>
          )
        })
      }
    } else if (
      currentShift &&
      currentShiftStartMoment &&
      currentShiftEndMoment
    ) {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <SwapShiftCard shift={currentShift} outlined />
          </Grid>
        </Grid>
      );
    }
    return "...";
  }
  return (
    <ModalContainer onClose={onClose} open={open} >
      <ModalWindowCenter>
        {shiftSwapWindow(currentShift, swapShift)}
        <div className={c.buttonsHolder}>
          <StandardButton
            className={`secondary ${c.btn}`}
            title="Cancel"
            onClick={onClose}
          />
          {currentShift && swapShift && onSubmit ? (
            <Fragment>
              <StandardButton
                className={c.btn}
                title="Send Request"
                accent
                onClick={() =>
                  swapShift?.map((shift) =>
                    onSubmit({ currentShift, swapShift: shift })
                  )
                }
              />
            </Fragment>
          ) : null}
          {(currentShift || swapShift) && onDelete && offerId ? (
            <Fragment>
              <StandardButton
                className={`danger ${c.btn} ${c.btnWarn}`}
                title={onAccept ? "Decline offer" : "Cancel shift request"}
                onClick={() => onDelete(offerId)}
              />
            </Fragment>
          ) : null}
          {(currentShift || swapShift) && onAccept && offerId ? (
            <Fragment>
              <StandardButton
                className={c.btn}
                accent
                title="Accept offer"
                onClick={() => onAccept(offerId, swapShift ? true : false)}
              />
            </Fragment>
          ) : null}
        </div>
      </ModalWindowCenter>
    </ModalContainer >
  );
}
