import { Box, Divider, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import ActionIcon from "../../../assets/images/arrow/action-icon.svg";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import Spinner from "../../../componentsV3/Spinner";
import Dropdown, {
  SelectedOption,
} from "../../../componentsV3/inputs/Dropdown";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import ModalContainer from "../../../componentsV3/modals/ModalContainer";
import ModalWindowCenter from "../../../componentsV3/modals/ModalWindowCenter";
import Models from "../../../css/model.module.css";
import styles from "../../../css/style.module.css";
import usePagination from "../../../hooks/usePagination";
import {
  GetSwapOffersResponse,
  OfferStatus,
  deleteOffer,
  getSwapOffers,
} from "../../../requests/offers";
import OfferRow from "./offer-row";
import {
  OffersTable as Table,
  OffersTh as Th,
  OffersThead as Thead,
  OffersTr as Tr,
} from "./styled";

function SwapOffersScreen() {
  const { enqueueSnackbar } = useSnackbar();

  const [offerToDelete, setOfferToDelete] = useState<number | undefined>(
    undefined
  );

  const [status, setStatus] = useState<SelectedOption<OfferStatus | undefined>>(
    {
      title: "All",
      value: undefined,
    }
  );
  const [order, setOrder] = useState<SelectedOption<"DESC" | "ASC">>({
    title: "Desc",
    value: "DESC",
  });
  const [offersCount, setOffersCount] = useState<number>(0);

  const { handlePageChange, page, pages, setTake, take } = usePagination({
    count: offersCount,
    take: 50,
  });

  const { data, isLoading, refetch } = useQuery<
    GetSwapOffersResponse,
    AxiosError
  >(
    ["get-swap-offers-for-admin", take, page, order, status],
    () => {
      const skip = (page - 1) * take;
      return getSwapOffers({
        take,
        skip,
        order: order.value as "DESC" | "ASC",
        status: status.value,
      });
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get scheduled SMS`,
          { variant: "error" }
        );
      },
      onSuccess: (data) => {
        if (data.count) {
          setOffersCount(data.count);
        }
      },
    }
  );

  const { mutate: cancelOffer } = useMutation<unknown, AxiosError, number>(
    (offerId) => {
      return deleteOffer(offerId);
    },
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to cancel the offer`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        setOfferToDelete(undefined);
        await refetch();
        enqueueSnackbar(`Offer cancelled`, {
          variant: "success",
        });
      },
    }
  );

  function onCancelClick(offerId: number) {
    setOfferToDelete(offerId);
  }

  function onCancelConfirm() {
    if (offerToDelete) {
      cancelOffer(offerToDelete);
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <RWrapper>
      <ModalContainer
        open={Boolean(offerToDelete)}
        onClose={() => setOfferToDelete(undefined)}
      >
        <ModalWindowCenter>
          <Box className={Models.new_container}>
            <Box>
              <Box className={Models.titlebox}>
                <Typography className={Models.title} variant="h5">
                  Cancel offer?
                </Typography>
              </Box>
              <Typography variant="body1">
                {" "}
                Confirm that you want to cancel offer "{offerToDelete}"
              </Typography>
            </Box>
            <Box>
              <Box className={Models.buttonsholder}>
                <Box
                  className={`${Models.submitbuttonsholder} ${Models.fillbtn}`}
                >
                  <Box className={Models.cancelbuttonmargin}>
                    <StandardButton
                      className={"secondary"}
                      title="Close"
                      onClick={() => setOfferToDelete(undefined)}
                    />
                  </Box>
                  <StandardButton
                    className={"danger"}
                    title="Delete"
                    onClick={onCancelConfirm}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalWindowCenter>
      </ModalContainer>
      <Box className={styles.pagetitlebox}>
        <Typography variant="h4" color="textPrimary">
          Swap offers
        </Typography>
      </Box>
      <Divider />
      <Box className={`${styles.pagetitlebox} ${styles.secondarytitlebox}`}>
        <Box className={`${styles.rightSide} ${styles.titleboxdropdownbtn}`}>
          <Box className={styles.dropdownholdercontainer}>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={status.title}
                onSelect={(v) =>
                  setStatus(v as SelectedOption<OfferStatus | undefined>)
                }
                options={[
                  { title: "All", value: undefined },
                  { title: "Pending", value: "pending" },
                  { title: "Accepted", value: "accepted" },
                  { title: "Cancelled", value: "cancelled" },
                  { title: "Declined", value: "declined" },
                ]}
              />
            </Box>

            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={`${take}`}
                onSelect={(v) => setTake(v.value as number)}
                options={[
                  { title: "5", value: 5 },
                  { title: "10", value: 10 },
                  { title: "20", value: 20 },
                  { title: "50", value: 50 },
                  { title: "100", value: 100 },
                  { title: "200", value: 200 },
                ]}
              />
            </Box>
            <Box className={styles.dropdownholder}>
              <Dropdown
                textShown={order.title}
                onSelect={(v) => setOrder(v as SelectedOption<"DESC" | "ASC">)}
                options={[
                  { title: "Desc", value: "DESC" },
                  { title: "Asc", value: "ASC" },
                ]}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {data?.count !== 0 ? (
        <div className={styles.tablecontainer}>
          <Table className={styles.swaptable}>
            <Thead>
              <Tr>
                <Th>Offer ID</Th>
                <Th>Offer placed at</Th>
                <Th>Outbound shift</Th>
                <Th>Inbound shift</Th>
                <Th width="70px">Status</Th>
                <Th
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Action
                  <img
                    src={ActionIcon}
                    alt="action"
                    style={{ paddingLeft: 6 }}
                  />
                </Th>
                <Th>Details</Th>
              </Tr>
            </Thead>
            <tbody>
              {data &&
                data.offers.map((offer, index) => (
                  <OfferRow
                    offer={offer}
                    key={offer.id}
                    index={index}
                    onCancelClick={onCancelClick}
                  />
                ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <Box className={styles.commonbox}>
          <h3 style={{ margin: 0 }}>No records found</h3>
        </Box>
      )}

      {data?.count !== 0 ? (
        <Pagination
          className={styles.pagination}
          count={pages}
          page={page}
          onChange={handlePageChange}
        />
      ) : null}
    </RWrapper>
  );
}

export default SwapOffersScreen;
