import React, { ReactNode } from "react";
import styles from "./styles.module.css";
import { Paper } from "@material-ui/core";

const ModalWindow = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {

  return (
    <Paper className={`${styles.container} ${className ? className : ""}`}>
      {children}
    </Paper>
  );
};

export default ModalWindow;
