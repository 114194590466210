import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Divider,
  Typography,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import CalendarHorizontalBase from "../../../components/calendars/CalendarHorizontalBase";
import moment, { Moment } from "moment";
import { Shift } from "../../RosterScreen/requests";
import { UserShiftsToSwapResponse } from "../../../requests/shifts";
import StandardButton from "../../../components/Buttons/StandardButton";
import Card from "./swapShiftCard";
import { useShift } from "../UserRequestSwapScreen/Provider";

interface SwapShiftComponentDesktopProps {
  startMoment: Moment;
  setstartMoment: (m: Moment) => void;
  shiftsToSwap: UserShiftsToSwapResponse;
  selectedShift?: Array<Shift>;
  ownShift: Shift;
  onSelectSwapClick: (s: void) => void;
}

const useStyles = makeStyles((theme) => {
  const cellHeight = theme.typography.fontSize * 5;
  return {
    desktopCalendar: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      // backgroundColor: "red",
      padding: theme.spacing(1),
      boxSizing: "border-box",
      minWidth: "fit-content",
    },
    desktopCalendarContainer: {
      display: "flex",
      flex: 1,
    },
    desktopCalendarHolder: {
      display: "flex",
      width: "100%",
    },
    desktopCalendarNamesColumn: {
      display: "flex",
      flexDirection: "column",
      // backgroundColor: "yellow",
      width: 150,
      overflow: "hidden",
    },
    desktopCalendarColumn: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      // border: "1px solid white",
    },
    desktopCalendarCell: {
      width: "100%",
      minWidth: 82,
      padding: theme.spacing(0.5),
      height: cellHeight,
      // backgroundColor: "black",
      boxSizing: "border-box",
    },
    desktopCalendarCellInner: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      // backgroundColor: "lightgreen",
      fontSize: theme.typography.fontSize,
      whiteSpace: "nowrap",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
      overflow: "hidden",
      transition: `box-shadow ${theme.transitions.duration.shorter}ms`,
    },
    activeShadow: {
      cursor: "pointer",
      "&:hover": {
        boxShadow: theme.shadows[1],
      },
    },
    desktopCalendarControlBar: {
      width: "100%",
      marginBottom: theme.spacing(1),
      // height: cellHeight * 2,
      // backgroundColor: "gold",
    },
    desktopCalendarNavigation: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      height: cellHeight,
    },
    desktopCalendarDescription: {
      display: "flex",
      alignItems: "center",
      flex: 1,
      height: cellHeight,
    },
    desktopCalendarDescriptionCell: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: theme.typography.fontSize,
    },
    monthText: {
      width: 150,
      textAlign: "center",
    },
    arrow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 40,
      height: "50%",
      borderRadius: theme.shape.borderRadius,
      textAlign: "center",
      cursor: "pointer",
      touchAction: "manipulation",
      transition: `all ${theme.transitions.duration.shorter}ms`,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    cardHolder: {
      // backgroundColor: "gold",
      width: "100%",
      padding: theme.spacing(1),
      boxSizing: "border-box",
    },
    cardHolderSelectedCardWrapper: {
      marginTop: theme.spacing(3),
    },
    removeButtonHolder: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      marginTop: theme.spacing(0.3),
    },
    selectButtonHolder: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    titleAboveTheCard: {
      marginBottom: theme.spacing(1),
    },
    titleBelowTheCard: {
      marginTop: theme.spacing(1),
    },
    titleInCard: {
      margin: theme.spacing(2),
    },
  };
});

export default function SwapShiftComponentDesktop({
  startMoment,
  setstartMoment,
  shiftsToSwap,
  selectedShift,
  // setselectedShift,
  ownShift,
  onSelectSwapClick,
}: SwapShiftComponentDesktopProps) {
  const c = useStyles();
  const { shifts, setShifts } = useShift();

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.only("sm"));
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.only("md"));
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.only("lg"));
  const xl = useMediaQuery((theme: Theme) => theme.breakpoints.only("xl"));

  const [amountOfDaysToShow, setamountOfDaysToShow] = useState(7);

  useEffect(() => {
    if (xs) {
      setamountOfDaysToShow(2);
    } else if (sm) {
      setamountOfDaysToShow(3);
    } else if (md) {
      setamountOfDaysToShow(4);
    } else if (lg) {
      setamountOfDaysToShow(7);
    } else if (xl) {
      setamountOfDaysToShow(12);
    }
  }, [xs, sm, md, lg, xl]);

  function dayPlus() {
    setstartMoment(startMoment.clone().add(1, "day"));
  }
  function dayMinus() {
    setstartMoment(startMoment.clone().subtract(1, "day"));
  }
  function monthPlus() {
    setstartMoment(startMoment.clone().add(1, "month"));
  }
  function monthMinus() {
    setstartMoment(startMoment.clone().subtract(1, "month"));
  }

  function getMoment(index: number) {
    return startMoment.clone().add(index, "days");
  }

  function onShiftCellClicked(shift?: Shift) {
    if (shift) {
      if (
        (selectedShift?.length) && selectedShift.includes(shift)) {
        setShifts((current: any) => current.filter(
          (shift: any) => shift.id
        ))
      } else if (shifts.length < 5) {
        setShifts([...shifts, shift])
      } else {
        setShifts([...shifts])
      }
    }
  }

  function onSelectRemoveClick(shift: Shift, id: any) {
    const newShift = shifts.filter(
      (shift: any) => shift.id !== id
    )
    setShifts(newShift)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        <Paper className={c.desktopCalendar} elevation={0}>
          <div className={c.desktopCalendarControlBar}>
            <div className={c.desktopCalendarNavigation}>
              <div className={c.arrow} onClick={dayMinus}>
                {"<"}
              </div>
              <div className={c.arrow} onClick={monthMinus}>
                {"<<"}
              </div>
              <span className={c.monthText}>{startMoment.format("MMMM")}</span>
              <div className={c.arrow} onClick={monthPlus}>
                {">>"}
              </div>
              <div className={c.arrow} onClick={dayPlus}>
                {">"}
              </div>
            </div>
            <Divider />
            <div className={c.desktopCalendarDescription}>
              <div className={c.desktopCalendarNamesColumn}>
                <div className={c.desktopCalendarCell}>
                  <Typography
                    variant="body1"
                    className={c.desktopCalendarDescriptionCell}
                  >
                    Staff Name
                  </Typography>
                </div>
              </div>

              <div className={c.desktopCalendarHolder}>
                {Array.from(Array(amountOfDaysToShow)).map((_, i) => (
                  <div key={i} className={c.desktopCalendarCell}>
                    <div className={c.desktopCalendarDescriptionCell}>
                      <span>{getMoment(i).format("dddd")}</span>
                      <span>{getMoment(i).format("DD MMM")}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Divider />
          </div>
          <div className={c.desktopCalendarContainer}>
            <div className={c.desktopCalendarNamesColumn}>
              {Object.keys(shiftsToSwap).map((k) => (
                <div key={k} className={c.desktopCalendarCell}>
                  <Typography
                    variant="body1"
                    className={c.desktopCalendarCellInner}
                  >
                    {shiftsToSwap[k as any].userName.fullName}
                  </Typography>
                </div>
              ))}
            </div>

            <div className={c.desktopCalendarHolder}>
              <CalendarHorizontalBase
                startMoment={startMoment}
                amountOfDays={amountOfDaysToShow}
                cellComponent={(d, m, i) => (
                  <div
                    className={c.desktopCalendarColumn}
                    key={m.format("YYYY-MM-DD")}
                  >
                    {Object.keys(shiftsToSwap).map((k) => {
                      const shifts =
                        shiftsToSwap[k as any].shifts[m.format("YYYY.MM.DD")];
                      const shift = shifts && shifts[0] ? shifts[0] : undefined;
                      const timeString = shift
                        ? `${moment(shift.startDts).format("HH:mm")} - ${moment(
                          shift.endDts
                        ).format("HH:mm")}`
                        : "";
                      return (
                        <div key={k} className={c.desktopCalendarCell}>
                          <div
                            className={`${c.desktopCalendarCellInner} ${timeString ? c.activeShadow : ""
                              }`}
                            onClick={() => onShiftCellClicked(shift)}
                          >
                            {timeString}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              />
            </div>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper elevation={0} className={c.cardHolder}>
          <div>
            <Typography
              variant="body1"
              className={c.titleAboveTheCard}
              align="center"
            >
              Current Shift
            </Typography>
            {ownShift ? <Card shift={ownShift} /> : null}
          </div>
          <Typography
            variant="body1"
            className={c.titleBelowTheCard}
            align="center"
          >
            Shifts to swap with
          </Typography>
          {selectedShift?.length ?
            selectedShift.map((shift) => ((
              <div className={c.cardHolderSelectedCardWrapper}>
                <Card shift={shift} />
                <div className={c.removeButtonHolder}>
                  <StandardButton
                    title="Remove"
                    onClick={() => onSelectRemoveClick(shift, shift.id)}
                  />
                </div>
              </div>
            ))
            ) : null
          }
          <div className={c.selectButtonHolder}>
            <StandardButton
              title="Select"
              accent
              onClick={() => onSelectSwapClick(shifts)}
              disabled={shifts.length ? false : true}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
