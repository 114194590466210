import React from "react";
import style from "./style.module.css";

const Spinner = () => {
    
    return(
        <>
        <div id={style.loading_bg}>
            <div className={style.loading}>
                <div className={`${style.effect_1} ${style.effects}`}></div>
                <div className={`${style.effect_2} ${style.effects}`}></div>
                <div className={`${style.effect_3} ${style.effects}`}></div>
            </div>
        </div>
        </>
    )
}

export default Spinner;