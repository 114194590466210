import React from "react";
import moment from "moment";
import styled from "styled-components";
import {
  CardTitle,
  CardText,
} from "../../../components/styled-components/text";
import { User } from "../../StaffScreen";
import { Shift } from "../../RosterScreen/requests";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.background.paper};
  padding: ${({ theme }) => theme.gap[1]};
  border: 1px solid ${({ theme }) => theme.colors.greyish};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  width: 300px;
`;

const CardRow = styled.div`
  display: flex;
  width: 100%;
`;

interface OfferSwapCardProps {
  shift: Shift;
  user?: User;
}
function OfferSwapCard({ shift, user }: OfferSwapCardProps) {
  const start = moment(shift.startDts);
  const end = moment(shift.endDts);
  return (
    <Card>
      <CardRow>
        <CardTitle>{`${start.format("ddd DD MMMM YYYY")}`}</CardTitle>
      </CardRow>
      <CardRow>
        <CardText flex={2}>Time</CardText>
        <CardText flex={3}>{`${start.format("HH:mm")} - ${end.format(
          "HH:mm"
        )}`}</CardText>
      </CardRow>
      <CardRow>
        <CardText flex={2}>Shift name</CardText>
        <CardText flex={3}>{shift.shiftName}</CardText>
      </CardRow>
      <CardRow>
        <CardText flex={2}>Location</CardText>
        <CardText flex={3}>{shift.roster?.location?.name}</CardText>
      </CardRow>
      <CardRow>
        <CardText flex={2}>Shift owner</CardText>
        <CardText flex={3}>{user?.fullName}</CardText>
      </CardRow>
    </Card>
  );
}
export default OfferSwapCard;
