import { useSnackbar } from "notistack";
import styles from '../../../../../css/style.module.css'
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useProgressBar } from "../../../../../componentsV3/bars/ProgressBarGlobal";
import { DateNavButtonLeft, DateNavButtonRight, DateNavText } from "../../../../../componentsV3/Buttons/DateNavigationButtons";
import YearCalendar, { YearCalendarMonthStatuses } from "../../../../../componentsV3/calendars/YearCalendar";
import { useUserSelections } from "../../../../../context/userSelectionsProvider";
import { getRosterStatusesYear } from "../../../../RosterScreen/requests";
import { Box, Divider } from "@material-ui/core";

const DateHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
`;

const RosterYearView = () => {
  const { year } = useParams<{ year: string }>();
  const { selectedRosters } = useUserSelections();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [yearToDisplay, setYearToDisplay] = useState<number>(
    year ? Number(year) : new Date().getFullYear()
  );
  const [yearCounter, setYearCounter] = useState<number>(yearToDisplay);

  const [statuses, setStatuses] = useState<YearCalendarMonthStatuses>({});

  const retrieveStatuses = useCallback(async () => {
    if (yearToDisplay && selectedRosters.length) {
      try {
        barActivate();
        const result = await getRosterStatusesYear({
          rosterIds: selectedRosters.map((r) => r.id),
          year: yearToDisplay,
        });
        setStatuses(result);
      } catch (err) {
        enqueueSnackbar("Unable to retrieve year statuses", {
          variant: "error",
        });
      } finally {
        barStop();
      }
    }
  }, [barActivate, barStop, enqueueSnackbar, selectedRosters, yearToDisplay]);

  useEffect(() => {
    retrieveStatuses();
  }, [retrieveStatuses]);

  useEffect(() => {
    const y = Number(year);
    if (y) {
      setYearToDisplay(y);
      setYearCounter(y);
    }
  }, [year]);

  useEffect(() => {
    if (yearCounter === yearToDisplay) {
      return;
    }
    history.push(`/request-swap/year/${yearCounter}`);
  }, [yearCounter, yearToDisplay, history]);

  return (
    <div>
      <Divider className={styles.rsspace} />
      <Box className={styles.framecontainer}>
        <Box className={styles.monthcalenderbox}>
          <Box className={styles.calenderbox} display='flex' justifyContent='center'>
            <DateHolder className={styles.calenderbutton}>
              <DateNavButtonLeft onClick={() => setYearCounter(yearCounter - 1)} />
              <DateNavText>{yearToDisplay}</DateNavText>
              <DateNavButtonRight onClick={() => setYearCounter(yearCounter + 1)} />
            </DateHolder>
          </Box>
        </Box>
        <YearCalendar year={yearToDisplay} statuses={statuses} path='request-swap' />
      </Box>
    </div>
  );
};

export default RosterYearView;
