import React, { useState } from "react";
import logo from "../img/roosterword@2x.png";
import logoold from "../img/rooster_logo_small.png";
import style from "./style.module.css";

export default ({ height = "8em" }) => {
  const [old, setold] = useState(false);

  let timeout: any;
  let timeoutToCancel: any;
  let count: number = 0;
  const countsToChange: number = 7;

  const logoClicked = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      count = 0;
    }, 500);
    count++;
    if (count === countsToChange) {
      setold(true);
      clearTimeout(timeoutToCancel);
      timeoutToCancel = setTimeout(() => {
        setold(false);
      }, 3000);
    }
  };
  return (
    <img
      src={old ? logoold : logo}
      alt=""
      className={style.logo}
      style={{ height: height }}
      onClick={logoClicked}
    />
  );
};
