import moment from "moment";
import { hoursMinutesRegex } from "../GLOBALS";
import { WrongFormatError } from "../errors/parametersErrors";

export const calculateDuration = (time1: string, time2: string) => {
  const a = moment(time1, "HH:mm");
  const b = moment(time2, "HH:mm");
  let d = b.diff(a, "minutes");
  if (d < 0) {
    b.add(1, "day");
  }
  d = b.diff(a, "minutes");
  return d;
};

export const calculateTime = (time: string, duration: number) => {
  return moment(time, "HH:mm").add(duration, "minutes").format("HH:mm");
};

export const convertTimeToHHmm = (time: string) => {
  return moment(time, "HH:mm").format("HH:mm");
};

export const timeOneLaterTimeTwo = (time1: string, time2: string) => {
  if (!hoursMinutesRegex.test(time1)) throw new WrongFormatError("time1");
  if (!hoursMinutesRegex.test(time2)) throw new WrongFormatError("time2");
  const a = moment(time1, "HH:mm");
  const b = moment(time2, "HH:mm");
  const d = b.diff(a, "minutes");
  return d < 0;
};
