import React, { useState } from "react";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";
import styles from "../../../../../css/model.module.css";
import styled from "styled-components";
import ModalContainer from "../../../../../componentsV3/modals/ModalContainer";
import ModalWindowCenter from "../../../../../componentsV3/modals/ModalWindowCenter";
import { CircularProgress, Typography, Box } from "@material-ui/core";
import OfferCardsBlock from "../../../../adminView/swapOffersScreen/offer-cards-block";
import StandardButton from "../../../../../componentsV3/Buttons/StandardButton";
import { getSingleSwapOffer, Offer } from "../../../../../requests/offers";


const Container = styled.div`
  font-size: 14px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 8px;
`;
interface ShiftEditOffersProps {
  offer: Offer;
  deleteOffer: (offerId: number) => void;
}
function ShiftEditOfferRow({ offer, deleteOffer }: ShiftEditOffersProps) {
  const [offerToDelete, setOfferToDelete] = useState<number | undefined>(
    undefined
  );
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useQuery<Offer, AxiosError>(
    [`get-single-offer-${offer.id}`],
    () => getSingleSwapOffer(offer.id),
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get swap offer details`,
          { variant: "error" }
        );
      },
    }
  );

  const close = () => {
    setOfferToDelete(undefined);
  };

  if (!data) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }
  return (
    <Container>
      <ModalContainer open={Boolean(offerToDelete)} onClose={close}>
        <ModalWindowCenter>
          <Box className={styles.new_container}>
            <Box>
              <Box className={styles.titlebox}>
                <Typography className={styles.title} variant="h5">Cancel offer?</Typography>
              </Box>
              <Typography variant="body1"> Confirm that you want to cancel offer "{offerToDelete}"</Typography>
            </Box>
            <Box>
              <Box className={styles.buttonsholder}>
                <Box className={`${styles.submitbuttonsholder} ${styles.fillbtn}`} >
                  <Box className={styles.cancelbuttonmargin}>
                    <StandardButton  className={'secondary'} title="Close" onClick={close} />
                  </Box>
                  <StandardButton
                  title="Delete"
                  className={'danger'}
                  onClick={() => {
                    deleteOffer(offer.id);
                    close();
                  }}
                />
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalWindowCenter>
      </ModalContainer>
      <OfferCardsBlock
        offer={data}
        onCancelClick={(offerId) => setOfferToDelete(offerId)}
      />
    </Container>
  );
}

export default ShiftEditOfferRow;
