import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Table,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AxiosError } from "axios";
import { FieldArray, Form, Formik } from "formik";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import { hoursMinutesRegex } from "../../../../GLOBALS";
import {
  DateNavButtonLeft,
  DateNavButtonRight,
  DateNavButtonText,
  DateNavText,
} from "../../../../componentsV3/Buttons/DateNavigationButtons";
import StandardButton from "../../../../componentsV3/Buttons/StandardButton";
import Dropdown from "../../../../componentsV3/inputs/Dropdown";
import ModalContainer from "../../../../componentsV3/modals/ModalContainer";
import ModalWindowCenter from "../../../../componentsV3/modals/ModalWindowCenter";
import { useUserSelections } from "../../../../context/userSelectionsProvider";
import styles from "../../../../css/style.module.css";
import { deleteShifts, postShifts } from "../../../../requests/shifts";
import { styledTheme } from "../../../../themesV3/styledTheme";
import {
  GetRostersShiftsResponse,
  Shift,
  getRostersShifts,
} from "../../requests";
import RosterDayViewFormRow from "./roster-day-view-form-row";

const useStyles = makeStyles((theme) => {
  return {
    checked: {
      "&.Mui-checked": {
        color: "#282828",
      },
    },
  };
});
const DateHolder = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;

const DateTextHolder = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
`;
const Th = styled.th<{ width?: string }>`
  width: ${({ width }) => width};
`;

const ButtonHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FloatingMenu = styled.div<{ x: number; y: number }>`
  display: flex;
  flex-direction: column;
  width: 100px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  position: fixed;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  z-index: 600;
`;

const FloatingMenuItem = styled.div<{ color?: string }>`
  cursor: pointer;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.deepRed};
  color: ${({ theme }) => theme.colors.lightGrey};
  &:hover {
    background-color: ${({ theme }) => theme.colors.deepRed};
  }
`;

const newShiftPlaceholder = {
  available: false,
  current: true,
  endDts: "",
  endTime: "",
  offersIn: [],
  offersOut: [],
  onCall: false,
  recordedEndDts: null,
  recordedStartDts: null,
  rosterId: "",
  rosteredUser: "",
  ownerName: "",
  shiftName: "",
  startDts: "",
  startTime: "",
  status: "draft",
  user: undefined,
  roster: undefined,
};

const validationSchema = yup.object({
  shifts: yup.array(
    yup.object({
      shiftName: yup.string().required("required"),
      rosterId: yup.number().required("required"),
      startTime: yup
        .string()
        .required("required")
        .matches(hoursMinutesRegex, "wrong format"),
      endTime: yup
        .string()
        .required("required")
        .matches(hoursMinutesRegex, "wrong format"),
    })
  ),
});

const RosterDayView = () => {
  const c = useStyles();

  const { year, month, day } = useParams<{
    year?: string;
    month?: string;
    day?: string;
  }>();
  const history = useHistory();
  const { selectedRosters } = useUserSelections();
  const { enqueueSnackbar } = useSnackbar();
  const [rightClickMenu, setRightClickMenu] = useState<{
    index?: number;
    x: number;
    y: number;
  }>({ index: undefined, x: 0, y: 0 });

  const [selectedData, setSelectedData] = useState<Array<any>>([]);

  const [isOpen, setIsOpen] = useState(false);

  const [currentMoment, setCurrentMoment] = useState<Moment | undefined>(
    undefined
  );

  const [monthToShow, setMonthToShow] = useState<number | undefined>(undefined);
  const [yearToShow, setYearToShow] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (year && month && day) {
      setCurrentMoment(moment(`${year}-${month}-${day}`, "YYYY-MM-DD"));
    }
  }, [year, month, day]);

  useEffect(() => {
    const m = Number(month);
    const y = Number(year);
    if (year && y && month && m) {
      if (m && m > 0 && m <= 12) setMonthToShow(m);
      if (y && y >= 2000 && y <= 2100) setYearToShow(y);
    } else {
      const d = new Date();
      setMonthToShow(d.getMonth() + 1);
      setYearToShow(d.getFullYear());
    }
  }, [month, year]);

  const { data: shifts, refetch } = useQuery<
    GetRostersShiftsResponse,
    AxiosError
  >(
    ["get-shifts-for-the-day", currentMoment, selectedRosters],
    async () => {
      return await getRostersShifts({
        rosterIds: selectedRosters.map((r) => r.id),
        date: currentMoment?.format("YYYY-MM-DD") as string,
      });
    },
    {
      keepPreviousData: true,
      initialData: [],
      enabled: Boolean(currentMoment && selectedRosters.length),
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get shifts`,
          { variant: "error" }
        );
      },
    }
  );

  const { mutateAsync } = useMutation<
    unknown,
    AxiosError,
    { deleted: Array<number> }
  >(
    (data) => {
      const promises = [];
      if (data.deleted && data.deleted.length) {
        promises.push(deleteShifts(data.deleted));
      }
      return Promise.all(promises);
    },
    {
      onError: (err) => {
        enqueueSnackbar(`Unable to Delete Shift`, { variant: "error" });
      },
      onSuccess: async () => {
        handleClose();
        setSelectedData([]);
        await refetch();
        enqueueSnackbar(`Shift Deleted Successfully`, {
          variant: "success",
        });
      },
    }
  );

  const { mutate } = useMutation<
    unknown,
    AxiosError,
    { shifts: Array<Shift>; deleted: Array<number> }
  >(
    (data) => {
      const promises = [];
      if (data.shifts.length) {
        promises.push(postShifts(data.shifts));
      }
      if (data.deleted && data.deleted.length) {
        promises.push(deleteShifts(data.deleted));
      }
      return Promise.all(promises);
    },
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to save changes`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        await refetch();
        enqueueSnackbar(`Saved`, {
          variant: "success",
        });
      },
    }
  );

  const onRightClick = (details: {
    id?: number;
    index: number;
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>;
  }) => {
    const { index, event } = details;
    setRightClickMenu({ index, x: event.clientX, y: event.clientY });
  };

  const onClickAway = () => {
    setRightClickMenu({ index: undefined, x: 0, y: 0 });
  };

  const _submit = async (form: { shifts: Array<Shift> }) => {
    if (currentMoment) {
      const withDts = form.shifts?.map((s) => {
        const [sh, sm] = s.startTime!.split(":");
        const [eh, em] = s.endTime!.split(":");

        //Start time
        let st = currentMoment.clone();
        if (s.startDts) st = moment(s.startDts);
        st.set("hours", Number(sh));
        st.set("minutes", Number(sm));

        //end time
        let en = st.clone();

        //logic to set end date
        if (s.startTime && s.endTime && s.startTime > s.endTime) {
          en.add(1, "day");
        }

        en.set("hours", Number(eh));
        en.set("minutes", Number(em));
        s.startDts = st.toDate();
        s.endDts = en.toDate();
        return s;
      });
      const existingIds: Array<number> = [];
      withDts!.forEach((s) => {
        if (s.id) {
          existingIds.push(s.id);
        }
      });
      const deleted = shifts
        ?.filter((s) => !existingIds.includes(s.id!))
        .map((s) => s.id);

      mutate({ shifts: withDts, deleted: deleted as Array<number> });
    }
  };

  const goToPreviousDay = useCallback(() => {
    if (currentMoment) {
      const prevDay = currentMoment
        .clone()
        .subtract(1, "day")
        .format("[year/]YYYY/[month/]M/[day/]D");
      history.push(`/roster/${prevDay}`);
    }
  }, [currentMoment, history]);

  const goToNextDay = useCallback(() => {
    if (currentMoment) {
      const nextDay = currentMoment
        .clone()
        .add(1, "day")
        .format("[year/]YYYY/[month/]M/[day/]D");
      history.push(`/roster/${nextDay}`);
    }
  }, [currentMoment, history]);

  const goToPreviousMonth = useCallback(() => {
    if (currentMoment) {
      const prevMonth = currentMoment
        .clone()
        .subtract(1, "month")
        .format("[year/]YYYY/[month/]M/[day/]D");
      history.push(`/roster/${prevMonth}`);
    }
  }, [currentMoment, history]);

  const goToNextMonth = useCallback(() => {
    if (currentMoment) {
      const nextMonth = currentMoment
        .clone()
        .add(1, "month")
        .format("[year/]YYYY/[month/]M/[day/]D");
      history.push(`/roster/${nextMonth}`);
    }
  }, [currentMoment, history]);

  const handleDialogOpen = React.useCallback(() => setIsOpen(true), []);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleClick = (e: any) => {
    const { checked } = e.target;
    if (shifts !== undefined && checked) {
      setSelectedData(shifts.map((data) => data.id));
    }
    if (!checked) {
      setSelectedData([]);
    }
  };

  const handleCancel = () => {
    handleClose();
    setSelectedData([]);
  };

  const handleDelete = () => {
    const deleted = shifts
      ?.filter((val) => selectedData.includes(Number(val.id)))
      .map((s) => s.id);
    mutateAsync({ deleted: deleted as Array<number> });
  };

  const { pathname } = useLocation();

  const [calendarViewSelectorText, setCalendarViewSelectorText] =
    useState<string>("");

  const adjustCalendarViewSelectorText = useCallback(() => {
    if (pathname.includes("day")) {
      setCalendarViewSelectorText("Day");
    } else if (pathname.includes("month")) {
      setCalendarViewSelectorText("Month");
    } else {
      setCalendarViewSelectorText("Year");
    }
  }, [pathname]);

  useEffect(() => {
    adjustCalendarViewSelectorText();
  }, [adjustCalendarViewSelectorText]);

  const handleCalendarViewSelectChange = useCallback(
    ({ value }: { title: string; value?: number | string }) => {
      const [, , , year, , month] = pathname.split("/");
      if (value === "year") {
        // go current year or year in the pathname
        history.push(`/roster/year/${year ? year : ""}`);
      } else if (value === "month") {
        // go to current month or month in the pathname
        const d = new Date();
        history.push(
          `/roster/year/${year ? year : d.getFullYear()}/month/${month ? month : ""
          }`
        );
      } else if (value === "day") {
        // go to today
        const d = new Date();
        history.push(
          `/roster/year/${d.getFullYear()}/month/${d.getMonth() + 1
          }/day/${d.getDate()}`
        );
      }
    },
    [history, pathname]
  );

  const handleSelectChange = () => {
    setSelectedData([]);
  };

  if (!yearToShow || !monthToShow) {
    return null;
  }

  if (!currentMoment) {
    return null;
  }

  return (
    <Fragment>
      <Divider className={styles.rsspace} />
      <Grid container spacing={2} className={styles.smHeading}>
        <Grid item xs={6} className={styles.smHeadingwrap}>
          <Box className={styles.headingcontainer}>
            <MdOutlineKeyboardArrowLeft
              style={{ cursor: "pointer" }}
              className={styles.headicon}
              onClick={() => history.push(`/`)}
            />
            <Typography variant="h3" align="center" className={styles.heading}>
              Add shift
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className={styles.framecontainer}>
        <Box
          className={`${styles.monthcalenderbox} ${styles.dayviewtopbutton}`}
        >
          <Box className={styles.calenderbox}>
            <Box className={styles.calendermenuoverlay}>
              <Box className={styles.calendermenuoverlaybox}>
                {currentMoment && (
                  <DateHolder className={styles.calenderbutton}>
                    <DateNavButtonLeft onClick={goToPreviousMonth} />
                    <DateTextHolder>
                      <DateNavButtonText
                        onClick={() =>
                          history.push(
                            `/roster/year/${currentMoment.format(
                              "YYYY/[month/]M"
                            )}`
                          )
                        }
                      >
                        {currentMoment.format("MMM YYYY")}
                      </DateNavButtonText>
                    </DateTextHolder>
                    <DateNavButtonRight onClick={goToNextMonth} />
                  </DateHolder>
                )}
                <Box className={styles.calendermenubtn}>
                  <Dropdown
                    textShown={calendarViewSelectorText}
                    onSelect={handleCalendarViewSelectChange}
                    options={[
                      { title: "Year", value: "year" },
                      { title: "Month", value: "month" },
                      { title: "Day", value: "day" },
                    ]}
                  />
                </Box>
              </Box>
              <Box className={styles.calendermenuoverlaybox}>
                {currentMoment && (
                  <DateHolder className={styles.calenderbutton}>
                    <DateNavButtonLeft onClick={goToPreviousDay} />
                    <DateTextHolder>
                      <DateNavText>{currentMoment.format("dddd")}</DateNavText>
                      <DateNavButtonText
                        onClick={() =>
                          history.push(
                            `/roster/year/${currentMoment.format(
                              "YYYY/[month/]M"
                            )}`
                          )
                        }
                      >
                        {currentMoment.format("MMM")},
                      </DateNavButtonText>
                      <DateNavText>{currentMoment.format("D")}</DateNavText>
                    </DateTextHolder>
                    <DateNavButtonRight onClick={goToNextDay} />
                  </DateHolder>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {shifts && (
          <Formik
            initialValues={{ shifts }}
            onSubmit={_submit}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {({ values, dirty, isSubmitting, resetForm, isValid }) => (
              <Form autoComplete="off">
                <FieldArray name="shifts">
                  {({ push, remove }) => (
                    <Fragment>
                      <div
                        className={`${styles.shifttablerow} ${styles.dayviewtablerow}`}
                      >
                        <Table className={styles.shifttable}>
                          <thead>
                            <tr>
                              <Th width={"1%"} />
                              <Th width={"1%"}>
                                <Checkbox
                                  checked={
                                    selectedData.length === shifts.length &&
                                    shifts.length !== 0
                                  }
                                  color="default"
                                  className={`${styles.checkbox} ${c.checked}`}
                                  onClick={(e) => handleClick(e)}
                                />
                              </Th>
                              <Th width={"1%"} />
                              <Th width={"15%"}>Roster</Th>
                              <Th width={"15%"}>Owner Name</Th>
                              <Th width={"10%"}>Assignee</Th>
                              <Th width={"10%"}>Shift name</Th>
                              <Th width={"1%"}>Avail</Th>
                              <Th width={"4%"}>On Call</Th>
                              <Th width={"10%"}>Start</Th>
                              <Th width={"10%"}>End</Th>
                              <Th width={"2%"}>Status</Th>
                              <Th width={"1%"} />
                              {/* <Th width={"78px"}>Editable</Th> */}
                              {/* <Th>
                                <TimeLineHeader>
                                  {Array.from(Array(24).keys()).map((item) => (
                                    <TimeHeaderCell key={item}>
                                      {`${item}`.padStart(2, "0")}
                                    </TimeHeaderCell>
                                  ))}
                                </TimeLineHeader>
                              </Th> */}
                            </tr>
                          </thead>
                          <tbody>
                            <Fragment>
                              {values.shifts &&
                                values.shifts.map((shift, index) => (
                                  <RosterDayViewFormRow
                                    refetchShifts={refetch}
                                    index={index}
                                    key={index}
                                    onRightClick={onRightClick}
                                    selectedData={selectedData}
                                    setSelectedData={setSelectedData}
                                  />
                                ))}
                              {shifts.length > 0 && (
                                <tr
                                  style={{ border: "none" }}
                                  className={styles.tablespace}
                                >
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                </tr>
                              )}
                              <tr
                                style={{ border: "none" }}
                                className={styles.tablespace}
                              >
                                <td>
                                  {rightClickMenu.index !== undefined ? (
                                    <ClickAwayListener
                                      onClickAway={onClickAway}
                                    >
                                      <FloatingMenu
                                        x={rightClickMenu.x}
                                        y={rightClickMenu.y}
                                        onClick={() => {
                                          remove(
                                            rightClickMenu.index as number
                                          );
                                          onClickAway();
                                        }}
                                      >
                                        <FloatingMenuItem
                                          color={styledTheme.colors.deepRed}
                                        >
                                          Remove
                                        </FloatingMenuItem>
                                      </FloatingMenu>
                                    </ClickAwayListener>
                                  ) : null}
                                </td>
                              </tr>
                              <tr />
                            </Fragment>
                          </tbody>
                        </Table>
                      </div>
                      <Box className={styles.shiftbtncontainer}>
                        <ButtonHolder style={{ width: "max-content" }}>
                          <StandardButton
                            type="submit"
                            title="Submit"
                            accent={true}
                            disabled={isSubmitting}
                          />
                          <StandardButton
                            type="button"
                            title="Add shift"
                            onClick={() => push(newShiftPlaceholder)}
                          />
                        </ButtonHolder>
                      </Box>
                    </Fragment>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        )}
      </Box>
      {selectedData && selectedData.length > 0 ? (
        <Box className={styles.dayshiftdeleteboxWrapper}>
          <Box className={styles.dayshiftdeletebox}>
            <Box>{selectedData?.length} selected</Box>
            <Box className={styles.shiftdelete}>
              <Button
                className={styles.shiftbtn}
                style={{ borderColor: "#9A9A9A", color: "#9A9A9A" }}
                variant="contained"
                onClick={handleSelectChange}
              >
                Cancel
              </Button>
              <Button
                startIcon={<RiDeleteBin6Line />}
                className={styles.shiftdeletebtn}
                variant="contained"
                style={{ borderColor: "red", color: "red" }}
                onClick={handleDialogOpen}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}
      <ModalContainer open={isOpen} onClose={handleCancel}>
        <ModalWindowCenter>
          <Box className={styles.shiftdelete}>
            <DialogContent style={{ textAlign: "left", padding: 0 }}>
              <h2>Delete the shift</h2>
              <Typography>
                Are you sure you want to delete the shift?
              </Typography>
            </DialogContent>
            <DialogActions
              style={{
                marginTop: 18,
                padding: 0,
                justifyContent: "flex-end",
              }}
            >
              <Button
                className={styles.shiftbtn}
                style={{ borderColor: "#9A9A9A", color: "#9A9A9A" }}
                variant="contained"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                startIcon={<RiDeleteBin6Line />}
                className={styles.shiftdeletebtn}
                variant="contained"
                style={{ borderColor: "red", color: "red" }}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </DialogActions>
          </Box>
        </ModalWindowCenter>
      </ModalContainer>
    </Fragment>
  );
};

export default RosterDayView;
