import React, { useState } from "react";
import styles from "./styles.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Hidden } from "@material-ui/core";
import  Header from "../header";

interface NavContainerProps {
  children?: Array<JSX.Element> | JSX.Element;
  sideNav: JSX.Element;
}

const useStyles = makeStyles((theme) => {
  const navWidth = 250;
  return {
    container: {
      paddingTop:'77px',
      minHeight: "100%",
      width: "100%",
      display: "flex",
      backgroundColor: theme.palette.background.default,
      position: "relative",
    },
    nav: {
      [theme.breakpoints.up("md")]: {
        width: navWidth,
        flexShrink: 0,
      },
    },
    content: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      position: "relative",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${navWidth}px)`,
      },
    },
  };
});

export default function NavContainer({ children, sideNav }: NavContainerProps) {
  const c = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
    <Header sideNav={sideNav}/>
    
    <div className={styles.container}>
      <nav className={c.nav}>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={open}
            anchor="left"
            onClose={() => setOpen(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            classes={{
              paper: styles.drawerPaper,
            }}
          >
            {sideNav}
          </Drawer>
        </Hidden>

        <Hidden smDown>
          <Drawer
            variant="permanent"
            open={open}
            anchor="left"
            classes={{
              paper: styles.drawerPaper,
            }}
          >
            {sideNav}
          </Drawer>
        </Hidden>
      </nav>

      <main className={c.content}>
        <Hidden mdUp>
          <div className={styles.topBar}>
            <div className={styles.hamburger} onClick={() => setOpen(!open)}>
              <div className={styles.hamburgerLine} />
              <div className={styles.hamburgerLine} />
              <div className={styles.hamburgerLine} />
            </div>
          </div>
        </Hidden>
        {children}
      </main>
    </div>
    </>
  );
}
