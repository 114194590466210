import { FormUser } from ".";
import { authorizedRequest } from "../../httprequests";
import { User } from "../StaffScreen";

interface RequestPostPutUser {
  user: FormUser;
}

export const requestPostUser = (user: FormUser) =>
  authorizedRequest.post<RequestPostPutUser, User>(`/staff/`, { user });

export const requestPutUser = (user: FormUser) =>
  authorizedRequest.put<RequestPostPutUser, User>(`/staff/`, { user });

export const requestGetUser = (reportsToId: number | string) =>
  authorizedRequest.get<User>(`/staff/user/${reportsToId}`);

export const requestReportsToUserData = (userID: number | string) =>
  authorizedRequest.get<any[]>(`/roster/reportsTo/${userID}`);
