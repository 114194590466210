import { Box, Grid, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useMutation } from "react-query";
import { useHistory } from 'react-router-dom';
import tabImg from "../../../assets/images/roosterbox.svg";
import StandardButton from "../../../componentsV3/Buttons/StandardButton";
import { useProgressBar } from "../../../componentsV3/bars/ProgressBarGlobal";
import { RWrapper } from "../../../componentsV3/layout/RWrapper";
import ModalContainer from "../../../componentsV3/modals/ModalContainer";
import ModalWindowCenter from "../../../componentsV3/modals/ModalWindowCenter";
import modalstyle from "../../../css/model.module.css";
import styles from "../../../css/style.module.css";
import { Roster, archiveRoster, deleteRoster, putRestoreArchiveRoster } from "../requests";
import ArchiveMenu from "./menu/ArchiveMenu";


const ArchiveRoster = () => {


    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { barActivate, barStop } = useProgressBar();


    const [rosters, setRosters] = useState<Array<Roster>>([]);

    const retrieveRosters = useCallback(async () => {
        try {
            barActivate();
            const result = await archiveRoster();
            setRosters(result);
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Unable to retrieve Archive Roster", { variant: "error" });
        } finally {
            barStop();
        }
    }, [setRosters, enqueueSnackbar, barStop, barActivate]);

    useEffect(() => {
        retrieveRosters();
    }, [retrieveRosters]);

    const [selectedRosters, setSelectedRosters] = useState<any>();


    const [openRestoreArchiveModal, setOpenRestoreArchiveModal] = useState<boolean>(false);
    const _closeRestoreArchiveModal = () => setOpenRestoreArchiveModal(false);

    const _openRestoreArchiveModal = (id: number) => {

        const selectedRoster = rosters.filter((e) => e.id === id);
        if (selectedRoster.length > 0) {
            setSelectedRosters(selectedRoster[0]);
            setOpenRestoreArchiveModal(true);
        }
    }
    const [openDeleteArchiveModal, setOpenDeleteArchiveModal] = useState<boolean>(false);
    const _closeDeleteArchiveModal = () => setOpenDeleteArchiveModal(false);
    const _openDeleteArchiveModal = (id: number) => {

        const selectedRoster = rosters.filter((e) => e.id === id);
        if (selectedRoster.length > 0) {
            setSelectedRosters(selectedRoster[0]);
            setOpenDeleteArchiveModal(true);
        }
    }

    const { mutateAsync } = useMutation<unknown, AxiosError, any>(
        () => putRestoreArchiveRoster(selectedRosters.id),
        {
            onError: (err) => {
                enqueueSnackbar(
                    "Unable to archived roster",
                    { variant: "error" }
                );
            },
            onSuccess: (response) => {
                enqueueSnackbar("Roster archived", { variant: "success" });
            },
        }
    );

    const handleClick = async () => {
        await mutateAsync({});
        retrieveRosters();
        setOpenRestoreArchiveModal(false);
    }

    const handleDelete = async () => {
        try {
            barActivate();
            await deleteRoster(selectedRosters.id);
            retrieveRosters();
            setOpenDeleteArchiveModal(false);
            enqueueSnackbar("Roster deleted", { variant: "success" });
        } catch (error) {
            console.error(error);
        } finally {
            barStop();
        }
    };

    return (
        <RWrapper>
            <ModalContainer
                onClose={_closeDeleteArchiveModal}
                open={openDeleteArchiveModal}
            >
                <ModalWindowCenter>
                    <Box className={modalstyle.new_container}>
                        <Box className={modalstyle.titlebox}>
                            <Typography className={modalstyle.title} variant="h5">
                                Delete The Roster
                            </Typography>
                        </Box>
                        <Typography variant="h6">
                            This archived roster will be deleted, and once complete <br />will not be recoverable.  Do you want to continue?
                        </Typography>

                        <Box className={modalstyle.buttonsholder}>
                            <Box className={`${modalstyle.submitbuttonsholder} ${modalstyle.fillbtn}`} >
                                <Box className={modalstyle.cancelbuttonmargin}>
                                    <StandardButton
                                        className={"secondary"}
                                        title="No"
                                        onClick={_closeDeleteArchiveModal}
                                    />
                                </Box>
                                <StandardButton
                                    className={"danger"}
                                    title="Yes"
                                    onClick={handleDelete}
                                />
                            </Box>
                        </Box>
                    </Box>
                </ModalWindowCenter>
            </ModalContainer>

            <ModalContainer
                onClose={_closeRestoreArchiveModal}
                open={openRestoreArchiveModal}
            >
                <ModalWindowCenter>
                    <Box className={modalstyle.new_container}>
                        <Box className={modalstyle.titlebox}>
                            <Typography className={modalstyle.title} variant="h5">
                                Restore The Roster
                            </Typography>
                        </Box>
                        <Typography variant="h6">
                            This archived roster will be made active again.<br />  Do you want to continue?
                        </Typography>

                        <Box className={modalstyle.buttonsholder}>
                            <Box className={`${modalstyle.submitbuttonsholder} ${modalstyle.fillbtn}`} >
                                <Box className={modalstyle.cancelbuttonmargin}>
                                    <StandardButton
                                        className={"secondary"}
                                        title="No"
                                        onClick={() => setOpenRestoreArchiveModal(false)}
                                    />
                                </Box>
                                <StandardButton
                                    className={"danger"}
                                    title="Yes"
                                    onClick={handleClick}
                                />
                            </Box>
                        </Box>
                    </Box>
                </ModalWindowCenter>
            </ModalContainer>

            <Box className={styles.pagetitlebox}>
                <BsArrowLeftShort className={styles.backicon} onClick={() => history.push(`/`)} />
                <Box mr={2}>
                    <Typography variant="h4" color="textPrimary">
                        Archived Rosters
                    </Typography>
                </Box>
            </Box>
            {rosters.length ? (
                <Box className={styles.tabcontent}>
                    <Grid container spacing={3}>
                        {rosters.map((r) => (
                            <Grid item xs={12} sm={6} md={6} lg={4} key={r.id}>
                                <Box className={styles.roosbox}>
                                    <Box className={styles.roosboxwrap}>
                                        <Box className={styles.titlebox}>
                                            <Box className={styles.ricon} style={{ background: `${r.color}` }}>
                                                <img src={tabImg} alt="rooster image" />
                                            </Box>
                                            <Box>
                                                <Typography variant="h5">{r.name}</Typography>
                                                <Box className={styles.locbox}>
                                                    <svg
                                                        width="22"
                                                        height="24"
                                                        viewBox="0 0 22 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M11 6C10.2089 6 9.43552 6.2346 8.77772 6.67412C8.11993 7.11365 7.60723 7.73836 7.30448 8.46927C7.00173 9.20017 6.92252 10.0044 7.07686 10.7804C7.2312 11.5563 7.61216 12.269 8.17157 12.8284C8.73098 13.3878 9.44372 13.7688 10.2196 13.9231C10.9956 14.0775 11.7998 13.9983 12.5307 13.6955C13.2616 13.3928 13.8864 12.8801 14.3259 12.2223C14.7654 11.5645 15 10.7911 15 10C15 8.93913 14.5786 7.92172 13.8284 7.17157C13.0783 6.42143 12.0609 6 11 6ZM11 12C10.6044 12 10.2178 11.8827 9.88886 11.6629C9.55996 11.4432 9.30362 11.1308 9.15224 10.7654C9.00087 10.3999 8.96126 9.99778 9.03843 9.60982C9.1156 9.22186 9.30608 8.86549 9.58579 8.58579C9.86549 8.30608 10.2219 8.1156 10.6098 8.03843C10.9978 7.96126 11.3999 8.00087 11.7654 8.15224C12.1308 8.30362 12.4432 8.55996 12.6629 8.88886C12.8827 9.21776 13 9.60444 13 10C13 10.5304 12.7893 11.0391 12.4142 11.4142C12.0391 11.7893 11.5304 12 11 12Z"
                                                            fill="#9B9FA1"
                                                        />
                                                        <path
                                                            d="M11 24C10.158 24.0043 9.32712 23.8068 8.57707 23.4241C7.82703 23.0413 7.1796 22.4844 6.68901 21.8C2.87801 16.543 0.945007 12.591 0.945007 10.053C0.945007 7.38625 2.00437 4.82872 3.89005 2.94304C5.77573 1.05736 8.33326 -0.0019989 11 -0.0019989C13.6668 -0.0019989 16.2243 1.05736 18.11 2.94304C19.9956 4.82872 21.055 7.38625 21.055 10.053C21.055 12.591 19.122 16.543 15.311 21.8C14.8204 22.4844 14.173 23.0413 13.4229 23.4241C12.6729 23.8068 11.8421 24.0043 11 24V24ZM11 2.181C8.91242 2.18338 6.91102 3.01373 5.43488 4.48987C3.95873 5.96602 3.12839 7.96742 3.12601 10.055C3.12601 12.065 5.01901 15.782 8.45501 20.521C8.7467 20.9228 9.12938 21.2498 9.57172 21.4753C10.0141 21.7008 10.5035 21.8183 11 21.8183C11.4965 21.8183 11.986 21.7008 12.4283 21.4753C12.8706 21.2498 13.2533 20.9228 13.545 20.521C16.981 15.782 18.874 12.065 18.874 10.055C18.8716 7.96742 18.0413 5.96602 16.5651 4.48987C15.089 3.01373 13.0876 2.18338 11 2.181V2.181Z"
                                                            fill="#9B9FA1"
                                                        />
                                                    </svg>
                                                    <Typography variant="body1">
                                                        {r.location.name}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={styles.menusbutton}>
                                        <ArchiveMenu
                                            archiveRoster={() => _openRestoreArchiveModal(r.id)}
                                            deleteRoster={() => _openDeleteArchiveModal(r.id)}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ) : <Box className={styles.commonbox}>
                <h3 style={{ margin: 0 }}>No records found</h3>
            </Box>
            }
        </RWrapper >
    )
}

export default ArchiveRoster;