import { Box } from "@material-ui/core";
import React, { ReactNode } from "react";
import { Header, headerHeight } from "../../header";
import styles from "./styles.module.css";

export const RWrapper = ({ children }: { children: ReactNode }) => (
  <Box height="100vh" display="flex" flexDirection="column">
    <Header />
    <div
      className={styles.wrapper}
      style={{ height: `calc(${headerHeight} - 100vh)` }}
    >
      {children}
    </div>
  </Box>
);
